import React, {useEffect, useMemo, useState} from 'react'
import {ColumnInstance, Row, useTable} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {companyColumnsAdmin} from './columns/_columns'
import {KTCard, KTCardBody, KTSVG} from '@metronic/helpers'
import {observer} from "mobx-react";
import {useIntl} from "react-intl";
import {isDemo, isProd} from "@utils/index";
import {Pagination} from "../../../../modules/Pagination";
import moment from "moment";
import {DatePicker} from "antd";
import {MinusOutlined} from "@ant-design/icons";
import localeRu from "antd/es/date-picker/locale/ru_RU";
import {useErrors} from "@providers/context/ErrorsProvider";
import {transactions} from "@store/transactions/transactions";
import {ITransaction} from "@providers/adminTransactions/_models";
import {UsersListLoading} from "@metronic/partials/widgets/tables/_modals/components/loading/UserListLoading";
import {statuses_platforms, statuses_transactions} from "@utils/constants";
import {Statistic} from "@providers/statistic/_request";
import {useNavigate, useParams} from "react-router-dom";
import {Modal} from "react-bootstrap";
import {FilterDropDown} from "./FilterDropDown";
import ArrayToSpanFormatter from "@utils/components/ArrayToSpanFormatter";
import {priceformatter} from "@utils/priceformatter";
import {divideByHundred} from "@utils/numbersOperations";
import {TransactionsSearch} from "./transactionsSearch";
import {ITransactions} from "@providers/statistic/_modals";
import { formatDateRequest } from '@utils/formatDateRequest'


const AdminTable = observer(() => {
    const data: ITransaction[] = transactions.getTransactions
    const columns = useMemo(() => companyColumnsAdmin, [data])

    const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
        columns,
        data,
    })
    const [filtersCount, setFiltersCount] = useState(3)

    const [showFilter, setShowFilter] = useState(false)

    const navigate = useNavigate()
    const {companyId} = useParams()
    const [totals, setTotals] = useState({
        totalSum: 0,
        totalElements: 0
    })
    const intl = useIntl()
    const [currentDate, setCurrentDate] = useState('month')
    const {setError} = useErrors()

    const filters = useMemo(()=>transactions.getFilters, [transactions.getFilters])

    const [confirmLoading, setConfirmLoading] = useState<boolean>(false)

    useEffect(() => {
        updatePage({})
    }, [filters])


    function updatePage(props: any) {
        setConfirmLoading(true)
        const data:ITransactions = {
            fromDate: formatDateRequest( filters.from_date, "fromDate") ,
            toDate:   formatDateRequest( filters.to_date, "toDate") ,
            page: props?.currentPage || 1,
            size: 20,
            statuses:filters.statuses,
            processingTypes: filters.processingTypes
        }

        if(filters.transactionId){
            data.transactionId = filters.transactionId
        }
        Statistic.getTransactions(data).then((result) => {
            if (result?.data?.result.content.length > 0) {
                result?.data?.result.content.map((item: any, index: number) => {
                    if (props?.currentPage > 2) {
                        item['num'] = ((props?.currentPage - 1) * 15) + index + 1
                    } else if (props?.currentPage == 2) {
                        item['num'] = 16 + index
                    } else {
                        item['num'] = index + 1
                    }
                    return item
                })
            }
            setTotals({
                totalSum: result.data.result.totalAmount,
                totalElements: result.data.result.totalElements
            })
            transactions.setTransactionsData(result?.data?.result?.content)
            transactions.setPagination({
                currentPage: props?.currentPage || 1,
                pageSize: 20,
                pages: result.data.result.totalPages,
                last: props?.currentPage === result.data.result.totalPages,
                first: props?.currentPage === 1,
                items: result.data.result.totalElements
            })
            window.scroll({
                top: 0,
                behavior: 'smooth'
            })
        }).catch(err => {
            setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
        }).finally(() => setConfirmLoading(false))

    }


    function replaceFormat(date: any, formatFrom: string, formatTo: string) {
        let newDate = moment(date, formatFrom).format(formatTo)
        return moment(newDate, formatTo)
    }

    const exportTransactions = async () => {
        const pagination = transactions.getPagination
        try {
            setConfirmLoading(true)
            const response = await Statistic.exportTransactions(companyId!, {
                fromDate: formatDateRequest( filters.from_date, "fromDate"),
                toDate: formatDateRequest( filters.to_date, "toDate"),
                page: pagination?.currentPage || 1,
                size: totals.totalElements,
                statuses: filters.statuses,
                processingTypes: filters.processingTypes
            })
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'transactions.xlsx');
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url)
        } catch (err: any) {
            setError({
                status: err?.response?.status,
                message: err?.response?.data?.message,
                isError: true
            })
        } finally {
            setConfirmLoading(false)
        }
    }


    return (
        <>
            <TransactionsSearch setFilters={(val:string)=>transactions.setFilters({...filters, transactionId: val})}/>
            <KTCard>
                <div className='toolbar toolbar-filter justify-content-end px-10 align-items-center'>
                    <p className='mb-0'>{intl.formatMessage({id: 'filter_by'})}</p>
                    <div className='filter_buttons_group'>
                        <button
                            className={`btn btn-sm btn-active-light-primary ${currentDate === 'day' ? 'btn-active' : "fw-normal"}`}
                            onClick={() => {
                                if (currentDate !== 'day') {
                                    setCurrentDate('day')
                                    transactions.setFilters({
                                        ...filters,
                                        from_date: moment().format('YYYY-MM-DD'),
                                        to_date: moment().format('YYYY-MM-DD')
                                    })
                                }
                            }}>
                            {intl.formatMessage({id: 'filter_by_day'})}
                        </button>
                        <button
                            className={`btn btn-sm btn-active-light-primary ${currentDate === 'week' ? 'btn-active' : "fw-normal"}`}
                            onClick={() => {
                                if (currentDate !== 'week') {
                                    setCurrentDate('week')
                                    transactions.setFilters({
                                        ...filters,
                                        from_date: moment().subtract(1, 'week').format('YYYY-MM-DD'),
                                        to_date: moment().format('YYYY-MM-DD')
                                    })
                                }

                            }}>
                            {intl.formatMessage({id: 'filter_by_week'})}
                        </button>
                        <button
                            className={`btn btn-sm btn-active-light-primary ${currentDate === 'month' ? 'btn-active' : "fw-normal"}`}
                            onClick={() => {
                                if (currentDate !== 'month') {
                                    setCurrentDate('month')
                                    transactions.setFilters({
                                        ...filters,
                                        from_date: moment().subtract(1, 'month').format('YYYY-MM-DD'),
                                        to_date: moment().format('YYYY-MM-DD')
                                    })
                                }
                            }}>
                            {intl.formatMessage({id: 'filter_by_month'})}
                        </button>
                    </div>
                    <div className="mx-4" style={{backgroundColor: "rgba(235, 237, 242, 1)", width: "1px", height: "40%"}}/>
                    <div>

                        <DatePicker.RangePicker
                            key={`${filters.from_date}-${filters.to_date}`}
                            style={{border: "none"}}
                            separator={<MinusOutlined/>}
                            locale={localeRu}
                            placeholder={[intl.formatMessage({id: 'calendar_start'}), intl.formatMessage({id: 'calendar_end'})]}
                            className='datepicker_form form-control-lg form-control-solid'
                            defaultValue={[replaceFormat(filters.from_date, 'YYYY-MM-DD', 'DD-MM-YYYY'),
                                replaceFormat(filters.to_date, 'YYYY-MM-DD', 'DD-MM-YYYY')]}
                            format='DD-MM-YYYY'
                            disabledDate={current => {
                                return isDemo() || current && current > moment().endOf('day');
                            }}
                            onKeyDown={(val) => {
                                if (val.code.toLowerCase().includes("key")) {
                                    val.preventDefault()
                                    val.stopPropagation()
                                    return
                                }
                            }}
                            //@ts-ignore
                            onChange={(dates: [any, any], dateStrings: [string, string]) => {
                                if (dates) {
                                    setCurrentDate('')
                                    transactions.setFilters({
                                        ...filters,
                                        from_date: replaceFormat(dateStrings[0], 'DD-MM-YYYY', 'YYYY-MM-DD').format(),
                                        to_date: replaceFormat(dateStrings[1], 'DD-MM-YYYY', 'YYYY-MM-DD').format()
                                    })
                                }

                            }}
                        />


                    </div>
                </div>
                <KTCardBody className='py-4'>
                    {confirmLoading && <UsersListLoading/>}
                    <div className={'d-flex justify-content-between align-items-center'}>
                        <div>
                            <button type='button' className='btn btn-primary px-4 fw-bolder fs-5'
                                    onClick={() => {
                                        navigate(`/dashboard`)
                                    }}>
                                <KTSVG path='/media/icons/duotune/graphs/gra001.svg' className='svg-icon-2'/>
                                Статистика
                            </button>
                            <button disabled={confirmLoading} type='button'
                                    className='btn bg-white text-muted ms-3 px-4 fw-bolder fs-5'
                                    onClick={exportTransactions}>
                                <KTSVG path='/media/icons/duotune/files/fil009.svg' className='svg-icon-2'/>
                                {intl.formatMessage({id: 'EXCEL'})}
                            </button>
                        </div>
                        <div className='d-flex justify-content-end py-5'>
                            <p className='fs-6 fw-semibold text-gray-600 me-10'>{intl.formatMessage({id: 'transactions'})}: <span
                                className='text-gray-800 fs-3'>{totals.totalElements}</span></p>
                            <p className='fs-6 fw-semibold text-gray-600'>{intl.formatMessage({id: 'total_amount'})}: <span
                                className='text-gray-800 fs-3'>{priceformatter(divideByHundred(totals.totalSum))}</span>
                            </p>
                        </div>
                    </div>
                    <div className='d-flex'>
                        <div className='filter_buttons_group my-4 col-lg-6'>

                            <div>
                                <a
                                    href='#'
                                    className='badge bg-light bg-active-primary fw-medium text-primary px-4 py-2'
                                    data-kt-menu-trigger='click'
                                    data-kt-menu-placement='bottom-end'
                                    data-kt-menu-flip='top-end'
                                    onClick={() => setShowFilter(!showFilter)}
                                >
                                    <KTSVG
                                        path='/media/icons/duotune/general/gen031.svg'
                                        className='svg-icon-5 text-primary me-1'
                                    />
                                    {filtersCount > 1 ? "Применено" : "Применен"} {filtersCount} {filtersCount > 1 ? "фильтра" : filtersCount === 1 ? "фильтр" : "фильтров"}
                                </a>
                            </div>

                            <Modal size="sm" show={showFilter} onHide={() => setShowFilter(false)}>
                                <FilterDropDown
                                    show={showFilter} filters={filters} updateFilter={(val: any) => {
                                    setShowFilter(false)

                                    let count = 1
                                    if (val.processingTypes.length > 0) count++
                                    if (val.statuses.length > 0) count++
                                    setFiltersCount(count)
                                    transactions.setFilters(val)
                                }} handleClose={() => setShowFilter(false)}/>
                            </Modal>

                            {!isProd() && <span className="badge px-4 py-2 mt-3"
                                                style={{
                                                    border: "1px solid #F5F8FA",
                                                    color: "rgba(76, 76, 102, 1)"
                                                }}><span
                                style={{color: "rgba(126, 130, 153, 1)"}}>{intl.formatMessage({id: 'PLATFORMS'})}: &nbsp;</span>
                                {
                                    statuses_platforms.length >= 7 ? 'Все' : <ArrayToSpanFormatter
                                        items={statuses_platforms.map(item => item.toUpperCase())}/>
                                }
                            </span>}
                            {filters.statuses?.length > 0 && <span className="badge px-4 py-2 ms-4"
                                                                   style={{
                                                                       border: "1px solid #F5F8FA",
                                                                       color: "rgba(76, 76, 102, 1)"
                                                                   }}><span
                                style={{color: "rgba(126, 130, 153, 1)"}}>{intl.formatMessage({id: 'STATUS'})}: &nbsp;</span>
                                {<ArrayToSpanFormatter
                                    items={statuses_transactions.every((item: any) => filters.statuses.includes(item)) ? [intl.formatMessage({id: "all"})] : filters.statuses.map((item: any) => intl.formatMessage({id: item}))}/>}</span>}
                            {filters.processingTypes?.length > 0 && <span className="badge px-4 py-2 ms-4"
                                                                          style={{
                                                                              border: "1px solid #F5F8FA",
                                                                              color: "rgba(76, 76, 102, 1)"
                                                                          }}><span
                                style={{color: "rgba(126, 130, 153, 1)"}}>{intl.formatMessage({id: 'Cards'})}: &nbsp;</span>
                                {<ArrayToSpanFormatter
                                    items={filters.processingTypes.map((item: any) => intl.formatMessage({id: item.toUpperCase()}))}/>}</span>}

                        </div>
                    </div>

                    {columns.length ? <div className='table-responsive'>
                        <table
                            id='kt_table_users'
                            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                            {...getTableProps()}
                        >
                            <thead>
                            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                {headers.map((column: ColumnInstance<ITransaction>) => (
                                    <CustomHeaderColumn key={column.id} column={column}/>
                                ))}
                            </tr>
                            </thead>
                            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                            {rows.length > 0 ? (
                                rows.map((row: Row<ITransaction>, i) => {
                                    prepareRow(row)
                                    return <CustomRow row={row} key={`row-${i}-${row.id}`} onClick={() => false}/>
                                })
                            ) : (
                                <tr>
                                    <td colSpan={7}>
                                        <div
                                            className='d-flex text-center w-100 align-content-center justify-content-center'>
                                            {intl.formatMessage({id: 'NO_MATCHING'})}
                                        </div>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div> : <div style={{height: "55vh"}} className="d-flex justify-content-center align-items-center">
                        <a href="https://demo.globalpay.uz" target="_blank" className="btn btn-success text-white fs-3">
                            {intl.formatMessage({id: "WATCH_DEMO_COMPANY1"})}
                            <KTSVG path='/media/icons/duotune/arrows/arr001.svg' className='svg-icon-3 ms-3'/>
                        </a>
                    </div>}
                    <Pagination withInfo position='end' infoText='Транзакций' pagination={transactions.getPagination}
                                updatePage={updatePage}/>

                </KTCardBody>
            </KTCard>

        </>
    )
})

export {AdminTable}
