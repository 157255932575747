import React from 'react';
import {useIntl} from "react-intl";
import {KTSVG} from "@metronic/helpers";

interface props {
    isPaid: boolean
}


const PaymentStatus: React.FC<props> = ({
                                            isPaid
                                        }) => {

    const classStatus: string = isPaid ? 'badge-light-success' : 'badge-light-dark'

    const statusIcon: string = isPaid ? '/media/svg/approved.svg' : '/media/icons/duotune/general/calendar-grey.svg'


    const intl = useIntl()

    return (
        <div>
            <div className={`badge rounded-4 ${classStatus} justify-content-start text-left align-content-center p-3`}
                 style={{fontSize: "12px", fontWeight: 500}}>
                {statusIcon && <img src={statusIcon} alt={''} className="me-1" style={{objectFit: 'contain'}}/>}
                <span className={`ms-1 fw-bold`}>{intl.formatMessage({id: isPaid ? 'paid' : 'not_paid'})}</span>
            </div>
        </div>
    );
};

export default PaymentStatus;