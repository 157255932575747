import React, {useState} from 'react';
import {DatePicker} from "antd";
import {MinusOutlined} from "@ant-design/icons";
import localeRu from "antd/es/date-picker/locale/ru_RU";
import localeEn from "antd/es/date-picker/locale/en_US";
import moment from "moment/moment";
import {transactions} from "@store/transactions/transactions";
import {IDatePicker} from "@providers/adminTransactions/_models";

interface props {

}

const DateRangePicker = (props: props) => {
    const filters = transactions.getFilters

    const lang = JSON.parse(localStorage.getItem('i18nConfig') ?? '{}')?.selectedLang
    const [dates, setDates] = useState<IDatePicker>({
        startDate: filters.from_date,
        endDate: filters.to_date
    })

    function replaceFormat(date: any, formatFrom: string, formatTo: string) {
        let newDate = moment(date, formatFrom).format(formatTo)
        return moment(newDate, formatTo)
    }

    function countDays() {
        let a = moment(dates.startDate),
            b = moment(dates.endDate)

        return b.diff(a, 'days')
    }

    return (
        <div className='mb-8'>
            <DatePicker.RangePicker
                style={{border: "none"}}
                separator={<MinusOutlined/>}
                locale={lang === 'ru' ? localeRu : localeEn}
                placeholder={["Start", "End"]}
                className='datepicker_form form-control-lg form-control-solid bg-white-input'
                defaultValue={[replaceFormat(dates.startDate, 'YYYY-MM-DD', 'DD-MM-YYYY'),
                    replaceFormat(dates.endDate, 'YYYY-MM-DD', 'DD-MM-YYYY')]}
                format='DD-MM-YYYY'
                disabledDate={current => {
                    return current && current > moment().endOf('day');
                }}
                //@ts-ignore
                onChange={(dates: [any, any], dateStrings: [string, string]) => {
                    if (dates) {

                        let from_date = replaceFormat(dateStrings[0], 'DD-MM-YYYY', 'YYYY-MM-DD').format()
                        let to_date = replaceFormat(dateStrings[1], 'DD-MM-YYYY', 'YYYY-MM-DD').format()

                        setDates({
                            startDate: from_date,
                            endDate: to_date
                        })


                        transactions.setFilters({
                            ...filters,
                            from_date: from_date,
                            to_date: to_date
                        })

                    }

                }}
            />
        </div>
    );
};

export default DateRangePicker;