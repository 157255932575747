/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link, useParams} from "react-router-dom";
import {observer} from "mobx-react";
import {useIntl} from "react-intl";
import {roleRoutingAdmin} from "@utils/index";
import {employeeStore} from "@store/employee";

type Props = {
    id: number
    text: string
}

const ContractLink: FC<Props> = observer(({id, text}) => {
    const {companyId} = useParams()

    return (
        <div className='fs-6 text-gray-600 fw-normal'>
            <Link to={`/${companyId}/autopay/contracts/${id}`} >
                {text}
            </Link>
        </div>
    )



})

export {ContractLink}
