import * as Yup from 'yup'
import {CreateCashbox} from "@providers/filial/_models";
import {useIntl} from "react-intl";
import {FILE_SIZE, SUPPORTED_FORMATS_IMAGES} from "@utils/constants";


function FilialSettingsSchema() {
    const intl = useIntl()

    const createFilialSettingsSchema = Yup.object({
        iconId: Yup.mixed().test('fileSize', "File is too large", value => {
            if (value !== null && typeof value === 'object') {
                const size_kb = +(value.size / 1024).toFixed(1)
                return size_kb <= FILE_SIZE
            }
            return true
        }).test('fileType', "Your Error Message", value => {
            if (typeof value === 'object') {
                return value ? SUPPORTED_FORMATS_IMAGES.includes(value.type) : true
            }
            return true
        }),
        name: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
            .max(50, intl.formatMessage({id: 'ERROR_VALIDATION_SYMBOLS_MAX'})).required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
        legalName: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
            .max(50, intl.formatMessage({id: 'ERROR_VALIDATION_SYMBOLS_MAX'})).required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
        maxAmount: Yup.string()
            .max(10, intl.formatMessage({id: 'ERROR_VALIDATION_SYMBOLS_MAX'}))
            .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
        minAmount: Yup.string()
            .max(10, intl.formatMessage({id: 'ERROR_VALIDATION_SYMBOLS_MAX'}))
            .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
        gnkSpic: Yup.string()
            .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
        packageCode: Yup.string()
            .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
        ndsRate: Yup.string()
            .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
        pinfl: Yup.string().min(14, 'Не достаточно символов'),
        humoTid: Yup.string()
            .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
        humoMid: Yup.string()
            .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
        uzcardTid: Yup.string()
            .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
        uzcardMid: Yup.string()
            .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
    })

    return createFilialSettingsSchema

}


const inits: CreateCashbox = {
    name: '',
    legalName: '',
    logoId: '',
    fixedPrice: 0,
    minAmount: 0,
    maxAmount: 0,
    uzcardMid: '',
    uzcardTid: '',
    humoMid: '',
    humoTid: '',
    billingUrl: '',
    gnkSpic: '',
    packageCode: '0',
    serviceCode: '',
    includeGnk: true,
    paymentInstrument: 'DIRECT',
    comission: 0,
    description: '',
    pinfl: '',
    providerId: 0,
    active: true,
    ndsRate: 0,
    vmEnabled: false
}


export {FilialSettingsSchema, inits}