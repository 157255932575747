import OtpInput from "react-otp-input";
import clsx from "clsx";
import Countdown, {zeroPad} from "react-countdown";
import React, {useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useIntl} from "react-intl";
import {Auth} from "@providers/auth/_request";
import {useAuth} from "../core/Auth";
import {useErrors} from "@providers/context/ErrorsProvider";


interface Props {
    setLoading: (val: boolean) => void
    setAccessToken: (val: string) => void
    setUserBlock: (val: boolean) => void
    setPasswordSetupBlock: (val: boolean) => void
    setCounter: (val: number) => void
    setLastDateOtp: (val: string) => void
    setSaveLastDate: (val: string) => void
    diffTimeBlock: (val: string) => string
    loading: boolean
    didForgotPassword: boolean
    userBlock: boolean
    confirm: boolean
    accessToken: string | null
    counter: number
}

interface ICompletion {
    minutes: number,
    seconds: number,
    completed: boolean
}


export function OtpInputComponent({
                                      setLoading,
                                      loading,
                                      accessToken,
                                      counter,
                                      setAccessToken,
                                      didForgotPassword,
                                      setPasswordSetupBlock,
                                      setCounter,
                                      diffTimeBlock,
                                      setLastDateOtp,
                                      setSaveLastDate,
                                      setUserBlock,
                                      userBlock,
                                      confirm
                                  }: Props) {

    const intl = useIntl()
    const [otp, setOtp] = useState('')
    const [otpError, setErrorOtp] = useState('')
    const [leftAttempts, setLeftAttempts] = useState(2)
    const [showResend, setShowResend] = useState(false)

    const {setError} = useErrors()

    const {saveAuth} = useAuth()


    const dateExpire = React.useMemo(() => {
        return Date.now() + 60000;
    }, [counter])


    const initialValues = {
        otp: ''
    }

    const loginSchema = Yup.object().shape({
        otp: Yup.string()
            .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
    })

    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {

        },
    })


    const confirmOtp = async (value: string) => {
        setLoading(true)

        try {
            if (accessToken) {
                Auth.confirm(accessToken, value)
                    .then(async (response) => {
                        setLoading(false)
                        if (didForgotPassword) {
                            setAccessToken(response.data.result.access_token)
                            setPasswordSetupBlock(true)
                        } else {
                            saveAuth(response.data.result)
                        }

                    }).catch(e => {
                    const fields = e?.response?.data?.fields || []
                    const lastResend = fields.find((item: any) => item?.name === 'lastOtpResend')?.error || null
                    const loginAttempts = fields.find((item: any) => item?.name === 'loginAttempts')?.error || null

                    if (loginAttempts) {
                        setLeftAttempts(Number(loginAttempts))
                    }

                    if (Number(loginAttempts) === 0 && lastResend) {
                        setSaveLastDate(lastResend)
                        setLastDateOtp(diffTimeBlock(lastResend))
                        setUserBlock(true)
                        setShowResend(false)
                        return
                    } else {
                        setShowResend(true)
                    }
                    setLoading(false)

                    if (e?.response?.status === 400) {
                        setErrorOtp(intl.formatMessage({id: 'not-code'}))
                    } else {
                        setError({status: e?.response?.status, message: e?.response?.data?.message, isError: true})
                    }

                })
            } else {
                setErrorOtp(intl.formatMessage({id: 'not-code'}))
                setLoading(false)

            }
        } catch (error) {
            saveAuth(undefined)
            setError({status: 0, message: 'Что-то пошло не так', isError: true})
            setLoading(false)
        }
    }

    const resendOtp = async () => {

        setLoading(true)

        await Auth.resend(accessToken || '')
            .then((response) => {

                setLoading(false)
                setErrorOtp('')
                setOtp('')
                setCounter(counter + 1)
                setShowResend(false)
            }).catch(e => {
                setLoading(false)
                setErrorOtp(intl.formatMessage({id: 'not-code'}))
                setError({status: e?.response?.status, message: e?.response?.data?.message, isError: true})
            })


    }


    const Completionist = () =>
        <div className='text-center mt-3'>
            {leftAttempts === 0 ? <p className='mb-7'>{intl.formatMessage({id: 'WARNING_ERROR'})}</p> : ''}
            <button
                type='button'
                id='kt_sign_in_submit'
                className='btn btn-lg btn-primary w-100 mb-5'
                disabled={leftAttempts === 0 || loading}
                onClick={() => resendOtp()}
            >

                {!loading && <span className='indicator-label'>{intl.formatMessage({id: 'RESEND'})}</span>}
                {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
                )}
            </button>

            <p>Осталось {leftAttempts} попыток</p>

        </div>;

    const renderer = ({minutes, seconds, completed}: ICompletion) => {
        if (completed) {
            return
        }
        return (
            <div style={{textAlign: "center", fontSize: "16px", marginTop: "10px"}}>
                {zeroPad(minutes)}:{zeroPad(seconds)}
            </div>
        );

    };

    return (
        <form
            className='card rounded-3 w-md-550px py-10'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_otp_form'
        >

            <div className='fv-row'>
                {confirm && !userBlock ? (<div className='fv-row mb-10'>
                    <div className='d-flex justify-content-center mt-n5'>
                        <div className='mb-10'>
                            {!!otpError.length ? (
                                <h3 className='fw-bolder text-dark text-center'>
                                    {otpError}
                                </h3>
                            ) : (<><h3
                                className='fw-bolder text-dark text-center'>{intl.formatMessage({id: 'SEND_CODE'})}</h3></>)}

                        </div>
                    </div>

                    <OtpInput
                        {...formik.getFieldProps('otp')}
                        value={otp}
                        className={!otpError.length ? clsx(
                            'form-control form-control-lg form-control-solid form-control-otp'
                        ) : clsx(
                            'form-control form-control-lg form-control-solid form-control-otp bg-light-danger form-otp-error'
                        )}
                        shouldAutoFocus={true}
                        numInputs={8}
                        isInputNum={true}
                        onChange={async (otp: any) => {
                            if (!otp.length || otp.match(/^\d/) && !otpError.length) {
                                setOtp(otp)
                                await formik.setFieldValue('otp', otp)
                                if (otp.length === 8 && leftAttempts !== 0) {
                                    await confirmOtp(otp)
                                }
                            }
                        }}
                        separator={<span></span>}
                    />

                    {showResend ? <Completionist/> : <Countdown date={dateExpire} renderer={renderer} key={counter}/>}


                </div>) : ''}
            </div>

        </form>
    )
}