/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import moment from "moment";

type Props = {
    created_date: string
}



const EmployeeLogsDate: FC<Props> = ({created_date}) => {

    return (
        <div className='d-flex align-items-center'>

            <div className='d-flex flex-column'>
                {moment(created_date).format('YYYY-MM-DD HH:mm')}
            </div>
        </div>
    )
}

export {EmployeeLogsDate}
