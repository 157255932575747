import * as Yup from "yup";
import moment from "moment/moment";
import {ContractEditItemRequest, ContractItemResponse} from "@providers/autopay/models/_models";


const editContractSchemas = Yup.object({
    id: Yup.number().required('Обязательное поле').max(99999999999999, 'Слишком много символов').required('Обязательное поле'),
    contractNumber: Yup.string().required('Обязательное поле')
        .max(150, 'Слишком много символов').required('Обязательное поле'),
    contractDate: Yup.date().max(moment().subtract(1, 'days').format('YYYY-MM-DD')).required('Обязательное поле'),
    merchantId: Yup.string().required('Обязательное поле')
        .max(150, 'Слишком много символов').required('Обязательное поле'),
    clientId: Yup.string().required('Обязательное поле')
        .max(150, 'Слишком много символов').required('Обязательное поле'),
    clientNumber: Yup.string()
        .max(17, 'Слишком много символов')
        .required('Обязательное поле'),
    productName: Yup.string().required('Обязательное поле')
        .max(150, 'Слишком много символов').required('Обязательное поле'),
    serviceId: Yup.number().required('Обязательное поле').max(99999999999999, 'Слишком много символов').required('Обязательное поле'),
    contractType: Yup.bool(), //credit and subscription
    contractVersion: Yup.object({
        id: Yup.number().required('Обязательное поле').max(99999999999999, 'Слишком много символов').required('Обязательное поле'),
        previousContractVersionId: Yup.number().required('Обязательное поле').max(99999999999999, 'Слишком много символов').required('Обязательное поле'),
        contractId: Yup.number().required('Обязательное поле').max(99999999999999, 'Слишком много символов').required('Обязательное поле'),
        productPrice: Yup.number().required('Обязательное поле').max(99999999999999, 'Слишком много символов').required('Обязательное поле'),
        paidSum: Yup.number().required('Обязательное поле').max(99999999999999, 'Слишком много символов').required('Обязательное поле'),
        prepaymentPrice: Yup.number().required('Обязательное поле').max(99999999999999, 'Слишком много символов').required('Обязательное поле'),
        discountPrice: Yup.number().required('Обязательное поле').max(99999999999999, 'Слишком много символов').required('Обязательное поле'),
        otpToken: Yup.string(),
        otpCheckTime: Yup.string(),
        overdueLimit: Yup.number().required('Обязательное поле').max(99999999999999, 'Слишком много символов').required('Обязательное поле'),
        status: Yup.string(), //[ ACTIVE, OUTDATED, TERMINATED, UNVERIFIED, CASH_PAID, FINISHED ]
        schedules: Yup.array().of(Yup.object({
            scheduleDate: Yup.date().max(moment().subtract(1, 'days').format('YYYY-MM-DD')).required('Обязательное поле'),
            paymentSum: Yup.string().required('Обязательное поле')
                .max(150, 'Слишком много символов').required('Обязательное поле'),
        })),
        cards: Yup.array().of(Yup.object({
            cardToken: Yup.string().required('Обязательное поле')
                .max(150, 'Слишком много символов').required('Обязательное поле'),
        })),
    }),
})


const inits: ContractItemResponse = {
    "id": 0,
    "contractNumber": "string",
    "contractDate": moment().format('YYYY-MM-DD'),
    "merchantId": "string",
    "clientId": "string",
    "clientNumber": "string",
    "productName": "string",
    "serviceId": 0,
    "contractType": "CREDIT",
    "contractVersion": {
        "id": 0,
        "previousContractVersionId": 0,
        "contractId": 0,
        "productPrice": 0,
        "paidSum": 0,
        "prepaymentPrice": 0,
        "discountPrice": 0,
        "otpToken": "string",
        "otpCheckTime": moment().format('YYYY-MM-DD'),
        "overdueLimit": 0,
        "status": "ACTIVE",
        "schedules": [
            {
                "scheduleDate": moment().format('YYYY-MM-DD'),
                "paymentSum": 0
            }
        ],
        "cards": [
            {
                "cardToken": "string"
            }
        ]
    }
}

// const inits: ContractItemResponse = {
//     "id": 0,
//     "contractNumber": "",
//     "contractDate": moment().format('YYYY-MM-DD'),
//     "merchantId": "",
//     "clientId": "",
//     "clientNumber": "",
//     "productName": "",
//     "serviceId": 0,
//     "contractType": "",
//     "contractVersion": {
//         "id": 0,
//         "previousContractVersionId": 0,
//         "contractId": 0,
//         "productPrice": 0,
//         "paidSum": 0,
//         "prepaymentPrice": 0,
//         "discountPrice": 0,
//         "otpToken": "string",
//         "otpCheckTime": moment().format('YYYY-MM-DD'),
//         "overdueLimit": 0,
//         "status": "",
//         "schedules": [
//             {
//                 "scheduleDate": moment().format('YYYY-MM-DD'),
//                 "paymentSum": 0
//             }
//         ],
//         "cards": [
//             {
//                 "cardToken": ""
//             }
//         ]
//     }
// }


const editContractRequestSchemas = Yup.object({
    discountPrice: Yup.string().required('Обязательное поле')
        .max(150, 'Слишком много символов').required('Обязательное поле'),
    overdueLimit: Yup.string().required('Обязательное поле')
        .max(150, 'Слишком много символов').required('Обязательное поле'),
    schemas: Yup.array().of(Yup.object({
        scheduleDate: Yup.date().max(moment().subtract(1, 'days').format('YYYY-MM-DD')).required('Обязательное поле'),
        paymentSum: Yup.string().required('Обязательное поле')
            .max(150, 'Слишком много символов').required('Обязательное поле'),
    })),
    cards: Yup.array().of(Yup.object({
        cardToken: Yup.string().required('Обязательное поле')
            .max(150, 'Слишком много символов').required('Обязательное поле'),
    })),
})

const editInits: ContractEditItemRequest = {
    "discountPrice": 0,
    "overdueLimit": 0,
    "schedules": [
        {
            "scheduleDate": moment().format('YYYY-MM-DD'),
            "paymentSum": 0
        }
    ],
    "cards": [
        {
            "cardToken": ""
        }
    ]
}


const dateFormat = 'DD-MM-YYYY'

export {inits, editInits, editContractSchemas, editContractRequestSchemas, dateFormat}