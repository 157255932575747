/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {ModSession} from "@providers/session/_modals";
const platform = require('platform')

type Props = {
  userAgent: string
}



const UserInfoCell: FC<Props> = ({userAgent}) => {

    var info = platform.parse(userAgent)


    return (
        <div className='d-flex align-items-center'>

            <div className='d-flex flex-column'>
                <a href='#' className='text-gray-800 text-hover-primary mb-1'>
                    {`${info?.manufacturer??''} ${info?.name??''} ${info?.os?.family??''} ${info?.os?.version??''}`}
                </a>
            </div>
        </div>
    )
}

export {UserInfoCell}
