import React, {FC, useEffect} from 'react'
import {KTCard} from "@metronic/helpers";
import {Table} from "./_modals/Table";



const Payments:FC = ()=>{


  return (
      <>
            <div>
                <Table />
            </div>
        </>
  )
}

export default function PaymentsMerchant(){
    return(
        <>
            <Payments />
        </>
    )
}