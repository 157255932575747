import React, {FC} from 'react'
import {ListViewProvider, useListView} from "./_modals/providers/ListViewProvider";
import {KTCard} from "@metronic/helpers";
import {UserEditModal} from "./_modals/user-edit-modal/UserEditModal";
import {ErrorProvider} from "@providers/context/ErrorsProvider";
import {Navigate, useOutletContext, useParams} from "react-router-dom";
import {roleRouting} from "@utils/index";
import {useIntl} from "react-intl";
import {AdminTable} from "./_modals/AdminTable";
import {UsersListToolbar} from "./_modals/table-components/header/UserListToolbar";
import {PageTitleCustom} from "@metronic/partials/pageTitleCustom";


const Admins:FC = ()=>{
    const {itemIdForUpdate} = useListView()

    return (
        <>
            <div className='card-header border-0 pt-6 pb-6'>

                <div className='card-toolbar'>
                    <UsersListToolbar />
                </div>
            </div>
            <KTCard>
                <AdminTable />
            </KTCard>

            {itemIdForUpdate !== undefined && <UserEditModal />}
        </>
    )
}

export default function EmployeesCompany(){
    //@ts-ignore
    const {role} = useOutletContext()
    const intl = useIntl()
    const {type} = useParams()

    if(role){
        if(roleRouting('employees', role)){
            return(
                <>
                    <PageTitleCustom title={intl.formatMessage({id: 'list_admins'})} breadCrumbs={[]}/>

                    <ListViewProvider>
                        <ErrorProvider>
                            <Admins />
                        </ErrorProvider>
                    </ListViewProvider>
                </>
            )
        }

        return <Navigate to='/error/404'/>
    }

    return <></>



}