import React, {useEffect, useState} from "react";
import {ErrorProvider, useErrors} from "@providers/context/ErrorsProvider";
import {ClientInfo} from "../clientsList/_modals/page-tabs/clientInfo/clientInfo";
import {PageLink, PageTitle} from "@metronic/layout/core";
import {KTCard, KTSVG} from "@metronic/helpers";
import {Nav} from "react-bootstrap";
import {ListViewProvider} from "../../../companies/_modals/providers/ListViewProvider";
import {PageTitleCustom} from "@metronic/partials/pageTitleCustom";
import {isDemo, NumReplace, ReplacePhone, roleRouting, roleRoutingAdmin} from "@utils/index";
import {useIntl} from "react-intl";
import {employeeStore} from "@store/employee";
import {Company} from "@providers/company/_request";
import {Dropdown, Tooltip} from "antd";
import {ClientService} from "@providers/autopay/requests/client.service";
import {useParams} from "react-router-dom";
import {IClientInfo, IClientStatistic, UpdateInfoClient} from "@providers/autopay/models/_models";
import ContractsListClient from "../clientsList/_modals/page-tabs/contractsList/contractsList";
import {UsersListLoading} from "@metronic/partials/widgets/tables/_modals/components/loading/UserListLoading";


const MainClientInfo = () =>{
    // const [companyData, setCompanyData] = useState<CompanyResponse | null>(null)
    // const {id} = useParams()
    const [activeKey, setActiveKey] = useState<string | null>('info')
    const intl = useIntl()
    const {pinfl,companyId} = useParams()
    const [clientInfo,setClientInfo] = useState<IClientInfo|null>(null)
    const [clientStatistic, setClientStatistic] = useState<IClientStatistic|null>(null)
    const {setError} = useErrors()
    const [loaded, setLoaded] = useState<boolean>(false)
    // const [searchParams, setSearchParams] = useSearchParams()

    useEffect(()=>{
        getClientByPinfl()
    },[])





    const classStatus = {
        DRAFT: 'secondary',
        ACTIVE: 'light-success',
        DISABLED: 'light-danger',
    }

    async function getClientByPinfl() {
        if(pinfl) {
            await ClientService.getClientStatisticsByPINFL(pinfl)
                .then((result)=>{
                    setClientInfo(result.data.data.client)
                    setClientStatistic(result.data.data.statistics)
                }).catch((err: any) => {
                    setError({message: err?.response?.data?.message, isError: true, status: err?.response?.status})
                }).finally(() => setLoaded(true))
        }
    }

    const breadCrumbsClientMain: Array<PageLink> = [
        {
            title:"Автосписание",
            path:"",
            isSeparator: false,
            isActive: false
        },
        {
            title:"Список пользователей",
            path:`/${companyId}/autopay/clients`,
            isSeparator: false,
            isActive: false
        },
        {
            title:pinfl?pinfl+'':'',
            path:``,
            isSeparator: true,
            isActive: true
        }
    ]

    if(!loaded) return <UsersListLoading/>

    const switchTabs = () => {
        switch (activeKey) {
            case 'info':
                return <ClientInfo clientInfo={clientInfo} updateClient={getClientByPinfl} />
            case 'contract':
                return <ContractsListClient />
        }
    }



    return (
        <>
            <PageTitleCustom title={'Данные клиента'} breadCrumbs={breadCrumbsClientMain}/>
            <KTCard className='mb-8'>
                <div className='card mb-0'>
                    <div className='card-body pt-9 pb-0'>
                        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                            <div className='me-7 mb-4'>
                                <div className='p-3 d-flex flex-column justify-content-center symbol logo-project bg-secondary symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                                    <p style={{fontSize: '4rem'}} className='text-center mb-0 fw-bolder text-primary'>{(clientInfo?.firstName||'').split(' ').map((item:string)=>item.length?item[0].toUpperCase():item).join('')}{(clientInfo?.lastName||'').split(' ').map((item:string)=>item.length?item[0].toUpperCase():item).join('')}</p>
                                </div>
                            </div>

                            <div className='flex-grow-1'>
                                <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                                    <div className='d-flex flex-column'>
                                        <div className='d-flex align-items-center mb-2'>
                                            <p className='text-gray-800 fs-2 fw-bolder me-1 mb-0'>
                                                {`${clientInfo?.firstName} ${clientInfo?.lastName} ${clientInfo?.middleName}`}
                                            </p>
                                            {/*@ts-ignore*/}
                                            <div className={`badge mx-5`}>

                                                {/*{companyData?.status?*/}
                                                {/*    (*/}

                                                {/*        <>{roleRoutingAdmin('action', employeeStore.getRole, 'change-status')*/}
                                                {/*            ?(<Dropdown overlay={widgetMenu}  open={modalIsOpen} >*/}
                                                {/*                <a onClick={(e) => {*/}
                                                {/*                    e.preventDefault()*/}
                                                {/*                    setModalIsOpen(!modalIsOpen)*/}
                                                {/*                }}>*/}
                                                {/*                    /!*@ts-ignore*!/*/}
                                                {/*                    <div  className={`badge badge-${classStatus[companyData?.status||'']} fw-bolder`}>*/}
                                                {/*                        /!*@ts-ignore*!/*/}
                                                {/*                        <KTSVG path={iconsStatus[companyData?.status]} className={`svg-icon-${classStatus[companyData?.status]} svg-icon-2hx me-2`} />*/}
                                                {/*                        {intl.formatMessage({id:companyData?.status})}*/}
                                                {/*                    </div>*/}
                                                {/*                </a>*/}
                                                {/*            </Dropdown>):(*/}
                                                {/*                /*@ts-ignore*/}
                                                {/*                <div  className={`badge badge-${classStatus[companyData?.status||'']} fw-bolder`}>*/}
                                                {/*                    /!*@ts-ignore*!/*/}
                                                {/*                    <KTSVG path={iconsStatus[companyData?.status]} className={`svg-icon-${classStatus[companyData?.status]} svg-icon-2hx me-2`} />*/}
                                                {/*                    {intl.formatMessage({id:companyData?.status})}*/}
                                                {/*                </div>)*/}
                                                {/*        }*/}
                                                {/*        </>*/}
                                                {/*    ) :''}*/}

                                            </div>
                                        </div>

                                        <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                                            <a
                                                href={clientInfo?.phoneNumber?`tel:${clientInfo?.phoneNumber}`:'/'}
                                                className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                                            >
                                                <KTSVG
                                                    path='/media/icons/phone.svg'
                                                    className='svg-icon-4 me-1'
                                                />

                                                {ReplacePhone(clientInfo?.phoneNumber||'')}
                                            </a>
                                        </div>
                                    </div>

                                </div>

                                <div className='d-flex flex-wrap flex-stack'>
                                    <div className='d-flex flex-column flex-grow-1 pe-8'>
                                        <div className='d-flex flex-wrap'>
                                            <div className='border border-gray-300 border-dashed rounded min-w-105px d-flex flex-column align-items-center py-3 px-4 me-6 mb-3'>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <div className='fs-2 fw-bolder me-5'>{`${clientStatistic?.finishedContracts}/${clientStatistic?.allContracts}`}</div>
                                                </div>

                                                <div className='fw-bold fs-6 text-gray-400'>Контракты</div>
                                            </div>

                                            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <div className='fs-2 fw-bolder me-5'>{NumReplace(clientStatistic?.totalSum?clientStatistic?.totalSum/100+'':'0')} UZS</div>
                                                </div>

                                                <div className='fw-bold fs-6 text-gray-400'>Общая сумма</div>
                                            </div>

                                            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <div className='fs-2 fw-bolder me-5'>{NumReplace(clientStatistic?.paidSum?clientStatistic?.paidSum/100+'':'0')} UZS</div>
                                                </div>

                                                <div className='fw-bold fs-6 text-gray-400'>Оплачено</div>
                                            </div>

                                            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <div className='fs-2 fw-bolder me-5'>{NumReplace((clientStatistic?.totalSum&&clientStatistic?.paidSum?((clientStatistic?.totalSum||0) - (clientStatistic?.paidSum||0))/100+'':''))} UZS</div>
                                                </div>

                                                <div className='fw-bold fs-6 text-gray-400'>Осталось</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='d-flex h-55px'>

                            <Nav fill variant="tabs" className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap"  onSelect={(e)=>setActiveKey(e)}>
                                <Nav.Item>
                                    <Nav.Link eventKey='info'
                                              className={`nav-link text-active-primary me-6 text-gray-600 ${activeKey === 'info' ? 'active' : ''}`}>Личные данные</Nav.Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Nav.Link eventKey='contract'
                                              className={`nav-link text-active-primary text-gray-600 me-6 ${activeKey === 'contract' ? 'active' : ''}`}>Контракты</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>

                    </div>
                </div>
            </KTCard>


            <div className='mt-5'>{switchTabs()}</div>
        </>)
}
const ClientView = () => {

    return (
            <ErrorProvider>
                <MainClientInfo />
            </ErrorProvider>
    )
}

export {ClientView}

