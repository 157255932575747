import React, {FC, useEffect, useState} from 'react'
import {useParams} from "react-router-dom";
import {useErrors} from "@providers/context/ErrorsProvider";
import moment from "moment";
import {useIntl} from "react-intl";
import {card_transactions, statuses_card, statuses_const, statuses_transactions} from "@utils/constants";
import {DatePicker, Tooltip} from "antd";
import {NumReplace} from "@utils/index";
import {Statistic} from "@providers/statistic/_request";
import localeRu from "antd/es/date-picker/locale/ru_RU";
import localeEn from "antd/es/date-picker/locale/en_US";
import {MinusOutlined} from "@ant-design/icons";
import {ChartDashboard} from "./_modals/dashboard/ChartDashboard";
import {toIsoStringHelper} from "../autopay/dateHelper";
import {IStatistic, statisticStatus} from "@providers/statistic/_modals";
import {divideByHundred} from "@utils/numbersOperations";
import { formatDateRequest } from '@utils/formatDateRequest';


interface IProps{
    setStatisticGeneral: (val:{count:number, amount:number})=>void
}

export const StatisticModule:FC<IProps> = (props)=>{
    const [loading, setLoading] = useState(true)
    const {companyId, id} = useParams()
    const {setError} = useErrors()
    const [statistic_data, setStatistic_data] = useState<any>({})
    const [renderData, setRenderData] = useState<any|null>(null)
    const [statisticDate, setStatisticDate] = useState(moment().subtract(1, 'month').format('YYYY-MM-DD'))
    const [statisticDateTo, setStatisticDateTo] = useState(moment().format('YYYY-MM-DD'))
    const lang = JSON.parse(localStorage.getItem('i18nConfig') ?? '{}')?.selectedLang


    const intl = useIntl()


    useEffect(()=>{
        getData()
    }, [statisticDate, statisticDateTo])


    function dataParse(data: any[], cards: string[]) {


        let date_arr = data.map(item => item.date)


        const series = ['general',...statuses_const].map((status: string) => {
            let name, dashboard

            name = `${intl.formatMessage({id: status.toUpperCase()})}`


            dashboard = data.map(item => {
                if(item[status]&&item[status][`totalAmount`]){
                    return item[status][`totalAmount`]
                }
                return  0
            })

            return {name, data: dashboard}
        })

        setRenderData({series, categories: date_arr})


    }





    async function getData(){
        if(companyId&&id){

            setLoading(true)


            const data:IStatistic = {
                statuses: statuses_transactions,
                processingTypes: card_transactions,
                cashboxes:[Number(id)],
                fromDate: formatDateRequest(statisticDate, "fromDate"), 
                toDate: formatDateRequest(statisticDateTo, "toDate"),
                companyId: Number(companyId)
            }


            await Statistic.getStatistic(data)
                .then(result=>{
                    if(result.data.result.init){
                        props.setStatisticGeneral({
                            amount: divideByHundred(result.data.result.init.totalAmount),
                            count: result.data.result.init.totalCount
                        })
                    }
                    setStatistic_data(result?.data?.result||[])
                }).catch(err=>setError({status: err?.response?.stat, message: err?.response?.data?.message, isError: true}))

            await Statistic.getDetailedStatistic(data)
                .then(response=>{
                    dataParse(response?.data?.result, statuses_card)
                }).catch(err=>setError({status: err?.response?.stat, message: err?.response?.data?.message, isError: true}))


            setLoading(false)
        }
    }

    function getCountStatuses(type: statisticStatus){

        if(statistic_data&&statistic_data[type]){

            const data = statistic_data[type]

            if (data) {

                return {amount: divideByHundred(data.totalAmount), count: data.totalCount}
            }
        }


        return {amount: 0, count: 0}

    }


    const statisticFilterHandler = (type:string, value:string)=>{

        if(type==='fromDate'&&value.length){
            setStatisticDate(value)
        }

        if(type==='toDate'&&value.length){
            setStatisticDateTo(value)
        }
    }

    function replaceFormat(date:any, formatFrom:string, formatTo:string){
        let newDate = moment(date, formatFrom).format(formatTo)
        return moment(newDate, formatTo)
    }

    return (
        <>

            <div className='mb-8'>
                <DatePicker.RangePicker
                    style={{border: "none"}}
                    separator={<MinusOutlined />}
                    locale={lang === 'ru' ? localeRu : localeEn}
                    placeholder={["Start", "End"]}
                    className='datepicker_form form-control-lg form-control-solid bg-white-input'
                    defaultValue={[replaceFormat(statisticDate, 'YYYY-MM-DD', 'DD-MM-YYYY'),
                        replaceFormat(statisticDateTo, 'YYYY-MM-DD', 'DD-MM-YYYY')]}
                    format='DD-MM-YYYY'
                    disabledDate={current => {
                        return current && current > moment().endOf('day');
                    }}
                    //@ts-ignore
                    onChange={(dates: [any, any], dateStrings: [string, string])=>{
                        if(dateStrings[0]) statisticFilterHandler('fromDate', replaceFormat(dateStrings[0], 'DD-MM-YYYY', 'YYYY-MM-DD').format())
                        if(dateStrings[1]) statisticFilterHandler('toDate',replaceFormat(dateStrings[1], 'DD-MM-YYYY', 'YYYY-MM-DD').format())


                    }}
                />
            </div>
            <div className='row g-5 g-xl-8'>
                <ChartMiniWidgetMixed tooltip={intl.formatMessage({id: 'tooltip_success'})} className='col-xl-4' color='success' data={{
                    status: 'APPROVED',
                    count: getCountStatuses(statisticStatus.approved)?.count,
                    amount: NumReplace(getCountStatuses(statisticStatus.approved)?.amount+'')+' UZS'
                }} />

                {/*<ChartMiniWidgetMixed tooltip={intl.formatMessage({id: 'tooltip_success'})} className='col-xl-4' color='init' data={{*/}
                {/*    status: 'INIT',*/}
                {/*    count: getCountStatuses('init')?.count??'',*/}
                {/*    amount: getCountStatuses('init')?.amount>0?NumReplace(getCountStatuses('init')?.amount/100+'')+' UZS':getCountStatuses('init')?.amount*/}
                {/*}} />*/}

                <ChartMiniWidgetMixed tooltip={intl.formatMessage({id: 'tooltip_canceled'})} className='col-xl-4' color='danger' data={{
                    status: 'REVERT',
                    count: getCountStatuses(statisticStatus.revert)?.count,
                    amount: NumReplace(getCountStatuses(statisticStatus.revert)?.amount+'')+' UZS'
                }} />

                <ChartMiniWidgetMixed tooltip={intl.formatMessage({id: 'tooltip_declined'})} className='col-xl-4' color='warning' data={{
                    status: 'FAILED',
                    count: getCountStatuses(statisticStatus.failed)?.count,
                    amount: NumReplace(getCountStatuses(statisticStatus.failed)?.amount+'')+' UZS'
                }} />

            </div>

            {
                !loading&&(<div className='mt-8'>

                <div className='card'>
                    <div className='card-header pt-10 border-bottom-0'>
                        <div>
                            <h1 className='mb-5 fw-bolder fs-3'>Статистика</h1>
                            <div className='d-flex'>
                                <div className='d-flex me-4 align-items-center'>
                                    <div className='me-1' style={{width:24, height:8, backgroundColor: '#50CD89', borderRadius:5}}/>
                                    <span >{intl.formatMessage({id:'APPROVED'})}</span>
                                </div>
                                {/*<div className='d-flex me-4 align-items-center'>*/}
                                {/*    <div className='me-1' style={{width:24, height:8, backgroundColor: '#0D47A1', borderRadius:5}}/>*/}
                                {/*    <span >{intl.formatMessage({id:'INIT'})}</span>*/}
                                {/*</div>*/}
                                <div className='d-flex align-items-center me-4'>
                                    <div className='me-1' style={{width:24, height:8,  backgroundColor: '#F1416C', borderRadius:5}}/>
                                    <span>{intl.formatMessage({id:'REVERT'})}</span>
                                </div>
                                <div className='d-flex align-items-center me-4'>
                                    <div className='me-1' style={{width:24, height:8,  backgroundColor: '#FFA621', borderRadius:5}}/>
                                    <span>{intl.formatMessage({id:'FAILED'})}</span>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='card-body'>
                        {renderData? <ChartDashboard className='card-xl-3 mb-5 mb-xl-8' data={renderData}/>:''}
                    </div>
                </div>


                </div>)
            }
        </>
    )
}

type ChartMiniWidgetMixedProps = {
    className: string
    color: string
    tooltip: string
    data: {
        status: string
        count: number,
        amount: string
    }
}


const ChartMiniWidgetMixed: FC<ChartMiniWidgetMixedProps> = ({className, tooltip, color, data}) => {
    const intl = useIntl()


    return <div className={`${className} `}>
        <div className={`card shadow`} style={{padding: "20px"}}>
            <div className={`bg-${color} w-100`} style={{
                height: "8px",
                position: "absolute",
                top: "0",
                left: "0",
                borderRadius: "12px 12px 0 0"
            }}/>
            <div className='card-body p-0 position-relative'>
                <div className='d-flex flex-stack'>
                    <h3 className='m-0 pt-1 fw-bold fs-4'
                        style={{color: "#464E5F",}}>{intl.formatMessage({id: data?.status})}</h3>
                    {
                        tooltip && (<Tooltip color='var(--kt-body-bg)' placement="topLeft" title={() => <span
                            style={{color: 'var(--kt-text-gray-800)'}}>{tooltip}</span>}>
                            <i className="fa-sharp fa-solid fa-circle-info"/>
                        </Tooltip>)
                    }
                </div>

                <div className={`pt-5 card-rounded w-100`} style={{color: "#8B8D99"}}>
                    <div className='d-flex justify-content-between'>
                        <p className='fs-6 text-gray-500'>Кол-во:</p>
                        <p className='fs-4 fw-bold text-gray-800'>{data?.count}</p>
                    </div>
                </div>

                <div className={` card-rounded w-100`}>
                    <div className='d-flex justify-content-between'>
                        <p className='fs-6 text-gray-500'>Сумма:</p>
                        <p className='fs-4 fw-bold text-gray-800'>{data?.amount}</p>
                    </div>
                </div>

            </div>
        </div>
    </div>
}

