import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'
import {useIntl} from "react-intl";
import {Auth} from "@providers/auth/_request";
import {useErrors} from "@providers/context/ErrorsProvider";
import InputMask from "react-input-mask";


interface Props {
    setLoading: (val: boolean) => void
    setConfirm: (val: boolean) => void
    setAccessToken: (val: string) => void
    setCounter: (val: number) => void
    counter: number
    loading: boolean
}


export function ForgotPassword({
                                   setLoading,
                                   setAccessToken,
                                   setConfirm,
                                   setCounter,
                                   counter,
                                   loading
                               }: Props) {

    const intl = useIntl()
    const {setError} = useErrors()

    const initialValues = {
        phone: ''
    }

    const loginSchema = Yup.object().shape({
        phone: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
    })

    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            await forgotPassword(values)
        },
    })

    const forgotPassword = async (values: { phone: string }) => {

        const {phone} = values
        const checkValue = phone.replace(/ /g, '').replace('+', '')
        const reg = new RegExp(/^[0-9]{12}$/)
        if (checkValue.match(reg)) {
            try {
                setLoading(true)
                const response = await Auth.resetPassword(checkValue)
                formik.resetForm()
                const token = response.data.result.access_token
                setAccessToken(token)
                setConfirm(true)
                setCounter(counter + 1)
            } catch (error: any) {
                setError({status: error?.response?.status, message: error?.response?.data?.message, isError: true})
            } finally {
                setLoading(false)
            }
        }
    }


    return (
        <>
            <form
                className='card rounded-3 w-md-550px py-10'
                onSubmit={formik.handleSubmit}
                noValidate
                id='kt_login_forgot_form'
            >
                <div className='fv-row mb-10'>
                    <label className='form-label fs-6 fw-bolder text-dark'>
                        {intl.formatMessage({id: 'phone'})}
                    </label>

                    <div className='d-flex align-items-center justify-content-end position-relative'>
                        <InputMask mask="+\9\9\8 99 999 99 99" {...formik.getFieldProps('phone')}
                                   className={clsx(`form-control form-control-lg form-control-solid ${formik.errors['phone'] ? '\'bg-light-danger form-error' : ''}`,)}
                                   value={formik.getFieldProps('phone').value}
                                   type="tel"
                                   disabled={loading}
                        />
                        {
                            loading &&
                            <span className='spinner-border spinner-border-sm align-middle ms-2'
                                  style={{position: 'absolute', right: '15px'}}/>
                        }
                    </div>

                    {formik.errors['phone'] && (
                        <div className='fv-plugins-message-container text-danger mt-3'>
                            <span role='alert'>{formik.errors['phone']}</span>
                        </div>
                    )}


                </div>

                <div className="d-flex justify-content-between">

                    <button className="btn btn-primary" type='submit'>
                        {intl.formatMessage({id: 'SEND'})}
                    </button>
                </div>
            </form>
        </>
    )
}
