import {FC} from 'react'
import {PageTitle} from "@metronic/layout/core";
import {Vertical} from "./forms/wizards/components/Vertical";
import {Navigate, useOutletContext, useParams} from 'react-router-dom';
import {ErrorProvider} from "@providers/context/ErrorsProvider";
import {useIntl} from "react-intl";
import {roleRouting, roleRoutingAdmin} from "@utils/index";




const FilialForm:FC = ()=>{
    return(
        <ErrorProvider>
            <Vertical/>
        </ErrorProvider>
    )
}

export default function FilialFormWrapper(){
    const {id} = useParams()
    //@ts-ignore
    const {role} = useOutletContext()
    const intl = useIntl()

    if(roleRouting('filial/create', role)||roleRoutingAdmin('page', role, 'filial-form')){
        return(
            <>
                <PageTitle breadcrumbs={[]}>{id? intl.formatMessage({id:"EDIT_FILIAL"}): intl.formatMessage({id:"ADD_FILIAL"})}</PageTitle>
                <FilialForm/>
            </>
        )
    }

    return <Navigate to={`/error/404`}/>
}