import React, {FC} from 'react'
import {KTCard} from "@metronic/helpers";
import {Table} from "./_modals/Table";
import {ErrorProvider} from "@providers/context/ErrorsProvider";
import {PageTitle} from "@metronic/layout/core";
import {useIntl} from "react-intl";

const Sessions:FC = ()=>{

    return (
        <>

            <KTCard>
                <Table />
            </KTCard>
        </>
    )
}

export default function EmployeesCompany(){
    const intl = useIntl()
    return(
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'SESSIONS'})}</PageTitle>
            <ErrorProvider>
                    <Sessions />
            </ErrorProvider>
        </>
    )
}