import React from "react";

type IProps = {
    theme: string
}
export function CustomiseEditView({theme}: IProps){

    return(
        <div className={`col-7 checkout py-16 d-flex justify-content-center ${theme === 'light' ? 'bg-white' : 'bg-custom-dark'}`}>
            <img src={`/media/checkout/checkout-${theme}.svg`} alt={''}/>
        </div>
    )
}