import {Modal} from 'react-bootstrap'
import {FC, useEffect, useState} from "react";
import {useIntl} from "react-intl";




export const ErrorsResponseAlert:FC<any> = ({status, message='', isError=false, setError})=>{
    const intl = useIntl()

    function handleClose(){
        setError(false)
    }




    return(
        <Modal show={isError} onHide={handleClose}>
            {/*<Modal.Header closeButton><h2>Error {status}</h2></Modal.Header>*/}
            <Modal.Header closeButton><h2>{typeof status !== 'number' ? status : `${intl.formatMessage({id:'error-modal'})}`}</h2></Modal.Header>
            <Modal.Body>
                <div className="error-icon align-items-center justify-content-center d-flex">
                    <i className="bi bi-x-lg"></i>
                </div>
                {
                    typeof status !== 'number'&&!message.length?intl.formatMessage({id:'undefined-error'}):''
                }
                {
                    message?.split('\n').map((item:any, index:number)=>{
                        // return(<p key={index} className='mt-3' style={{wordBreak:'break-all'}}>{item}</p>)
                        return(<p key={index} className='mt-3'>{item}</p>)
                    })
                }

            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="swal2-confirm btn btn-primary" aria-label=""
                        // style={{display: 'inline-block'}} onClick={handleClose}>Ok, got it!</button>
                        style={{display: 'inline-block'}} onClick={handleClose}>{typeof status !== 'number' ?  intl.formatMessage({id:'okay'}): intl.formatMessage({id:'ok-goit'})}</button>
            </Modal.Footer>
        </Modal>
    )
}