import {instantse} from "@providers/request";
import {AccountResponse, CreateEmployee, IEmployeeType} from "@providers/employee/_models";
import {ID} from "@metronic/helpers";
import {AxiosResponse} from "axios";


export const Employee = {

    getEmployee(companyId:string){
        return instantse.get(`v1/company/${companyId}/employee`)
            .then(result=>result)
    },

    getEmployeeLogs(companyId:string, id: string, params={}){
        return instantse.get(`v1/company/${companyId}/employee/${id}/log`, {params})
            .then(result=>result)
    },

    getEmployeeInvited(companyId:string){
        return instantse.get(`v1/company/${companyId}/employee/invited`)
            .then(result=>result)
    },

    getEmployeeByFilial(companyId:string, branchId: string){
        return instantse.get(`v1/company/${companyId}/cashbox/${branchId}/employee`)
            .then(result=>result)
    },

    getEmployeeById(companyId:string, employeeId:ID){
        return instantse.get(`v1/company/${companyId}/employee/${employeeId}`)
            .then(result=>result)
    },

    createEmployee(data:CreateEmployee):Promise<AxiosResponse<{result:AccountResponse}>>{
        return instantse.post(`/v1/account`, data)
            .then(result=>result)
    },

    deleteEmployee(companyId:string, employeeId:ID){
        return instantse.delete(`v1/company/${companyId}/employee/${employeeId}`)
            .then(result=>result)
    },

    editEmployee(companyId:string, employeeId:number, data:CreateEmployee){
        return instantse.put(`v1/company/${companyId}/employee/${employeeId}`, data)
            .then(result=>result)
    },

    addEmployeeToBranches(companyId:string, employeeId:ID, data:{branches: number[]}){
        return instantse.put(`v1/company/${companyId}/employee/${employeeId}/cashboxes`, data)
            .then(result=>result)
    },
    getEmployeeTypes(): Promise<AxiosResponse<{result: IEmployeeType[]}>> {
        return instantse.get(`/v1/employee_type/all`)
    }
}