import React from 'react';
import {useIntl} from "react-intl";
import CashPaymentModal from "../cashPaymentModal/cashPaymentModal";
import {ContractStatus} from "@providers/autopay/models/_models";
import {contract} from "@store/contract/contract";
import {priceformatter} from "@utils/priceformatter";
import PaymentsTable from "../paymentsTable/paymentsTable";
import {divideByHundred} from "@utils/numbersOperations";
import moment from "moment";
import {dateFormat} from "../../editContractHelper";
import {StatusAutopay} from "../../../contractsList/_modals/columns/StatusAutopay";

interface props {
    status: ContractStatus
}

const PaymentSchedule: React.FC<props> = ({
                                              status
                                          }) => {

    const intl = useIntl()

    const {entity} = contract.getContract


    const headerInfo = [
        {
            label: 'price_credit',
            value: entity ? priceformatter(divideByHundred(entity.contractVersion.productPrice)) : 0
        },
        {
            label: 'prepaymentPrice',
            value: entity ? priceformatter(divideByHundred(entity.contractVersion.prepaymentPrice)) : 0
        },
        {
            label: 'left_sum',
            value: entity ? priceformatter(divideByHundred(entity.contractVersion.remainingSum)) : 0
        },
        {
            label: 'created_at_contract',
            value: entity ? moment(entity.contractDate).format(dateFormat) : ''
        },
        {
            label: 'date_final_contract',
            value: entity?.contractVersion.schedules.length ? moment(entity?.contractVersion.schedules[entity?.contractVersion.schedules.length - 1].scheduleDate).format(dateFormat) : ''
        }
    ]


    return (
        <div className={'card  mt-8'}
        >
            <div className="card-header d-flex ps-9 align-items-center">
                <h2 className="mb-0">
                    {intl.formatMessage({id: 'GRAF_PAY'})}
                </h2>
                <CashPaymentModal status={status} schedules={entity!.contractVersion.schedules}/>
            </div>
            <div className={'card-body'}>
                {headerInfo.map(({
                                     label,
                                     value
                                 }) => (
                    <div key={label} className="d-flex align-items-center mb-5"><p
                        className="mb-0 text-gray-600 fs-6 col-lg-4">{intl.formatMessage({id: label})}</p>
                        <div
                            className="mb-0 text-gray-800 fw-bold fs-5">
                            <div className={'d-flex align-items-center'}>{value}</div>
                        </div>
                    </div>
                ))}
                <PaymentsTable/>
            </div>
        </div>
    );
};

export default PaymentSchedule;