import * as Yup from "yup";
import {IPaymentCash} from "@providers/autopay/models/_models";
import {priceformatter} from "@utils/priceformatter";

const initialValues: IPaymentCash = {
    paymentSum: undefined,
}


const validationSchema = (total: number) => Yup.object({
    paymentSum: Yup.number().max(total, `Сумма не может превышать ${priceformatter(total)}`).min(1, 'Сумма не может быть отрицательной').required('Это поле обязательное')})


export {initialValues, validationSchema}