import React, {useState} from 'react';
import {Modal} from "react-bootstrap";
import {useModal} from "../../../../../../../hooks/useModal";
import {useIntl} from "react-intl";
import {KTSVG} from "@metronic/helpers";
import {deleteWebPayment, getWebPayments} from "@providers/webPayments/request";
import {IWebPaymentResponse, IWebPaymentsFilters} from "@providers/webPayments/models";
import {web_payments} from "@store/web-payments/web-payments";
import {useParams} from "react-router-dom";
import {useErrors} from "@providers/context/ErrorsProvider";
import {openNotificationSuccess} from "@metronic/partials/notification";
import {UsersListLoading} from "@metronic/partials/widgets/tables/_modals/components/loading/UserListLoading";

interface props {
    invoiceId: number
    title: string
}

const InvoiceDelete = ({invoiceId, title}: props) => {

    const {open, onOpen, onClose} = useModal()
    const intl = useIntl()
    const {id} = useParams()
    const {setError} = useErrors()
    const pagination = web_payments.getPagination
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false)

    const onDelete = () => {
        onRemoveInvoice()
    }


    const onRemoveInvoice = async () => {
        setConfirmLoading(true)
        try {
            const success: boolean = await deleteWebPayment(invoiceId).then((response) => response.data.result.ok)
            openNotificationSuccess(intl.formatMessage({id: 'webPayments.removed'}))
            await webPaymentsRecieve()
            onClose()
        } catch (err: any) {
            setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
        } finally {
            setConfirmLoading(false)
        }
    }


    const webPaymentsRecieve = async () => {
        const data: IWebPaymentsFilters = {
            page: pagination?.currentPage || 1,
            cashBoxId: id ?? '',
            size: 20,
        }
        try {
            const response: IWebPaymentResponse = await getWebPayments(data).then((response) => response.data)
            web_payments.setWebPayments(response.result.content)
            web_payments.setPagination({
                currentPage: pagination?.currentPage || 1,
                pageSize: 20,
                pages: response.result.totalPages,
                last: pagination?.currentPage === response.result.totalPages,
                first: pagination?.currentPage === 1,
                items: response.result.totalElements
            })
        } catch (err: any) {
            setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
        }
    }

    return (
        <>
            {confirmLoading && <UsersListLoading/>}
            <button onClick={onOpen}
                    className={'border-0 bg-transparent  d-flex align-items-center justify-content-center'}>
                <KTSVG path='/media/icons/remove.svg' svgClassName={'svg-icon-danger'}
                       className='svg-icon-2 svg-icon-danger'/>
            </button>

            <Modal show={open} onHide={onClose}>
                <Modal.Header closeButton>
                    <h2>{intl.formatMessage({id: "CHECK_DELETE"})}</h2>
                </Modal.Header>
                <Modal.Body>
                    <div className='fs-3'>{intl.formatMessage({id: "webPayments.remove"})}</div>
                </Modal.Body>
                <Modal.Footer className='justify-content-between'>
                    <button type="button" className="swal2-confirm btn btn-secondary" aria-label=""
                            style={{display: 'inline-block'}} onClick={onClose}>
                        {intl.formatMessage({id: "CANCEL_BUTTON"})}
                    </button>
                    <button onClick={onDelete} className="swal2-confirm btn btn-danger" aria-label=""
                            style={{display: 'inline-block'}}>
                        {intl.formatMessage({id: "delete"})}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default InvoiceDelete;