import {instantse} from "@providers/request";
import {
    IFiscalChequeRequest,
    IFiscalChequeResult,
    TransactionsGeneralRequest
} from "@providers/adminTransactions/_models";
import {AxiosResponse} from "axios";

export const TransactionsInfo = {
    getGeneralTransactions(data: TransactionsGeneralRequest) {
        return instantse.post('/v1/gs/company/transactions/general', data)
            .then(result => result)
    },
    getTransactionsCompany(data: TransactionsGeneralRequest) {
        return instantse.post('/v1/gs/company/transactions', data)
            .then(result => result)
    },
    getCompanyStatistic(data: any) {
        return instantse.post('/v1/gs/company/statistics', data)
            .then(result => result)
    },

    revertTransactions(id: string) {
        return instantse.post(`/v1/company/transactions/revert/${id}`)
    },
    getTransactionCheque({id, companyId}: IFiscalChequeRequest): Promise<AxiosResponse<IFiscalChequeResult>> {
        const params = new URLSearchParams()
        params.set('companyId', companyId.toString())
        return instantse.get(`/v1/company/transactions/${id}/receipt`, {
            params
        })
    }

}