import * as Yup from "yup";
import {useFormik} from "formik";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import clsx from 'clsx'
import {useErrors} from "@providers/context/ErrorsProvider";
import {Auth} from "@providers/auth/_request";


interface Props {
    loading: boolean
    accessToken: string|null
    setConfirm: (val:boolean)=>void
    setDidForgotPass: (val:boolean)=>void
    setPasswordSetupBlock: (val:boolean)=>void
}


export function SetNewPass({loading, setConfirm, setPasswordSetupBlock, accessToken, setDidForgotPass}: Props){

    const intl = useIntl()
    const {setError} = useErrors()
    const [hiddenPass, setHiddenPass] = useState(true)
    const [validateError, setValidateError] = useState<string|null>(null)




    const initialValues = {
        password: '',
        passwordRepeat: '',
    }

    const loginSchema = Yup.object().shape({
        password: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
        passwordRepeat: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
    })

    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            await changePassword(values)
        },
    })

    async function changePassword(values:{password: string, passwordRepeat:string}){
        const {password, passwordRepeat} = values

        if(password!==passwordRepeat){
            setValidateError('Пароли не совпадают')
            return
        }

        await Auth.putPassword(password, accessToken)
            .then(()=>{
                setConfirm(false)
                setDidForgotPass(false)
                setPasswordSetupBlock(false)

            })
            .catch(err=>{
                setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
            })
    }


    function cancelCopyPast(e:React.KeyboardEvent<HTMLElement>){
        if(e.ctrlKey && e.which == 86) e.preventDefault();
        if(e.shiftKey && e.which == 45) e.preventDefault();
    }



    return(
        <form
            className='card rounded-3 w-md-550px py-10'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_set_pass_form'
        >
            <div className='fv-row'>
                <div className='fv-row mb-10'>
                    <div className='d-flex justify-content-between mt-n5'>
                        <div className='mb-10'>
                            <h3 className='fw-bolder text-dark text-center'>{intl.formatMessage({id: 'SETUP_PASSWORD'})}</h3>
                        </div>
                    </div>
                    <div className="mb-4">
                        <label className='form-label fs-6 fw-bolder text-dark'>
                            {intl.formatMessage({id: 'ENTER_PASSWORD'})}
                        </label>
                        <div className='d-flex align-items-center justify-content-end position-relative'>
                            <input
                                type={hiddenPass ? "password" : "text"}
                                {...formik.getFieldProps('password')}
                                value={formik.getFieldProps('password').value}
                                className={clsx(
                                    `form-control form-control-lg form-control-solid ${validateError?'bg-light-danger form-error':''}`

                                )}

                                onChange={(e)=> {
                                    formik.handleChange(e)
                                    setValidateError('')
                                }}

                            />
                            {
                                loading &&
                                <span className='spinner-border spinner-border-sm align-middle ms-2'
                                      style={{position: 'absolute', right: '15px'}}/>
                            }
                        </div>

                        {validateError && (
                            <div className='fv-plugins-message-container text-danger mt-3'>
                                <span role='alert'>{validateError}</span>
                            </div>
                        )}
                    </div>
                    <div>
                        <label className='form-label fs-6 fw-bolder text-dark'>
                            {intl.formatMessage({id: 'REPEAT_PASSWORD'})}
                        </label>
                        <div className='d-flex align-items-center justify-content-end position-relative'>
                            <input
                                type={hiddenPass ? "password" : "text"}
                                {...formik.getFieldProps('passwordRepeat')}
                                value={formik.getFieldProps('passwordRepeat').value}
                                onKeyDown={cancelCopyPast}
                                onKeyPress={cancelCopyPast}
                                className={clsx(
                                    `form-control form-control-lg form-control-solid ${validateError?'bg-light-danger form-error':''}`

                                )}
                                onChange={(e)=> {
                                    formik.handleChange(e)
                                    setValidateError('')
                                }}

                            />
                            {
                                loading ?
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'
                                          style={{position: 'absolute', right: '15px'}}/> :
                                    <span className='bi-eye-fill align-middle ms-2 cursor-pointer'
                                          style={{position: 'absolute', right: '-20px'}}
                                          onMouseDown={() => setHiddenPass(false)}
                                          onMouseUp={() => setHiddenPass(true)}/>
                            }
                        </div>

                        {validateError && (
                            <div className='fv-plugins-message-container text-danger mt-3'>
                                <span role='alert'>{validateError}</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="d-flex justify-content-between">
                <button className="btn btn-primary" type='submit'>
                    {intl.formatMessage({id: 'LOGIN'})}
                </button>
            </div>
        </form>
    )
}