/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {EmployeeLogs} from "@providers/employee/_models";
import {useIntl} from "react-intl";

type Props = {
    logs: EmployeeLogs
}



const EmployeeLogsActions: FC<Props> = ({logs}) => {
    const intl = useIntl()

    return (
        <div className='d-flex align-items-center'>

            <div className='d-flex flex-column'>
                {logs.changed_from&&logs.changed_to?intl.formatMessage({id:"CHANGED"}):logs.changed_from?intl.formatMessage({id:"DELETED"}):intl.formatMessage({id:"ADDED"})}
            </div>
        </div>
    )
}

export {EmployeeLogsActions}
