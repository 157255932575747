import {KTCard, KTSVG} from "@metronic/helpers";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Company} from "@providers/company/_request";
import {Dropdown, Menu, Tooltip} from "antd";
import {Nav} from "react-bootstrap";
import {useIntl} from "react-intl";
import {CompanyResponseOne, CompanyUpdate} from "@providers/company/_models";
import {Settings} from "./_tabs/Settings";
import {Transactions} from "./_tabs/Transactions";
import {Employees} from "./_tabs/Employees";
import {Dashboard} from "./_tabs/Dashboard";
import {FilialTab} from "./_tabs/Filial";
import {UserEditModal} from "../_modals/user-edit-modal/UserEditModal";
import {ListViewProvider, useListView} from "../_modals/providers/ListViewProvider";
import {ErrorProvider, useErrors} from "@providers/context/ErrorsProvider";
import {Filial} from "@providers/filial/_request";
import {NumReplace, roleRouting, roleRoutingAdmin} from "@utils/index";
import {PageLink} from "@metronic/layout/core";
import {PageTitleCustom} from "@metronic/partials/pageTitleCustom";
import {observer} from "mobx-react";
import {employeeStore} from "@store/employee";
import {UsersListLoading} from "@metronic/partials/widgets/tables/_modals/components/loading/UserListLoading";
import {openNotificationSuccess} from "@metronic/partials/notification";
import {filial} from "@store/filial";
import {company} from "@store/company";
import {priceformatter} from "@utils/priceformatter";
import {divideByHundred} from "@utils/numbersOperations";
import {SettingsCheckout} from "./_tabs/SettingsCheckout";


const classStatus: IStatuses = {
    DRAFT: 'secondary',
    ACTIVE: 'light-success',
    DISABLED: 'light-danger',
}

interface IStatuses {
    DRAFT: string
    ACTIVE: string
    DISABLED: string
}

const iconsStatus: IStatuses = {
    DRAFT: "/media/svg/revert.svg",
    ACTIVE: "/media/svg/approved.svg",
    DISABLED: "/media/svg/failed.svg",
}






const CompanyView = observer(() => {
    const {itemIdForUpdate} = useListView()
    const {id} = useParams()
    const [companyData, setCompany] = useState<CompanyResponseOne | null>(null)
    const [activeKey, setActiveKey] = useState<string | null>('dashboard')
    const intl = useIntl()
    const {setError} = useErrors()
    const [staticticInfo, setStatisticInfo] = useState<any>({})
    const [statusChecked, setStatusChecked] = useState<boolean>(!!companyData?.active)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [ready, setReady] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false)



    const createCompanyBreadCrumbs: Array<PageLink> = [
        {
            title: 'Компании',
            path: '/companies',
            isSeparator: false,
            isActive: false,
        },
        {
            title: companyData ? companyData?.name : '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
    ]

    useEffect(() => {
        if (id) {
            Promise.all([getCompany(id),
                getFilials()])
                .then(() => setReady(true))
        }

        return () => {
            filial.setFilial([])
        }
    }, [id])

    useEffect(() => {
        let page = document.querySelector('.page')
        if (page) {
            page.addEventListener('click', () => {
                setModalIsOpen(false)
            })
        }

        return () => {
            if (page) {
                page.removeEventListener('click', () => {
                    setModalIsOpen(false)
                })
            }
        }

    }, [])


    const widgetMenu = (
        <Menu className='p-7 change_status_group'>
            <p className='text-gray-800 fw-bold'>Изменить статус</p>
            <div>

                <div className={`item_check_status d-flex align-items-center justify-content-between border border-gray-300 mb-3
               ${statusChecked ? 'active' : ''}`}

                     onClick={() => {
                         setStatusChecked(true)
                     }}
                >
                    <div className='d-flex  align-items-center'>
                       <span className='me-5'>
                          <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <rect width="28" height="28" rx="6" fill="#ECFDF3"/>
                            <path d="M18 11L12.5 16.5L10 14" stroke="#12B76A" strokeWidth="1.5" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                          </svg>

                       </span>
                        <p className='fw-bold fs-6 text-gray-800 mb-0'>Активный</p>
                    </div>
                    <div>
                        <div className="form-check form-check-custom form-check-solid">
                            <input className="form-check-input"
                                   type="radio"
                                   checked={statusChecked}

                            />
                        </div>
                    </div>
                </div>
                <div className={`item_check_status d-flex align-items-center justify-content-between border border-gray-300 
               ${!statusChecked ? 'active' : ''}`}
                     onClick={() => {
                         setStatusChecked(false)
                     }}
                >
                    <div className='d-flex  align-items-center'>
                       <span className='me-5'>
                          <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <rect width="28" height="28" rx="6" fill="#FEF3F2"/>
                            <path d="M17 11L11 17M11 11L17 17" stroke="#F04438" strokeWidth="1.5"
                                  strokeLinecap="round" strokeLinejoin="round"/>
                          </svg>


                       </span>
                        <p className='fw-bold fs-6 text-gray-800 mb-0'>Неактивный</p>
                    </div>
                    <div>
                        <div className="form-check form-check-custom form-check-solid">
                            <input className="form-check-input"
                                   type="radio"
                                   checked={!statusChecked}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className='mt-8'>
                <button className='btn btn-light fw-bold me-3' onClick={() => setModalIsOpen(false)}>
                    Отмена
                </button>
                <button className='btn btn-primary fw-bold' onClick={() => changeStatusSubmit()}>
                    Применить
                </button>
            </div>
        </Menu>
    );

    function changeStatusSubmit() {
        setModalIsOpen(false)

        if (companyData) {
            const data: Partial<CompanyUpdate> = {
                id: companyData.id,
                active: statusChecked
            }
            setConfirmLoading(true)
            Company.partiallyEdit(data)
                .then(() => {
                    openNotificationSuccess(intl.formatMessage({id: 'companyEdited'}))
                    updateData()
                })
                .catch(err => {
                    setStatusChecked(companyData.active)
                    setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
                }).finally(() => setConfirmLoading(false))
        }

    }

    async function getCompany(id: string) {
        await Company.getById(id)
            .then(result => {
                const status: boolean = result?.data?.result.active
                setStatusChecked(status)
                setCompany(result?.data?.result)
            }).catch(err => {
                setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
            })

    }

    async function getFilials() {

        Filial.getFilial(id || '', 1, 50)
            .then(async (response) => {
                filial.setFilial(response?.data?.result?.content)
                // await getTransactionGeneral(response?.data?.result.content)
            })
            .catch(err => {
                setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
            })
    }


    async function updateData() {
        await getCompany(id || '')
        await Company.getCompany().then(result => {
            company.setCompany(result?.data?.result)
        })
    }


    function renderTabs() {
        switch (activeKey) {
            case 'settings':
                if (companyData) {
                    return <Settings updateData={updateData} companyData={companyData}/>
                }
                return
            case 'transactions':
                return <Transactions general={staticticInfo}/>
            case 'employee':
                return <Employees/>
            case 'dashboard':
                return <Dashboard setStatisticGeneral={(val) => setStatisticInfo(val)}/>
            case 'cashboxes':
                return <FilialTab/>
            case 'checkout':
                if (companyData) {
                    return <SettingsCheckout updateData={updateData} companyData={companyData}/>
                }
                return

            default:
                return
        }
    }

    if (!ready) <UsersListLoading/>


    return (
        <>
            <PageTitleCustom title={companyData?.name || ''} breadCrumbs={createCompanyBreadCrumbs}/>
            {confirmLoading && <UsersListLoading/>}
            <KTCard className='mb-5'>

                <div className='card mb-5 mb-xl-10'>
                    <div className='card-body pt-9 pb-0'>
                        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                            <div className='me-7 mb-4'>
                                <div
                                    className='p-3 d-flex flex-column justify-content-center symbol logo-project bg-secondary symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                                    {
                                        ready ? <>{
                                            !companyData?.logoFile ? (<p style={{fontSize: '4rem'}}
                                                                         className='text-center mb-0 fw-bolder text-primary'>{(companyData?.name || '').split(' ').map((item: string) => item.length ? item[0].toUpperCase() : item).join('')}</p>)
                                                : (<img className='w-100 h-100' style={{objectFit: 'contain'}}
                                                        src={Company.getFiles(typeof companyData.logoFile === 'string' ? companyData.logoFile : '')}
                                                        alt=''/>)
                                        }</> : ''
                                    }

                                </div>
                            </div>

                            <div className='flex-grow-1'>
                                <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                                    <div className='d-flex flex-column'>
                                        <div className='d-flex align-items-center mb-2'>
                                            <p className='text-gray-800 fs-2 fw-bolder me-1 mb-0'>
                                                {companyData?.name}
                                            </p>
                                            <div className={` mx-5`}>


                                                <>
                                                    {
                                                        roleRoutingAdmin('action', employeeStore.getRole, 'is-admin') ? (
                                                            <Dropdown overlay={widgetMenu} open={modalIsOpen}>
                                                                <a onClick={(e) => {
                                                                    e.preventDefault()
                                                                    setModalIsOpen(!modalIsOpen)
                                                                }}>
                                                                    <div
                                                                        className={`badge badge-${classStatus[companyData?.active ? 'ACTIVE' : 'DISABLED']} fw-bolder`}>
                                                                        <KTSVG
                                                                            path={iconsStatus[companyData?.active ? 'ACTIVE' : 'DISABLED']}
                                                                            className={`svg-icon-${classStatus[companyData?.active ? 'ACTIVE' : 'DISABLED']} svg-icon-2hx me-2`}/>
                                                                        {intl.formatMessage({id: companyData?.active ? 'ACTIVE' : 'DISABLED'})}
                                                                    </div>
                                                                </a>
                                                            </Dropdown>) : (<>
                                                            <div
                                                                className={`badge badge-${classStatus[companyData?.active ? 'ACTIVE' : 'DISABLED']} fw-bolder`}>
                                                                <KTSVG
                                                                    path={iconsStatus[companyData?.active ? 'ACTIVE' : 'DISABLED']}
                                                                    className={`svg-icon-${classStatus[companyData?.active ? 'ACTIVE' : 'DISABLED']} svg-icon-2hx me-2`}/>
                                                                {intl.formatMessage({id: companyData?.active ? 'ACTIVE' : 'DISABLED'})}
                                                            </div>
                                                        </>)
                                                    }
                                                    {
                                                        !companyData?.ownerId ? <div
                                                            className={`ms-3 badge badge-${classStatus['DISABLED']} fw-bolder`}>
                                                            {intl.formatMessage({id: 'not_owner'})}
                                                        </div> : ''
                                                    }
                                                </>


                                            </div>
                                        </div>

                                        <div className='d-flex flex-wrap  fw-bold fs-6 mb-4 pe-2'>
                                            <span
                                                className='d-flex align-items-center text-break text-gray-400 me-5 mb-2'
                                            >
                                                <KTSVG
                                                    path='/media/icons/build.svg'
                                                    className='svg-icon-4 me-1'
                                                />

                                                {companyData?.addressRegistry}
                                            </span>


                                        </div>
                                    </div>

                                </div>

                                <div className='d-flex flex-wrap flex-stack'>
                                    <div className='d-flex flex-column flex-grow-1 pe-8'>
                                        <div className='d-flex flex-wrap'>
                                            <div
                                                className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <div
                                                        className='fs-2 fw-bolder me-5'>{companyData ? companyData.totalCount : ''}</div>
                                                    <Tooltip color='var(--kt-body-bg)' placement="topLeft"
                                                             title={() => <span
                                                                 style={{color: 'var(--kt-text-gray-800)'}}>{intl.formatMessage({id: 'transactions_info_count'})}</span>}>
                                                        <i style={{fontSize: "16px"}}
                                                           className="fa-sharp fa-solid fa-circle-info"/>
                                                    </Tooltip>
                                                </div>

                                                <div className='fw-bold fs-6 text-gray-400'>Все транзакции</div>
                                            </div>

                                            <div
                                                className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <div
                                                        className='fs-2 fw-bolder me-5'>{NumReplace(companyData?.totalAmount ? priceformatter(divideByHundred(companyData.totalAmount)) : priceformatter(0))}
                                                    </div>
                                                    <Tooltip color='var(--kt-body-bg)' placement="topLeft"
                                                             title={() => <span
                                                                 style={{color: 'var(--kt-text-gray-800)'}}>{intl.formatMessage({id: 'amount_from_register'})}</span>}>
                                                        <i style={{fontSize: "16px"}}
                                                           className="fa-sharp fa-solid fa-circle-info"/>
                                                    </Tooltip>
                                                </div>

                                                <div className='fw-bold fs-6 text-gray-400'>Общая сумма</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className='d-flex overflow-auto h-55px'>

                            <Nav fill variant="tabs"
                                 className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap"
                                 onSelect={(e) => setActiveKey(e)}>

                                <Nav.Item>
                                    <Nav.Link eventKey="dashboard"
                                              className={`nav-link text-active-primary me-6 text-gray-800 ${activeKey === 'dashboard' ? 'active' : ''}`}>{intl.formatMessage({id: "DASHBOARD_MERCHANT"})}</Nav.Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Nav.Link eventKey="transactions"
                                              className={`nav-link text-active-primary text-gray-800 me-6 ${activeKey === 'transaction' ? 'active' : ''}`}>{intl.formatMessage({id: "trans"})}</Nav.Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Nav.Link eventKey="cashboxes"
                                              className={`nav-link text-active-primary text-gray-800 me-6 ${activeKey === 'cashboxes' ? 'active' : ''}`}>{intl.formatMessage({id: "cashboxes"})}</Nav.Link>
                                </Nav.Item>

                                {/*<Nav.Item>*/}
                                {/*    <Nav.Link eventKey="employee"*/}
                                {/*              className={`nav-link text-active-primary text-gray-800 me-6 ${activeKey === 'employee' ? 'active' : ''}`}>{intl.formatMessage({id: "employee"})}</Nav.Link>*/}
                                {/*</Nav.Item>*/}

                                <Nav.Item>
                                    <Nav.Link eventKey="settings"
                                              className={`nav-link text-active-primary text-gray-800 me-6 ${activeKey === 'settings' ? 'active' : ''}`}>{intl.formatMessage({id: "settings"})}</Nav.Link>
                                </Nav.Item>


                                {
                                    roleRoutingAdmin('action', employeeStore.getRole, 'settings-checkout') ||
                                    roleRouting('settings-checkout', employeeStore.getRole, 'action')
                                        ? (<Nav.Item>
                                            <Nav.Link eventKey="checkout"
                                                      className={`nav-link text-active-primary text-gray-800 me-6 ${activeKey === 'checkout' ? 'active' : ''}`}>Настройка
                                                чекаута</Nav.Link>
                                        </Nav.Item>) : ''
                                }


                            </Nav>
                        </div>

                    </div>
                </div>
            </KTCard>

            <div>
                {renderTabs()}
            </div>

            {itemIdForUpdate !== undefined && <UserEditModal/>}

        </>
    )
})


const CompanyViewWrapper = () => (
    <ListViewProvider>
        <ErrorProvider>
            <CompanyView/>
        </ErrorProvider>
    </ListViewProvider>
)

export {CompanyViewWrapper}



