import React from 'react';
import {IContract} from "@providers/autopay/models/_models";
import ContractStop from "../contractStop/contractStop";
import PaymentSchedule from "../paymentSchedule/paymentSchedule";

interface props {
    contract: IContract
}

const ContractSettings: React.FC<props> = ({
                                               contract
                                           }) => {

    function isShowContractStop (): boolean {
        let access = true

        if(contract.contractVersion.status === 'FINISHED' ||contract.contractVersion.status === 'TERMINATED' ) {
            access = false
        }
        return access
    }


    return (
        <>
            <PaymentSchedule status={contract.contractVersion.status}/>
            {isShowContractStop() ? <ContractStop status={contract.contractVersion.status}/> : null}
        </>
    );
};

export default ContractSettings;