/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import clsx from 'clsx'
import {useLayout} from '../../../core'
import {KTSVG, toAbsoluteUrl} from '../../../../helpers'
import {useThemeMode} from "@metronic/partials";
import {observer} from "mobx-react";
import {isDemo} from "@utils/index";
import {AsideMenuAdmin} from "@metronic/layout/components/aside/admin/AsideMenuAdmin";

const AsideAdmin: FC = observer(() => {
    const {config, classes} = useLayout()
    const asideRef = useRef<HTMLDivElement | null>(null)
    const {aside} = config
    const {mode} = useThemeMode()
    const [toggleAside, setToggleAside] = useState(false)



    const minimize = () => {
        setToggleAside(!toggleAside)
        asideRef.current?.classList.add('animating')
        setTimeout(() => {
            asideRef.current?.classList.remove('animating')
        }, 300)
    }

    useEffect(() => {
        document.body.setAttribute('data-kt-aside-minimize', toggleAside ? 'on' : 'off')
    }, [toggleAside])


    return (

        <div
            id='kt_aside'
            className={`${clsx('aside', classes.aside.join(' '))} aside-light app-sidebar`}
            data-kt-drawer='true'
            data-kt-drawer-name='aside'
            data-kt-drawer-activate='{default: true, lg: false}'
            data-kt-drawer-overlay='true'
            data-kt-drawer-width="{default:'200px', '300px': '250px'}"
            data-kt-drawer-direction='start'
            data-kt-drawer-toggle='#kt_aside_mobile_toggle'
            ref={asideRef}
        >
            {/* begin::Brand */}
            <div className='aside-logo flex-column-auto position-relative' id='kt_aside_logo'>
                {/* begin::Logo */}
                {mode === 'dark' && (

                    <Link
                        to={`/companies`}
                        style={{width: "125px"}}>

                        <img
                            alt='Logo'
                            className='w-100 h-100 logo-default'
                            // src={toAbsoluteUrl('/media/logos/logo-dark-default.svg')}
                            src={toAbsoluteUrl(`/media/logos/logo-default-dark-${isDemo() ? "demo" : "beta"}.svg`)}
                        />

                        <img
                            alt='Logo'
                            className='w-100 h-100 logo-minimize'
                            src={toAbsoluteUrl('/media/logos/logo-dark-small.svg')}
                        />
                    </Link>
                )}
                {mode === 'light' && (
                    <Link
                        to={`/companies`}
                        style={{width: "125px"}}>
                        <img
                            alt='Logo'
                            className='w-100 h-100 logo-default'
                            // src={toAbsoluteUrl('/media/logos/logo-default-light.svg')}
                            src={toAbsoluteUrl(`/media/logos/logo-default-light-${isDemo() ? "demo" : "beta"}.svg`)}
                        />

                        <img
                            alt='Logo'
                            className='w-100 h-100  logo-minimize'
                            src={toAbsoluteUrl('/media/logos/logo-small.svg')}
                        />
                    </Link>
                )}
                {/* end::Logo */}

                {/* begin::Aside toggler */}
                {aside.minimize && (

                    <div
                        id='kt_aside_toggle'
                        className="rotate-arrow-sidebar app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle"
                        data-kt-toggle='true'
                        data-kt-toggle-state='active'
                        data-kt-toggle-target='body'
                        data-kt-toggle-name='aside-minimize'
                        onClick={minimize}
                    >

                        <KTSVG
                            path={'/media/icons/duotune/arrows/arr080.svg'}
                            className={'svg-icon-1 rotate-360 color-orange'}
                        />
                    </div>

                )}
                {/* end::Aside toggler */}
            </div>
            {/* end::Brand */}

            {/* begin::Aside menu */}
            <div className='aside-menu flex-column-fluid'>
                <AsideMenuAdmin asideMenuCSSClasses={classes.asideMenu}/>
            </div>
            {/* end::Aside menu */}


            <div className='aside-footer flex-column-auto pt-5 pb-11 px-5' id='kt_aside_footer'>

            </div>


        </div>
    )
})

export {AsideAdmin}
