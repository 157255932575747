import {useIntl} from "react-intl";
import {statuses_card, statuses_const} from "@utils/constants";
import React, {useState} from "react";


type TypeTableSort = {
    show: boolean
    updateFilter: (val: any[]) => void
    columns: any[]
    columns_state: any[]
    handleClose: VoidFunction
}

function TableSort (props:TypeTableSort){
    const {show, updateFilter, handleClose, columns, columns_state} = props
    const intl = useIntl()
    const [columnsChange, setColumnsChange] = useState<any[]>(columns_state)


    return (
        <div className={`menu menu-sub filter-dropdown menu-sub-dropdown w-250px w-md-350px w-sm-300 ${show && 'show'}`}
             data-kt-menu='true'>
            <div className='px-7 py-5'>
                <div className='fs-4 text-dark fw-bold'>{intl.formatMessage({id: 'Filters'})}</div>
            </div>

            <div className='separator border-gray-200'></div>

            <div className='px-7 py-5'>

                <div className='mb-6'>
                    <label className='fw-bold fs-7'>{intl.formatMessage({id: 'STATUS'})}:</label>

                    <div className=''>
                        {
                            columns.map((item:any)=>{
                                return(
                                    <label className='form-check form-check-sm form-check-custom form-check-solid me-5 mt-3'>
                                        <input className='form-check-input' type='checkbox'
                                               checked={columnsChange.find((col:any)=>col.type===item.type)}
                                               disabled={item.disable}
                                               value={item.type}
                                               onChange={(e) => {
                                                   if (!e.target.checked) {
                                                       setColumnsChange(columnsChange.filter((col:any)=>col.type!==item.type))
                                                   } else {
                                                       const data = [...columnsChange,item].map((col:any)=>col.type)
                                                       const newData = columns.filter((col:any)=>data.includes(col.type))

                                                       setColumnsChange(newData)
                                                   }

                                               }}
                                        />
                                        <span className='form-check-label'>{intl.formatMessage({id:item.type})}</span>
                                    </label>
                                )
                            })
                        }


                    </div>
                </div>
                <div className='d-flex justify-content-between'>

                    <button type='submit' className='btn btn-primary text-white fw-bolder' data-kt-menu-dismiss='true'
                            onClick={() => {
                                updateFilter(columnsChange)
                            }}>
                        {intl.formatMessage({id: 'Apply'})}
                    </button>
                    <button type='submit' className='btn btn-secondary text-muted fw-bolder' data-kt-menu-dismiss='true'
                        // onClick={() => handleClose()}>
                            onClick={() => {
                                updateFilter(columns)
                            }}>
                        {intl.formatMessage({id: 'reset'})}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default TableSort