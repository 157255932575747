import React, {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {useListView} from '../providers/ListViewProvider'
import {AccountResponse, CreateEmployee, EmployeeM} from "@providers/employee/_models";
import {Employee} from "@providers/employee/_request"
import {useParams} from "react-router-dom";
import {ACCOUNTANT, ANALYST, CASHIER, CEO, JUNIOR_CASHIER, OWNER, REGEXP_PHONE} from "@utils/constants";
import {observer} from "mobx-react";
import {employeeStore} from "@store/employee";
import {useIntl} from "react-intl";
import Select from "react-select";
import {customStyles} from "@metronic/assets/ts/select.style";
import {Filial} from "@providers/filial/_request";
import InputMask from "react-input-mask";
import {useErrors} from "@providers/context/ErrorsProvider";
import {localErrorsMessage} from "@utils/index";

type Props = {
  isUserLoading: boolean
  user?: AccountResponse
}


const initialEmployee:Partial<CreateEmployee> = {
  phone: '',
  employeeTypeId: 0,
  firstName: '',
  lastName: '',
  email: ''
}

const UserEditModalForm: FC<Props> = observer(({user, isUserLoading}) => {
  const {setItemIdForUpdate} = useListView()
  const {setError} = useErrors()
  const {companyId} = useParams()
  const intl = useIntl()

  useEffect(()=>{
    let elem:any = document.body
    elem.addEventListener('click', (e:any)=>{
      if(e.target.getAttribute('id')==='kt_modal_add_user'){
        setItemIdForUpdate(undefined)
      }


    })
  },[])

  const positionsOptions = [
    {
      value: OWNER,
      label:intl.formatMessage({id:OWNER})
    },
    {
      value: CEO,
      label:intl.formatMessage({id:CEO})
    },
    {
      value: ANALYST,
      label:intl.formatMessage({id:ANALYST})
    },
    {
      value: ACCOUNTANT,
      label:intl.formatMessage({id:ACCOUNTANT})
    },
    {
      value: CASHIER,
      label:intl.formatMessage({id:CASHIER})
    },
    {
      value: JUNIOR_CASHIER,
      label:intl.formatMessage({id:JUNIOR_CASHIER})
    }
  ]


  const createUserSchema = Yup.object().shape({
    phone: Yup.string()
        .matches(
            REGEXP_PHONE,
            intl.formatMessage({id: 'ERROR_VALIDATION_PHONE'})
        )
        .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
    position: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
  })

  const editUserSchema = Yup.object().shape({
    position: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
  })

  const [userForEdit] = useState<Partial<CreateEmployee>>({
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    employeeTypeId: initialEmployee.employeeTypeId,
    phone: user?.phone || initialEmployee.phone,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh&&companyId) {
      Employee.getEmployee(companyId).then(response=>employeeStore.setEmployee(response?.data?.result||[]))
    }
    setItemIdForUpdate(undefined)
  }



  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: !user?.id?createUserSchema:editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      const checkValue = (values?.phone||'').replace(/ /g,'').replace('+','')
      const data:CreateEmployee = {
        phone: checkValue,
        role: 'USER',
        employeeTypeId: values.employeeTypeId?Number(values.employeeTypeId):0,
        firstName: values.firstName||'',
        lastName: values.lastName||'',
        email: values.email,
        companyId: Number(companyId)
      }

      if (user?.id&&companyId) {

        await Employee.editEmployee(companyId??'', user.id, data)
            .then(()=>{}).catch(err=>{
              setError({status: err?.response?.status, message: err?.response?.data?.code === "METHOD_NOT_ALLOWED"?intl.formatMessage({id: 'not_role'}):err?.response?.data?.message, isError: true})
            })
      } else {

        await Employee.createEmployee(data)
            .then(()=>{}).catch(err=>{
              setError({status: err?.response?.status, message: localErrorsMessage('post-employee', 'message-match', err?.response?.data?.message, intl), isError: true})
            })
      }

      setSubmitting(true)
      cancel(true)

    },
  })



  return (
      <>

        <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
          {/* begin::Scroll */}
          <div
              className='d-flex flex-column me-n7 pe-7'
              id='kt_modal_add_user_scroll'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-dependencies='#kt_modal_add_user_header'
              data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
              data-kt-scroll-offset='300px'
          >
            {/* begin::Input group */}

            {
              !user?.id?(<>{/* begin::Input group */}

                {/* end::Input group */}

                {/* begin::Input group */}
                <div className='fv-row mb-7'>
                  {/* begin::Label */}
                  <label className='required fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'phone'})}</label>
                  {/* end::Label */}

                  {/* begin::Input */}

                  <InputMask
                      {...formik.getFieldProps('phone')}
                      mask="+\9\9\8 99 999 99 99"
                      placeholder='+998 90 999 99 99'
                      className={clsx(
                          'form-control form-control-solid mb-3 mb-lg-0',
                          {'is-invalid': formik.touched.phone && formik.errors.phone},
                          {
                            'is-valid': formik.touched.phone && !formik.errors.phone,
                          }
                      )}
                      type='text'
                      name='phone'
                      autoComplete='off'
                      disabled={formik.isSubmitting || isUserLoading}

                  />
                  {/* end::Input */}
                  {formik.touched.phone && formik.errors.phone && (
                      <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.phone}</span>
                      </div>
                  )}
                </div>
                {/* end::Input group */}</>):''
            }

            {/* begin::Input group */}
            <div className='mb-7'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-5'>{intl.formatMessage({id: 'POSITION'})}</label>
              <Select
                  {...formik.getFieldProps('position')}
                  value={positionsOptions.find((item:any)=>formik.values['employeeTypeId']===item.value)}
                  onChange={(e)=>{formik.setFieldValue('position', e?.value)}}
                  name='employeeTypeId'
                  styles={customStyles}
                  isMulti={false}
                  placeholder={intl.formatMessage({id: 'select'})}
                  options={positionsOptions}
              />

            </div>
            {/* end::Input group */}
          </div>
          {/* end::Scroll */}

          {/* begin::Actions */}
          <div className='text-left pt-5'>

            <button
                type='submit'
                className='btn btn-primary'
                data-kt-users-modal-action='submit'
                disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
            >
              <span className='indicator-label'>{intl.formatMessage({id: user?.id?'SAVE':'CREATE_BUTTON'})}</span>
              {(formik.isSubmitting || isUserLoading) && (
                  <span className='indicator-progress'>
                Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
              )}
            </button>
          </div>
          {/* end::Actions */}
        </form>
        {(formik.isSubmitting || isUserLoading) && ''}

        {user?.id&&<AddFilial user={user} cancel={(val)=>cancel(val)}/>}
      </>
  )
})

const AddFilial = observer(({user, cancel}:{user:EmployeeM, cancel: (val:boolean)=>void})=>{
  const [filialFormEdit] = useState({cashboxes: user.cashboxes||[]})
  const [filialOptions, setFilial] = useState<any>([])
  const {companyId} = useParams()
  const {setError} = useErrors()
  const intl = useIntl()


  useEffect(()=>{
    if(companyId){
      Filial.getFilial(companyId)
          .then(result=>{
            setFilial((result?.data?.result.content||[]).map((item:any)=>{
              return {
                value: item.id,
                label:item.name
              }
            }))
          })
    }
  }, [])

  const branchesShema = Yup.object().shape({
    cashboxes: Yup.array()
  })



  const formik = useFormik({
    initialValues: filialFormEdit,
    validationSchema: branchesShema,
    enableReinitialize: true,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)

      if(companyId&&user.id){

        //@ts-ignore
        await Employee.addEmployeeToBranches(companyId, user.id, {cashboxes: values.cashboxes}).then(result=>{})
            .catch(err=>{
              setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
            })

        cancel(true)
      }
    },
  })

  const onChange = (value:{value: string, label:string}[])=>{
    formik.setFieldValue('cashboxes', value.map(item=>item.value))
  }


  return(
      <div className='mb-7 mt-15'>
        <p className='fw-bold fs-6 mb-2'>{intl.formatMessage({id: 'IN_CASHBOX'})}</p>
        <form className='form' onSubmit={formik.handleSubmit} noValidate>
          <Select  value={filialOptions.map((filial:any)=>{
            if(formik.values.cashboxes.find(item=>item===filial.value)){
              return filial
            }
            {/*@ts-ignore*/}
          })} options={filialOptions} styles={customStyles} isMulti={true} onChange={onChange}  placeholder={intl.formatMessage({id: 'select'})}/>

          <div className='text-left pt-5'>
            <button
                type='submit'
                className='btn btn-primary mt-7'
                data-kt-users-modal-action='submit'
                disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
            >
              <span className='indicator-label'>{intl.formatMessage({id: 'ADD_BUTTON'})}</span>
              {(formik.isSubmitting ) && (
                  <span className='indicator-progress'>
                  Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>


      </div>
  )
})

export {UserEditModalForm}
