import {FC} from 'react'
import moment from "moment/moment";

type Props = {
  last_access_date: string
}

const UserLastLoginCell: FC<Props> = ({last_access_date}) => {
  return (
      <div className='fw-bolder'>{moment(last_access_date).format('YYYY-MM-DD HH:mm')}</div>
  )
}

export {UserLastLoginCell}
