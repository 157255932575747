import React, {FC, MouseEventHandler, useEffect, useRef, useState} from 'react'
import {KTSVG} from '@metronic/helpers'
import {Step1} from './steps/Step1'
import {Step2} from './steps/Step2'
import {Step3} from './steps/Step3'
import {Step4} from './steps/Step4'
import {StepperComponent} from '@metronic/assets/ts/components'
import {Formik, Form, FormikValues} from 'formik'

import {AgentSchema, initsCompany, initsIndividual} from './CreateAccountWizardHelper'

import {useNavigate, useParams} from "react-router-dom";
import {observer} from "mobx-react";
import {useErrors} from "@providers/context/ErrorsProvider";
import {useIntl} from "react-intl";
import {filterObject, isDemo, localErrorsMessage} from "@utils/index";
import {Tooltip} from "antd";
import {AgentCreate} from "@providers/agents/_models";
import {Agent} from "@providers/agents/_request";



const Vertical: FC = observer(() => {
    const {type} = useParams()
    const agentSchema = AgentSchema()
    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)
    const [currentSchema, setCurrentSchema] = useState(agentSchema[0])
    const [initValues] = useState<AgentCreate>(type==='individual'?initsIndividual:initsCompany)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const {setError} = useErrors()
    const intl = useIntl()
    const [completedSteps, setCompletedSteps] = useState(0)



    const loadStepper = () => {
        stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
    }

    const prevStep = () => {
        if (!stepper.current) {
            return
        }
        stepper.current.goPrev()

        setCurrentSchema(agentSchema[stepper.current.currentStepIndex - 1])
    }


    const submitStep = async (values: AgentCreate, actions: FormikValues) => {
        if (stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber! && isDemo()) return

        if (!stepper.current) {
            return
        }

        setCurrentSchema(agentSchema[stepper.current.currentStepIndex])

        if (stepper.current.currentStepIndex === 3) {
            setLoading(true)

            await createAgent(values, stepper.current, actions)
            setLoading(false)

        }

        if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber&&stepper.current.currentStepIndex!==3) {
            setCompletedSteps(stepper.current?.currentStepIndex)
            stepper.current.goNext()
        } else {
            // stepper.current.goto(4)
            // actions.resetForm()
            // setCompletedSteps(0)
        }
    }

    useEffect(() => {
        if (!stepperRef.current) {
            return
        }

        loadStepper()
    }, [stepperRef])



    async function createAgent(values: AgentCreate, stepperData:any, actionsData:any) {
        const checkValue = values.phone.replace(/ /g, '').replace('+', '')

        let replace_values:any = {}

        for(let key in values){
            //@ts-ignore
            if(values[key]||values[key].trim().length){
                //@ts-ignore
                replace_values[key] = values[key]
            }
            if(key==='phone'){
                //@ts-ignore
                replace_values[key] = checkValue
            }

            if(key==='companies'){
                //@ts-ignore
                replace_values[key] = values.companies.map((item:any)=>{
                    return {
                        ...item,
                        commission: item.commission*1000
                    }
                })
            }
        }




        await Agent.createAgent(replace_values)
            .then((res) => {
                stepperData.goto(4)
                actionsData.resetForm()
                setCompletedSteps(0)
            }).catch(err => {
                let message = ''
                if(err?.response?.data?.message==='Validation failed'){
                    err?.response?.data?.fields.forEach((item:any)=>{
                        message+=`${item.name} - ${item.error}\n`
                    })
                }else{
                    message = localErrorsMessage('post-agent', 'message-match', err?.response?.data?.message, intl)
                }
                setError({status: err?.response?.status, message:  message, isError: true})
            })
    }



    const customValidate = (e: any, values: any) => {
        const isNotRequired = ['middle_name']
        const errors = filterObject(values, (val: any, key: string) => isNotRequired.includes(key) ? false : val === "" || val === null || val === 0)

        let err_message = ''
        if (Object.keys(errors).length) {
            const firstErrKey = Object.keys(errors)[0]
            const firstErrorSchemaIndex = agentSchema.findIndex(schema => {
                const arrSchemaKeys = Object.keys(schema.fields)
                return arrSchemaKeys.includes(firstErrKey)
            })
            stepper.current?.goto(firstErrorSchemaIndex + 1)
            setCurrentSchema(agentSchema[firstErrorSchemaIndex])
            for (let err in errors) {
                //@ts-ignore
                err_message += `* ${intl.formatMessage({id: err})} - ${intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})} ` + "\n"
            }
            setError({status: '', message: err_message, isError: true})
            e.preventDefault()
            return false
        }
        return true
    }


    return (
        <>
            <div
                ref={stepperRef}
                className='stepper stepper-pills stepper-column   d-flex flex-column flex-xl-row flex-row-fluid'
                id='kt_create_account_stepper'
            >

                <div
                    className='d-flex justify-content-center bg-body rounded justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
                    <div className='px-6 px-lg-10 px-xxl-15 py-20'>
                        <div className='stepper-nav'>
                            <div className='stepper-item current' data-kt-stepper-element='nav'>

                                <div className="d-flex align-items-center cursor-pointer" onClick={() => {
                                    if (!stepper.current) {
                                        return
                                    }
                                    setCurrentSchema(agentSchema[0])

                                    stepper.current?.goto(1)
                                    stepper.current?.steps.forEach((item, index) => {
                                        if (completedSteps > index) return
                                        item.classList.remove(('completed'))
                                    })
                                }}>
                                    <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <span className='stepper-number'>1</span>
                                    </div>

                                    <div className="d-flex flex-column">
                                        <div className='stepper-label'>
                                            <h3 className='stepper-title'>{intl.formatMessage({id: 'Agent-step1'})}</h3>
                                        </div>
                                        <div className='stepper-label'>
                                            <span
                                                className='stepper-subtitle fw-normal'>{intl.formatMessage({id: 'Agent-step1.1'})}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='stepper-item' data-kt-stepper-element='nav'>
                                <div className='stepper-line h-40px'></div>
                                <div className="d-flex align-items-center cursor-pointer" onClick={() => {
                                    if (!stepper.current) {
                                        return
                                    }
                                    setCurrentSchema(agentSchema[1])

                                    stepper.current?.goto(2)
                                    stepper.current?.steps.forEach((item, index) => {
                                        if (completedSteps > index) return
                                        item.classList.remove(('completed'))
                                    })
                                }}>
                                    <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <span className='stepper-number'>2</span>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <div className='stepper-label'>
                                            <h3 className='stepper-title'>{intl.formatMessage({id: 'Agent-step2'})}</h3>
                                        </div>
                                        <div className='stepper-label'>
                                            <span
                                                className='stepper-subtitle fw-normal'>{intl.formatMessage({id: 'Agent-step2.1'})}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='stepper-item' data-kt-stepper-element='nav'>
                                <div className='stepper-line h-40px'></div>
                                <div className="d-flex align-items-center cursor-pointer" onClick={() => {
                                    if (!stepper.current) {
                                        return
                                    }
                                    setCurrentSchema(agentSchema[2])

                                    stepper.current?.goto(3)
                                    stepper.current?.steps.forEach((item, index) => {
                                        if (completedSteps > index) return
                                        item.classList.remove(('completed'))
                                    })
                                }}>
                                    <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <span className='stepper-number'>3</span>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <div className='stepper-label'>
                                            <h3 className='stepper-title'>{intl.formatMessage({id: 'Agent-step3'})}</h3>
                                        </div>
                                        <div className='stepper-label'>
                                            <span
                                                className='stepper-subtitle fw-normal'>{intl.formatMessage({id: 'Agent-step3.1'})}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='stepper-item d-none' data-kt-stepper-element='nav'>
                                <div className='stepper-line h-40px'></div>
                                <div className="d-flex align-items-center cursor-pointer" onClick={() => {
                                    if (!stepper.current) {
                                        return
                                    }
                                    setCurrentSchema(agentSchema[2])

                                    stepper.current?.goto(4)
                                    stepper.current?.steps.forEach((item, index) => {
                                        if (completedSteps > index) return
                                        item.classList.remove(('completed'))
                                    })
                                }}>
                                    <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <span className='stepper-number'>4</span>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <div className='stepper-label'>
                                            <h3 className='stepper-title'>{intl.formatMessage({id: 'UPLOADING_DOCUMENTS'})}</h3>
                                        </div>
                                        <div className='stepper-label'>
                                            <span
                                                className='stepper-subtitle fw-normal'>{intl.formatMessage({id: 'span_step_uploading'})}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

                <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
                    <Formik enableReinitialize={true} validationSchema={currentSchema} initialValues={initValues}
                            onSubmit={submitStep}>
                        {(props) => {
                            const {
                                values,
                                errors,
                                handleChange,
                                setFieldValue,
                                submitForm,
                                touched,
                                setFieldTouched,
                                isSubmitting
                            } = props

                            return (

                                <Form className='py-20 w-100 w-xl-700px px-9' noValidate id='kt_create_account_form'>
                                    <div className='current' data-kt-stepper-element='content'>
                                        {/*@ts-ignore*/}
                                        <Step1 values={values} handleChange={handleChange} setFieldValue={setFieldValue}
                                               errors={errors} />
                                    </div>

                                    <div data-kt-stepper-element='content'>
                                        {/*@ts-ignore*/}
                                        <Step2 isSubmitting={isSubmitting} values={values} handleChange={handleChange} type={type} setFieldValue={setFieldValue} errors={errors} touched={touched} setFieldTouched={setFieldTouched}/>
                                    </div>

                                    <div data-kt-stepper-element='content'>
                                        {/*@ts-ignore*/}
                                        <Step3 handleChange={handleChange} setFieldValue={setFieldValue}
                                               values={values}/>
                                    </div>

                                    <div data-kt-stepper-element='content'>
                                        <Step4 />
                                    </div>

                                    {
                                        stepper.current?.currentStepIndex===4?(<div className='text-center'>
                                            <button
                                                onClick={()=>{
                                                    navigate(`/agents/${type}`)
                                                }}
                                                type='button'
                                                className='btn btn-lg btn-primary'
                                                data-kt-stepper-action='previous'
                                            >
                                                {intl.formatMessage({id: 'close'})}
                                            </button>
                                        </div>):''
                                    }


                                    <div className={`${stepper.current?.currentStepIndex===4?'d-none':'d-flex flex-stack'}`}>
                                        <div className='mr-2'>
                                            <button
                                                onClick={prevStep}
                                                type='button'
                                                className='btn btn-lg btn-light-primary me-3'
                                                data-kt-stepper-action='previous'
                                            >
                                                <KTSVG
                                                    path='/media/icons/duotune/arrows/arr063.svg'
                                                    className='svg-icon-4 me-1'
                                                />
                                                {intl.formatMessage({id: 'BACK'})}
                                            </button>
                                        </div>
                                        <Tooltip
                                            zIndex={(stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber! && isDemo()) ? 1 : -1}
                                            color='var(--kt-body-bg)' title={() => <span
                                            style={{color: 'var(--kt-text-gray-600)'}}>{(stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber! && isDemo()) ? intl.formatMessage({id: "DEMO_DISABLED"}) : ""}</span>}>
                                            <div>
                                                <button onClick={(e) => {
                                                    if (stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber! && isDemo()) return
                                                    // if (stepper.current?.currentStepIndex && stepper.current?.currentStepIndex >= completedSteps) return

                                                    // if (stepper.current?.currentStepIndex === 4) {
                                                    //     try {
                                                    //         const val = await currentSchema.validate(values)
                                                    //     } catch (e) {
                                                    //     }
                                                    // }

                                                    if (stepper.current?.currentStepIndex === 4) {
                                                        const canContinue = customValidate(e, values)
                                                        if (!canContinue) return
                                                    }

                                                    let err_message = ''
                                                    if (Object.keys(errors).length) {
                                                        for (let err in errors) {
                                                            //@ts-ignore
                                                            err_message += `* ${intl.formatMessage({id: err})} - ${errors[err]} ` + "\n"
                                                        }
                                                        setError({status: '', message: err_message, isError: true})
                                                    }
                                                }
                                                } type='submit' className='btn btn-lg btn-primary me-3'
                                                        disabled={loading || (stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber! && isDemo())}>
                                                <span className='indicator-label'>
                                            {stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber ? intl.formatMessage({id: 'CREATE'}) : stepper.current?.currentStepIndex !==
                                                stepper.current?.totatStepsNumber! - 1 && intl.formatMessage({id: 'THEN'})}
                                                    {stepper.current?.currentStepIndex ===
                                                        stepper.current?.totatStepsNumber! - 1 && intl.formatMessage({id: 'SEND'})}
                                                    <KTSVG
                                                        path='/media/icons/duotune/arrows/arr064.svg'
                                                        className='svg-icon-3 ms-2 me-0'
                                                    />
                                                </span>
                                                </button>
                                            </div>
                                        </Tooltip>
                                    </div>
                                </Form>
                            )
                        }
                        }
                    </Formik>
                </div>
            </div>
        </>
    )
})

export {Vertical}
