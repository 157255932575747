import InputMask from "react-input-mask";
import clsx from "clsx";
import React, {useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useErrors} from "@providers/context/ErrorsProvider";
import {Auth} from "@providers/auth/_request";
import {isDemo} from "@utils/index";


interface Props {
    loading: boolean
    counter: number
    setUserBlock: (val:boolean)=>void
    setLoading: (val:boolean)=>void
    setConfirm: (val:boolean)=>void
    setDidForgotPassword: (val:boolean)=>void
    setCounter: (val:number)=>void
    setSaveLastDate: (val:string)=>void
    setLastDateOtp: (val:string)=>void
    diffTimeBlock: (val:string)=>string
    setAccessToken: (val:string)=>void
}


export function LoginByPassword({loading,
                                    setUserBlock,
                                    setLoading,
                                    setConfirm,
                                    setCounter,
                                    setAccessToken,
                                    setDidForgotPassword,
                                    setSaveLastDate,
                                    setLastDateOtp,
                                    diffTimeBlock,
                                    counter}:Props){


    const intl = useIntl()
    const {setError} = useErrors()
    const [phone, setPhone] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [passError, setPassError] = useState<string|null>(null)




    useEffect(() => {
        if (isDemo()) {
            loginByPassword(null, "998900000000", "12345678")
        }
    }, [])

    const initialValues = {
        phone: '',
    }

    const loginSchema = Yup.object().shape({
        phone: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
    })

    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            //await submitForm(values, setStatus, setSubmitting)
        },
    })


    function loginByPassword(e?: any, demoPhone?: string, demoPass?: string) {
        e?.preventDefault()
        const checkValue = demoPhone ?? phone.replace(/ /g, '').replace('+', '')
        const reg = new RegExp(/^[0-9]{12}$/)


        if ((checkValue.match(reg)&&password?.length)||(demoPhone&&demoPass)) {
            setLoading(true)
            Auth.loginPassword(checkValue, demoPass ?? password)
                .then((response) => {
                    setUserBlock(false)
                    setLoading(false)
                    setConfirm(true)
                    setCounter(counter + 1)
                    const token = response.data.result.access_token
                    setAccessToken(token)
                })
                .catch((error) => {
                    setLoading(false)
                    const fields = error?.response?.data?.fields || []
                    const lastResend = fields.find((item:any)=>item?.name==='lastOtpResend')?.error||null

                    if(lastResend){
                        setSaveLastDate(lastResend)
                        setLastDateOtp(diffTimeBlock(lastResend))
                        setUserBlock(true)
                        return
                    }

                    if(error?.response?.data?.code==='BAD_REQUEST'){
                        setPassError(intl.formatMessage({id:'password-auth-error'}))
                    }else{
                        setError({status: error?.response?.status, message: error?.response?.data?.message, isError: true})
                    }


                })
        }else{
            if(!password?.length) setPassError('Это поле обязательно к заполнению')
        }
    }




    return(
        <form
            className='card rounded-3 w-md-550px py-10'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
        >
            <div className='fv-row mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark'>
                    {intl.formatMessage({id: 'phone'})}
                </label>

                <div className='d-flex align-items-center justify-content-end position-relative'>
                    <InputMask mask="+\9\9\8 99 999 99 99" {...formik.getFieldProps('phone')}
                               className={clsx('form-control form-control-lg form-control-solid',)}
                               value={phone}
                               onChange={(event: React.FormEvent<HTMLInputElement>) => {
                                   formik.handleChange(event)
                                   setPhone(event.currentTarget.value)
                                   setPassError(null)

                               }}
                               type="tel"
                               disabled={loading}
                    />
                    {
                        loading &&
                        <span className='spinner-border spinner-border-sm align-middle ms-2'
                              style={{position: 'absolute', right: '15px'}}/>
                    }
                </div>

                {passError && (
                    <div className='fv-plugins-message-container text-danger mt-3'>
                        <span role='alert'>{passError}</span>
                    </div>
                )}


            </div>
            <div className='fv-row mb-10'>
                <label className='form-label fs-6 fw-bolder text-dark'>
                    {intl.formatMessage({id: 'PASSWORD'})}
                </label>

                <div className='d-flex align-items-center justify-content-end position-relative'>
                    <input
                        type="password"
                        {...formik.getFieldProps('password')}
                        value={password}
                        disabled={loading}
                        className={clsx(
                            'form-control form-control-lg form-control-solid'
                        )}
                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                            setPassword(e.currentTarget.value)
                            setPassError(null)
                        }}
                    />
                    {
                        loading &&
                        <span className='spinner-border spinner-border-sm align-middle ms-2'
                              style={{position: 'absolute', right: '15px'}}/>
                    }
                </div>

                {passError ? (<div className='fv-plugins-message-container text-danger mt-3'>
                        <span role='alert'>{passError}</span>
                    </div>
                ):''}
            </div>

            <div className="d-flex justify-content-between">
                <span className="text-muted text-decoration-underline d-inline-block cursor-pointer"
                      onClick={()=>setDidForgotPassword(true)}>
                    {intl.formatMessage({id: 'FORGOT_PASS?'})}
                </span>
                <button disabled={loading} className="btn btn-primary" onClick={(e) => loginByPassword(e)}>
                    {intl.formatMessage({id: 'LOGIN'})}
                </button>
            </div>
        </form>
    )
}