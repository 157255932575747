export interface IStatistic {
    cashboxes?: any[]
    processingTypes?: string[],
    statuses?: string[],
    companyId?: number
    fromDate?: string
    toDate?: string
}
// "ID" | "SUM" | "CARD_NUMBER" | "STATUS" | "CREATED_AT"
export enum statisticStatus  {
    approved='approved',
    failed='failed',
    general='general',
    revert='revert'
}

export interface IDetaildStatisticResponse{
    result: IDetailedStatisticStatuses[]
}

export interface IDetailedStatisticStatuses{
    "general": IDetailedStatistic,
    "approved": IDetailedStatistic,
    "failed": IDetailedStatistic,
    "revert": IDetailedStatistic
    "init": {
        "totalCount": number,
        "totalAmount": number
    },
    "date": string
}



interface IDetailedStatistic{
    "totalAmount": number,
    "totalCount": number,
    "uzcardCount": number,
    "uzcardAmount": number,
    "humoCount": number,
    "humoAmount": number,
    "visaCount": number,
    "visaAmount": number,
    "masterCount": number,
    "masterAmount": number
}

export interface ITransactions {
    companyId?: number,
    transactionId?: string,
    "size": number,
    "page": number,
    "cashboxes"?: number[],
    "processingTypes": string[],
    "statuses": string[],
    "fromDate": string,
    "toDate": string
}

export interface IStatisticsID {
    id: string,
    branches: any[]
    page: number,
    count: number
}