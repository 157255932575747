/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {MenuComponent} from '@metronic/assets/ts/components'
import { KTSVG} from '@metronic/helpers'
import {useNavigate, useParams} from "react-router-dom";
import {observer} from "mobx-react";
import {useIntl} from "react-intl";
import {roleRouting, roleRoutingAdmin} from "@utils/index";
import {employeeStore} from "@store/employee";
import {useListView} from "../providers/ListViewProvider";
import {confirmAlert} from "react-confirm-alert";
import {Admins} from "@providers/admins/_request";
import {useErrors} from "@providers/context/ErrorsProvider";
import {Employee} from "@providers/employee/_request";


type Props = {
  id: number
}

const UserActionsCell: FC<Props> = observer(({id}) => {

    const {companyId} = useParams()
    const intl = useIntl()
    const role = employeeStore.getRole
    const navigate = useNavigate()
    const {setError} = useErrors()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])




    const deleteItem = async ()=>{
        if(roleRoutingAdmin('action', role, 'employee-delete')||roleRouting('employees/delete', role)){
            confirmAlert({
                title: intl.formatMessage({id: 'WARNING'}),
                message: intl.formatMessage({id:'WARNING_DELETE_EMPLOYEE'}),
                buttons: [
                    {
                        label: intl.formatMessage({id: 'ACTIONS_YES'}),
                        onClick: async () => {
                            await Employee.deleteEmployee(companyId||'',id)
                                .then(()=>{
                                    employeeStore.setEmployeeFilial(employeeStore.getEmployeeFilial.filter(item=>item.id!==id))
                                }).catch(err=>{
                                    setError({status: err?.response?.status, message: err?.response?.data?.code === "METHOD_NOT_ALLOWED"?intl.formatMessage({id: 'not_employee'}):err?.response?.data?.message, isError: true})
                                })

                        }
                    },
                    {
                        label: intl.formatMessage({id: 'ACTIONS_NO'}),
                        onClick: async () => {
                            return
                        }
                    }
                ]
            })

        }
    }



  return (
      <div>
          {(roleRoutingAdmin('action', role, 'employee-logs')||roleRouting('employee/logs', role))?
              (  <a href="#" onClick={(e)=>{
              e.preventDefault()
              navigate(`/${companyId}/employees/view/${id}`)
          }} className="btn btn-icon-gray-500 btn-active-icon-primary p-5">
              <KTSVG path='/media/icons/notepad.svg'
                     className="svg-icon-2 svg_icon_edit"
              /></a>):''}

          {(roleRoutingAdmin('action', role, 'employee-delete')||roleRouting('employees/delete', role))
              ?(<a href="#" onClick={(e)=>{
              e.preventDefault()
              deleteItem()
          }} className="btn btn-icon-gray-500 btn-active-icon-danger p-5">
              <KTSVG path='/media/icons/duotune/general/gen027.svg'
                     className="svg-icon-2 svg_icon_delete"/></a>):''}
      </div>
  )
})

export {UserActionsCell}
