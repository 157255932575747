import {FC, PropsWithChildren, useMemo} from 'react'
import {HeaderProps} from 'react-table'
import {useIntl} from "react-intl";
import {observer} from "mobx-react";
import {agentStore} from "@store/agents";
import {Agent} from "@providers/agents/_request";
import {useParams} from "react-router-dom";
import {autopayStore} from "@store/autopay";

type Props = {
  className?: string
  title?: string
  tableProps: PropsWithChildren<HeaderProps<any>>
}
const TableCustomHeader: FC<Props> = observer(({className, title, tableProps}) => {
  const id = tableProps.column.id
  const intl = useIntl()
  const sort = useMemo(() => autopayStore.getSortingContract, [autopayStore.getSortingContract])
  const {type} = useParams()



  async function updateData(){

    // await Agent.getAgents(dataFilter)
    //     .then(result=>{
    //       agentStore.setAgent(result?.data?.result.map((item: any, index: number) => {
    //         if (sort.page && sort.page > 2) {
    //           item['num'] = ((sort.page - 1) * 20) + index + 1
    //         } else if (sort.page == 2) {
    //           item['num'] = 21 + index
    //         } else {
    //           item['num'] = index + 1
    //         }
    //         return item
    //       }))
    //     })
  }




  const sortColumn = () => {
    // avoid sorting for these columns
    if (id === 'actions' || id === 'selection') {
      return
    }

    if(id==='productPrice'||id==='prepaymentPrice'||id==='paidSum'||id==='status'){
      window.alert(id)
      autopayStore.setSortingContracts({...sort, sortFromMax:!sort.sortFromMax, sortBy:id.toUpperCase() })
      //updateData()
    }





  }



  return (
      <th
          {...tableProps.column.getHeaderProps()}
          className={`${className} ${(sort.sortBy||'').toLowerCase() === id ? sort.sortFromMax ? "table-sort-desc" : "table-sort-asc" : ""} text-left`}
          style={{cursor: 'pointer', textTransform: "capitalize"}}
          onClick={sortColumn}
      >
        {title&&title.length?intl.formatMessage({id:title}):''}
      </th>
  )
})

export {TableCustomHeader}
