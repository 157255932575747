import clsx from 'clsx'
import {KTSVG} from '@metronic/helpers'
import {ThemeModeType, useThemeMode} from "@metronic/partials";
import {Link, useParams} from "react-router-dom";
import {Company} from "@providers/company/_request";
import {company} from "@store/company";
import {observer} from "mobx-react";
import {useEffect, useMemo, useState} from "react";
import {AccountMe} from "@providers/account/_models";
import {Account} from "@providers/account/_request";


/* eslint-disable jsx-a11y/anchor-is-valid */
type Props = {
    toggleBtnClass?: string
    toggleBtnIconClass?: string
    menuPlacement?: string
    menuTrigger?: string
}

const CompanySwitcher = observer(({
                                      toggleBtnClass = '',
                                      menuPlacement = 'bottom-end',
                                      menuTrigger = "{default: 'click', lg: 'hover'}",
                                  }: Props) => {
    const user = useMemo(()=>company.getMe, [company.getMe])

    const {companyId} = useParams()

    useEffect(() => {
        getData()
    }, [])


    function getData() {

        Company.getCompany()
            .then((response) => {
                if (response.data.result) {
                    company.setCompany(response.data.result)
                }
            }).catch(e => {
        })
    }


    return (
        <div className="d-flex flex-column align-items-end">
            {/* begin::Menu toggle */}
            <div className="flex-grow-1" style={{fontSize: "12px", color: "rgba(109, 109, 128, 1)", fontWeight: 600}}>
                {user?.first_name}
            </div>
            <div className="flex-grow-1">
                <a
                    href='#'
                    // className={clsx('btn btn-icon ', toggleBtnClass)}
                    style={{width: "100px"}}
                    data-kt-menu-trigger={menuTrigger}
                    data-kt-menu-attach='parent'
                    data-kt-menu-placement={menuPlacement}
                >
                    <p
                        className={"mb-0"}
                        style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "100px",
                            textAlign: "end",
                            color: "rgba(76, 76, 102, 1)",
                            fontSize: "12px",
                            fontWeight: 600
                        }}
                    >{companyId && company.getCompany.find(item => item.id === +companyId)?.name}
                    </p>
                </a>
                {/* begin::Menu toggle */}

                {/* begin::Menu */}
                <div
                    className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px'
                    data-kt-menu='true'
                >
                    {/* begin::Menu item */}
                    {
                        company.getCompany.map(item => {
                            return (
                                <div className='menu-item px-3 my-0' key={item.id}>
                                    <Link
                                        to={`/${item.id}/dashboard-merchant`}
                                        className={clsx('menu-link px-3 py-2', {active: companyId ? +companyId === item.id : false})}
                                    >
                                        <p
                                            className={"mb-0"}
                                            style={{
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis"
                                            }}
                                        >{item.name}
                                        </p>
                                    </Link>
                                </div>
                            )
                        })
                    }
                    {/* end::Menu item */}
                </div>
            </div>
            {/* end::Menu */}
        </div>
    )
})

export {CompanySwitcher}
