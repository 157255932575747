import React from 'react';
import {useIntl} from "react-intl";

interface props {

}

const Offer: React.FC<props> = () => {
    const intl = useIntl()
    return (
        <div className={'card p-8'}>
            <h1>{intl.formatMessage({id :'agreement.title1'})}</h1>
            <h4 className='font-weight'>{intl.formatMessage({id :'agreement.title2'})}</h4>
            <p>{intl.formatMessage({id :'agreement.text1'})}</p>
            <p>{intl.formatMessage({id :'agreement.text2'})}</p>
            <p>{intl.formatMessage({id :'agreement.text3'})}</p>
            <p>{intl.formatMessage({id :'agreement.text4'})}</p>
            <h4 className='font-weight'>{intl.formatMessage({id :'agreement.title3'})}</h4>
            <p>{intl.formatMessage({id :'agreement.text5'})}</p>
            <p>{intl.formatMessage({id :'agreement.text6'})}</p>
            <h4 className='font-weight'>{intl.formatMessage({id :'agreement.title4'})}</h4>
            {
                Array.from(Array(20).keys()).map(item => {
                    return (<p key={item}>{intl.formatMessage({id :`agreement.text${item + 7}`})}</p>)
                })
            }
            <h4 className='font-weight'>{intl.formatMessage({id :'agreement.title5'})}</h4>
            {
                Array.from(Array(7).keys()).map(item => {
                    return (<p key={item}>{intl.formatMessage({id :`agreement.text${item + 27}`})}</p>)
                })
            }
            <h4 className='font-weight'>{intl.formatMessage({id :'agreement.title6'})}</h4>
            {
                Array.from(Array(12).keys()).map(item => {
                    return (<p key={item}>{intl.formatMessage({id :`agreement.text${item + 34}`})}</p>)
                })
            }
            <h4 className='font-weight'>{intl.formatMessage({id :'agreement.title7'})}</h4>
            {
                Array.from(Array(2).keys()).map(item => {
                    return (<p key={item}>{intl.formatMessage({id :`agreement.text${item + 46}`})}</p>)
                })
            }
            <h4 className='font-weight'>{intl.formatMessage({id :'agreement.title8'})}</h4>
            {
                Array.from(Array(2).keys()).map(item => {
                    return (<p key={item}>{intl.formatMessage({id :`agreement.text${item + 48}`})}</p>)
                })
            }
            <h4 className='font-weight'>{intl.formatMessage({id :'agreement.title9'})}</h4>
            <p>{intl.formatMessage({id :`agreement.text50`})}</p>
            <h4 className='font-weight'>{intl.formatMessage({id :'agreement.title10'})}</h4>
            {
                Array.from(Array(5).keys()).map(item => {
                    return (<p key={item}>{intl.formatMessage({id :`agreement.text${item + 51}`})}</p>)
                })
            }
            <h4 className='font-weight'>{intl.formatMessage({id :'agreement.title11'})}</h4>
            {
                Array.from(Array(8).keys()).map(item => {
                    return (<p key={item}>{intl.formatMessage({id :`agreement.text${item + 56}`})}</p>)
                })
            }
        </div>
    );
};

export default Offer;