/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from "react-router-dom";
import {CompanyResponse} from "@providers/company/_models";
import {observer} from "mobx-react";
import {image_default, JUNIOR_CASHIER} from "@utils/constants";
import {employeeStore} from "@store/employee";
import {Company} from "@providers/company/_request";
import {roleRoutingAdmin} from "@utils/index";
import {useIntl} from "react-intl";

type Props = {
    data: any
}

const MerchantName: FC<Props> = observer(({data}) => {


    if(roleRoutingAdmin('action', employeeStore.getRole, 'link-merchant')){
        return (
            <Link to={`/companies/${data.company_id}`}>
                <div className=' d-flex align-items-center '>
                    <div className='logo-table-admin'
                         style={{backgroundImage: `url(${data?.logo_file?Company.getFiles(data?.logo_file):`/${image_default}`})`}}/>
                    <p className='fs-6 text-gray-600 fw-normal mb-0'>{data?.company_name}</p>
                </div>
            </Link>
        )
    }

    return (
        <div className=' d-flex align-items-center '>
            <div className='logo-table-admin'
                 style={{backgroundImage: `url(${data?.logo_file?Company.getFiles(data?.logo_file):`/${image_default}`})`}}/>
            <p className='fs-6 text-gray-600 fw-normal mb-0'>{data?.company_name}</p>
        </div>
    )

})

export {MerchantName}
