/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {statuses_card, statuses_const, statuses_contracts} from "@utils/constants";
import {useIntl} from "react-intl";
import {isProd} from "@utils/index";

type FilterProps = {
    show: boolean
    updateFilter: (val: boolean) => void
    filters: any
    disableStatuses?: boolean
    handleClose: VoidFunction
}

const icons:any = {
    ACTIVE: '/media/svg/approved.svg',
    UNVERIFIED: '/media/icons/duotune/general/load.svg',
    TERMINATED: '/media/icons/duotune/general/close.svg',
    OUTDATED: '/media/icons/duotune/general/status_calendar.svg',
    CASH_PAID: '/media/icons/duotune/general/pay.svg',
    FINISHED: '/media/icons/duotune/general/check.svg'
}

const classStatus:any = {
    OUTDATED: "badge-light-dark",
    ACTIVE: "badge-light-success",
    TERMINATED: "badge-light-danger",
    FINISHED: "badge-light-primary",
    CASH_PAID: "badge-light-info",
    UNVERIFIED: "badge-light-warning",
    default_status: "badge-light-dark"
}

export function FilterDropDown({show, updateFilter, filters, disableStatuses, handleClose}: FilterProps) {

    const intl = useIntl()



    const [filterValues, setFilterValues] = useState(filters)



    function filtersHandler(type: string, values: any) {
        const update_filters: any = {}
        update_filters[type] = values
        setFilterValues({...filterValues, ...update_filters})
    }

    useEffect(() => {
        setFilterValues(filters)
    }, [filters])
    return (
        <div className={`menu menu-sub filter-dropdown menu-sub-dropdown w-250px w-md-350px w-sm-300 ${show && 'show'}`}
             data-kt-menu='true'>
            <div className='px-7 py-5'>
                <div className='fs-4 text-dark fw-bold'>{intl.formatMessage({id: 'Filters contracts'})}</div>
            </div>

            <div className='separator border-gray-200'></div>

            <div className='px-7 py-5'>

                <div className='mb-6'>

                    <div className=''>

                        {
                            statuses_contracts.map((item: string, index) => {
                                const status_icon:any = icons[item]
                                const status_style:any = classStatus[item]?classStatus[item]:classStatus['default_status']

                                return (
                                    <div key={index} className='d-flex justify-content-between align-items-center mt-3'>
                                        <label
                                            className='form-check form-check-sm form-check-custom form-check-solid me-5 '>
                                            <input className='form-check-input' type='checkbox'
                                                   checked={(filterValues?.statuses||[]).includes(item.toUpperCase())}
                                                   value={item}
                                                   disabled={disableStatuses}
                                                   onChange={(e) => {
                                                       if (!e.target.checked) {
                                                           filtersHandler('statuses', (filterValues?.statuses||[]).filter((item: string) => item !== e.target.value))
                                                       } else {
                                                           filtersHandler('statuses', [...filterValues?.statuses, e.target.value])
                                                       }

                                                   }}
                                            />
                                            <span
                                                className='form-check-label'>{intl.formatMessage({id: item.toUpperCase()})}</span>
                                        </label>

                                        <div className={`badge rounded-4 ${status_style} justify-content-start text-left align-content-center p-3`} style={{fontSize: "12px", fontWeight: 500}}>
                                            {status_icon&&<img src={status_icon}  style={{objectFit: 'contain'}}/>}
                                        </div>


                                    </div>
                                )


                            })
                        }
                    </div>
                </div>


                <div className='d-flex justify-content-between'>

                    <button type='submit' className='btn btn-primary text-white fw-bolder' data-kt-menu-dismiss='true'
                            onClick={() => {
                                updateFilter(filterValues)
                            }}>
                        {intl.formatMessage({id: 'Apply'})}
                    </button>
                    <button type='submit' className='btn btn-secondary text-muted fw-bolder' data-kt-menu-dismiss='true'
                        // onClick={() => handleClose()}>
                            onClick={() => {
                                const updateStatuses: any = {}
                                const updateCards: any = {}
                                updateStatuses['statuses'] = statuses_contracts.map(item => item.toUpperCase())
                                updateFilter({...filterValues, ...updateStatuses, ...updateCards})
                            }}>
                        {intl.formatMessage({id: 'reset'})}
                    </button>
                </div>
            </div>
        </div>
    )
}
