import {autopayInstance} from "@providers/autopay/requests/axiosConfig";
import {ISchedulePay} from "@providers/autopay/models/_models";


export const ScheduleService = {
    async postCashPayment(contractId: string, data: ISchedulePay[]) {
        return autopayInstance.post(`/schedules/payments/cash?contractId=${contractId}`, data)
    },
    async withdrawMoneyFromCard(scheduleId: string) {
        return autopayInstance.get(`/schedules/payments/card/${scheduleId}`)
    },
    async getExpiredByContractId(contractId: string) {
        return autopayInstance.get(`/schedules/expired?contractId=${contractId}`)
    }
}