import React, {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useListView} from '../providers/ListViewProvider'
import {AccountResponse, CreateEmployee} from "@providers/employee/_models";
import {Employee} from "@providers/employee/_request"
import {useParams} from "react-router-dom";
import {REGEXP_PHONE} from "@utils/constants";
import {observer} from "mobx-react";
import {employeeStore} from "@store/employee";
import {useIntl} from "react-intl";
import {useErrors} from "@providers/context/ErrorsProvider";
import {localErrorsMessage} from "@utils/index";
import {Vertical} from "../../forms/edit-agent/components/Vertical";

type Props = {
  isUserLoading: boolean
  user?: AccountResponse
}


const initialEmployee:Partial<CreateEmployee> = {
  phone: '',
  employeeTypeId: 0,
  firstName: '',
  lastName: '',
  email: ''
}

type IPositions = {
  value: number,
  label: string
}

const UserEditModalForm: FC<Props> = observer(({user, isUserLoading}) => {
  const {setItemIdForUpdate} = useListView()
  const [positionsOptions, setPositionOptions] = useState<IPositions[]>()
  const {setError} = useErrors()
  const {companyId} = useParams()
  const intl = useIntl()

  useEffect(()=>{
    let elem:any = document.body
    elem.addEventListener('click', (e:any)=>{
      if(e.target.getAttribute('id')==='kt_modal_add_user'){
        setItemIdForUpdate(undefined)
      }
    })

    getEmployeeTypes()
  },[])




  async function getEmployeeTypes() {
    Employee.getEmployeeTypes()
        .then(result=>{
          setPositionOptions(result.data.result.map(item=>{
            return {
              value: item.id,
              label: item.name
            }
          }))
        })
        .catch(err=>{
          setError({status: err?.response?.status, message: localErrorsMessage('post-employee', 'message-match', err?.response?.data?.message, intl), isError: true})
        })
  }


  const createUserSchema = Yup.object().shape({
    firstName: Yup.string()
        .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
    lastName: Yup.string()
        .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
    email: Yup.string()
        .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
    phone: Yup.string()
        .matches(
            REGEXP_PHONE,
            intl.formatMessage({id: 'ERROR_VALIDATION_PHONE'})
        )
        .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
    employeeTypeId: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
  })

  const editUserSchema = Yup.object().shape({
    position: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
  })

  const [userForEdit] = useState<Partial<CreateEmployee>>({
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    employeeTypeId: initialEmployee.employeeTypeId,
    phone: user?.phone || initialEmployee.phone,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh&&companyId) {
      Employee.getEmployee(companyId).then(response=>employeeStore.setEmployee(response?.data?.result||[]))
    }
    setItemIdForUpdate(undefined)
  }



  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: !user?.id?createUserSchema:editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      const checkValue = (values?.phone||'').replace(/ /g,'').replace('+','')
      const data:CreateEmployee = {
        phone: checkValue,
        role: 'USER',
        employeeTypeId: values.employeeTypeId?Number(values.employeeTypeId):0,
        firstName: values.firstName||'',
        lastName: values.lastName||'',
        email: values.email,
        companyId: Number(companyId)
      }


      if (user?.id) {

        await Employee.editEmployee(companyId??'', user.id, data)
            .then(()=>{}).catch(err=>{
              setError({status: err?.response?.status, message: err?.response?.data?.code === "METHOD_NOT_ALLOWED"?intl.formatMessage({id: 'not_role'}):err?.response?.data?.message, isError: true})
            })
      } else {

        await Employee.createEmployee(data)
            .then(()=>{}).catch(err=>{
              setError({status: err?.response?.status, message: localErrorsMessage('post-employee', 'message-match', err?.response?.data?.message, intl), isError: true})
            })
      }

      setSubmitting(true)
      cancel(true)

    },
  })



  return (
      <>

        <Vertical agent={user}/>
      </>
  )
})



export {UserEditModalForm}
