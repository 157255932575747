import React, {FC, useEffect, useRef, useState} from 'react'
import {KTSVG} from '@metronic/helpers'
import {StepperComponent} from '@metronic/assets/ts/components'
import {ErrorMessage, Field, Form, Formik} from 'formik'

import {CompanySchema, inits} from './CreateAccountWizardHelper'

import {ICompanyCreate, ICreateCompanyForm} from "@providers/company/_models";
import {Company} from "@providers/company/_request";
import {useNavigate} from "react-router-dom";
import {company} from "@store/company";
import {observer} from "mobx-react";
import {useErrors} from "@providers/context/ErrorsProvider";
import {useIntl} from "react-intl";
import {isDemo, localErrorsMessage} from "@utils/index";
import {Tooltip} from "antd";
import InputMask from "react-input-mask";
import UserSearchInput from "./user-search-input/user-search-input";
import {UsersListLoading} from "@metronic/partials/widgets/tables/_modals/components/loading/UserListLoading";
import {IAssignAccount, ICreateAccount} from "@providers/account/_models";
import {rolesStore} from "@store/roles";
import {USER_ADMIN} from "@utils/constants";
import UploadLogo from "./uploadLogo/uploadLogo";
import {Account} from "@providers/account/_request";
import {openNotificationError, openNotificationSuccess} from "@metronic/partials/notification";
import {employeeStore} from "@store/employee";
import {IEmployeeType} from "@providers/employee/_models";


const Vertical: FC = observer(() => {
    const companySchema = CompanySchema()
    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)
    const [currentSchema] = useState(companySchema[0])
    const [initValues, setInitValues] = useState<ICreateCompanyForm>(inits)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const {setError} = useErrors()
    const intl = useIntl()
    const [id, setId] = useState<number | undefined>()

    const role = rolesStore.getRoles.find((role) => role === USER_ADMIN)

    const employeeTypes: IEmployeeType[] = employeeStore.getEmployeeTypes

    const onSetId = (value: number | undefined) => {
        setId(value)
    }

    useEffect(() => {
        setInitValues({
            ...initValues
        })
    }, [company.getMe])


    const submitStep = (values: ICreateCompanyForm) => {
        const accountData: ICreateAccount = {
            email: values.email,
            lastName: values.lastName,
            firstName: values.firstName,
            phone: values.phone.replace(/\s/g, '').replace('+', '').replaceAll('_', ''),
            role: '',
            employeeTypeId: 1
        }

        if (employeeTypes.length) {
            accountData.employeeTypeId = employeeTypes[0].id
        }

        if (role) {
            accountData.role = role
        }


        const data: ICompanyCreate = {
            name: values.name,
            inn: values.inn,
            active: true,
            addressRegistry: values.addressRegistry,
            brandName: values.brandName,
            categoryId: 4
        }
        const formData = new FormData()


        if (values.logoFile) {
            formData.append('logoFile', values.logoFile)
        }

        setLoading(true)
        if (values.logoFile) {
            createCompanyWithLogo(data, formData, accountData)
        } else {
            createCompany(data, accountData)
        }
    }


    const createAccountHandler = (data: ICreateAccount) => {
        Account.createAccount(data).then((response) => {
        }).catch((err) => {
            setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
            openNotificationError(err?.response?.data?.message)
        }).finally(() => {
            setLoading(false)
            openNotificationSuccess(intl.formatMessage({id: 'ADD_COMPANY_SUCCESS'}))
            navigate('/companies')
        })
    }

    const createCompanyWithLogo = (values: ICompanyCreate, formData: FormData, userData: ICreateAccount) => {
        Company.createCompany(values).then((res) => {
            Company.createCompanyFiles(formData, res.data.result.id.toString()).catch((err) => setError({
                status: err?.response?.status,
                message: err?.response?.data?.message,
                isError: true
            }))
            if (!id) {
                const data: ICreateAccount = {
                    ...userData,
                    companyId: res.data.result.id
                }
                setId(res.data.result.id)
                createAccountHandler(data)
                return
            }
            assignAccountToCompany(res.data.result.id)
        }).catch(err => {
            let message = ''
            if (err?.response?.data?.message === 'Validation failed') {
                err?.response?.data?.fields.forEach((item: any) => {
                    message += `${item.name} - ${item.error}\n`
                })
            } else {
                message = localErrorsMessage('post-company', 'message-match', err?.response?.data?.message, intl)
            }
            setError({status: err?.response?.status, message: message, isError: true})

        }).finally(() => setLoading(false))
    }

    const createCompany = (values: ICompanyCreate, userData: ICreateAccount) => {
        Company.createCompany(values)
            .then((response) => {
                if (response && response.data) {
                    if (!id) {
                        const data: ICreateAccount = {
                            ...userData,
                            companyId: response.data.result.id
                        }
                        setId(response.data.result.id)
                        createAccountHandler(data)
                        return
                    }
                    assignAccountToCompany(response.data.result.id)
                }
            }).catch(err => {
            let message = ''
            if (err?.response?.data?.message === 'Validation failed') {
                err?.response?.data?.fields.forEach((item: any) => {
                    message += `${item.name} - ${item.error}\n`
                })
            } else {
                message = localErrorsMessage('post-company', 'message-match', err?.response?.data?.message, intl)
            }
            setError({status: err?.response?.status, message: message, isError: true})

        }).finally(() => setLoading(false))
    }


    const assignAccountToCompany = (companyId: number) => {
        if (id) {
            const data: IAssignAccount = {
                id: id,
                companies: [companyId]
            }
            Account.assignUserToCompany(data).then((response) => {
            }).catch((err) => {
                openNotificationError(err?.response?.data?.message)
                setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
            }).finally(() => {
                setLoading(false)
                openNotificationSuccess(intl.formatMessage({id: 'ADD_COMPANY_SUCCESS'}))
                navigate('/companies')
            })
        }
    }

    return (
        <>
            <div
                ref={stepperRef}
                className='stepper stepper-pills stepper-column   d-flex flex-column flex-xl-row flex-row-fluid'
                id='kt_create_account_stepper'
            >
                {loading && <UsersListLoading/>}


                <div className='d-flex flex-row-fluid bg-body rounded'>
                    <Formik enableReinitialize={true} validationSchema={currentSchema} initialValues={initValues}
                            onSubmit={submitStep}>
                        {(props) => {
                            const {
                                values,
                                errors,
                                setFieldValue
                            } = props

                            return (

                                <Form className='py-20 w-100 px-9' noValidate id='kt_create_account_form'>
                                    <div className='w-100'>
                                        <div className="pb-14">
                                            <h1 className="fw-bold text-dark">{intl.formatMessage({id: 'companyCreation'})}</h1>
                                        </div>
                                        <div className="pb-10 pb-lg-12">
                                            <p className='fs-2 text-gray-400'>{intl.formatMessage({id: 'upload_file_text'})}</p>
                                            <div className='d-flex align-items-center'>
                                                <div style={{width: '50px', height: '50px'}} className='mx-3'>
                                                    <img alt={''} className='w-100 h-100' style={{objectFit: 'contain'}}
                                                         src='/media/svg/files/jpeg.png'/>
                                                </div>
                                                <div style={{width: '50px', height: '50px'}}>
                                                    <img alt={''} className='w-100 h-100' style={{objectFit: 'contain'}}
                                                         src='/media/svg/files/png.png'/>
                                                </div>
                                            </div>
                                            <p className='fs-2 text-gray-400 mt-3'>{intl.formatMessage({id: 'upload_file_text1'})}</p>
                                            <UploadLogo {...props}/>
                                        </div>
                                        <div className='mb-8 fv-row'>
                                            <label
                                                className='form-label mb-3 required'>{intl.formatMessage({id: 'companyName'})}</label>
                                            <Field
                                                type='text'
                                                className={`form-control form-control-lg form-control-solid`}
                                                name='name'
                                                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                    if (!e.currentTarget.value.length || e.currentTarget.value.match(/^[a-zA-Z0-9А-Яа-я\s]+$/)) {
                                                        props.handleChange(e)
                                                    }
                                                }}
                                            />
                                            <div className='text-danger mt-2'>
                                                <ErrorMessage name='name'/>
                                            </div>
                                        </div>
                                        <div className='mb-8 fv-row'>
                                            <label
                                                className='form-label mb-3 required'>{intl.formatMessage({id: 'name'})}</label>
                                            <Field
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                name='brandName'
                                                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                    if (!e.currentTarget.value.length || e.currentTarget.value.match(/^[a-zA-Z0-9А-Яа-я\s]+$/)) {
                                                        props.handleChange(e)
                                                    }
                                                }}
                                            />
                                            <div className='text-danger mt-2'>
                                                <ErrorMessage name='brandName'/>
                                            </div>
                                        </div>
                                        <div className='mb-8 fv-row'>
                                            <label
                                                className='form-label mb-3 required'>{intl.formatMessage({id: 'addressRegistry'})}</label>
                                            <Field
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                name='addressRegistry'
                                                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                    if (!e.currentTarget.value.length || e.currentTarget.value.match(/^[a-zA-ZА-Я-а-я0-9-,.\s]+$/)) {
                                                        props.handleChange(e)
                                                    }
                                                }}
                                            />
                                            <div className='text-danger mt-2'>
                                                <ErrorMessage name='addressRegistry'/>
                                            </div>
                                        </div>
                                        <div className='mb-8 fv-row'>
                                            <label
                                                className='form-label mb-3 required'>{intl.formatMessage({id: 'inn'})}</label>
                                            <Field
                                                type='text'
                                                placeholder='123456789'
                                                className='form-control form-control-lg form-control-solid'
                                                name='inn'
                                                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                    if (e.currentTarget.value.match(/^\d{0,9}$/)) props.handleChange(e)
                                                }}
                                            />
                                            <div className='text-danger mt-2'>
                                                <ErrorMessage name='inn'/>
                                            </div>
                                        </div>
                                        <UserSearchInput setId={onSetId} setFieldValue={setFieldValue}/>
                                        <div className='mb-8 fv-row'>
                                            <label
                                                className='form-label mb-3 required'>{intl.formatMessage({id: 'ceo_first_name'})}</label>
                                            <Field
                                                type='text'
                                                className={`${id ? 'disabled' : ''} form-control form-control-lg form-control-solid`}
                                                name='firstName'
                                                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                    if (!e.currentTarget.value.length || e.currentTarget.value.match(/^[a-zA-Z0-9А-Яа-я\s]+$/)) {
                                                        props.handleChange(e)
                                                    }
                                                }}
                                            />
                                            <div className='text-danger mt-2'>
                                                <ErrorMessage name='first_name'/>
                                            </div>
                                        </div>
                                        <div className='mb-8 fv-row'>
                                            <label
                                                className='form-label mb-3 required'>{intl.formatMessage({id: 'ceo_last_name'})}</label>
                                            <Field
                                                type='text'
                                                className={`${id ? 'disabled' : ''} form-control form-control-lg form-control-solid`}
                                                name='lastName'
                                                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                    if (!e.currentTarget.value.length || e.currentTarget.value.match(/^[a-zA-Z0-9А-Яа-я\s]+$/)) {
                                                        props.handleChange(e)
                                                    }
                                                }}
                                            />
                                            <div className='text-danger mt-2'>
                                                <ErrorMessage name='last_name'/>
                                            </div>
                                        </div>
                                        <div className='mb-8 fv-row'>
                                            <label
                                                className='form-label mb-3 required'>{intl.formatMessage({id: 'phone'})}</label>
                                            <Field
                                                type='text'
                                                component={InputMask}
                                                mask="+\9\9\8 99 999 99 99"
                                                className={`${id ? 'disabled' : ''} form-control form-control-lg form-control-solid`}
                                                name='phone'
                                                value={values.phone}
                                                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                    const value = e.currentTarget.value || '';
                                                    setFieldValue('phone', value)

                                                }}
                                            />
                                            <div className='text-danger mt-2'>
                                                <ErrorMessage name='phone'/>
                                            </div>
                                        </div>
                                        <div className='mb-8 fv-row'>
                                            <label
                                                className='form-label mb-3'>{intl.formatMessage({id: 'email'})}</label>
                                            <Field
                                                type='email'
                                                className={`${id ? 'disabled' : ''} form-control form-control-lg form-control-solid`}
                                                name='email'
                                                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                    props.handleChange(e)
                                                }}
                                            />
                                            <div className='text-danger mt-2'>
                                                <ErrorMessage name='email'/>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='d-flex justify-content-end'>
                                        <Tooltip
                                            zIndex={(stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber! && isDemo()) ? 1 : -1}
                                            color='var(--kt-body-bg)' title={() => <span
                                            style={{color: 'var(--kt-text-gray-600)'}}>{(stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber! && isDemo()) ? intl.formatMessage({id: "DEMO_DISABLED"}) : ""}</span>}>
                                            <div>
                                                <button onClick={() => {
                                                    if (stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber! && isDemo()) return
                                                    let err_message = ''
                                                    if (Object.keys(errors).length) {
                                                        for (let err in errors) {
                                                            //@ts-ignore
                                                            err_message += `* ${intl.formatMessage({id: err})} - ${errors[err]} ` + "\n"
                                                        }
                                                        setError({status: '', message: err_message, isError: true})
                                                    }
                                                }
                                                } type='submit' className='btn btn-lg btn-primary me-3'
                                                        disabled={loading || (stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber! && isDemo())}>
                                                <span className='indicator-label'>
                                            {stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber ? intl.formatMessage({id: 'CREATE'}) : stepper.current?.currentStepIndex !==
                                                stepper.current?.totatStepsNumber! - 1 && intl.formatMessage({id: 'THEN'})}
                                                    {stepper.current?.currentStepIndex ===
                                                        stepper.current?.totatStepsNumber! - 1 && intl.formatMessage({id: 'SEND'})}
                                                    <KTSVG
                                                        path='/media/icons/duotune/arrows/arr064.svg'
                                                        className='svg-icon-3 ms-2 me-0'
                                                    />
                                                </span>
                                                </button>
                                            </div>
                                        </Tooltip>
                                    </div>
                                </Form>
                            )
                        }
                        }
                    </Formik>
                </div>
            </div>
        </>
    )
})

export {Vertical}
