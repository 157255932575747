/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, ReactEventHandler, useEffect} from 'react'
import {Field, ErrorMessage, FormikProps} from 'formik'
import {CreateCompany} from "@providers/company/_models";
import InputMask from "react-input-mask";
import moment from "moment";
import {useIntl} from "react-intl";


interface IProps extends FormikProps<CreateCompany> {
    handleChange(arg: any): void;
    values:any
    setFieldValue(name:string, val:any):void;

}

const Step1: FC<IProps> = (props) => {
    const intl = useIntl()


    return (
        <div className='w-100'>
            <div className="pb-14">
                <h1 className="fw-bold text-dark">{intl.formatMessage({id: 'step1_title'})}</h1>
            </div>

            <div className='mb-8 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id:'pinfl'})}</label>
                <Field
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='pinfl'
                    placeholder='00000000000000'
                    onChange={(e: React.FormEvent<HTMLInputElement>)=>{
                        if (e.currentTarget.value.match(/^\d{0,14}$/)) props.handleChange(e)
                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='pinfl' />
                </div>
            </div>
            <div className='mb-12 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id:'phone'})}</label>
                <Field
                    type='text'
                    placeholder='+998 90 999 99 99'
                    component={InputMask}
                    mask="+\9\9\8 99 999 99 99"
                    className='form-control form-control-lg form-control-solid'
                    name='phoneNumber'
                    value={props.values['phoneNumber']}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        const value = e.currentTarget.value || '';
                        props.setFieldValue('phoneNumber', value)
                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='phoneNumber' />
                </div>
            </div>
            <div className='mb-12 fv-row'>
                <label className='form-label mb-3'>{intl.formatMessage({id:'phoneNumber_second'})}</label>
                <Field
                    type='text'
                    placeholder='+998 90 999 99 99'
                    component={InputMask}
                    mask="+\9\9\8 99 999 99 99"
                    className='form-control form-control-lg form-control-solid'
                    name='phoneNumber_second'
                    value={props.values['phoneNumber_second']}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        const value = e.currentTarget.value || '';
                        props.setFieldValue('phoneNumber_second', value)
                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='phoneNumber_second' />
                </div>
            </div>
            <div className='mb-8 fv-row d-flex justify-content-between'>
                <div className='fv-row me-3 w-100'>
                    <label className='form-label mb-3 required'>{intl.formatMessage({id:'passportSerie'})}</label>
                    <Field
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        name='passportSeries'
                        onChange={(e: React.FormEvent<HTMLInputElement>)=>{
                            if(!e.currentTarget.value.length||e.currentTarget.value.match(/^[Aa-zZ]{0,2}$/ig))
                                props.setFieldValue('passportSeries', e.currentTarget.value.replace(/^[aA-zZ]{2}/, (match)=>match.toUpperCase()))
                        }}
                    />
                    <div className='text-danger mt-2'>
                        <ErrorMessage name='passportSeries' />
                    </div>
                </div>
                <div className='fv-row w-100'>
                    <label className='form-label mb-3 required'>{intl.formatMessage({id:'passportNumber'})}</label>
                    <Field
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        name='passportNumber'
                        onChange={(e: React.FormEvent<HTMLInputElement>)=>{
                            if(!e.currentTarget.value.length||e.currentTarget.value.match(/^\d{0,7}$/))
                                props.handleChange(e)
                        }}
                    />
                    <div className='text-danger mt-2'>
                        <ErrorMessage name='passportNumber' />
                    </div>
                </div>
            </div>

            <div className='mb-8 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id:'ceo_first_name'})}</label>
                <Field
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='firstName'
                    placeholder={intl.formatMessage({id:'ceo_first_name'})}
                    onChange={(e: React.FormEvent<HTMLInputElement>)=>{
                        if(!e.currentTarget.value.length||e.currentTarget.value.match(/^[a-zA-Z\s]+$/)){
                            props.handleChange(e)
                        }

                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='firstName' />
                </div>
            </div>

            <div className='mb-8 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id:'ceo_last_name'})}</label>
                <Field
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='lastName'
                    placeholder={intl.formatMessage({id:'ceo_last_name'})}
                    onChange={(e: React.FormEvent<HTMLInputElement>)=>{
                        if(!e.currentTarget.value.length||e.currentTarget.value.match(/^[a-zA-Z\s]+$/)){
                            props.handleChange(e)
                        }
                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='lastName' />
                </div>
            </div>

            <div className='mb-8 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id:'ceo_middle_name'})}</label>
                <Field
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='middleName'
                    placeholder={intl.formatMessage({id:'ceo_middle_name'})}
                    onChange={(e: React.FormEvent<HTMLInputElement>)=>{
                        if(!e.currentTarget.value.length||e.currentTarget.value.match(/^[a-zA-Z\s]+$/)){
                            props.handleChange(e)
                        }
                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='middleName' />
                </div>
            </div>

            <div className='mb-8 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id:'issuedBy'})}</label>
                <Field
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='issuedBy'
                    onChange={(e: React.FormEvent<HTMLInputElement>)=>{
                        if(!e.currentTarget.value.length||e.currentTarget.value.match(/^[a-zA-Z\s]+$/)){
                            props.handleChange(e)
                        }
                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='issuedBy' />
                </div>
            </div>




        </div>
    )
}

export {Step1}
