import {KTCard} from "@metronic/helpers";
import {UsersListHeader} from "./_modals/table-components/header/UsersListHeader";
import React, {useState} from "react";
import {ErrorProvider} from "@providers/context/ErrorsProvider";
import {observer} from "mobx-react";
import {useIntl} from "react-intl";
import {PageTitleCustom} from "@metronic/partials/pageTitleCustom";
import {TableStatistics} from "./_modals/TableStatistics";
import {useListView} from "./_modals/providers/ListViewProvider";
import {UserEditModal} from "./_modals/user-edit-modal/UserEditModal";
import {ListViewProvider} from "./_modals/providers/ListViewProvider";


const AgentPage = () => {
    const {itemIdForUpdate} = useListView()

    return (
        <>
            <UsersListHeader />
            <KTCard>
                <TableStatistics />
            </KTCard>
            {itemIdForUpdate !== undefined && <UserEditModal />}

        </>
    )
}





const AgentStatisticsWrapper = observer(() => {
    const intl = useIntl()
    return(
        <ListViewProvider>
            <ErrorProvider>
                <PageTitleCustom title={intl.formatMessage({id:'agent-title'})} breadCrumbs={[]}/>
                <AgentPage />
            </ErrorProvider>
        </ListViewProvider>
    )
})

export {AgentStatisticsWrapper}



