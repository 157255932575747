import {makeAutoObservable, toJS} from "mobx";
import {ICashbox} from "@providers/filial/_models";

class Filial{
    filial: ICashbox[] = []
    filialOne: ICashbox = {} as ICashbox

    constructor() {
        makeAutoObservable(this)
    }

    setFilial(data: ICashbox[]){
        this.filial = data
    }

    get getFilial(){
        return toJS(this.filial)
    }

    setFilialOne(data: ICashbox){
        this.filialOne = data
    }

    get getFilialOne(){
        return toJS(this.filialOne)
    }
}



export const filial = new Filial()