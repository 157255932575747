import {ErrorProvider, useErrors} from "@providers/context/ErrorsProvider";
import React, {FC, useEffect, useState} from "react";
import {PageTitle} from "@metronic/layout/core";
import {useIntl} from "react-intl";
import {useParams} from "react-router-dom";
import {ContractsService} from "@providers/autopay/requests/contracts.service";
import {contract} from "@store/contract/contract";
import {UsersListLoading} from "@metronic/partials/widgets/tables/_modals/components/loading/UserListLoading";
import {observer} from "mobx-react";
import ContractInfo from "./components/contractInfo/contractInfo";
import ContractData from "./components/contractData/contractData";
import ContractSettings from "./components/contractSettings/contractSettings";
import {TabsKeys} from "@providers/autopay/models/_models";


const EditContractInner: FC = observer(() => {
    const [activeKey, setActiveKey] = useState<TabsKeys>('personal')
    const {setError} = useErrors()

    const intl = useIntl()

    const {contractId} = useParams()


    const onChangeKey = (key: TabsKeys) => {
        setActiveKey(key)
    }

    const {entity, errors, loaded} = contract.getContract

    const getContactDetails = async () => {
        if (contractId) {
            try {
                let {data} = await ContractsService.getContractById(contractId)
                contract.setContract(data.data)
            } catch (e: any) {
                if (e.response.status === 404) {
                    setError({message: intl.formatMessage({id: 'contract_not_found'}), isError: true, status: 404})
                }
                if (e?.response?.data?.error || e?.response?.data?.message) {
                    setError({message: e?.response?.data?.error || e?.response?.data?.message , isError: true, status: e?.response?.status})
                    contract.setContractError(e.response.data.error || e?.response?.data?.message)
                }
            }
        }
    }




    useEffect(() => {
        getContactDetails()
    }, [])


    if (!loaded) {
        return <UsersListLoading/>
    }

    if (errors.isError) {
        return  <div className='text-danger fs-2 mt-2'>
            {errors.message}
        </div>
    }

    const switchTabs = () => {
        switch (activeKey) {
            case 'personal':
                return <ContractData contract={entity!}/>
            case 'settings':
                return <ContractSettings contract={entity!}/>
        }
    }

    return (
        <div>
            <ContractInfo activeKey={activeKey} onChangeKey={onChangeKey} contract={entity!}/>
            <div className="tab-content" id="myTabContent">
                {switchTabs()}
            </div>
        </div>
    );
})




const EditContract: FC = () => {
    const intl = useIntl()

    return (
        <ErrorProvider>
            <PageTitle>{intl.formatMessage({id: "DETAILS_CONTRACT"})}</PageTitle>
            <EditContractInner/>
        </ErrorProvider>
    );
};

export default EditContract;






