import {AUTOPAY_BASE_URL, autopayInstance} from "@providers/autopay/requests/axiosConfig";
import {EditContractRequestDTO} from "../_interfaces";
import {
    ContractEditItemRequest,
    ContractItemResponse,
    CreateContract,
    ICardAddData
} from "@providers/autopay/models/_models";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {sso_selected} from "@providers/autopay/requests/axiosConfig";

export const GetToken = {
    async getTokenRequest() {
        const formData = new URLSearchParams();
        formData.append('client_id', 'cards')
        formData.append('client_secret', 'CCcmoAt8cI3NEY9HDN64SQD4qR8DanMh')
        formData.append('grant_type', 'client_credentials')
        formData.append('scope', 'openid')
        return sso_selected.post(`/openid-connect/token`, formData)
    },
}

export const ContractsService = {
    async getContractById(contractId: string) {
        return autopayInstance.get(`/contracts/${contractId}`)
    },
    async putContractById(contractId: string, data: ContractEditItemRequest) {
        return autopayInstance.put(`/contracts/${contractId}`, data)
    },
    async postContract(data: CreateContract) {
        return autopayInstance.post('/contracts', data)
    },
    async resendOTPCode(contractId: string) {
        return autopayInstance.patch(`/contracts/${contractId}/verification/resend`)
    },
    async stopContractById(contractId: string) {
        return autopayInstance.patch(`/contracts/${contractId}/terminate`)
    },
    async activateContractById(contractId: string, payload?: Object) {
        return autopayInstance.patch(`/contracts/${contractId}/activate`, payload)
    },
    async verifyContactByOTP(contractId: string, verificationCode: string) {
        return autopayInstance.get(`/contracts/${contractId}/verify?verificationCode=${verificationCode}`)
    },
    async generateUniqueLink(contractId: string) {
        return autopayInstance.post(`/contracts/${contractId}/unique/url`, null)
    },
    async getHistoryOfContractPayments(contractId: string) {
        return autopayInstance.get(`/contracts/${contractId}/history/payment`)
    },

    async seacrhContracts(phoneNumber: string, page: string = "1", size: string = "20", sortBy: string = "id", filters:any) {
        let request_params = `?page=${page}&size=${size}`
        request_params += sortBy?`&sortBy=${sortBy}`:''
        request_params += filters?.from_date?`&from_date=${filters?.from_date}`:''
        request_params += filters?.to_date?`&to_date=${filters?.to_date}`:''
        request_params += filters?.statuses?`&statusTypes=${filters?.statuses.join(',')}`:''
        return autopayInstance.get(`/clients/number/${phoneNumber}/contracts${request_params}`)
    },

    async exportToExcel(contractId: string) {
        return autopayInstance.get(`/contracts/${contractId}/export/excel`)
    },
    async getActiveContractById(contractId: string) {
        return autopayInstance.get(`/contracts/${contractId}/active`)
    },
    async deleteUnverifiedContract(contractId: string) {
        return autopayInstance.delete(`/contracts/${contractId}/unverified`)
    },
    async getExpiredByContractId(contractId: string) {
        return autopayInstance.get(`/contracts/${contractId}/schedules/expired`)
    },
    async addCard(data: ICardAddData) {
        return autopayInstance.post(`/cards/contract`, data)
    }
}