export const OWNER = 'OWNER'
export const CEO = 'CEO'
export const ACCOUNTANT = 'ACCOUNTANT'
export const CASHIER = 'CASHIER'
export const JUNIOR_CASHIER = 'JUNIOR_CASHIER'
export const ANALYST = 'ANALYST'
export const ADMIN = 'ADMIN'

export const USER_ADMIN = 'USER_ADMIN'

export const USER = 'USER'
export const MODERATOR = 'MODERATOR'
export const AGENT = 'AGENT'
export const SUPER_ADMIN = 'SUPER_ADMIN'
export const REGEXP_PHONE = /^\+998\s(94|95|99|97|90|77|91|93|55|33|88|98)\s\d{3}\s\d{2}\s\d{2}$/
export const REGEXP_PHONE_2 = /(^\+998\s(94|95|99|97|90|77|91|93|55|33|88|98)\s\d{3}\s\d{2}\s\d{2}$)|(^998(94|95|99|97|90|77|91|93|55|33|88|98)\d{3}\d{2}\d{2}$)/
export const FILE_SIZE = 2000
export const SUPPORTED_FORMATS = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf']

export const SUPPORTED_LOGO_FORMATS = ['image/jpeg', 'image/jpg', 'image/png',]
export const SUPPORTED_FORMATS_IMAGES = ['image/jpeg', 'image/jpg', 'image/png']
export const image_default = 'media/default-placeholder.png'
export const SUCCESSFUL = 'SUCCESSFUL'
export const CANCELED = 'CANCELED'
export const DECLINED = 'DECLINED'
export const INIT = 'INIT'
export const APPROVED = 'APPROVED'
export const REVERT = 'REVERT'
export const FAILED = 'FAILED'
export const HUMO = 'HUMO'
export const UZCARD = 'UZCARD'

export const statuses_const = ['approved', 'failed', 'revert', 'init']
export const statuses_transactions = ['PARTIAL_REVERT', 'APPROVED', 'REVERT', 'FAILED']
export const statuses_card = ['uzcard', 'humo', 'visa', 'master']
export const card_transactions = ["UZCARD", "HUMO", "VISA", "MASTERCARD"]
export const statuses_international_card = ['visa', 'master']
export const statuses_platforms = ['paymo', 'pulpul', 'paynet', 'oson', 'payme', 'global pay', 'apelsin']
export const statuses_contracts = ['OUTDATED', 'ACTIVE', 'TERMINATED', 'FINISHED', 'UNVERIFIED']


export const statuses_for_cheque = ['APPROVED', 'REVERT_PARTIAL', "REVERT"]


export const DEMO_URL = 'https://demo-api.globalpay.uz'
export const PROD_URL = 'https://merchant-api.globalpay.uz'
export const DEV_URL = 'https://merchant-api-dev.globalpay.uz'

export const INDIVIDUAL = 'INDIVIDUAL'
export const COMPANY = 'COMPANY'


export const textPattern: RegExp = /^[a-zA-ZА-Яа-я0-9".""\s-]+$/

export const company_hide_fields: string[] = ['totalCount', 'totalAmount', 'lastTransaction', 'style']


