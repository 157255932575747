import React, {FC} from 'react'
import {ListViewProvider, useListView} from "./_modals/providers/ListViewProvider";
import {KTCard} from "@metronic/helpers";
import {UsersListHeader} from "./_modals/table-components/header/UsersListHeader";
import {Table} from "./_modals/Table";
import {ErrorProvider} from "@providers/context/ErrorsProvider";
import {UserEditModal} from "./_modals/user-edit-modal/UserEditModal";

const Employees:FC = ()=>{
    const {itemIdForUpdate} = useListView()

  return (
      <>

            <div>
                <UsersListHeader />
                <Table />
            </div>
          {itemIdForUpdate !== undefined && <UserEditModal />}
        </>
  )
}

export default function EmployeesMerchant(){
    return(
        <>
            <ListViewProvider>
                <ErrorProvider>
                    <Employees />
                </ErrorProvider>
            </ListViewProvider>
        </>
    )
}