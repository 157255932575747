import {FC} from 'react'
import {useIntl} from "react-intl";

type Props = {
  position?: string
}

const UserLastLoginCell: FC<Props> = ({position}) => {
  const intl = useIntl()
  return (
      <div className='fw-bolder'>{intl.formatMessage({id: position})}</div>
  )
}

export {UserLastLoginCell}
