import {Modal} from 'react-bootstrap'
import {FC, useEffect, useState} from "react";
import {useIntl} from "react-intl";


type WarningProps = {
    message: string|null,
    isAlert: boolean,
    setShow: (val:any)=>void
}





export const WarningAlert:FC<WarningProps> = ({message, isAlert=false, setShow})=>{

    const intl = useIntl()

    function handleClose(){
        setShow(null)
    }

    return(
        <Modal show={isAlert} onHide={handleClose}>
            <Modal.Header closeButton><h2>{intl.formatMessage({id:'warning_alert'})}</h2></Modal.Header>
            <Modal.Body>

                <p className='mt-3' style={{wordBreak:'break-all'}}>{message}</p>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="swal2-confirm btn btn-light-primary" aria-label=""
                        style={{display: 'inline-block'}} onClick={handleClose}>{intl.formatMessage({id:'ok_button'})}</button>
            </Modal.Footer>
        </Modal>
    )
}