import {PageTitle} from "@metronic/layout/core";
import React, {FC, useEffect, useState} from 'react';
import {MerchantService} from "@providers/autopay/requests/merchant.service";
import {Link, useParams} from "react-router-dom";
import {KTCard, KTCardBody, KTSVG} from "@metronic/helpers";
import {ContractListItemResponse, SubscriptionItemResponse} from "@providers/autopay/models/_models";
import {useError} from "@utils/hooks/error.hook";
import {ErrorsResponseAlert} from "../../../modules/alerts/ErrorsResponseAlert";
import {ErrorProvider, useErrors} from "@providers/context/ErrorsProvider";
import {getContractStatus} from "../contracts/contractsList/contractsListHelper";
import {useIntl} from "react-intl";

const tableHeaders: Array<Object> = [
    {"contractId": "ID контракта"},
    {"contractNumber": "Номер контракта"},
    {"contractDate": "ДАТА"},
    {"clientId": "ПИНФЛ"},
    {"clientNumber": "Тел номер"},
    {"productName": "НАИМЕНОВАНИЕ"},
    {"serviceId": "ID сервиса"},
    {"productPrice": "ЦЕНА"},
    {"prepaymentPrice": "Сумма предоплаты"},
    {"discountPrice": "Сумма скидки"},
    {"paidSum": "Уплаченная сумма"},
    {"overdueLimit": "Лимит просрочки"},
    {"status": "СТАТУС"},
]


const SubscriptionsListInner: FC = () => {

    const [tableBody, setTableBody] = useState<Array<SubscriptionItemResponse>>([])
    const [totalPagesCount, setTotalPagesCount] = useState<number>(1)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [sortByItem, setSortByItem] = useState<string>("id")
    const {companyId} = useParams()
    const {error, setError} = useErrors()
    const intl = useIntl()


    let pages: Array<string> = [];
    if (totalPagesCount > 10) {
        if (currentPage > 5) {
            pages.push("1", "...");
            for (let i = currentPage - 4; i <= currentPage + 5; i++) {
                pages.push(i.toString());
                if (i === currentPage + 5) pages.push("...", totalPagesCount.toString());
                if (i === totalPagesCount) break;
            }
        } else {
            for (let i = 1; i <= 10; i++) {
                pages.push(i.toString());
                if (i === 10) pages.push("...", totalPagesCount.toString());
                if (i === totalPagesCount) break;
            }
        }
    } else {
        for (let i = 1; i <= totalPagesCount; i++) {
            pages.push(i.toString());
        }
    }


    const onSelectPage = async (page: string) => {
        try {
            setIsLoading(true)
            await _fetchData(page)
        } catch (error: Error | any) {
            setError({
                status: error?.response?.status,
                message: error?.response?.data?.message,
                isError: true
            })
        } finally {
            setIsLoading(false)
        }


    }

    const onChangeSortBy = async (sortBy: string) => {
        try {
            setIsLoading(true)
            await _fetchData("1", sortBy)
            setSortByItem(sortBy)
        } catch (error: Error | any) {
            setError({status: error?.response?.status, message: error?.response?.data?.message, isError: true})
        } finally {
            setIsLoading(false)
        }
    }

    const _fetchData = async (page?: string, sortBy?: string) => {
        // const {data} = await MerchantService.getMerchantSubscriptions(companyId!.toString(), page, undefined, sortBy ?? sortByItem)
        const {data} = await MerchantService.getMerchantSubscriptions("testMerchant1", page, undefined, sortBy ?? sortByItem)
        setTableBody(data.data.data)
        setTotalPagesCount(data.data.totalPages)
        setCurrentPage(data.data.currentPage)
    }


    useEffect(() => {
        _fetchData().then()
    }, [])

    return (
        <>
            <div className='d-flex justify-content-end mb-5' data-kt-user-table-toolbar='base'>
                {/*<Link to={`/${companyId}/autopay/subscriptions/create`}>*/}
                <button disabled type='button' className='btn btn-primary'>
                    <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2'/>
                    Добавить подписку
                </button>
                {/*</Link>*/}
            </div>
            <KTCard>
                <KTCardBody>
                    <div className='table-responsive'>
                        <table
                            id='kt_table_users'
                            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer table-hover'
                        >
                            <thead>
                            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                {tableHeaders.map((item, index) => {
                                    const translation: string = Object.values(item)[0]
                                    const value: string = Object.keys(item)[0]
                                    return <th
                                        key={index}
                                        className={`${value === sortByItem && "table-sort-desc text-primary"} ${index !== 0 ? "border-start" : ""} cursor-pointer w-100 text-nowrap px-3 text-center`}
                                        onClick={async () => {
                                            await onChangeSortBy(value)
                                        }}
                                    >
                                        {translation}
                                    </th>
                                })}
                            </tr>
                            </thead>
                            <tbody className='text-gray-600 fw-bold'>
                            {tableBody.length > 0
                                ? tableBody.map((item, index) =>
                                    <tr key={item.contractId + Math.random() * 100} className="cursor-pointer"
                                        // onClick={() => {
                                        //     navigate(`/${companyId}/autopay/contracts/${item.contractId}`, {state: item})}}
                                    >
                                        {Object.entries(item).map((el, index) =>
                                            // <td className={`${index === 0 && "ps-3"} text-center text-nowrap px-3`}>{el[0] === "status" ? (el[1].toString().toLowerCase() === "active" ? "Активно" : "Архивировано") : el[1]}</td>)
                                            <td className={`${index === 0 && "ps-3"} text-center text-nowrap px-3`}
                                                key={index + el[0]}>{el[0] === "status" ? getContractStatus(el[1].toString(),intl) : el[1]}</td>)
                                        }
                                    </tr>
                                ) : (
                                    <tr>
                                        <td colSpan={tableHeaders.length}>
                                            <div
                                                className='d-flex text-center w-100 align-content-center justify-content-center'>
                                                Нет данных по подпискам
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                        {isLoading && ''}


                    </div>
                </KTCardBody>
                {tableBody.length > 0 && <ul className="pagination mb-4">
                    {/*{currentPage !== 1 && <li className="page-item">*/}
                    {/*    <button className="page-link"*/}
                    {/*            disabled={currentPage === 1}*/}
                    {/*            onClick={async () => {*/}
                    {/*                await onSelectPage("1")*/}
                    {/*            }}*/}
                    {/*    >*/}
                    {/*        &lt;*/}
                    {/*    </button>*/}
                    {/*</li>}*/}
                    {pages.map((p, index) => (
                        <li key={index} className={`page-item ${p === currentPage.toString() && "active"}`}>
                            <button className="page-link"
                                    disabled={p === "..."}
                                    onClick={async () => {
                                        if (currentPage.toString() === p) return
                                        await onSelectPage(p)
                                    }}
                            >
                                {p}
                            </button>
                        </li>
                    ))}
                    {/*{currentPage !== totalPagesCount && <li className="page-item">*/}
                    {/*    <button className="page-link"*/}
                    {/*            disabled={currentPage === totalPagesCount}*/}
                    {/*            onClick={async () => {*/}
                    {/*                await onSelectPage(totalPagesCount.toString())*/}
                    {/*            }}*/}
                    {/*    >*/}
                    {/*        &gt;*/}
                    {/*    </button>*/}
                    {/*</li>}*/}
                </ul>}
            </KTCard>
        </>
    );
};


const SubscriptionsList: FC = () => {
    return (
        <ErrorProvider>
            <PageTitle>Список подписок</PageTitle>
            <SubscriptionsListInner/>
        </ErrorProvider>
    );
};

export default SubscriptionsList;