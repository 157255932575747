import React from 'react';

interface props {
    title: string
    classNames?: string
}

const DefaultCell = ({title, classNames}: props) => {
    return (
        <span className={classNames}>
           {title}
        </span>
    );
};

export default DefaultCell;