import {FC, useState, createContext, useContext, useMemo, Dispatch, SetStateAction} from 'react'
import {
    ID,
    calculatedGroupingIsDisabled,
    calculateIsAllDataSelected,
    groupingOnSelect,
    groupingOnSelectAll,
    WithChildren,
} from '@metronic/helpers'
import {useError} from "@utils/hooks/error.hook";
import {observer} from "mobx-react";
import {employeeStore} from "@store/employee";


export type ListViewContextProps = {
    selected: Array<ID>
    onSelect: (selectedId: ID) => void
    onSelectAll: () => void
    clearSelected: () => void
    // NULL => (CREATION MODE) | MODAL IS OPENED
    // NUMBER => (EDIT MODE) | MODAL IS OPENED
    // UNDEFINED => MODAL IS CLOSED
    itemIdForUpdate?: ID
    setItemIdForUpdate: Dispatch<SetStateAction<ID>>
    isAllSelected: boolean
    disabled: boolean

}

export const initialListView: ListViewContextProps = {
    selected: [],
    onSelect: () => {},
    onSelectAll: () => {},
    clearSelected: () => {},
    setItemIdForUpdate: () => {},
    isAllSelected: false,
    disabled: false
}

const ListViewContext = createContext<ListViewContextProps>(initialListView)

const ListViewProvider: FC<WithChildren> = observer(({children}) => {
    const [selected, setSelected] = useState<Array<ID>>(initialListView.selected)
    const [itemIdForUpdate, setItemIdForUpdate] = useState<ID>(initialListView.itemIdForUpdate)
    const data = employeeStore.getEmployeeFilial
    const disabled = useMemo(() => calculatedGroupingIsDisabled(false, data), [data])
    const isAllSelected = useMemo(() => calculateIsAllDataSelected(data, selected), [data, selected])


    return (
        <ListViewContext.Provider
            value={{
                selected,
                itemIdForUpdate,
                setItemIdForUpdate,
                disabled,
                isAllSelected,
                onSelect: (id: ID) => {
                    groupingOnSelect(id, selected, setSelected)
                },

                onSelectAll: () => {
                    groupingOnSelectAll(isAllSelected, setSelected, data)
                },
                clearSelected: () => {
                    setSelected([])
                },
            }}
        >
            {children}
        </ListViewContext.Provider>
    )
})

const useListView = () => useContext(ListViewContext)

export {ListViewProvider, useListView}
