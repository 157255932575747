import React from 'react';
import InvoiceForm from "./components/invoice-form/invoice-form";
import InvoiceSearch from "./components/invoice-search/invoice-search";
import {KTCard, KTCardBody} from "@metronic/helpers";
import Table from "./table/table";

interface props {

}

const WebPayments = (props: props) => {
    return (
        <section className={'my-1 mb-5'}>
            <div className={'d-flex align-items-center justify-content-between'}>
                <InvoiceForm/>
                <InvoiceSearch/>
            </div>
            <KTCard className={'mt-5'}>
                <KTCardBody className={'py-4'}>
                    <Table/>
                </KTCardBody>
            </KTCard>
        </section>
    );
};

export default WebPayments;