/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {MenuComponent} from '@metronic/assets/ts/components'
import {ID, KTSVG, QUERIES} from '@metronic/helpers'
import {Link} from "react-router-dom";
import {Company} from "@providers/company/_request";
import {observer} from "mobx-react";
import {company} from "@store/company";
import {useIntl} from "react-intl";

type Props = {
  id: number
}


const UserActionsCell:FC<Props> = observer(({id}) => {

    const intl = useIntl()

    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])




    return (
        <>
            <a
                href='#'
                className='btn btn-light btn-active-light-primary btn-sm'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
            >
                {intl.formatMessage({id: 'ACTIONS'})}
                <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
            </a>
            {/* begin::Menu */}
            <div
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                data-kt-menu='true'
            >
                {/* begin::Menu item */}
                <div className='menu-item px-3'>
                    <Link to={`/${id}/settings-merchant`} className='menu-link px-3'>
                        {intl.formatMessage({id:'EDIT_BUTTON'})}
                    </Link>
                </div>
                {/* end::Menu item */}
            </div>
            {/* end::Menu */}
        </>
    )
})

export {UserActionsCell}
