function divideByHundred (num: number) {
    return num / 100
}


function multiplyByHundred (num: number) {
    return num * 100
}

export {multiplyByHundred, divideByHundred}
