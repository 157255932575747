/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {observer} from "mobx-react";
import moment from "moment";

type Props = {
    contract: {
        contract_id:string,
        contract_expiry_date: string
    }
}

const AgentContract: FC<Props> = observer(({contract}) => {

    return (
        <div >
            <p className='fs-6 text-gray-800 fw-semibold mb-0'>{contract?.contract_id}</p>
            <p className='fs-6 text-gray-600 fw-normal'>{moment(contract?.contract_expiry_date).format('DD/MM/YYYY')}</p>
        </div>
    )
})

export {AgentContract}
