import {makeAutoObservable, toJS} from "mobx";
import {IEmployee, IEmployeeType, IRole} from "@providers/employee/_models";

class EmployeeStore {
    employee: IEmployee[] = []
    role: string = ''
    employeeFilial: IEmployee[] = []
    employeeTypes: IEmployeeType[] = []

    constructor() {
        makeAutoObservable(this)
    }

    setEmployee(data: any) {
        this.employee = data
    }

    setEmployeeFilial(data : any) {
        this.employeeFilial = data
    }

    setRole(role: string) {
        this.role = role
    }

    get getEmployee() {
        return toJS(this.employee)
    }

    get getEmployeeFilial() {
        return toJS(this.employeeFilial)
    }

    get getRole() {
        return toJS(this.role)
    }

    get getEmployeeTypes () {
        return toJS(this.employeeTypes)
    }

    setEmployeeTypes (roles: IEmployeeType[]) {
        this.employeeTypes = roles
    }

}


export const employeeStore = new EmployeeStore()