import {autopayInstance} from "@providers/autopay/requests/axiosConfig";


export const ClientService = {
    async getClientDataByPINFL(pinfl: string) {
        return autopayInstance.get(`/clients/${pinfl}`)
    },
    async getClientStatisticsByPINFL(pinfl: string) {
        return autopayInstance.get(`/clients/${pinfl}/statistics`)
    },
    async getClientContractsByPINFL(pinfl: string, page: string = "1", size: string = "20", sortBy: string = "id",orderType:string= "ASC",filters:any) {
        let request_params = `?page=${page}&size=${size}`
        request_params += sortBy?`&sortBy=${sortBy}`:''
        request_params += filters?.from_date?`&from_date=${filters?.from_date}`:''
        request_params += filters?.to_date?`&to_date=${filters?.to_date}`:''
        request_params += orderType?`&orderType=${orderType}`:''
        request_params += filters?.statuses?`&statusTypes=${filters?.statuses.join(',')}`:''

        return autopayInstance.get(`/clients/${pinfl}/contracts${request_params}`)
    },
    async updateClientInfo(pinfl:string,data:any){
        return autopayInstance.put(`/clients?pinfl=${pinfl}`,data)
    }
}