import {FC, PropsWithChildren, useMemo} from 'react'
import {HeaderProps} from 'react-table'
import {useIntl} from "react-intl";
import {observer} from "mobx-react";
import {agentStore} from "@store/agents";
import {Agent} from "@providers/agents/_request";
import {toIsoStringHelper} from "../../../autopay/dateHelper";
import moment from "moment/moment";
import {useParams} from "react-router-dom";

type Props = {
  className?: string
  title?: string
  tableProps: PropsWithChildren<HeaderProps<any>>
}
const TableCustomHeader: FC<Props> = observer(({className, title, tableProps}) => {
  const id_column = tableProps.column.id
  const {id} = useParams()
  const intl = useIntl()
  const sort = useMemo(() => agentStore.getSorting, [agentStore.getSorting])





  async function updateData(){

    const dataFilter = {
      page: sort?.page,
      count: 20,
      from_date: sort?.from_date,
      to_date: sort?.to_date,
      sort_by: sort.sortBy,
      sort_order: sort.sortFromMax?'ASC':'DESC'
    }
    if(id){
      await Agent.getStatisticById(id,dataFilter)
          .then(result=>{
            agentStore.setStatisticPersonal(result?.data?.result.map((item: any, index: number) => {
              if (sort.page && sort.page > 2) {
                item['num'] = ((sort.page - 1) * 20) + index + 1
              } else if (sort.page == 2) {
                item['num'] = 21 + index
              } else {
                item['num'] = index + 1
              }
              return item
            }))
          })
    }
  }




  const sortColumn = () => {
    // avoid sorting for these columns
    if (id_column === 'actions' || id_column === 'selection') {
      return
    }

    if(id_column==='agent_income'||id_column==='company_income'||id_column==='company_name'||id_column==='company'){
      agentStore.setSorting({...sort, sortFromMax:!sort.sortFromMax, sortBy:id_column.toUpperCase()})
      updateData()
    }





  }


  return (
      <th
          {...tableProps.column.getHeaderProps()}
          className={`${className} ${(sort.sortBy||'').toLowerCase() === id_column ? 
              sort.sortFromMax ? "table-sort-desc" : "table-sort-asc" : ""} text-left`}
          style={{cursor: 'pointer', textTransform: "capitalize"}}
          onClick={sortColumn}
      >
        {title&&title.length?intl.formatMessage({id:title}):''}
      </th>
  )
})

export {TableCustomHeader}
