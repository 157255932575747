// @ts-nocheck
import {Column} from 'react-table'
import {UserLastLoginCell} from './UserLastLoginCell'
import {UserActionsCell} from './UserActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {TableCustomHeader} from './TableCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {EmployeeM} from "@providers/employee/_models";
import {useIntl} from "react-intl";
import {TableText} from "./TableText";
import {ReplacePhone} from "@utils/index";

const usersColumns: ReadonlyArray<Column<EmployeeM>> = [
  // {
  //   Header: (props) => <UserSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='№' className='text-start min-w-35px' />,
    id: 'num',
    Cell: ({...props}) => <TableText text={props.data[props.row.index].num} />,
  },

  {
    Header: (props) => {
      const intl = useIntl()
      return <TableCustomHeader tableProps={props} title={intl.formatMessage({id:"phone"})} className='min-w-125px' />
    },
    id: 'phone',
    Cell: ({...props}) => <TableText text={ReplacePhone(props.data[props.row.index].phone||'')} />
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return <TableCustomHeader tableProps={props} title={intl.formatMessage({id:"POSITION"})} className='min-w-125px' />
    },
    id: 'position',
    Cell: ({...props}) => <UserLastLoginCell position={props.data[props.row.index].position} />,
  },

  {
    Header: (props) => {
      const intl = useIntl()
      return <TableCustomHeader tableProps={props} title={intl.formatMessage({id:"ACTIONS"})} className='text-end min-w-100px' />
    },
    id: 'actions',
    Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index].id} />,
  },
]



export {usersColumns}
