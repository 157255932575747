import {useEffect, useMemo, useState} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'


import {usersColumns} from './columns/_columns'
import {KTCardBody} from '@metronic/helpers'
import {observer} from "mobx-react";
import {useParams} from "react-router-dom";
import {Employee} from "@providers/employee/_request";
import {EmployeeLogs} from "@providers/employee/_models";
import {useErrors} from "@providers/context/ErrorsProvider";
import {useIntl} from "react-intl";

const Table = observer(() => {
    const [logs, setLogs] = useState([])
    const data = useMemo(() => logs, [logs])
    const columns = useMemo(() => usersColumns, [logs])
    const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
        columns,
        data,
    })
    const {setError} = useErrors()
    const intl = useIntl()


    const {companyId, id} = useParams()

    useEffect(()=>{
        getSessionLogs()
    }, [id, companyId])

    const getSessionLogs = ()=>{
        if(companyId&&id){
            Employee.getEmployeeLogs(companyId, id, {page:1, count:100})
                .then(result=>{
                    setLogs(result?.data?.result??[])
                }).catch(e=>{setError({status: e?.response?.status, message: e?.response?.data?.message, isError: true})})
        }

    }

    return (
        <KTCardBody className='py-4'>
            <div className='table-responsive'>
                <table
                    id='kt_table_users'
                    className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                    {...getTableProps()}
                >
                    <thead>
                    <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                        {headers.map((column: ColumnInstance<EmployeeLogs>) => (
                            <CustomHeaderColumn key={column.id} column={column} />
                        ))}
                    </tr>
                    </thead>
                    <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                    {rows.length > 0 ? (
                        rows.map((row: Row<EmployeeLogs>, i) => {
                            prepareRow(row)
                            return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                        })
                    ) : (
                        <tr>
                            <td colSpan={7}>
                                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                    {intl.formatMessage({id:"NOT_MATCHING"})}
                                </div>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>

        </KTCardBody>
    )
})

export {Table}
