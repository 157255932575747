import React, {ChangeEvent, useState} from 'react';
import {KTSVG} from "@metronic/helpers";
import {web_payments} from "@store/web-payments/web-payments";
import {useIntl} from "react-intl";

interface props {

}

const InvoiceSearch = (props: props) => {

    const intl = useIntl()
    const [searchText, setSearchText] = useState<string>('')
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        web_payments.setFilters({search: searchText})
    }


    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value)
    }



    return (
        <form onSubmit={onSubmit} className='d-flex align-items-center position-relative col-4'>
            <input
                style={{border: "none", borderRadius: "6px", height: "44px"}}
                type='text'
                onChange={onChange}
                data-kt-user-table-filter='search'
                className='form-control pe-5'
                placeholder={intl.formatMessage({id: 'searchById'})}
                value={searchText}
            />
            {searchText.length > 0 &&
                <span className='position-absolute end-0 me-8 pe-8' onClick={() => {
                    setSearchText('')
                    web_payments.setFilters({search: ''})
                }}>
                            <KTSVG
                                path='/media/icons/duotune/general/gen034.svg'
                                className='svg-icon-1 text-hover-primary'
                            />
                        </span>}
            <button type="submit"
                    style={{borderTopRightRadius: "6px", borderBottomRightRadius: "6px", width: "44px"}}
                    className='border-0 text-hover-secondary cursor-pointer position-absolute end-0 bg-primary h-100 d-flex justify-content-center align-items-center'>
                <KTSVG
                    path='/media/icons/duotune/general/gen021.svg'
                    className='svg-icon-1 text-white'
                />
            </button>
        </form>
    );
};

export default InvoiceSearch;