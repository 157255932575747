import React from 'react';
import {ContractItemResponse, IContract, TabsKeys} from "@providers/autopay/models/_models";
import {useIntl} from "react-intl";
import {KTSVG} from "@metronic/helpers";
import {StatusAutopay} from "../../../contractsList/_modals/columns/StatusAutopay";
import {Link, useParams} from "react-router-dom";

interface props {
    contract: IContract
    onChangeKey: (key: TabsKeys) => void
    activeKey: TabsKeys
}


const ContractInfo: React.FC<props> = ({
                                           contract,
                                           onChangeKey,
                                           activeKey
                                       }) => {
    const intl = useIntl()
    const {companyId} = useParams()

    const {
        contractNumber,
        contractDate,
        clientId,
        contractVersion,
        clientInformationDTO
    } = contract


    const onSetDataActive = () => onChangeKey('personal')
    const onSetSettingsActive = () => onChangeKey('settings')
    const clientInfo = [
        {
            label: 'fio',
            value: clientInformationDTO ? fullName() : undefined,
            link: `/${companyId}/autopay/clients/${clientId}`,
            isLocal: true
        },
        {
            label: 'clientNumber',
            value: clientInformationDTO ? '+' + clientInformationDTO.phoneNumber : undefined,
            link: `tel:${clientInformationDTO.phoneNumber}`
        },
        {
            label: 'passport',
            value: clientInformationDTO ? `${clientInformationDTO.passportSeries} ${clientInformationDTO.passportNumber}` : undefined
        },
        {
            label: 'clientPinfl',
            value: clientInformationDTO?.pinfl
        }
    ]


    const tabs = [
        {
            onClick: onSetDataActive,
            title: 'Agent-step2.1',
            tab: 'personal'
        },
        {
            onClick: onSetSettingsActive,
            title: 'SETTINGS_MERCHANT',
            tab: 'settings'
        }
    ]

    function fullName() {
        let fn = `${clientInformationDTO.firstName} ${clientInformationDTO.lastName} ${clientInformationDTO.middleName}`
        if (fn.length > 15) {
            fn = fn.slice(0, 15) + '...'
        }
        return fn
    }

    return (
        <div className={'card p-9 pb-0'}>
            <div className={'d-flex align-items-start'}>
                <div className={''}>
                    <h1 className={'text-gray-700 mb-0'}>
                        {intl.formatMessage({id: 'CONTRACT'})} №{contractNumber}
                    </h1>
                    <div className={'d-flex align-items-center mt-2'}>
                        <KTSVG path={'/media/icons/calendar.svg'}/>
                        <p className={'text-gray-500 mb-0 ms-2'}>
                            {contractDate}
                        </p>
                    </div>
                </div>
                <div className={'ms-5'}>
                    <StatusAutopay status={contractVersion.status}/>
                </div>
            </div>
            <div className={'d-flex align-items-start flex-wrap mt-14'}>
                {clientInfo.map(({
                                     label, value, link, isLocal
                                 }) => (value ?
                        <div key={label} style={{
                            whiteSpace: 'nowrap'
                        }}
                             className="border border-gray-300 border-dashed rounded py-3 px-4 me-7 mb-5">
                            <div className="d-flex align-items-center justify-content-between">
                                {link ? isLocal ? <Link to={link} style={{color: 'inherit'}}
                                                        className="text-hover-primary fs-2 fw-bolder me-5">
                                        {value}
                                    </Link> :
                                    <a style={{color: 'inherit'}} className="text-hover-primary fs-2 fw-bolder me-5"
                                       href={link}>
                                        {value}
                                    </a> : <div className="fs-2 fw-bolder me-5">{value}</div>}
                            </div>
                            <div className="fw-bold fs-6 text-gray-400">{intl.formatMessage({id: label})}</div>
                        </div> : null
                ))}
            </div>
            <div
                className="nav mt-10 nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap nav nav-tabs">
                {tabs.map(({
                               onClick,
                               title,
                               tab
                           }) => (
                    <div onClick={onClick} key={title} className="nav-item"><a role="button"
                                                                               href="#"
                                                                               className={`nav-link text-active-primary me-6 text-gray-800 ${tab === activeKey ? 'active' : ''} nav-link`}
                    >{intl.formatMessage({id: title})}</a></div>
                ))}
            </div>
        </div>
    );
};

export default ContractInfo;