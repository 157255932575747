
export const customStyles2 = {
    menu: (provided:any, state:any) => {

        return {
            ...provided,
            color: 'var(--kt-input-solid-color)',
            padding: 10,
            borderRadius: '0',
            border: '1px solid var(--kt-input-solid-bg)'
        }
    },
    singleValue: (provided:any, state:any)=>({
        ...provided,
        color: "var(--kt-input-solid-color)",
    }),
    control: (provided:any, state:any)=>({
        ...provided,
        backgroundColor: 'var(--kt-input-bg)',
        border: '1px solid var(--kt-input-border-color)',
        hoverBorderColor: 'transparent',
        boxShadow: 'none',
        minHeight: 'calc(1.5em + 1.65rem + 2px)',
        fontSize: '1.15rem',
        borderRadius: '0.625rem'

    }),

    indicatorSeparator: (provided:any, state:any)=>({
        ...provided,
        backgroundColor: 'var(--kt-input-solid-bg)'
    }),
    option: (provided:any, state:any)=>({
        ...provided,
        ':active': {
            backgroundColor: '#009ef7',
            color: '#fff'
        },
        ':hover': {
            backgroundColor: '#009ef7',
            color: '#fff'
        }
    }),
    multiValueRemove: (provided:any, state:any)=>({
        ...provided,
        color: '#DE350B'
    })
}

export const customStyles = {

    menu: (provided:any, state:any) => {

        return {
            ...provided,
            color: 'var(--kt-input-solid-color)',
            backgroundColor: 'var(--kt-input-solid-bg)',
            padding: 10,
            borderRadius: '0',
            border: '1px solid var(--kt-input-solid-bg)'
        }
    },
    singleValue: (provided:any, state:any)=>({
        ...provided,
        color: "var(--kt-input-solid-color)",
    }),
    control: (provided:any, state:any)=>({
        ...provided,
        backgroundColor: 'var(--kt-input-solid-bg)',
        borderColor: 'transparent!important',
        hoverBorderColor: 'transparent',
        boxShadow: 'none',
        minHeight: 'calc(1.5em + 1.65rem + 2px)',
        fontSize: '1.15rem',
        borderRadius: '0.625rem'

    }),

    indicatorSeparator: (provided:any, state:any)=>({
        ...provided,
        backgroundColor: 'var(--kt-input-solid-bg)'
    }),
    option: (provided:any, state:any)=>({
        ...provided,
        ':active': {
            backgroundColor: '#009ef7',
            color: '#fff'
        },
        ':hover': {
            backgroundColor: '#009ef7',
            color: '#fff'
        }
    }),
    multiValueRemove: (provided:any, state:any)=>({
        ...provided,
        color: '#DE350B'
    })

}