// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserLastLoginCell} from './UserLastLoginCell'
import {UserActionsCell} from './UserActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {TableCustomHeader} from './TableCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {CreateEmployee, EmployeeM, IEmployee} from "@providers/employee/_models";
import {InfoPhone} from "./InfoPhone";
import {useIntl} from "react-intl";
import {AdminRequest, AdminResponse} from "@providers/admins/_models";

const adminColumns: ReadonlyArray<Column<AdminResponse>> = [


  {
    Header: (props) => {
      const intl = useIntl()
      return <TableCustomHeader tableProps={props} title={"ID"} className='min-w-125px' />
    },
    accessor: 'id',
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return <TableCustomHeader tableProps={props} title={intl.formatMessage({id:"admin.name"})} className='min-w-125px' />
    },
    accessor: 'firstName',
  },

  {
    Header: (props) => {
      const intl = useIntl()
      return <TableCustomHeader tableProps={props} title={intl.formatMessage({id:"admin.surname"})} className='min-w-125px' />
    },
    accessor: 'lastName',
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return <TableCustomHeader tableProps={props} title={intl.formatMessage({id:"phone"})} className='min-w-125px' />
    },
    accessor: 'phone',
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return <TableCustomHeader tableProps={props} title={intl.formatMessage({id:"ACTIONS"})} className='text-end min-w-100px' />
    },
    id: 'actions',
    Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index].id} admin={props.data[props.row.index]} />,
  },
]

const usersColumnsOwner: ReadonlyArray<Column<IEmployee>> = [

  {
    Header: (props) => <UserSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  },

  {
    Header: (props) => {
      const intl = useIntl()
      return <TableCustomHeader tableProps={props} title={intl.formatMessage({id: 'PHONE'})} className='min-w-125px'/>
    },
    id: 'phone',
    Cell: ({...props}) => <InfoPhone user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => {
        const intl = useIntl()
        return <TableCustomHeader tableProps={props} title={intl.formatMessage({id: 'POSITION'})} className='min-w-125px' />
    },
    id: 'position',
    Cell: ({...props}) => <UserLastLoginCell position={props.data[props.row.index].position} />,
  },

  {
    Header: (props) => {
      const intl = useIntl()
      return <TableCustomHeader tableProps={props} title={intl.formatMessage({id: 'ACTIONS'})} className='text-end min-w-100px' />
    },
    id: 'actions',
    Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index].id} />,
  },
]

const usersColumns: ReadonlyArray<Column<IEmployee>> = [


  {
    Header: (props) => {
      const intl = useIntl()
      return <TableCustomHeader tableProps={props} title={intl.formatMessage({id: 'PHONE'})} className='min-w-125px' />
    },
    accessor: 'phone',
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return <TableCustomHeader tableProps={props} title={intl.formatMessage({id: 'POSITION'})} className='min-w-125px' />
    },
    id: 'position',
    Cell: ({...props}) => <UserLastLoginCell position={props.data[props.row.index].position} />,
  },

]

export {usersColumns, usersColumnsOwner, adminColumns}
