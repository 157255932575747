/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {MenuComponent} from '@metronic/assets/ts/components'
import {ID, KTSVG, QUERIES} from '@metronic/helpers'
import {Link, useNavigate, useParams} from "react-router-dom";
import {Company} from "@providers/company/_request";
import {observer} from "mobx-react";
import {company} from "@store/company";
import {useIntl} from "react-intl";
import {useListView} from "../providers/ListViewProvider";
import {useErrors} from "@providers/context/ErrorsProvider";
import {employeeStore} from "@store/employee";
import {roleRoutingAdmin} from "@utils/index";
import {confirmAlert} from "react-confirm-alert";
import {Admins} from "@providers/admins/_request";
import {admins} from "@store/admins";
import {Employee} from "@providers/employee/_request";

type Props = {
    Employeeid: number
}


const ActionsButtons:FC<Props> = observer(({Employeeid}) => {

    const intl = useIntl()
    const {setItemIdForUpdate} = useListView()
    const {setError} = useErrors()
    const navigate = useNavigate()
    const role = employeeStore.getRole
    const {id} = useParams()

    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])

    const openEditModal = () => {
        setItemIdForUpdate(Employeeid)
    }

    const deleteItem = async ()=>{
        if(roleRoutingAdmin('action', role, 'employee-delete')){
            confirmAlert({
                title: intl.formatMessage({id: 'WARNING'}),
                message: intl.formatMessage({id:'WARNING_DELETE_EMPLOYEE'}),
                buttons: [
                    {
                        label: intl.formatMessage({id: 'ACTIONS_YES'}),
                        onClick: async () => {
                            await Employee.deleteEmployee(id||'', Employeeid)
                                .then(()=>{
                                    employeeStore.setEmployee(employeeStore.getEmployee.filter(item=>item.id!==Employeeid))
                                }).catch(err=>{
                                    setError({status: err?.response?.status, message: err?.response?.data?.code === "METHOD_NOT_ALLOWED"?intl.formatMessage({id: 'not_employee'}):err?.response?.data?.message, isError: true})
                                })

                        }
                    },
                    {
                        label: intl.formatMessage({id: 'ACTIONS_NO'}),
                        onClick: async () => {
                            return
                        }
                    }
                ]
            })

        }
    }

    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])






    return (
        <>
            <div className='d-flex justify-content-end'>
                {roleRoutingAdmin('action', role, 'employee-edit')?(  <a href="#" onClick={(e)=>{
                    e.preventDefault()
                    openEditModal()
                }} className="btn btn-icon-gray-500 btn-active-icon-primary p-5">
                    <KTSVG path='/media/icons/duotune/general/gen055.svg'
                           className="svg-icon-2 svg_icon_edit"
                    /></a>):''}

                {roleRoutingAdmin('action', role, 'employee-delete')?(<a href="#" onClick={(e)=>{
                    e.preventDefault()
                    deleteItem()
                }} className="btn btn-icon-gray-500 btn-active-icon-danger p-5">
                    <KTSVG path='/media/icons/duotune/general/gen027.svg'
                           className="svg-icon-2 svg_icon_delete"/></a>):''}



            </div>
        </>
    )
})

export {ActionsButtons}
