import {Schedule} from "@providers/autopay/models/_models";
import React, {useEffect, useRef} from "react";
import {useThemeMode} from "@metronic/partials";
import {getCSS, getCSSVariableValue} from "@metronic/assets/ts/_utils";
import ApexCharts, {ApexOptions} from "apexcharts";
import {KTSVG} from "@metronic/helpers";
import {useIntl} from "react-intl";
import {NumReplace} from "@utils/index";

type Props = {
    className: string,
    onCreateGraphic?: Function,
    onDeleteGraphic?: Function,
    onCashPayment?: Function,
    schedules: Array<Schedule> | undefined,
    shouldShowButtons: boolean,
    isEditPage?: boolean,
}

export const PaymentGraphicChart: React.FC<Props> = ({
                                                         className,
                                                         onCreateGraphic,
                                                         onDeleteGraphic,
                                                         onCashPayment,
                                                         schedules,
                                                         shouldShowButtons,
                                                         isEditPage
                                                     }) => {
    const chartRef = useRef<HTMLDivElement | null>(null)
    const {mode} = useThemeMode()
    const intl = useIntl()

    const refreshMode = () => {
        if (!chartRef.current) {
            return
        }
        // const sortedData = schedules?.sort((a, b) => {
        //     return new Date(b.scheduleDate).valueOf() - new Date(a.scheduleDate).valueOf()
        // }).reverse()
        const paymentsData = schedules?.map(schedule => schedule.paymentSum)
        const intervalsData = schedules?.map(schedule => schedule.scheduleDate)

        const height = parseInt(getCSS(chartRef.current, 'height'))

        const chart = new ApexCharts(chartRef.current, getChartOptions(height, paymentsData ?? [], intervalsData ?? [],intl))
        if (chart) {
            chart.render()
        }

        return chart
    }

    useEffect(() => {
        const chart = refreshMode()

        return () => {
            if (chart) {
                chart.destroy()
            }
        }
    }, [chartRef, mode, schedules])

    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>{intl.formatMessage({id:"GRAF_PAY"})}</span>
                </h3>

                {/* begin::Toolbar */}
                {shouldShowButtons && <div className='card-toolbar' data-kt-buttons='true'>
                    {isEditPage &&
                        <button onClick={() => onCashPayment!()}
                                className='btn btn-sm btn-color-muted px-4 bg-success text-white me-4'
                                id='kt_charts_widget_3_week_btn' type="button"
                        >
                            {intl.formatMessage({id:"ADD_CASH"})}
                        </button>}
                    <button onClick={() => onDeleteGraphic!()}
                            className='btn btn-sm btn-color-muted px-4 bg-danger text-white me-4'
                            id='kt_charts_widget_3_week_btn' type="button"
                    >
                        {intl.formatMessage({id:"DELETE_PAY"})}
                    </button>
                    <button onClick={() => onCreateGraphic!()}
                            className='btn active btn-sm btn-color-muted btn-active btn-active-primary px-4'
                            id='kt_charts_widget_3_week_btn' type="button"
                    >
                        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2'/>
                        {intl.formatMessage({id:"ADD_PAY"})}
                    </button>
                </div>}
                {/* end::Toolbar */}
            </div>
            {/* end::Header */}

            {/* begin::Body */}
            <div className='card-body'>
                {/* begin::Chart */}
                <div ref={chartRef} id='kt_charts_widget_3_chart' style={{height: '350px'}}></div>
                {/* end::Chart */}
            </div>
            {/* end::Body */}
        </div>
    )
}

function getChartOptions(height: number, paymentsData: Array<number>, intervalsData: Array<string>,intl:any): ApexOptions {
    const labelColor = getCSSVariableValue('--kt-gray-500')
    const borderColor = getCSSVariableValue('--kt-gray-200')
    const baseColor = getCSSVariableValue('--kt-info')
    const lightColor = getCSSVariableValue('--kt-info-light')

    return {
        series: [
            {
                name: intl.formatMessage({id:"ADD_PAY"}),
                data: paymentsData,
            },
        ],
        chart: {
            fontFamily: 'inherit',
            type: 'area',
            height: 350,
            toolbar: {
                show: false,
            },
            defaultLocale: 'ru',
            locales: [{
                name: 'ru',
                options: {
                    months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                    shortMonths: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
                    days: ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'],
                    shortDays: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
                }
            }, {
                name: 'uz',
                options: {
                    months: ['Yanvar', 'Fevral', 'Mart', 'Aprel', 'May', 'Iyun', 'Iyul', 'Avgust', 'Sentyabr', 'Oktyabr', 'Noyabr', 'Dekabr'],
                    shortMonths: ['Yan', 'Fev', 'Mar', 'Apr', 'May', 'Iyu', 'Iyu', 'Avg', 'Sen', 'Okt', 'Noy', 'Dek'],
                    days: ['Dushanba', 'Seshanba', 'Chorshanba', 'Payshanba', 'Juma', 'Shanba', 'Yakshanba'],
                    shortDays: ['Dush', 'Sesh', 'Chor', 'Pay', 'Jum', 'Sha', 'Yak'],
                }
            }]
        },
        plotOptions: {},
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        fill: {
            type: 'solid',
            opacity: 1,
        },
        stroke: {
            curve: 'smooth',
            show: true,
            width: 3,
            colors: [baseColor],
        },
        xaxis: {
            categories: intervalsData,
            type: "datetime",
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                datetimeFormatter: {
                    year: 'yyyy',
                    month: 'MMM \'yy',
                    day: 'dd MMM',
                    hour: 'HH:mm'
                },
                show: true,
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
            crosshairs: {
                position: 'front',
                stroke: {
                    color: baseColor,
                    width: 1,
                    dashArray: 3,
                },
            },
            tooltip: {
                enabled: true,
                formatter: undefined,
                offsetY: 0,
                style: {
                    fontSize: '12px',
                },
            },
        },
        yaxis: {
            title: {
                text: "Сумма"
            },
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
        },
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
        },
        tooltip: {
            style: {
                fontSize: '12px',
            },
            y: {
                formatter: function (val) {
                    return NumReplace(val+'') + ` ${intl.formatMessage({id:"SUMM"})}`
                },
            },
        },
        colors: [lightColor],
        grid: {
            borderColor: borderColor,
            strokeDashArray: 4,
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },
        markers: {
            strokeColors: baseColor,
            strokeWidth: 3,
        },
    }
}