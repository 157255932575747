import React, {FC, useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import {FormikProps} from "formik";
import {CreateCompany} from "@providers/company/_models";
import {useIntl} from "react-intl";
import {useErrors} from "@providers/context/ErrorsProvider";
import {FILE_SIZE, SUPPORTED_FORMATS, SUPPORTED_FORMATS_IMAGES} from "@utils/constants";
import {KTSVG} from "@metronic/helpers";



interface IProps extends FormikProps<CreateCompany> {
    handleChange(arg: any): void
    setFieldValue(name:string, val:any):void
    values: any
}

const Step3: FC<IProps> = (props) => {

    const intl = useIntl()

  return (
    <div className='w-100'>
      <div className="pb-10 pb-lg-12">
        <h1 className="fw-bold text-dark">{intl.formatMessage({id:'cashbox_logo_upload'})}</h1>
          <p className='fs-2 text-gray-400'>{intl.formatMessage({id:'upload_file_text'})}</p>
          <div className='d-flex align-items-center'>
              <div style={{width:'50px', height: '50px'}} className='mx-3'>
                  <img className='w-100 h-100' style={{objectFit:'contain'}} src='/media/svg/files/jpeg.png'/>
              </div>
              <div style={{width:'50px', height: '50px'}}>
                  <img className='w-100 h-100' style={{objectFit:'contain'}} src='/media/svg/files/png.png'/>
              </div>
          </div>
          <p className='fs-2 text-gray-400 mt-3'>{intl.formatMessage({id:'upload_file_text1'})}</p>
      </div>
        <UploadLogo {...props}  />

    </div>
  )
}

interface IUploadDocument{
    handleChange(arg: any): void
    values:any
    setFieldValue(name:string, val:any):void
}
const arrIconsFiles:any = {
    pdf: '/media/svg/files/pdf.svg',
    png: '/media/svg/files/png.png',
    jpeg: '/media/svg/files/jpeg.png',
    jpg: '/media/svg/files/jpeg.png'
}




function UploadLogo(props:IUploadDocument){
    const intl = useIntl()
    const {setError} = useErrors()
    //@ts-ignore
    const onDrop = useCallback(acceptedFiles => {

        if(acceptedFiles.length) {
            const size_kb = +(acceptedFiles[0].size/ 1024).toFixed(1)
            if(size_kb>FILE_SIZE){
                return setError({status: 0, message: intl.formatMessage(({id:'ERROR_FILE_SIZE'})), isError: true})
            }
            if(!SUPPORTED_FORMATS_IMAGES.includes(acceptedFiles[0].type)){
                return setError({status: 0, message: intl.formatMessage(({id:'ERROR_FILE_TYPE'})), isError: true})
            }
            props.setFieldValue('logoId', acceptedFiles[0])
        }
        else props.setFieldValue('logoId', undefined)
    }, [])
    const {getRootProps, getInputProps} = useDropzone({onDrop, multiple:false})


    function getIcon(type:string){
        for(let key in arrIconsFiles){
            if(type.match(key)){
                return arrIconsFiles[key]
            }
        }

        return ''
    }

    return(
        <div className="fv-row mb-8">
            <div className="dropzone" {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="dz-message needsclick position-relative">
            <span className="svg-icon svg-icon-3hx svg-icon-primary">
                {
                    props.values['logoId']?(<div style={{width: '35px', height: '35px'}}>
                        <img className='w-100 h-100' style={{objectFit:'contain'}} src={getIcon(props.values['logoId']?.type)}/>
                    </div>):(<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.3"
                              d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM14.5 12L12.7 9.3C12.3 8.9 11.7 8.9 11.3 9.3L10 12H11.5V17C11.5 17.6 11.4 18 12 18C12.6 18 12.5 17.6 12.5 17V12H14.5Z"
                              fill="currentColor"/>
                        <path
                            d="M13 11.5V17.9355C13 18.2742 12.6 19 12 19C11.4 19 11 18.2742 11 17.9355V11.5H13Z"
                            fill="currentColor"/>
                        <path
                            d="M8.2575 11.4411C7.82942 11.8015 8.08434 12.5 8.64398 12.5H15.356C15.9157 12.5 16.1706 11.8015 15.7425 11.4411L12.4375 8.65789C12.1875 8.44737 11.8125 8.44737 11.5625 8.65789L8.2575 11.4411Z"
                            fill="currentColor"/>
                        <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z"
                              fill="currentColor"/>
                    </svg>)
                }
            </span>
                    <div className="ms-4">
                        <h3 className="dfs-3 fw-bold text-gray-900 mb-1">{props.values['logoId']?(<div className="d-flex justify-content-between flex-stack py-4 border border-top-0 border-left-0 border-right-0 border-dashed" >

                            <div className="d-flex align-items-center">
                                <div>
                                    <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">{props.values['logoId']?.name}</a>
                                    <div className="fw-semibold text-muted">{(props.values['logoId'].size / 1024).toFixed(1) + ' Kb'}</div>
                                </div>
                            </div>
                            <button className='btn btn-sm btn-icon btn-danger position-absolute' style={{right:0}} onClick={(e)=>{
                                e.stopPropagation()
                                props.setFieldValue('logoId', undefined)
                            }}><KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' /></button>
                        </div>):intl.formatMessage({id:'DROP_FILES_LOGO'})}</h3>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {Step3}
