import {useIntl} from "react-intl";

const statuses: Array<string> = ["ACTIVE", "OUTDATED", "TERMINATED", "UNVERIFIED", "CASH_PAID", "FINISHED"]

const getContractStatus = (status: string,intl:any) => {

    switch (status) {
        case statuses[0]:
            return intl.formatMessage({id:"ACTIVE"});
        case statuses[1]:
            return intl.formatMessage({id:"OUTDATED"});
        case statuses[2]:
            return intl.formatMessage({id:"TERMINATED"});
        case statuses[3]:
            return intl.formatMessage({id:"UNVERIFIED"});
        case statuses[4]:
            return intl.formatMessage({id:"CASH_PAID"});
        case statuses[5]:
            return intl.formatMessage({id:"FINISHED"});
        default:
            return intl.formatMessage({id:"NOT_STATUS"})
    }
}

const showContractStatusButton = (status: string,intl:any) => {
    switch (status) {
        case statuses[0]:
            return intl.formatMessage({id:"STOP_STATUS"});
        case statuses[1]:
            return intl.formatMessage({id:"NO_ACTION"});
        case statuses[2]:
            return intl.formatMessage({id:"ACTIVE_CONTRACT"});
        case statuses[3]:
            return intl.formatMessage({id:"VERIFIED_CONTRACT"});
        case statuses[4]:
            return intl.formatMessage({id:"NO_ACTION"});
        case statuses[5]:
            return intl.formatMessage({id:"NO_ACTION"});
        default:
            return intl.formatMessage({id:"NO_ACTION"})
    }
}

export {getContractStatus, showContractStatusButton}