import {observer} from "mobx-react";
import {useNavigate, useParams} from "react-router-dom";
import React, {FC, useEffect, useMemo, useState} from "react";
import {agentStore} from "@store/agents";
import {ColumnInstance, Row, useTable} from "react-table";
import {useErrors} from "@providers/context/ErrorsProvider";
import localeEn from "antd/es/date-picker/locale/en_US";
import {toIsoStringHelper} from "../../../autopay/dateHelper";
import moment from "moment/moment";
import {Agent} from "@providers/agents/_request";
import {useIntl} from "react-intl";
import {KTCard, KTCardBody, KTSVG} from "@metronic/helpers";
import {CompanyResponse} from "@providers/company/_models";
import {CustomHeaderColumn} from "../../_modals/columns/CustomHeaderColumn";
import {CustomRow} from "../../_modals/columns/CustomRow";
import {Pagination} from "../../../../modules/Pagination";
import {merchantsAgentPage} from "../../../agent-statistic/_modals/columns/_columns";
import {IStatistic} from "../index";
import {useListView} from "../_modals/providers/ListViewProvider";
import {FilterDropDown} from "../../../payments/transactions/_modals/FilterDropDown";
import {isDemo, NumReplace} from "@utils/index";
import {DatePicker} from "antd";
import {MinusOutlined} from "@ant-design/icons";
import localeRu from "antd/es/date-picker/locale/ru_RU";

type Iprops = {
    general:IStatistic
    agent: any
}


const Merchants:FC<Iprops> = observer((props) => {
    const {id} = useParams()
    const {setItemIdForUpdate} = useListView()
    const {agent} = props
    const [generalData, setGeneralData] = useState<IStatistic|null>(null)
    const data = useMemo(() => agentStore.getStatisticPersonal, [agentStore.getStatisticPersonal])
    const pagination = useMemo(() => agentStore.getSorting, [agentStore.getSorting])
    const columns = useMemo(() => merchantsAgentPage, [agentStore.getStatisticPersonal])
    const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
        columns,
        data,
    })

    const [filtersDay, setFiltersDay] = useState('month')


    const {setError} = useErrors()

    const lang = JSON.parse(localStorage.getItem('i18nConfig') ?? '{}')?.selectedLang

    if (lang === "uz") {
        localeEn.lang.locale = "uz-latn"
    }

    useEffect(()=>{
        if(agentStore.getSorting.from_date&&agentStore.getSorting.to_date){
            getGeneralData()
        }
    }, [agentStore.getStatisticGeneralPage])




    useEffect(()=>{
        agentStore.setSorting({...agentStore.getSorting, page: pagination.page,
            count: 20,
            from_date: toIsoStringHelper(moment().subtract(1, 'month').startOf('day').toDate()),
            to_date: toIsoStringHelper(moment().endOf('day').toDate())})

        getAgents()


    }, [id])


    async function getGeneralData(){

        if(id){
            await Agent.getAgentsGeneralById(id, {
                "from_date": agentStore.getSorting?.from_date,
                "to_date": agentStore.getSorting?.to_date
            }) .then(result=>{
                setGeneralData(result?.data?.result)
            }).catch((err:any)=>{
                setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
            })
        }
    }




    async function getAgents(){


        const dataFilter = {
            page: pagination.page,
            count: 20,
            from_date: agentStore.getSorting?.from_date,
            to_date: agentStore.getSorting?.to_date
        }
        if(id){
            await Agent.getStatisticById(id, dataFilter)
                .then(result=>{

                    agentStore.setStatisticPersonal(result?.data?.result.map((item: any, index: number) => {
                        if (dataFilter.page && dataFilter.page > 2) {
                            item['num'] = ((dataFilter.page - 1) * 20) + index + 1
                        } else if (dataFilter.page == 2) {
                            item['num'] = 21 + index
                        } else {
                            item['num'] = index + 1
                        }
                        return item
                    }))

                    agentStore.setSorting({...agentStore.getSorting,...result?.data?.meta,   page:dataFilter.page, currentPage: dataFilter.page})
                    window.scroll({
                        top:0,
                        behavior: 'smooth'
                    })

                }).catch((err:any)=>{
                    setError({status: err?.response?.status, message: err?.response?.data?.message, isError:true})
                })
        }
    }





    const intl = useIntl()

    async function updatePage(props:any){
        agentStore.setSorting({...agentStore.getSorting, page: props.currentPage})
        getAgents()

    }

    function replaceFormat(date:any, formatFrom:string, formatTo:string){
        let newDate = moment(date, formatFrom).format(formatTo)
        return moment(newDate, formatTo)
    }

    function countDays(){

        let a = moment(agentStore.getSorting.from_date),
            b = moment(agentStore.getSorting.to_date)

        return b.diff(a, 'days')
    }





    return (
        <>

        <button type='button' className='btn btn-primary mt-10 mb-10' onClick={()=>setItemIdForUpdate(agent)}>
            <KTSVG path='/media/icons/duotune/general/gen041.svg' className='svg-icon-2' />
            {intl.formatMessage({id:'MERCHANT-ADD'})}
        </button>
        <KTCard className='mb-5'>
        <KTCardBody className='py-4'>
            <div className='d-flex justify-content-between'>
                <div className='d-flex toolbar-filter justify-content-end pe-10 align-items-center'>



                    <div className='filter_buttons_group'>

                        <button
                            disabled={isDemo()}
                            className={`btn text-gray-800 btn-sm btn-active-light-primary ${countDays() === 7 ? 'btn-active' : "fw-bold" }`}
                            onClick={async () => {
                                if (filtersDay !== 'week') {

                                    const from_date = moment().subtract(1, 'week').startOf('day').toDate()
                                    const to_date = moment().endOf('day').toDate()

                                    setFiltersDay('week')
                                    agentStore.setSorting({...agentStore.getSorting, page: pagination.page,
                                        count: 20,
                                        from_date: toIsoStringHelper(from_date),
                                        to_date: toIsoStringHelper(to_date)})
                                    await updatePage({...agentStore.getSorting})
                                    await getGeneralData()
                                }

                            }}>
                            {intl.formatMessage({id: 'filter_by_week'})}
                        </button>
                        <button
                            className={`btn text-gray-800 btn-sm btn-active-light-primary ${(countDays() >=28&&countDays()<=31) ? 'btn-active' : "fw-bold" }`}
                            onClick={async() => {
                                if (filtersDay !== 'month') {

                                    const from_date = moment().subtract(1, 'month').startOf('day').toDate()
                                    const to_date = moment().endOf('day').toDate()


                                    setFiltersDay('month')
                                    agentStore.setSorting({...agentStore.getSorting, page: pagination.page,
                                        count: 20,
                                        from_date: toIsoStringHelper(from_date),
                                        to_date: toIsoStringHelper(to_date)})
                                    await updatePage({...agentStore.getSorting})
                                    await getGeneralData()
                                }

                            }}>
                            {intl.formatMessage({id: 'filter_by_month'})}
                        </button>
                    </div>
                    <div className="mx-4" style={{backgroundColor: "rgba(235, 237, 242, 1)", width: "1px", height: "40%"}}/>
                    <div>
                        <DatePicker.RangePicker
                            style={{border: "none"}}
                            separator={<MinusOutlined/>}
                            locale={lang === 'ru' ? localeRu : localeEn}
                            placeholder={[intl.formatMessage({id: 'calendar_start'}), intl.formatMessage({id: 'calendar_end'})]}
                            key={JSON.stringify(agentStore.getSorting)}
                            className='datepicker_form form-control-lg form-control-solid'
                            defaultValue={[replaceFormat(agentStore.getSorting?.from_date||'', 'YYYY-MM-DD', 'DD-MM-YYYY'),
                                replaceFormat(agentStore.getSorting?.to_date||'', 'YYYY-MM-DD', 'DD-MM-YYYY')]}
                            format='DD-MM-YYYY'
                            disabledDate={current => {
                                return isDemo() || current && current > moment().endOf('day');
                            }}
                            onKeyDown={(val) => {
                                if (val.code.toLowerCase().includes("key")) {
                                    val.preventDefault()
                                    val.stopPropagation()
                                    return
                                }
                            }}
                            //@ts-ignore
                            onChange={async(dates: [any, any], dateStrings: [string, string]) => {
                                if (dates) {

                                    let from_date = replaceFormat(dateStrings[0], 'DD-MM-YYYY', 'YYYY-MM-DD').startOf('day').toDate()
                                    let to_date = replaceFormat(dateStrings[1], 'DD-MM-YYYY', 'YYYY-MM-DD').endOf('day').toDate()



                                    agentStore.setSorting({...agentStore.getSorting, page: pagination.page,
                                        count: 20,
                                        from_date: toIsoStringHelper(from_date),
                                        to_date: toIsoStringHelper(to_date)})
                                    await updatePage({...agentStore.getSorting})
                                    await getGeneralData()
                                    setFiltersDay('')
                                }

                            }}
                        />
                    </div>
                </div>
                <div className='d-flex justify-content-end py-5'>
                    <p className='fs-6 fw-semibold text-gray-600 me-10'>{intl.formatMessage({id:'merchants'})}: <span className='text-gray-800 fs-3'>{generalData?.merchants||0}</span></p>
                    <p className='fs-6 fw-semibold text-gray-600'>{intl.formatMessage({id:'income'})}: <span className='text-gray-800 fs-3'>{generalData?.income?NumReplace(generalData?.income/100+''):0}</span></p>
                </div>
            </div>

            {columns.length ? <div className='table-responsive'>
                <table
                    id='kt_table_users'
                    className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                    {...getTableProps()}
                >
                    <thead>
                    <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                        {headers.map((column: ColumnInstance<CompanyResponse>) => (
                            <CustomHeaderColumn key={column.id} column={column}/>
                        ))}
                    </tr>
                    </thead>
                    <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                    {rows.length > 0 ? (
                        rows.map((row: Row<CompanyResponse>, i) => {
                            prepareRow(row)
                            return <CustomRow row={row} key={`row-${i}-${row.id}`}/>
                        })
                    ) : (
                        <tr>
                            <td colSpan={7}>
                                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                    {intl.formatMessage({id: 'NO_MATCHING'})}
                                </div>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div> : <div style={{height: "55vh"}} className="d-flex justify-content-center align-items-center">
                <a href="https://demo.globalpay.uz" target="_blank" className="btn btn-success text-white fs-3">
                    {intl.formatMessage({id: "WATCH_DEMO_COMPANY1"})}
                    <KTSVG path='/media/icons/duotune/arrows/arr001.svg' className='svg-icon-3 ms-3' />
                </a>
            </div>}
            <Pagination withInfo={true} position='end' infoText='агентов' pagination={pagination} updatePage={updatePage}/>

        </KTCardBody>
        </KTCard>
    </>
    )
})


export {Merchants}