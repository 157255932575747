import {makeAutoObservable, toJS} from "mobx";
import {IRole, IRolesState} from "@providers/roles/_models";

class Roles {

    roles: IRolesState = {
        entity: [],
        loaded: false,
        errors: {
            isError: false,
            message: ''
        }

    }

    constructor() {
        makeAutoObservable(this)
    }


    setRoles(roles: string[]) {
        this.roles.entity = roles
    }

    get getRoles() {
        return toJS(this.roles.entity)
    }


}


export const rolesStore = new Roles()