import {KTSVG} from "@metronic/helpers";
import {useListView} from "../../providers/ListViewProvider";
import {useIntl} from "react-intl";
import AdminForm from "../../components/admin-form/admin-form";


const UsersListToolbar = () => {
    const {setItemIdForUpdate} = useListView()
    const openAddUserModal = () => {
        setItemIdForUpdate(null)
    }
    const intl = useIntl()
    return (
        <div className='d-flex justify-content-start' data-kt-user-table-toolbar='base'>

            {/* begin::Add user */}
            <AdminForm/>
            {/* end::Add user */}
        </div>
    )
}

export {UsersListToolbar}
