import {instantse} from "@providers/request";
import {CompanyResponseOne, CompanyUpdate, ICreateTheme, IStyle} from "@providers/company/_models";
import {Axios, AxiosResponse} from "axios";

export const Company = {
    getCompany(params = {}) {
        return instantse.post('/v1/company/page', {}, {
            params
        })
            .then(result => result)
    },
    getMyCompanies(params = {}) {
        return instantse.get('/v1/company/my', {params})
            .then(result => result)
    },
    createCompany(data: any) {
        return instantse.post('/v1/company', data)
            .then(result => result)
    },
    createCompanyFiles(data: any, companyId: string) {
        return instantse.post(`/v1/company/${companyId}/upload`, data, {
            headers: {'Content-Type': 'multipart/form-data'}
        })
            .then(result => result)
    },
    deleteCompany(id: number) {
        return instantse.delete(`/v1/company/${id}`)
            .then(result => result)
    },
    editCompany(data: CompanyUpdate) {
        return instantse.put(`/v1/company`, data, {
            // headers: {'Content-Type': 'multipart/form-data'}
        })
            .then(result => result)
    },
    partiallyEdit(data: Partial<CompanyUpdate>) {
        return instantse.patch(`/v1/company`, data)
    },
    getById(id: string): Promise<AxiosResponse<{ result: CompanyResponseOne }>> {
        return instantse.get(`/v1/company/${id}`)
            .then(result => result)
    },
    getCompanyTypes() {
        return instantse.get('/v1/list/types')
    },
    getCompanyFields() {
        return instantse.get('/v1/list/activity-fields')
    },
    getFiles(fileId: string) {
        return `${process.env.REACT_APP_PUBLIC_URL}/v1/file/${fileId}`
    },
    createTheme(data: ICreateTheme): Promise<AxiosResponse<IStyle>>  {
        return instantse.post('/v1/style', data)
    },
    updateTheme (data: IStyle): Promise<AxiosResponse<IStyle>> {
        return instantse.put('/v1/style', data)
    }

}