import {instantse} from "@providers/request";
import {ICashbox, ICashboxesResponse, IEposSend} from "@providers/filial/_models";
import {AxiosResponse} from "axios";


export const Filial = {
    getFilialById(branch_id: string): Promise<AxiosResponse<{result: ICashbox}>> {
        return instantse.get(`/v1/cashbox/${branch_id}`)
            .then(result => result)
    },
    patchFilial (company_id: string, branch_id: string, data: Partial<ICashbox>): Promise<AxiosResponse<{result: ICashbox}>> {
      return instantse.patch(`/v1/cashbox`, data)
    },
    deleteFilial(branch_id: string) {
        return instantse.delete(`/v1/cashbox/${branch_id}`)
            .then(result => result)
    },
    postFilial( data: any) {
        return instantse.post(`/v1/cashbox`, data, {
            // headers: {'Content-Type': 'multipart/form-data'}
        })
            .then(result => result)
    },
    postFilialFiles(data: any, branch_id: string) {
        return instantse.post(`/v1/cashbox/${branch_id}/upload`, data, {
            headers: {'Content-Type': 'multipart/form-data'}
        })
            .then(result => result)
    },
    getFilial(company_id: string, page?: number, size?: number): Promise<AxiosResponse<ICashboxesResponse>> {

        const params = new URLSearchParams()

        params.set('page',  page ? page.toString() :'1')

        params.set('size', size ? size.toString() : '20')

        return instantse.get(`/v1/cashbox/${company_id}/company`, {
            params
        })
    },
    eposesCrud(data: IEposSend) {
        const {
            company_id,
            branch_id,
            eposes
        } = data
        return instantse.put(`/v1/company/${company_id}/cashbox/${branch_id}/epos`, {eposes: eposes})
    }
}