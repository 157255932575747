import {KTCard, KTCardBody} from "@metronic/helpers";
import React, {useState} from "react";
import {CompanyResponseOne} from "@providers/company/_models";
import {CustomiseEditView} from "../../_modals/tabsComponents/CustomiseEditView";
import {CustomiseToolbar} from "../../_modals/tabsComponents/CustomiseToolbar";


type IProps = {
    companyData: CompanyResponseOne
    updateData: () => void
}


export function SettingsCheckout({companyData, updateData}: IProps) {
    const {style} = companyData

    const currentTheme = style.find(({name}) => name === 'theme')


    const [theme, setTheme] = useState<string>(currentTheme?.value ?? 'light')

    const onReset = () => setTheme('light')

    return (
        <KTCard>
            <KTCardBody className={`py-4`}>
                <div className={`d-flex checkout-wrap theme-${theme}`}>
                    <CustomiseEditView theme={theme}/>
                    <CustomiseToolbar options={{
                        onUpdate: updateData,
                        currentTheme,
                        onReset,
                        theme,
                        setTheme
                    }}/>
                </div>
            </KTCardBody>
        </KTCard>
    )
}