import {makeAutoObservable, toJS} from "mobx";

class AutopayStore{

    sorting_contracts:any = {sortBy: "DATE", sortFromMax: true}


    sorting_contracts_client:any = {sortBy: "DATE", sortFromMax: true}

    constructor() {
        makeAutoObservable(this)
    }

    setSortingContracts(data:any){
        this.sorting_contracts = data
    }


    get getSortingContract(){
        return toJS(this.sorting_contracts)
    }

    setSortingContractsClient(data:any){
        this.sorting_contracts_client = data
    }


    get getSortingContractClient(){
        return toJS(this.sorting_contracts)
    }


}



export const autopayStore = new AutopayStore()