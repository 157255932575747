import React from "react";

type ArrayToSpanFormatter = {
    items: Array<string>
}

const ArrayToSpanFormatter = ({items}: ArrayToSpanFormatter) => {
    let data
    if (items.length > 3) {
        const newItems = items.slice(0, 3)
        data = newItems.join(", ") + ` и еще + ${items.length - newItems.length}`
    } else {
        data = items.join(", ")
    }
    return <span>{data}</span>
}

export default ArrayToSpanFormatter