import {IAssignAccount, ICreateAccount} from "@providers/account/_models";
import {ErrorMessage, Field, Form, Formik, FormikHelpers} from "formik";
import {isDemo, roleRoutingAdmin} from "@utils/index";
import {Account} from "@providers/account/_request";
import {openNotificationSuccess} from "@metronic/partials/notification";
import React, {useState} from "react";
import {initsAccount, SettingsAccountSchema} from "../../../settings-merchant/CompanyAboutHelper";
import {KTCard, KTCardBody} from "@metronic/helpers";
import {employeeStore} from "@store/employee";
import UserSearchInput from "../../forms/wizards/components/user-search-input/user-search-input";
import InputMask from "react-input-mask";
import {useIntl} from "react-intl";
import {useErrors} from "@providers/context/ErrorsProvider";


type IProps = {
    setLoading: (val:boolean)=>void
    formShow: number
    setFormShow: (val:number)=>void
    companyId: string | undefined
    updateData: ()=>void

}

export function FormAddCreateAccount(props:IProps){
    const {setLoading, formShow, setFormShow, companyId, updateData} = props
    const [initValOwner, setInitvalOwner] = useState<ICreateAccount>(initsAccount)
    const [accountId, setAccountId] = useState<number | undefined>()
    const [currentShemaAccount] = useState(SettingsAccountSchema())
    const intl = useIntl()
    const {setError} = useErrors()
    const onSubCreateAccount = (values: ICreateAccount, actions: FormikHelpers<ICreateAccount>) => {
        if (isDemo()) return
        setLoading(true)
        if(accountId){
            addAccountCompany(accountId, actions)
        }else{
            createAccount(values, actions)
        }

    }


    const createAccount = (values: ICreateAccount, actions: FormikHelpers<ICreateAccount>)=>{
        const data: ICreateAccount = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            companyId: Number(companyId),
            phone: values.phone.replace(/\s/g, '').replace('+', '').replaceAll('_', ''),
            role: values.role,
            employeeTypeId: 1
        }

        Account.createAccount(data)
            .then(result=>{
                window.scroll({top: 0, behavior: 'smooth'})
                setFormShow(0)
                updateData()
                actions.resetForm()
            })
            .catch(err => {
                setLoading(false)
                setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
            }).finally(() => setLoading(false))
    }

    const addAccountCompany = (userId:number, actions: FormikHelpers<ICreateAccount>)=>{
        const data:IAssignAccount = {
            id:userId,
            companies: [Number(companyId)]
        }
        Account.assignUserToCompany(data)
            .then(result=>{
                setFormShow(0)
                updateData()
                actions.resetForm()
            }).catch(err => {
            setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
        }).finally(() => setLoading(false))
    }

    return (
        <div className={`mt-3`}>
            <Formik enableReinitialize={true}  validationSchema={currentShemaAccount}
                    initialValues={initValOwner} onSubmit={onSubCreateAccount}>
                {(props) => {

                    const {
                        errors,
                        values,
                        setFieldValue,
                        setFieldTouched
                    } = props



                    return (
                        <Form>
                            <KTCard>
                                <div className='card-header d-flex py-5 px-10 align-items-center'>
                                    <h3 className='mb-0'>{intl.formatMessage({id: "title_assign_account"})}</h3>
                                    {
                                        formShow !== 2 && roleRoutingAdmin('action', employeeStore.getRole, 'is-admin') ? (<button onClick={() => {
                                            setFormShow(2)

                                        }} type='button' className='btn btn-md btn-primary'>
                                  <span className='indicator-label'>
                                    {intl.formatMessage({id: "EDIT_BUTTON"})}
                                  </span>
                                        </button>) : ''
                                    }
                                </div>
                                <KTCardBody>
                                    {formShow==2&&<UserSearchInput setId={(id:number|undefined)=> {
                                        setAccountId(id)
                                        setTimeout(()=>{
                                            setFieldTouched('firstName', true)
                                            setFieldTouched('lastName', true)
                                        }, 100)

                                    }} setFieldValue={setFieldValue}/>}
                                    <div className='mb-8 fv-row'>
                                        <label
                                            className='form-label mb-3 required'>{intl.formatMessage({id: 'ceo_first_name'})}</label>
                                        <Field
                                            type='text'
                                            className={`${accountId || formShow!==2 ? 'disabled' : ''} form-control form-control-lg form-control-solid`}
                                            name='firstName'
                                            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                if (!e.currentTarget.value.length || e.currentTarget.value.match(/^[a-zA-Z0-9А-Яа-я\s]+$/)) {
                                                    props.handleChange(e)
                                                }
                                            }}
                                        />
                                        <div className='text-danger mt-2'>
                                            <ErrorMessage name='firstName'/>
                                        </div>
                                    </div>

                                    <div className='mb-8 fv-row'>
                                        <label
                                            className='form-label mb-3 required'>{intl.formatMessage({id: 'ceo_last_name'})}</label>
                                        <Field
                                            type='text'
                                            className={`${accountId || formShow!==2 ? 'disabled' : ''} form-control form-control-lg form-control-solid`}
                                            name='lastName'
                                            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                if (!e.currentTarget.value.length || e.currentTarget.value.match(/^[a-zA-Z0-9А-Яа-я\s]+$/)) {
                                                    props.handleChange(e)
                                                }
                                            }}
                                        />
                                        <div className='text-danger mt-2'>
                                            <ErrorMessage name='lastName'/>
                                        </div>
                                    </div>
                                    <div className='mb-8 fv-row'>
                                        <label
                                            className='form-label mb-3 required'>{intl.formatMessage({id: 'phone'})}</label>
                                        <Field
                                            type='text'
                                            component={InputMask}
                                            mask="+\9\9\8 99 999 99 99"
                                            className={`${accountId || formShow!==2 ? 'disabled' : ''} form-control form-control-lg form-control-solid`}
                                            name='phone'
                                            value={values.phone}
                                            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                const value = e.currentTarget.value || '';
                                                setFieldValue('phone', value)

                                            }}
                                        />
                                        <div className='text-danger mt-2'>
                                            <ErrorMessage name='phone'/>
                                        </div>
                                    </div>
                                    <div className='mb-8 fv-row'>
                                        <label
                                            className='form-label mb-3'>{intl.formatMessage({id: 'email'})}</label>
                                        <Field
                                            type='email'
                                            className={`${accountId || formShow!==2 ? 'disabled' : ''} form-control form-control-lg form-control-solid`}
                                            name='email'
                                            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                props.handleChange(e)
                                            }}
                                        />
                                        <div className='text-danger mt-2'>
                                            <ErrorMessage name='email'/>
                                        </div>
                                    </div>
                                </KTCardBody>
                                {
                                    formShow === 2 ? (
                                        <div className='card-footer py-5 px-10 d-flex justify-content-end'>
                                            <button className='btn btn-light me-3' onClick={() => {
                                                setFormShow(0)
                                                setAccountId(undefined)
                                            }}>{intl.formatMessage({id: 'Cancel'})}</button>
                                            <button className='btn btn-primary' type='submit' onClick={() => {
                                                let err_message = ''
                                                if (Object.keys(errors).length) {
                                                    for (let err in errors) {
                                                        //@ts-ignore
                                                        err_message += `* ${intl.formatMessage({id: err})} - ${errors[err]} ` + "\n"
                                                    }
                                                    setError({status: '', message: err_message, isError: true})
                                                }
                                            }}>{intl.formatMessage({id: 'SAVE_EDITION'})}</button>
                                        </div>) : ''
                                }
                            </KTCard>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}