import React, {FC, useEffect, useState} from 'react'
import {KTSVG} from "@metronic/helpers";
import {useIntl} from "react-intl";





const Step4: FC  = () => {
    const intl = useIntl()

  return (
      <div className='w-100'>
          <div className='pb-8 pb-lg-10'>
              <h1 className='fw-bolder text-dark'>{intl.formatMessage({id:'agent-create-success'})}</h1>
          </div>

          <div className='mb-0 theme-light-show w-300px h-300px mx-auto mb-15'>
              <img className='w-100 h-100' style={{objectFit:'contain'}} src='/media/illustrations/unitedpalms-1/17.png'/>
          </div>
          <div className='mb-0 theme-dark-show w-300px h-300px mx-auto mb-15'>
              <img className='w-100 h-100' style={{objectFit:'contain'}} src='/media/illustrations/unitedpalms-1/17-dark.png'/>
          </div>
      </div>
  )
}

export {Step4}
