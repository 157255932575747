import React, {ContextType, FC} from 'react'
import {PageTitle} from "@metronic/layout/core";
import Dashboard from "./dashboard";
import {ErrorProvider} from "@providers/context/ErrorsProvider";
import {useIntl} from "react-intl";
import {Navigate, useOutletContext, useParams} from "react-router-dom";
import {isDemo, roleRouting, roleRoutingAdmin} from "@utils/index";
import AdminDashboard from "./admin/admin-dashboard";
import {PageTitleCustom} from "@metronic/partials/pageTitleCustom";

type IProps = {
    role: string
}

const DashboardMerchant:FC<IProps> = (props)=>{
    const {role} = props
    return(
        <div>
            {
                roleRoutingAdmin('action', role, 'is-admin')?<AdminDashboard/>:<Dashboard />
            }
        </div>
    )
}

export default function DashboardsMerchant(){
    const intl = useIntl()
    const {companyId} = useParams()
    //@ts-ignore
    const {role} = useOutletContext()


    if(role&&role!=='USER'){
        if(roleRouting('dashboard-merchant', role)||roleRoutingAdmin('page', role, 'dashboard')||isDemo()){
            return(
                <ErrorProvider>
                    <PageTitleCustom title={intl.formatMessage({id: 'DASHBOARD_MERCHANT'})} breadCrumbs={[]}/>
                    <DashboardMerchant role={role}/>
                </ErrorProvider>
            )
        }

        return <Navigate to={`/${companyId}/filial`}/>
    }

    return (<></>)


}