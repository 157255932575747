import React, {FC, useEffect, useState} from "react";
import {ClientService} from "@providers/autopay/requests/client.service";
import {useParams} from "react-router-dom";
import {IClientInfo, UpdateInfoClient} from "@providers/autopay/models/_models";
import {isDemo, ReplacePhone} from "@utils/index";
import {ErrorMessage, Field, Form, Formik} from "formik";
import Alert from "react-bootstrap/Alert";
import {KTCard, KTCardBody} from "@metronic/helpers";
import {Company} from "@providers/company/_request";
import {DropImages} from "@metronic/partials/DropImages";
import AsyncSelect from "react-select/async";
import {customStyles2} from "@metronic/assets/ts/select.style";
import InputMask from "react-input-mask";
import {employeeStore} from "@store/employee";
import {OWNER} from "@utils/constants";
import {Tooltip} from "antd";
import {useIntl} from "react-intl";
import {initsInfo, UpdateClientInfo} from "./ClientAboutHelper";
import {CreateCompany} from "@providers/company/_models";

type IProps = {clientInfo:any,updateClient:()=>void}


const ClientInfo = ({clientInfo,updateClient}:IProps) =>{
    const {pinfl} = useParams()
    const [alert, setAlert] = useState<string | null>(null)
    const intl = useIntl()
    const [formShow, setFormShow] = useState(0)
    // const intl = useIntl()
    const schemaInfo = UpdateClientInfo()
    const [createInfoClient, setCreateInfoClient] = useState(schemaInfo)
    const [initValues, setInitValues] = useState<UpdateInfoClient>(initsInfo)
    const [loading, setLoading] = useState(false)

    const onSub = async (values: UpdateInfoClient) => {
        if (isDemo()) return
        setLoading(true)
        setFormShow(0)
        await updateInfoClient(values)
        setLoading(false)
        await updateClient()
    }


    const updateInfoClient = async (values:UpdateInfoClient)=> {
        const checkValue = values.phoneNumber.replace(/ /g, '').replace('+', '')

        if(pinfl) {
            await ClientService.updateClientInfo(pinfl,values)
                .then((result)=>{
                    setAlert('Клиент успешно отредактирован')
                    setTimeout(() => {
                        setAlert(null)
                    }, 3000)
                })
        }

    }

    useEffect(()=>{
        setCreateInfoClient(schemaInfo)
        setInitValues(clientInfo)
    },[clientInfo])


    return (
        <div className='d-flex flex-row-fluid flex-center position-relative'>
            {alert && (
                <Alert className='position-fixed' style={{zIndex: "4", top: "20%"}} key={'success'}
                       variant={'success'}>
                    {alert}
                </Alert>
            )}
            <Formik enableReinitialize={true} validationSchema={createInfoClient}
                    initialValues={initValues} onSubmit={onSub}>
                {(props) => {
                    const {
                        values,
                        touched,
                        errors,
                        dirty,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        handleReset,
                        setFieldValue
                    } = props


                    return (
                        <Form className=' w-100'>
                            <KTCard>
                                <div className='card-header d-flex py-5 px-10 align-items-center'>
                                    <h3 className='mb-0'>{intl.formatMessage({id: "title_company_settings"})}</h3>
                                    {
                                        formShow!==1?(<button onClick={() => {
                                            setFormShow(1)

                                        }} type='button' className='btn btn-md btn-primary'>
                                  <span className='indicator-label'>
                                    {intl.formatMessage({id: "EDIT_BUTTON"})}
                                  </span>
                                        </button>):''
                                    }
                                </div>
                                <KTCardBody>
                                    {
                                        formShow===1?(
                                            <div className='w-100'>
                                                <div className='w-100 mt-5'>
                                                    <div className='mb-10 w-100'>
                                                        <div className='d-flex'>
                                                            <label
                                                                className='form-label mb-3 required col-lg-3'>Номер телефона</label>
                                                            <div className='col-lg-9'>
                                                                <Field
                                                                    type='text'
                                                                    className='form-control form-control-lg'
                                                                    name='phoneNumber'
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='col-lg-3'/>
                                                            <div className='text-danger mt-2'>
                                                                <ErrorMessage name='phoneNumber'/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='mb-10 w-100'>
                                                        <div className='d-flex'>
                                                            <label className='form-label mb-3 required col-lg-3'>Номер и серия паспорта</label>
                                                            <div className='col-lg-9 d-flex'>
                                                                <Field
                                                                    type='text'
                                                                    className='form-control form-control-lg'
                                                                    name='passportSeries'
                                                                    style={{width:"72px",marginRight:"24px"}}
                                                                />
                                                                <Field
                                                                    type='text'
                                                                    className='form-control form-control-lg'
                                                                    name='passportNumber'
                                                                    style={{width:"124px"}}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='col-lg-3'/>
                                                            <div className='text-danger mt-2'>
                                                                <ErrorMessage name='passportSeries'/>
                                                            </div>
                                                            <div className='text-danger mt-2'>
                                                                <ErrorMessage name='passportNumber'/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='mb-10 w-100'>
                                                        <div className='d-flex'>
                                                            <label
                                                                className='form-label mb-3 required col-lg-3'>Имя</label>
                                                            <div className='col-lg-9'>
                                                                <Field
                                                                    type='text'
                                                                    className='form-control form-control-lg'
                                                                    name='firstName'
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='col-lg-3'/>
                                                            <div className='text-danger mt-2'>
                                                                <ErrorMessage name='firstName'/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='mb-10 w-100'>
                                                        <div className='d-flex'>
                                                            <label
                                                                className='form-label mb-3 required col-lg-3'>Фамилия</label>
                                                            <div className='col-lg-9'>
                                                                <Field
                                                                    type='text'
                                                                    className='form-control form-control-lg'
                                                                    name='lastName'
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='col-lg-3'/>
                                                            <div className='text-danger mt-2'>
                                                                <ErrorMessage name='lastName'/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='mb-10 w-100'>
                                                        <div className='d-flex'>
                                                            <label
                                                                className='form-label mb-3 required col-lg-3'>Отчество</label>
                                                            <div className='col-lg-9'>
                                                                <Field
                                                                    type='text'
                                                                    className='form-control form-control-lg'
                                                                    name='middleName'
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='col-lg-3'/>
                                                            <div className='text-danger mt-2'>
                                                                <ErrorMessage name='middleName'/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='mb-10 w-100'>
                                                        <div className='d-flex'>
                                                            <label
                                                                className='form-label mb-3 required col-lg-3'>Кем выдан паспорт</label>
                                                            <div className='col-lg-9'>
                                                                <Field
                                                                    type='text'
                                                                    className='form-control form-control-lg'
                                                                    name='issuedBy'
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='col-lg-3'/>
                                                            <div className='text-danger mt-2'>
                                                                <ErrorMessage name='issuedBy'/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>):(
                                            <div>
                                                <div className="d-flex" style={{marginBottom:"20px"}}>
                                                    <p className="col-3 fw-semibold text-gray-500 m-0" style={{fontSize:"14px"}}>ПИНФЛ</p>
                                                    <span className="col-9 fw-bold" style={{fontSize:"16px"}}>{clientInfo?.pinfl}</span>
                                                </div>
                                                <div className="d-flex" style={{marginBottom:"20px"}}>
                                                    <p className="col-3 fw-semibold text-gray-500 m-0" style={{fontSize:"14px"}}>Номер телефона</p>
                                                    <span className="col-9 fw-bold" style={{fontSize:"16px"}}>{ReplacePhone(clientInfo?.phoneNumber||'')}</span>
                                                </div>
                                                <div className="d-flex" style={{marginBottom:"20px"}}>
                                                    <p className="col-3 fw-semibold text-gray-500 m-0" style={{fontSize:"14px"}}>Номер и серия паспорта</p>
                                                    <span className="col-9 fw-bold" style={{fontSize:"16px"}}>{`${clientInfo?.passportSeries} ${clientInfo?.passportNumber}`}</span>
                                                </div>
                                                <div className="d-flex" style={{marginBottom:"20px"}}>
                                                    <p className="col-3 fw-semibold text-gray-500 m-0" style={{fontSize:"14px"}}>ФИО</p>
                                                    <span className="col-9 fw-bold" style={{fontSize:"16px"}}>{`${clientInfo?.firstName} ${clientInfo?.lastName} ${clientInfo?.middleName}`}</span>
                                                </div>
                                                <div className="d-flex" style={{marginBottom:"20px"}}>
                                                    <p className="col-3 fw-semibold text-gray-500 m-0" style={{fontSize:"14px"}}>Кем выдан паспорт</p>
                                                    <span className="col-9 fw-bold" style={{fontSize:"16px"}}>{clientInfo?.issuedBy}</span>
                                                </div>
                                                <div className="d-flex">
                                                    <p className="col-3 fw-semibold text-gray-500 m-0" style={{fontSize:"14px"}}>Статус</p>
                                                    <span className="col-9 fw-bold" style={{fontSize:"16px"}}>-</span>
                                                </div>
                                        </div>)
                                    }
                                </KTCardBody>
                                {
                                    formShow===1?( <div className='card-footer py-5 px-10 d-flex justify-content-end'>
                                        <button className='btn btn-light me-3' onClick={()=>{
                                            setFormShow(0)
                                            setInitValues(initValues)
                                        }}>{intl.formatMessage({id:'Cancel'})}</button>
                                        <button className='btn btn-primary' type='submit' onClick={()=>{}}>{intl.formatMessage({id:'SAVE_EDITION'})}</button>
                                    </div>):''
                                }
                            </KTCard>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}


export {ClientInfo}