import moment from "moment"

type formatDateRequestType = (date: string, key: "fromDate" | "toDate") => string

export const formatDateRequest: formatDateRequestType = (date, key) => {

    if (key === "fromDate")
        return moment(date).format()

    return moment(new Date(date).setHours(23, 59, 59)).format()
}