import {FC} from 'react'

type Props = {
  current: boolean
}


const UserTwoStepsCell: FC<Props> = ({current}) => (
  <> {<div className={`badge ${current?"badge-light-success":"badge-light-warning"} fw-bolder`}>{current?'В сети':'Не в сети'}</div>}</>
)

export {UserTwoStepsCell}
