import {instantse} from "@providers/request";


export const Session = {

    getSession(){
        return instantse.get('/v1/account/sessions')
            .then(response=>response)
    },

    getLogs(){
        return instantse.get('/v1/account/sessions')
            .then(response=>response)
    }
}