import {Link} from "react-router-dom";
import React, {FC} from "react";
import {PageLink} from "@metronic/layout/core";


type Iprops = {
    title: string
    breadCrumbs: PageLink[]|[]
}

export const PageTitleCustom:FC<Iprops> = ({title, breadCrumbs})=>{
    return(
        <div className='page-title-header-main' style={!breadCrumbs.length?{padding: '20px 0'}:{}}>
            <h1 className='d-flex align-items-center text-dark fw-bolder my-1 fs-3'>{title}</h1>
            <ul className='breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1'>{breadCrumbs.map((item:any, index: number)=>{
                if(!item.isSeparator) {
                    return <React.Fragment key={index} >
                        <li className='breadcrumb-item text-muted'>
                            <Link to={item.path} className='text-muted text-hover-primary'>{item.title}</Link>
                        </li>
                        <li className='breadcrumb-item text-muted'><span className='text-muted user-select-none'>{'>'}</span></li>
                    </React.Fragment>
                }
                return <li key={index} className='breadcrumb-item '><span className=' user-select-none'>{item.title}</span></li>
            })}</ul>
        </div>
    )
}