import {Modal} from "react-bootstrap";
import React, {useState} from "react";
import {useIntl} from "react-intl";
import {KTSVG} from "@metronic/helpers";


type ApproveContractModal = {
    shouldShow: boolean,
    onCreate: Function,
    onGenerate: Function,
    orderId: any,
    isLoading: boolean,
    closeModal: Function,
    children: JSX.Element
}


export function PreviewModal(props:ApproveContractModal){
    const {shouldShow, onCreate, closeModal, children, onGenerate, orderId, isLoading} = props
    const intl = useIntl()






    return <Modal show={shouldShow} onHide={()=>closeModal()} size={"xl"}>
        <Modal.Header closeButton>
            <h2>Превью контракта</h2>
        </Modal.Header>
        <Modal.Body>
            {children}
        </Modal.Body>
        <Modal.Footer className='justify-content-end'>
            <button disabled={isLoading} type="button" className="swal2-confirm btn btn-danger" aria-label=""
                    style={{display: 'inline-block'}} onClick={()=>closeModal()}>
                {intl.formatMessage({id:"CANCEL_BUTTON"})}
            </button>
            <button disabled={isLoading||!orderId} type="button" className="swal2-confirm btn btn-light-primary" aria-label=""
                    style={{display: 'inline-block'}} onClick={()=>onGenerate()}>
                <KTSVG
                    path='/media/icons/duotune/general/share.svg'
                    className='svg-icon-4 me-1'
                />
                {intl.formatMessage({id:"share"})}
            </button>
            <button type='submit' disabled={isLoading} onClick={()=>onCreate()} className="swal2-confirm btn btn-primary" aria-label=""
                    style={{display: 'inline-block'}}>
                {intl.formatMessage({id:"CREATE_BUTTON"})}
            </button>
        </Modal.Footer>
    </Modal>
}