import {useIntl} from "react-intl";
import * as Yup from 'yup'
import {UpdateInfoClient} from "@providers/autopay/models/_models";


function UpdateClientInfo() {
    const intl = useIntl()
    return Yup.object({
        phoneNumber: Yup.string().required('Обязательное поле'),
        passportSeries: Yup.string().required('Обязательное поле')
            .min(2,"Не достаточно символов").required('Обязательное поле'),
        passportNumber: Yup.string().required('Обязательное поле'),
        firstName: Yup.string().required('Обязательное поле')
            .max(50, 'Слишком много символов').required('Обязательное поле'),
        lastName: Yup.string().required('Обязательное поле')
            .max(50, 'Слишком много символов').required('Обязательное поле'),
        middleName: Yup.string().required('Обязательное поле')
            .max(50, 'Слишком много символов').required('Обязательное поле'),
        issuedBy: Yup.string().required('Обязательное поле')
            .max(50, 'Слишком много символов').required('Обязательное поле'),
    })
}


const initsInfo: UpdateInfoClient = {
    phoneNumber: "",
    passportSeries: "",
    passportNumber: "",
    firstName: "",
    lastName: "",
    middleName: "",
    issuedBy: ""
}

export {UpdateClientInfo,initsInfo}