export type AccountMe = {
    first_name: string,
    last_name: string,
    middle_name: string,
    phone: string,
    has_password: boolean,
    password_changed_date?: string,
    id: number
}


export interface AccountByIdResponse {
    result: IAccountInfo
}

export type AccountPut = {
    first_name: string,
    last_name: string,
    middle_name: string
}

export enum userRoles {
    SUPER_ADMIN="SUPER_ADMIN",
    USER_ADMIN = "USER_ADMIN"
}

export interface ICreateAccount {
    id?: number
    firstName: string
    lastName: string
    phone: string
    email?: string
    role: string
    companyId?: number
    employeeTypeId?: number
}


export interface IAssignAccount {
    id: number
    companies: number[]
}

export interface IAccountInfo {
    id: number
    firstName: string
    lastName: string
    phone: string
    email?: string
}


export interface ISearchAccount {
    phone: string[]
}