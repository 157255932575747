
import {UsersListFilter} from './UsersListFilter'
import {KTSVG} from "@metronic/helpers";
import {useListView} from "@metronic/partials/widgets/tables/_modals/core/ListViewProvider";
import {useNavigate, useParams} from "react-router-dom";
import {observer} from "mobx-react";
import {CEO, OWNER} from "@utils/constants";
import {employeeStore} from "@store/employee";
import {useIntl} from "react-intl";



const UsersListToolbar = observer(() => {
    const {setItemIdForUpdate} = useListView()
    const {companyId} = useParams()
    const navigate = useNavigate()
    const intl = useIntl()
    const openAddUserModal = () => {
        navigate(`/${companyId}/cashboxes/create`)
    }

    return (
        <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
            {/*<UsersListFilter />*/}

            {
                employeeStore.getRole===OWNER||employeeStore.getRole===CEO?(<button type='button' className='btn btn-primary' onClick={openAddUserModal}>
                    <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                    {intl.formatMessage({id:"ADD_BUTTON"})}
                </button>):( '')
            }
        </div>
    )
})

export {UsersListToolbar}
