import {FC} from 'react'
import {useIntl} from "react-intl";

type Props = {
  status: string
}

const classStatus = {
  DRAFT: "badge-light-warning",
  ACTIVE: "badge-light-success",
  DISABLED: "badge-light-danger",
}


const UserLastLoginCell: FC<Props> = ({status}) => {

  const intl = useIntl()
  return (
      //@ts-ignore
      <div className={`badge ${classStatus[status]} fw-bolder`}>{intl.formatMessage({id:status})}</div>
  )
}

export {UserLastLoginCell}
