import React, {FC, useEffect, useMemo, useState} from "react";
import {observer} from "mobx-react";
import {initsAccount, SettingsMerchantSchema} from "../../../settings-merchant/CompanyAboutHelper";
import {useNavigate, useParams} from "react-router-dom";
import {CompanyResponseOne, CompanyUpdate} from "@providers/company/_models";
import {useIntl} from "react-intl";
import {company} from "@store/company";
import {useErrors} from "@providers/context/ErrorsProvider";
import {Company} from "@providers/company/_request";
import {isDemo, roleRoutingAdmin} from "@utils/index";
import {ErrorMessage, Field, Form, Formik, FormikHelpers} from "formik";
import {Tooltip} from "antd";
import {employeeStore} from "@store/employee";
import {Modal} from "react-bootstrap";
import {KTCard, KTCardBody} from "@metronic/helpers";
import {DropImages} from '@metronic/partials/DropImages';
import {UsersListLoading} from "@metronic/partials/widgets/tables/_modals/components/loading/UserListLoading";
import {openNotificationSuccess} from "@metronic/partials/notification";
import {ICreateAccount} from "@providers/account/_models";
import {company_hide_fields} from "@utils/constants";
import {FormAddCreateAccount} from "../../_modals/tabsComponents/FormAddCreateAccount";
import {RenderOwnerInfo} from "../../_modals/tabsComponents/RenderOwnerInfo";

type IProps = {
    updateData: () => void
    companyData: CompanyResponseOne
}


export const Settings: FC<IProps> = observer(({updateData, companyData}) => {
    const [currentShema] = useState(SettingsMerchantSchema())
    const {id} = useParams()
    const [initValues, setInitValues] = useState<CompanyResponseOne>(companyData)
    const [initValOwner, setInitvalOwner] = useState<ICreateAccount>(initsAccount)
    const [deactivate, setDeactivate] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [formShow, setFormShow] = useState(0)
    const intl = useIntl()
    const [statusChecked, setStatusChecked] = useState<boolean>(companyData.active)
    const [readFile, setReadFile] = useState<any | null>(null)
    const ownerId = useMemo(() => companyData.ownerId, [companyData])

    const {setError} = useErrors()


    useEffect(() => {
        if (companyData) {
            setInitValues(companyData)
            setStatusChecked(companyData.active)
        }

        if (id) {
            setInitvalOwner({...initValOwner, companyId: Number(id)})
        }
    }, [companyData])


    const onSub = (values: CompanyResponseOne, actions: FormikHelpers<CompanyResponseOne>) => {
        if (isDemo()) return
        const formData = new FormData()
        if (values.logoFile && typeof values.logoFile !== 'string') {
            formData.append('logoFile', values.logoFile)
            editCompanyWithLogo(values, formData, actions)
            return
        }
        setLoading(true)
        editCompany(values, actions)
    }


    const editCompany = (values: CompanyResponseOne, actions: FormikHelpers<CompanyResponseOne>) => {
        const data: Partial<CompanyUpdate> = {
            id: values.id,
            brandName: values.brandName,
            addressRegistry: values.addressRegistry,
            inn: values.inn,
            name: values.name,
            active: statusChecked
        }
        if (values.clientId) {
            data.clientId = Number(values.clientId)
        }
        Company.partiallyEdit(data)
            .then(() => {
                openNotificationSuccess(intl.formatMessage({id: 'companyEdited'}))
                window.scroll({top: 0, behavior: 'smooth'})
                updateData()
                setFormShow(0)
            }).catch(err => {
            actions.resetForm()
            setInitValues(companyData)
            setStatusChecked(companyData.active)
            setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
        }).finally(() => setLoading(false))
    }
    const editCompanyWithLogo = (values: CompanyResponseOne, formData: any, actions: FormikHelpers<CompanyResponseOne>) => {
        const data: Partial<CompanyUpdate> = {
            id: values.id,
            brandName: values.brandName,
            addressRegistry: values.addressRegistry,
            inn: values.inn,
            name: values.name,
            active: statusChecked
        }
        if (values.clientId) {
            data.clientId = values.clientId
        }
        Company.partiallyEdit(data)
            .then(() => Company.createCompanyFiles(formData, id ?? "0"))
            .then(() => {
                openNotificationSuccess(intl.formatMessage({id: 'companyEdited'}))
                window.scroll({top: 0, behavior: 'smooth'})
                updateData()
                setFormShow(0)
            }).catch(err => {
            actions.resetForm()
            setInitValues(companyData)
            setStatusChecked(companyData.active)
            setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
        })
            .catch(err => {
                actions.resetForm()
                setInitValues(companyData)
                setStatusChecked(companyData.active)
                setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
            }).finally(() => setLoading(false))
    }

    const deleteItem = (companyId: string) => {
        if (deactivate) {
            Company.deleteCompany(+companyId)
                .then(() => {
                    company.setCompany(company.getCompany.filter(item => item.id !== companyData?.id))
                    openNotificationSuccess(intl.formatMessage({id: 'companyDeleted'}))
                    navigate('/companies')
                }).catch(err => {
                setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
            })
        }
    }


    return (
        <div>
            {loading && <UsersListLoading/>}

            <div className='d-flex flex-row-fluid flex-center position-relative'>
                <Formik enableReinitialize={true} validationSchema={currentShema}
                        initialValues={initValues} onSubmit={onSub}>
                    {(props) => {

                        const {
                            errors,
                            setValues,
                            values
                        } = props

                        return (
                            <Form className='w-100'>
                                <KTCard>
                                    <div className='card-header d-flex py-5 px-10 align-items-center'>
                                        <h3 className='mb-0'>{intl.formatMessage({id: "title_company_settings"})}</h3>
                                        {
                                            formShow !== 1 && roleRoutingAdmin('action', employeeStore.getRole, 'is-admin') ? (
                                                <button onClick={() => {
                                                    setFormShow(1)

                                                }} type='button' className='btn btn-md btn-primary'>
                                  <span className='indicator-label'>
                                    {intl.formatMessage({id: "EDIT_BUTTON"})}
                                  </span>
                                                </button>) : ''
                                        }
                                    </div>
                                    <KTCardBody>
                                        {
                                            formShow === 1 ? (<div className='w-100'>

                                                <div className='d-flex'>
                                                    <label
                                                        className='form-label mb-3 col-lg-3'>{intl.formatMessage({id: "logo_file"})}</label>
                                                    <div className='d-flex align-items-center col-lg-9'>
                                                        <div className='logo-form-upload mb-3 w-126px h-126px'>
                                                            {readFile ? <img alt={''} className='w-100 h-100'
                                                                             src={readFile}/> : initValues.logoFile ?
                                                                <img alt={''} className='w-100 h-100'
                                                                     src={Company.getFiles(typeof initValues?.logoFile === 'string' ? initValues?.logoFile : '')}/> :
                                                                <img alt={''} className='w-100 h-100'
                                                                     src="https://via.placeholder.com/150/FAFAFA/000000/?text=Пусто"/>}
                                                        </div>

                                                        <DropImages {...props}
                                                                    setFile={(file: any) => setReadFile(file)}
                                                                    fieldName='logoFile'/>

                                                    </div>
                                                </div>
                                                <div className='w-100 mt-5'>
                                                    <div className='mb-10 w-100'>
                                                        <div className='d-flex'>
                                                            <label
                                                                className='form-label mb-3 required col-lg-3'>{intl.formatMessage({id: "brandName"})}</label>
                                                            <div className='col-lg-9'>
                                                                <Field
                                                                    type='text'
                                                                    className='form-control form-control-lg'
                                                                    name='brandName'
                                                                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                                        if (!e.currentTarget.value.length || e.currentTarget.value.match(/^[a-zA-Z0-9А-Яа-я\s]+$/)) {
                                                                            props.handleChange(e)
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='col-lg-3'/>
                                                            <div className='text-danger mt-2'>
                                                                <ErrorMessage name='brandName'/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='mb-10 w-100'>
                                                        <div className='d-flex'>
                                                            <label
                                                                className='form-label mb-3 required col-lg-3'>{intl.formatMessage({id: "address"})}</label>
                                                            <div className='col-lg-9'>
                                                                <Field
                                                                    type='text'
                                                                    className='form-control form-control-lg'
                                                                    name='addressRegistry'
                                                                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                                        if (!e.currentTarget.value.length || e.currentTarget.value.match(/^[a-zA-ZА-Я-а-я0-9-,.\s]+$/)) {
                                                                            props.handleChange(e)
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='col-lg-3'/>
                                                            <div className='text-danger mt-2'>
                                                                <ErrorMessage name='addressRegistry'/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='mb-10 w-100'>
                                                        <div className='d-flex'>
                                                            <label
                                                                className='form-label mb-3 required col-lg-3'>{intl.formatMessage({id: "inn"})}</label>
                                                            <div className='col-lg-9'>
                                                                <Field
                                                                    type='text'
                                                                    className='form-control form-control-lg'
                                                                    name='inn'
                                                                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                                        if (e.currentTarget.value.match(/^\d{0,9}$/)) props.handleChange(e)
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='col-lg-3'/>
                                                            <div className='text-danger mt-2'>
                                                                <ErrorMessage name='inn'/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {roleRoutingAdmin('action', employeeStore.getRole, 'add_client_id')  && <div className='mb-10 w-100'>
                                                        <div className='d-flex'>
                                                            <label
                                                                className='form-label mb-3 col-lg-3'>{intl.formatMessage({id: "webPayments.clientId"})}</label>
                                                            <div className='col-lg-9'>
                                                                <Field
                                                                    type='text'
                                                                    className='form-control form-control-lg'
                                                                    name='clientId'
                                                                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                                        if (e.currentTarget.value.match(/^\d{0,150}$/)) props.handleChange(e)
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='col-lg-3'/>
                                                            <div className='text-danger mt-2'>
                                                                <ErrorMessage name='clientId'/>
                                                            </div>
                                                        </div>
                                                    </div>}

                                                    {roleRoutingAdmin('action', employeeStore.getRole, 'change-status') ?
                                                        <div className='mb-10 w-100'>
                                                            <div className='d-flex'>
                                                                <label
                                                                    className='form-label mb-3 required col-lg-3'>{intl.formatMessage({id: "status"})}</label>
                                                                <div className='col-lg-9'>
                                                                    <div className='change_status_group'>

                                                                        <div className={`item_check_status w-100 d-flex align-items-center justify-content-between border border-gray-300 mb-3
                                                                        ${statusChecked ? 'active' : ''}`}

                                                                             onClick={() => {
                                                                                 setStatusChecked(true)
                                                                             }}
                                                                        >
                                                                            <div className='d-flex  align-items-center'>
                                                                           <span className='me-5'>
                                                                              <svg width="28" height="28"
                                                                                   viewBox="0 0 28 28" fill="none"
                                                                                   xmlns="http://www.w3.org/2000/svg">
                                                                                <rect width="28" height="28" rx="6"
                                                                                      fill="#ECFDF3"/>
                                                                                <path d="M18 11L12.5 16.5L10 14"
                                                                                      stroke="#12B76A"
                                                                                      strokeWidth="1.5"
                                                                                      strokeLinecap="round"
                                                                                      strokeLinejoin="round"/>
                                                                              </svg>

                                                                           </span>
                                                                                <p className='fw-bold fs-6 text-gray-800 mb-0'>Активный</p>
                                                                            </div>
                                                                            <div>
                                                                                <div
                                                                                    className="form-check form-check-custom form-check-solid">
                                                                                    <input className="form-check-input"
                                                                                           type="radio"
                                                                                           onChange={() => {

                                                                                           }}
                                                                                           checked={statusChecked}

                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className={`item_check_status w-100 d-flex align-items-center justify-content-between border border-gray-300 
               ${!statusChecked ? 'active' : ''}`}
                                                                             onClick={() => {
                                                                                 setStatusChecked(false)
                                                                             }}
                                                                        >
                                                                            <div className='d-flex  align-items-center'>
                                                                           <span className='me-5'>
                                                                              <svg width="28" height="28"
                                                                                   viewBox="0 0 28 28" fill="none"
                                                                                   xmlns="http://www.w3.org/2000/svg">
                                                                                <rect width="28" height="28" rx="6"
                                                                                      fill="#FEF3F2"/>
                                                                                <path d="M17 11L11 17M11 11L17 17"
                                                                                      stroke="#F04438"
                                                                                      strokeWidth="1.5"
                                                                                      strokeLinecap="round"
                                                                                      strokeLinejoin="round"/>
                                                                              </svg>


                                                                           </span>
                                                                                <p className='fw-bold fs-6 text-gray-800 mb-0'>Неактивный</p>
                                                                            </div>
                                                                            <div>
                                                                                <div
                                                                                    className="form-check form-check-custom form-check-solid">
                                                                                    <input className="form-check-input"
                                                                                           type="radio"
                                                                                           onChange={() => {

                                                                                           }}
                                                                                           checked={!statusChecked}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> : ''}
                                                </div>
                                            </div>) : (<div>
                                                {Object.entries(initValues).filter(([key]) => !company_hide_fields.includes(key)).map(([key, value]) => (
                                                    key !== 'active' && key !== 'id' && key !== 'logoFile' &&
                                                    <div key={key} className='d-flex align-items-center mb-5'>
                                                        <p className='mb-0 text-gray-600 fs-6 col-lg-4'>{intl.formatMessage({id: key === 'name' ? 'companyName' : key})}</p>
                                                        {
                                                            key === 'ownerId' && ownerId ?
                                                                <RenderOwnerInfo companyData={companyData}/>
                                                                :
                                                                <p className='mb-0 text-gray-800 fw-bold fs-5 text-break'>{typeof value === 'string' && value}</p>
                                                        }
                                                    </div>
                                                ))}
                                            </div>)
                                        }
                                    </KTCardBody>
                                    {
                                        formShow === 1 ? (
                                            <div className='card-footer py-5 px-10 d-flex justify-content-end'>
                                                <button className='btn btn-light me-3' onClick={() => {
                                                    setFormShow(0)
                                                    setValues(initValues)
                                                }}>{intl.formatMessage({id: 'Cancel'})}</button>
                                                <button className='btn btn-primary' type='submit' onClick={() => {
                                                    let err_message = ''
                                                    if (Object.keys(errors).length) {
                                                        for (let err in errors) {
                                                            //@ts-ignore
                                                            err_message += `* ${intl.formatMessage({id: err})} - ${errors[err]} ` + "\n"
                                                        }
                                                        setError({status: '', message: err_message, isError: true})
                                                    }
                                                }}>{intl.formatMessage({id: 'SAVE_EDITION'})}</button>
                                            </div>) : ''
                                    }
                                </KTCard>


                            </Form>
                        )
                    }}
                </Formik>


            </div>

            {!ownerId ? <FormAddCreateAccount setFormShow={setFormShow}
                                              formShow={formShow}
                                              setLoading={setLoading}
                                              companyId={id} updateData={updateData}/> : ''}


            <div>
                {
                    roleRoutingAdmin('action', employeeStore.getRole, 'is-admin') ? (
                        <div className="card mt-10">
                            <div className="card-header border-0 cursor-pointer" role="button"
                                 data-bs-toggle="collapse" data-bs-target="#kt_account_deactivate"
                                 aria-expanded="true" aria-controls="kt_account_deactivate">
                                <div className="card-title m-0">
                                    <h3 className="fw-bold m-0">{intl.formatMessage({id: "DELETE_COMPANY"})}</h3>
                                </div>
                            </div>
                            <div id="kt_account_settings_deactivate" className="collapse show">
                                <div id="kt_account_deactivate_form" className="form">
                                    <div className="card-body border-top p-9">
                                        <div
                                            className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6">
                                              <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
																<rect opacity="0.3" x="2" y="2" width="20" height="20"
                                                                      rx="10" fill="currentColor"/>
																<rect x="11" y="14" width="7" height="2" rx="1"
                                                                      transform="rotate(-90 11 14)"
                                                                      fill="currentColor"/>
																<rect x="11" y="17" width="2" height="2" rx="1"
                                                                      transform="rotate(-90 11 17)"
                                                                      fill="currentColor"/>
															</svg>
														</span>
                                            <div className="d-flex flex-stack flex-grow-1">
                                                <div className="fw-semibold">
                                                    <h4 className="text-gray-900 fw-bold">{intl.formatMessage({id: "DELETE_COMPANY1"})}</h4>
                                                    <div
                                                        className="fs-6 text-gray-700">{intl.formatMessage({id: "WARNING_COMPANY"})}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-check form-check-solid fv-row">
                                            <input onChange={(e) => {
                                                setDeactivate(e.target.checked)
                                            }} name="deactivate" className="form-check-input"
                                                   type="checkbox"
                                                   value="" id="deactivate"/>
                                            <label
                                                className="form-check-label fw-semibold ps-2 fs-6">{intl.formatMessage({id: "confirm"})}</label>
                                        </div>
                                    </div>
                                    <div className="card-footer d-flex justify-content-end py-6 px-9">
                                        {
                                            isDemo() ? (<Tooltip zIndex={1} color='var(--kt-body-bg)'
                                                                 title={() => <span
                                                                     style={{color: 'var(--kt-text-gray-600)'}}>{intl.formatMessage({id: "DEMO_DISABLED"})}</span>}>
                                                <div>
                                                    <button
                                                        id="kt_account_deactivate_account_submit"
                                                        type="button"
                                                        className="btn btn-danger fw-semibold"
                                                        disabled={!deactivate || isDemo()}>
                                                        {intl.formatMessage({id: "DELETE_COMPANY"})}
                                                    </button>
                                                </div>
                                            </Tooltip>) : (<button
                                                id="kt_account_deactivate_account_submit"
                                                type="button"
                                                className="btn btn-danger fw-semibold"
                                                disabled={!deactivate}
                                                onClick={() => {
                                                    setShowDeleteModal(true)
                                                }}>{intl.formatMessage({id: "DELETE_COMPANY"})}
                                            </button>)
                                        }

                                    </div>
                                </div>
                            </div>

                        </div>) : ''
                }
            </div>

            <DeleteCompanyModal onCancel={() => setShowDeleteModal(false)}
                                setIsLoading={(val: boolean) => setLoading(val)}
                                onDelete={() => {
                                    setShowDeleteModal(false)
                                    deleteItem(id ?? '')
                                }}
                                shouldShow={showDeleteModal}/>
        </div>
    )
})

type DeleteCompanyModal = {
    onCancel: Function
    setIsLoading: (val: boolean) => void,
    onDelete: Function,
    shouldShow: boolean
}

export const DeleteCompanyModal: FC<DeleteCompanyModal> = ({
                                                               onCancel,
                                                               setIsLoading,
                                                               onDelete,
                                                               shouldShow
                                                           }) => {
    const {setError} = useErrors()
    const handleClose = () => {
        onCancel()
    }
    const intl = useIntl()

    const handleDelete = async () => {
        try {
            setIsLoading(true)
            onDelete()
        } catch (error: Error | any) {
            setError({
                status: error?.response?.status,
                message: error?.response?.data?.message,
                isError: true
            })
        } finally {
            setIsLoading(false)
        }
    }


    return <Modal show={shouldShow} onHide={handleClose}>
        <Modal.Header closeButton>
            <h2>{intl.formatMessage({id: "CHECK_DELETE"})}</h2>
        </Modal.Header>
        <Modal.Body>
            <div className='fs-3'>{intl.formatMessage({id: "DELETE_APPROVE"})}</div>
        </Modal.Body>
        <Modal.Footer className='justify-content-between'>
            <button type="button" className="swal2-confirm btn btn-secondary" aria-label=""
                    style={{display: 'inline-block'}} onClick={handleClose}>
                {intl.formatMessage({id: "CANCEL_BUTTON"})}
            </button>
            <button onClick={handleDelete} className="swal2-confirm btn btn-danger" aria-label=""
                    style={{display: 'inline-block'}}>
                {intl.formatMessage({id: "delete"})}
            </button>
        </Modal.Footer>
    </Modal>
}

