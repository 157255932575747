/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link, useParams} from "react-router-dom";
import {CompanyResponse} from "@providers/company/_models";
import {observer} from "mobx-react";
import {image_default, JUNIOR_CASHIER} from "@utils/constants";
import {employeeStore} from "@store/employee";
import {Company} from "@providers/company/_request";
import {roleRoutingAdmin} from "@utils/index";
import {useIntl} from "react-intl";
import {IClientsResponse} from "@providers/autopay/_interfaces";

type Props = {
    data: any
}

const ClientsName: FC<Props> = observer(({data}) => {
    const {companyId} = useParams()

    return (
        <Link to={`/${companyId}/autopay/clients/${data.pinfl}`} className='fs-6 fw-normal' style={{maxWidth:"225px",whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis",display:"block"}}>
            {`${data?.firstName || ''} ${data?.lastName||''} ${data?.middleName||''}`}
        </Link>
    )
})

export {ClientsName}
