export const serviceCodeOptions = [
  {label: 'service_code', value: ''},
  {label: 'service_code.001', value: '001'},
  {label: 'service_code.002', value: '002'},
  {label: 'service_code.003', value: '003'},
  {label: 'service_code.004', value: '004'},
  {label: 'service_code.005', value: '005'},
  {label: 'service_code.006', value: '006'},
  {label: 'service_code.007', value: '007'},
  {label: 'service_code.008', value: '008'},
  {label: 'service_code.009', value: '009'},
  {label: 'service_code.010', value: '010'},
  {label: 'service_code.011', value: '011'},
  {label: 'service_code.012', value: '012'},
  {label: 'service_code.013', value: '013'},
  {label: 'service_code.014', value: '014'},
  {label: 'service_code.015', value: '015'},
  {label: 'service_code.016', value: '016'},
  {label: 'service_code.017', value: '017'},
  {label: 'service_code.018', value: '018'},
  {label: 'service_code.019', value: '019'},
  {label: 'service_code.020', value: '020'},
  {label: 'service_code.021', value: '021'},
  {label: 'service_code.022', value: '022'},
  {label: 'service_code.023', value: '023'},
  {label: 'service_code.024', value: '024'},
  {label: 'service_code.025', value: '025'},
  {label: 'service_code.026', value: '026'},
  {label: 'service_code.027', value: '027'},
  {label: 'service_code.028', value: '028'},
  {label: 'service_code.029', value: '029'},
  {label: 'service_code.030', value: '030'},
  {label: 'service_code.031', value: '031'},
  {label: 'service_code.032', value: '032'},
  {label: 'service_code.033', value: '033'},
  {label: 'service_code.034', value: '034'},
]
