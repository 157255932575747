import {useListView} from '../../providers/ListViewProvider'
import { confirmAlert } from 'react-confirm-alert';
import {AUTH_LOCAL_STORAGE_KEY} from "../../../../../../modules/auth";
import {observer} from "mobx-react";
import {Employee} from "@providers/employee/_request";
import {useParams} from "react-router-dom";
import {employeeStore} from "@store/employee";
import {useIntl} from "react-intl";
import {useErrors} from "@providers/context/ErrorsProvider";

const UsersListGrouping = observer(() => {
    const {selected, clearSelected} = useListView()
    const {setError} = useErrors()
    const {companyId, id} = useParams()
    const intl = useIntl()

    const deleteSelectedItems = ()=>{
        if(companyId&&id){
            confirmAlert({
                title: intl.formatMessage({id: 'WARNING'}),
                message: intl.formatMessage({id: selected.length>1?'WARNING_DELETE_BRANCH_EMPLOYEES':'WARNING_DELETE_BRANCH_EMPLOYEE'}),
                buttons: [
                    {
                        label: intl.formatMessage({id: 'ACTIONS_YES'}),
                        onClick: async () => {
                            selected.forEach(async(item)=>{
                                await Employee.getEmployeeById(companyId, item)
                                    .then(async(result)=>{
                                        let branches = result?.data?.result?.branches || []
                                        branches = branches.filter((branch:number)=>branch!==+id)
                                        await Employee.addEmployeeToBranches(companyId, item, {branches})
                                            .then(()=>{
                                                employeeStore.setEmployeeFilial(employeeStore.getEmployeeFilial.filter(empl=>empl.id!==item))
                                            })
                                            .catch(err=>{setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})})
                                    })

                            })
                            clearSelected()

                        }
                    },
                    {
                        label: intl.formatMessage({id: 'ACTIONS_NO'}),
                        onClick: async () => {
                            clearSelected()
                        }
                    }
                ]
            });
        }
    }

    return (
        <div className='d-flex justify-content-end align-items-center'>
            <div className='fw-bolder me-5'>
                <span className='me-2'>{selected.length}</span> Selected
            </div>

            <button
                type='button'
                className='btn btn-danger'
                onClick={async () => deleteSelectedItems()}
            >
                Delete Selected
            </button>
        </div>
    )
})

export {UsersListGrouping}
