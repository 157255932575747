/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {ModSession} from "@providers/session/_modals";
import {NumReplace} from "@utils/index";


type Props = {
    sum: string
}



const TableSum: FC<Props> = ({sum}) => {

    const number = (Number(sum) / 100).toString()

    return (
        <div className='d-flex align-items-center justify-content-start'>

            <div className='d-flex flex-column'>
                <p className='text-gray-800 text-hover-primary mb-1 text-center'>{NumReplace(number??''+'')} UZS</p>
            </div>
        </div>
    )
}

export {TableSum}
