import {Column} from 'react-table'
import {TableCustomHeader} from './TableCustomHeader'
import {TableText} from "./TableText";
import {NumReplace} from "@utils/index";
import moment from "moment";


const grafikColumns: ReadonlyArray<Column<any>> = [

  {
    Header: (props) => <TableCustomHeader tableProps={props} title='№' className='min-w-50px fw-bold text-gray-700' />,
    id: 'num',
    Cell: ({...props}) => <TableText text={props.data[props.row.index]?.num} className='text-gray-900' translate={false}/>,
  },

  {
    Header: (props) => <TableCustomHeader tableProps={props} title='date_payments' className='min-w-125px fw-bold text-gray-700' />,
    id: 'date',
    Cell: ({...props}) => <TableText text={moment(props.data[props.row.index]?.scheduleDate).format('DD/MM/YYYY')} className='text-gray-600' translate={false} />,
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='payment_by_day' className='min-w-125px fw-bold text-gray-700 text-right' />,
    id: 'payment_sum',
    Cell: ({...props}) => <TableText text={`${NumReplace(props.data[props.row.index]?.paymentSum+'')} UZS`} className='text-gray-600 text-right' translate={false} />,
  },


 



]




export {grafikColumns}
