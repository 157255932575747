import {makeAutoObservable, toJS} from "mobx";
import {IWebPayment, IWebPaymentFilters, IWebPaymentSend} from "@providers/webPayments/models";


class WebPayments {
    filters: IWebPaymentFilters = {
        search: ''
    }
    pagination: any = {}
    web_payments: IWebPaymentSend[] = []


    constructor() {
        makeAutoObservable(this)
    }

    get getWebPayments(): IWebPaymentSend[] {
        return toJS(this.web_payments)
    }

    get getFilters() {
        return toJS(this.filters)
    }

    get getPagination() {
        return toJS(this.pagination)
    }

    setWebPayments(web_payments: IWebPaymentSend[]) {
        this.web_payments = web_payments
    }

    setPagination(pagination: any) {
        this.pagination = pagination
    }

    setFilters(filters: IWebPaymentFilters) {
        this.filters = filters
    }
}

export const web_payments = new WebPayments()