export function addMonths(date: Date, amount: number) {
    const endDate = new Date(date.getTime());
    const originalTimeZoneOffset = endDate.getTimezoneOffset();
    endDate.setMonth(endDate.getMonth() + amount);
    while (monthDiff(date, endDate) > amount) {
        endDate.setDate(endDate.getDate() - 1);
    }
    const endTimeZoneOffset = endDate.getTimezoneOffset();
    const diff = endTimeZoneOffset - originalTimeZoneOffset;
    const finalDate = diff ? endDate.setMinutes(endDate.getMinutes() - diff) : endDate;
    return new Date(finalDate);
}

export function subtractMonths(date: Date, amount: number) {
    const endDate = new Date(date.getTime());
    const originalTimeZoneOffset = endDate.getTimezoneOffset();
    endDate.setMonth(endDate.getMonth() - amount);
    while (monthDiff(date, endDate) > amount) {
        endDate.setDate(endDate.getDate() - 1);
    }
    const endTimeZoneOffset = endDate.getTimezoneOffset();
    const diff = endTimeZoneOffset - originalTimeZoneOffset;
    const finalDate = diff ? endDate.setMinutes(endDate.getMinutes() - diff) : endDate;
    return new Date(finalDate);
}

function monthDiff(from: Date, to: Date) {
    const years = to.getFullYear() - from.getFullYear();
    const months = to.getMonth() - from.getMonth();
    return 12 * years + months;
}

export function toIsoStringHelper(date: Date) {
    var tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-',
        pad = function(num: number) {
            return (num < 10 ? '0' : '') + num;
        };

    return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) +
        dif + pad(Math.floor(Math.abs(tzo) / 60)) +
        ':' + pad(Math.abs(tzo) % 60);
}