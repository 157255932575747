import {autopayInstance} from "@providers/autopay/requests/axiosConfig";


export const MerchantService = {
    async getMerchantSubscriptions(merchantId: string, page: string = "1", size: string = "20", sortBy: string = "id") {
        return autopayInstance.get(`/merchants/${merchantId}/subscriptions?page=${page}&size=${size}&sortBy=${sortBy}`)
    },
    async getMerchantSubscriptionsByClientId(merchantId: string, clientId: string, page: string = "1", size: string = "20", sortBy: string = "id") {
        return autopayInstance.get(`/merchants/${merchantId}/subscriptions/${clientId}?page=${page}&size=${size}&sortBy=${sortBy}`)
    },
    async getMerchantContracts(merchantId: string, page: string = "1", size: string = "20", sortBy: string = "id", filters:any) {
        let request_params = `?page=${page}&size=${size}`
        request_params += sortBy?`&sortBy=${sortBy}`:''
        request_params += `&orderType=DESC`
        request_params += filters?.from_date?`&from_date=${filters?.from_date}`:''
        request_params += filters?.to_date?`&to_date=${filters?.to_date}`:''
        request_params += filters?.statuses?`&statusTypes=${filters?.statuses.join(',')}`:''

        return autopayInstance.get(`/merchants/${merchantId}/contracts${request_params}`)
    },
    async getMerchantServices(merchantId: string) {
        return autopayInstance.get(`/merchants/${merchantId}/services`)
    },
    async getMerchantContractsByClientId(merchantId: string, clientId: string, page: string = "1", size: string = "20", sortBy: string = "id") {
        return autopayInstance.get(`/merchants/${merchantId}/contracts/${clientId}?page=${page}&size=${size}&sortBy=${sortBy}`)
    },
    async getMerchantClients(merchantId: string, page: string = "1", size: string = "20") {
        return autopayInstance.get(`/merchants/${merchantId}/clients?page=${page}&size=${size}`)
    }
}