import React, {useState} from 'react';
import {useIntl} from "react-intl";
import ContractStopModal from "../contractStopModal/contractStopModal";
import {ContractStatus} from "@providers/autopay/models/_models";

interface props {
    status: ContractStatus
}

const ContractStop: React.FC<props> = ({
                                           status
                                       }) => {

    const [isApproved, setIsApproved] = useState<boolean>(false)

    const onChange = () => setIsApproved(!isApproved)

    const intl = useIntl()


    return (
        <div className={'card  mt-5'}
        >
            <div className="card-header d-flex ps-9 align-items-center"><h2 className="mb-0">
                {intl.formatMessage({id: 'stop_contract'})}
            </h2>
            </div>
            <div className={'card-body'}>
                <div
                    className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6">
                    <span
                        className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                        <svg width="24" height="24"
                             viewBox="0 0 24 24"
                             fill="none"
                             xmlns="http://www.w3.org/2000/svg"><rect
                            opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect><rect
                            x="11"
                            y="14"
                            width="7"
                            height="2"
                            rx="1"
                            transform="rotate(-90 11 14)"
                            fill="currentColor"></rect><rect
                            x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)"
                            fill="currentColor"></rect>
                        </svg>
                    </span>
                    <div className="d-flex flex-stack flex-grow-1">
                        <div className="fw-semibold"><h4 className="text-gray-900 fw-bold">
                            {intl.formatMessage({id: 'stopAlert'})}
                        </h4>
                            <div className="fs-6 text-gray-700">
                                {intl.formatMessage({id: 'stopAlertText'})}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-check form-check-custom form-check-solid form-check-default">
                    <input onChange={onChange} className="form-check-input" type="checkbox" checked={isApproved}
                           id="flexCheckDefault"/>
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                        {intl.formatMessage({id: 'stop_contract_confirm'})}
                    </label>
                </div>
            </div>
            <div className={'card-footer'}>
                <ContractStopModal status={status} isApproved={isApproved}/>
            </div>
        </div>
    );
};

export default ContractStop;