/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react';
import {ErrorProvider} from "@providers/context/ErrorsProvider";

import moment from "moment";
import {OtpInputComponent} from "./OtpInputComponent";
import {LoginByPassword} from "./LoginByPassword";
import {LanguageSelectDropdown} from "./LanguageSelectDropdown";
import {SetNewPass} from "./SetNewPass";
import {ForgotPassword} from "./ForgotPassword";


function Login() {
    const [loading, setLoading] = useState(false)
    const [confirm, setConfirm] = useState(false)
    const [accessToken, setAccessToken] = useState<string|null>(null)
    const [counter, setCounter] = useState(0)


    const [userBlock, setUserBlock] = useState(false)
    const [passwordSetupBlock, setPasswordSetupBlock] = useState(false)
    const [didForgotPassword, setDidForgotPassword] = useState(false)
    const [lastDateOtp, setLastDateOtp] = useState<string|null>(null)

    const [saveLastDate, setSaveLastDate] = useState('')


    useEffect(()=>{
        const timer = setInterval(()=>{
            if(saveLastDate.length) setLastDateOtp(diffTimeBlock(saveLastDate))
        }, 60000)

        return ()=>{
            clearInterval(timer)
        }
    }, [saveLastDate])






    function diffTimeBlock(timeBlocked:string):string{
        const dateBlock = new Date(timeBlocked)
        dateBlock.setHours(dateBlock.getHours() + 5)
        const dateNow = new Date()


        const dateUnblock = new Date(dateBlock.getTime())
        dateUnblock.setHours(dateBlock.getHours()+12)



        let hours = moment(dateUnblock).diff(moment(dateNow), 'hours'),
            minutes = moment.utc(moment(dateUnblock, "HH:mm:ss").diff(moment(dateNow, "HH:mm:ss"))).format("mm")

        return `${hours > 9?hours:`0${hours}`}:${minutes}`
    }



    return (
        <>
            <div className="w-100 d-flex justify-content-end">
                <LanguageSelectDropdown/>
            </div>
            <div>
                <div className="auth_info">
                    <div>
                        <svg width="46" height="38" viewBox="0 0 46 38" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M8.34998 5.52999C17.7763 -2.49379 31.9602 -1.40083 40.0305 7.97133C47.4736 16.615 47.1169 29.2819 39.6217 37.4933L6.24208 37.4217C6.12512 37.2921 6.00899 37.1612 5.89438 37.0283C-2.17585 27.6561 -1.07643 13.5538 8.34998 5.52999Z"
                                  fill="#E9532F"/>
                        </svg>
                    </div>
                    <p className="text-center text-dark">Личный кабинет</p>
                </div>

                {!confirm && !userBlock && !didForgotPassword && <LoginByPassword loading={loading}
                                                            setAccessToken={(val:string)=>setAccessToken(val)}
                                                           setLoading={(val:boolean)=>setLoading(val)}
                                                           setSaveLastDate={(val:string)=>setSaveLastDate(val)}
                                                           setUserBlock={(val:boolean)=>setUserBlock(val)}
                                                           setLastDateOtp={(val:string)=>setLastDateOtp(val)}
                                                           setConfirm={(val:boolean)=>setConfirm(val)}
                                                           setCounter={(val:number)=>setCounter(val)}
                                                            setDidForgotPassword={(val:boolean)=>setDidForgotPassword(val)}
                                                           counter={counter}
                                                           diffTimeBlock={(val:string)=>diffTimeBlock(val)}
                />}

                {didForgotPassword && !confirm &&<ForgotPassword loading={loading}
                                                    counter={counter}
                                                    setAccessToken={(val:string)=>setAccessToken(val)}
                                                    setLoading={(val:boolean)=>setLoading(val)}
                                                    setCounter={(val:number)=>setCounter(val)}
                                                    setConfirm={(val:boolean)=>setConfirm(val)}


                />}

                {passwordSetupBlock&&<SetNewPass
                    loading={loading}
                    setDidForgotPass={(val:boolean)=>setDidForgotPassword(val)}
                    accessToken={accessToken}
                    setPasswordSetupBlock={(val:boolean)=>setPasswordSetupBlock(val)}
                    setConfirm={(val:boolean)=>setConfirm(val)}
                />}


                {userBlock&&(<div className='fv-row'>
                    <div className='mb-10'>
                        <h3 className='fw-bolder text-dark text-center'>Было использованно слишком много попыток вход в Кабинет Мерчант! В целях безопасности, данная учетная запись заблокирована на {lastDateOtp?lastDateOtp:'00'} часов</h3>

                    </div>
                </div>)}

                {!passwordSetupBlock?<OtpInputComponent accessToken={accessToken}
                                                        counter={counter}
                                                        setCounter={(val)=>setCounter(val)}
                                                        setAccessToken={(val:string)=>setAccessToken(val)}
                                                        setLastDateOtp={(val:string)=>setLastDateOtp(val)}
                                                        setSaveLastDate={(val:string)=>setSaveLastDate(val)}
                                                        setLoading={(val:boolean)=>setLoading(val)}
                                                        diffTimeBlock={(val:string)=>diffTimeBlock(val)}
                                                        setUserBlock={(val:boolean)=>setUserBlock(val)}
                                                        setPasswordSetupBlock={(val:boolean)=>setPasswordSetupBlock(val)}
                                                        confirm={confirm}
                                                        didForgotPassword={didForgotPassword}
                                                        userBlock={userBlock}
                                                        loading={loading}
                />:''}

            </div>
            <div>
                Global Pay © {moment().format('YYYY')}
            </div>
        </>
    )
}



export default function LoginWrapper() {
    return (
        <ErrorProvider>
            <Login/>
        </ErrorProvider>
    )
}