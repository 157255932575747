import React, {FC} from 'react'
import {KTCard} from "@metronic/helpers";
import {Table} from "./_modals/Table";
import {ErrorProvider} from "@providers/context/ErrorsProvider";
import {PageTitle} from "@metronic/layout/core";
import {useIntl} from "react-intl";

const SessionsLogs:FC = ()=>{

    return (
        <>
            <KTCard>
                <Table />
            </KTCard>
        </>
    )
}

export default function EmployeesLogs(){
    return(
        <>
            <ErrorProvider>
                <SessionsLogs />
            </ErrorProvider>
        </>
    )
}