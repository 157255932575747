import React, {FC, useEffect, useState} from 'react'
import {ErrorMessage, Field, FieldArray, FormikProps} from "formik";
import {CreateCompany} from "@providers/company/_models";
import {useIntl} from "react-intl";
import {useErrors} from "@providers/context/ErrorsProvider";
import {KTSVG} from "@metronic/helpers";
import {Col, Row, Tooltip} from "antd";
import AsyncSelect from "react-select/async";
import {customStyles} from "@metronic/assets/ts/select.style";
import {Agent} from "@providers/agents/_request";
import {localErrorsMessage} from "@utils/index";
import moment from "moment";
import {Company} from "@providers/company/_request";
import {image_default} from "@utils/constants";



interface IProps extends FormikProps<CreateCompany> {
    handleChange(arg: any): void
    setFieldValue(name:string, val:any):void
    values: any
}

const Step2: FC<IProps> = (props) => {

    const intl = useIntl()
    const [merchants, setMerchants] = useState<any[]>([])
    const [merchantsAll, setMerchantsAll] = useState<any[]>([])
    const {setError} = useErrors()


    useEffect(()=>{
        getMerchantsData()
    }, [])



    async function getMerchantsData(){
        await Agent.getMerchants()
            .then(result=>{

                setMerchants(result?.data?.result.map((item:any)=>{

                    return {
                        value:item.id,
                        label:<div className='d-flex align-items-center'>
                            <div style={{borderRadius:'6px', overflow:'hidden', marginRight:'8px', width:24, height:24}}>
                                {item.logo_file?<img src={item.logo_file?Company.getFiles(item.logo_file):image_default} className='w-100 h-100'/>:''}
                            </div>
                            {item.name}
                        </div>,
                        name: item.name
                    }
                }))


                setMerchantsAll([...result?.data?.result.map((item:any)=>{

                    return {
                        value:item.id,
                        label:<div className='d-flex align-items-center'>
                            <div style={{borderRadius:'6px', overflow:'hidden', marginRight:'8px', width:24, height:24}}>
                                {item?.logo_file? <img src={item.logo_file?Company.getFiles(item.logo_file):image_default} className='w-100 h-100'/>:''}
                            </div>
                            {item.name}
                        </div>
                    }
                }), ...props.values.companies.map((item:any)=>{

                    return {
                        value:item.company_id,
                        label:<div className='d-flex align-items-center'>
                            <div style={{borderRadius:'6px', overflow:'hidden', marginRight:'8px', width:24, height:24}}>
                                {item?.logo_file? <img src={item.logo_file?Company.getFiles(item.logo_file):image_default} className='w-100 h-100'/>:''}
                            </div>
                            {item.company_name}
                        </div>
                    }
                })])
            })
            .catch(err=>{
                setError({status: err?.response?.status, message:  localErrorsMessage('post-company', 'message-match', err?.response?.data?.message, intl), isError: true})
            })
    }



    return (
        <div className='w-100'>

            <FieldArray
                name="companies"
                render={arrayHelpers=>{

                    return (
                        //@ts-ignore
                        <div >{
                            //@ts-ignore
                            props.values.companies.map((req, index)=>{


                                return (
                                    <div key={index} className='border border-gray-300 mb-10'
                                         style={{padding:'16px 16px 56px 16px', borderRadius:'12px'}}>

                                        <div className='position-relative'>


                                            <Row gutter={[12,12]} className='mb-5'>
                                                <Col lg={props.values.companies[index]?.company_id?18:24} className='fv-row'>
                                                    <label className='form-label required'>{intl.formatMessage({id:'add-merchant.label'})}</label>
                                                    <Field
                                                        component={AsyncSelect}
                                                        styles={customStyles}
                                                        defaultOptions={merchants}
                                                        value={merchantsAll.find((item:any)=>item.value===props.values['companies'][index]['company_id'])||null}
                                                        placeholder={intl.formatMessage({id:"select"})}
                                                        name={`companies.${index}.company_id`}
                                                        noOptionsMessage={({inputValue}:{inputValue:any}) => !inputValue ? 'Нет данных' : "Нет данных"}
                                                        onChange={(e:any)=>{
                                                            let companies = props.values.companies.map((comp:any, indexComp:number)=>{
                                                                if(index===indexComp){

                                                                    return{
                                                                        ...comp,
                                                                        company_id: e.value

                                                                    }
                                                                }

                                                                return comp

                                                            })




                                                            props.setFieldValue('companies', companies)
                                                            const ids = companies.map((item:any)=>item.company_id)
                                                            setMerchants(merchantsAll.filter((item:any)=>!ids.includes(item.value)))
                                                        }}


                                                    />

                                                    <div className='text-danger mt-2'>
                                                        <ErrorMessage name={`companies.${index}.company_id`} />
                                                    </div>
                                                </Col>


                                                {
                                                    props.values.companies[index].company_id!==0?
                                                        (<Col lg={6} className='position-relative'>
                                                            <label className='form-label required'>%</label>

                                                                <Field
                                                                    type='text'
                                                                    className='form-control form-control-lg form-control-solid'
                                                                    name={`companies.${index}.commission`}
                                                                    onChange={(e:any)=>{
                                                                        if (e.currentTarget.value.match(/^\s*\d*[\.]?\d*\s*$/)&&e.currentTarget.value<=100) props.handleChange(e)
                                                                    }}

                                                                />

                                                            <Tooltip placement="topLeft" title='Процент агента с мерчанта'>
                                                                <div style={{right:'15px', top:'40px'}} className='position-absolute'>
                                                                    <KTSVG path={'/media/svg/icon-info2.svg'}
                                                                           className={`svg-icon-2hx me-2 `} />
                                                                </div>

                                                            </Tooltip>
                                                            <div className='text-danger mt-2'>
                                                                <ErrorMessage name={`companies.${index}.commission`}/>
                                                            </div>
                                                        </Col>):''
                                                }
                                            </Row>

                                            <div>
                                                {
                                                    props.values.companies[index].company_id!==0?
                                                        (<>
                                                            <div className='mb-5'>
                                                                <label className='form-label mb-3 required'>{intl.formatMessage({id: 'add-merchant.label1'})}</label>
                                                                <Field
                                                                    type='text'
                                                                    className='form-control form-control-lg form-control-solid'
                                                                    name={`companies.${index}.contract_id`}
                                                                />
                                                                <div className='text-danger mt-2'>
                                                                    <ErrorMessage name={`companies.${index}.contract_id`}/>
                                                                </div>
                                                            </div>
                                                            <div className='mb-5'>
                                                                <label className='form-label mb-3 required'>{intl.formatMessage({id: 'contract_expiry_date'})}</label>
                                                                <Field
                                                                    type='date'
                                                                    className='form-control form-control-lg form-control-solid'
                                                                    name={`companies.${index}.contract_expiry_date`}
                                                                    min='1899-01-01' max='2125-01-01'
                                                                    value={moment(props.values.companies[index]['contract_expiry_date']).format('YYYY-MM-DD')}
                                                                />

                                                                <div className='text-danger mt-2'>
                                                                    <ErrorMessage name={`companies.${index}.contract_expiry_date`}/>
                                                                </div>
                                                            </div>
                                                        </>):''
                                                }
                                            </div>




                                            <Tooltip placement="topLeft" title='Удалить'>
                                                <button
                                                    type="button"
                                                    className={`btn btn-sm btn-icon btn-light-danger me-3 position-absolute end-0 ${index===0?'d-none':''}`}
                                                    onClick={() => arrayHelpers.remove(index)}
                                                >
                                                    <KTSVG path='/media/svg/basket.svg' className='svg-icon-2' />
                                                </button>

                                            </Tooltip>


                                        </div>


                                    </div>
                                )
                            })
                        } <button className='btn btn-sm btn-primary me-3 mb-5' type="button" onClick={() => {

                            return arrayHelpers.push({
                                contract_expiry_date: '',
                                commission: '',
                                contract_id: '',
                                company_id:0
                            })
                        }}>
                            <KTSVG
                                path='/media/icons/duotune/general/gen041.svg'
                                className='svg-icon-4 me-1'
                            />
                            {intl.formatMessage({id:'add-merchant'})}
                        </button></div>
                    )

                }}></FieldArray>


        </div>
    )
}




export {Step2}
