import {FC, useEffect} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG, WithChildren} from '../../../helpers'
import {useLayout} from '../../core'

type Props = {
    to: string
    title: string
    icon?: string
    fontIcon?: string
    hasBullet?: boolean
    disable?: boolean
    onClick?: Function
    externalLink?: string
    className?: string
}

const AsideMenuItem: FC<Props & WithChildren> = ({
                                                     children,
                                                     to,
                                                     title,
                                                     icon,
                                                     fontIcon,
                                                     hasBullet = false,
                                                     disable = false,
                                                     onClick,
                                                     externalLink,
                                                     className
                                                 }) => {
    const {pathname} = useLocation()
    const isActive = checkIsActive(pathname, to)
    const {config} = useLayout()
    const {aside} = config

    return (
        <div className={`menu-item ${className}`} onClick={onClick ? (e) => {
            onClick()
            e.preventDefault()
            e.stopPropagation()
        } : undefined}>
            {externalLink ?
                <a href={externalLink} className={clsx('menu-link without-sub', {active: isActive})}>
                    {hasBullet && (
                        <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
                    )}
                    {icon && aside.menuIcon === 'svg' && (
                        <span className='menu-icon'>
            <KTSVG path={icon} className='svg-icon-2'/>
          </span>
                    )}
                    {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
                    <span className='menu-title'>{title}</span>
                </a> :
                <Link onClick={(e) => {
                    disable && e.preventDefault()
                }} className={clsx('menu-link without-sub', {active: isActive})} to={to}>
                    {hasBullet && (
                        <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
                    )}
                    {icon && aside.menuIcon === 'svg' && (
                        <span className='menu-icon'>
            <KTSVG path={icon} className='svg-icon-2'/>
          </span>
                    )}
                    {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
                    <span className='menu-title'>{title}</span>
                </Link>}
            {children}
        </div>
    )
}


export {AsideMenuItem}
