// @ts-nocheck
import {FC} from 'react'
import {ColumnInstance} from 'react-table'
import {CompanyResponse} from "@providers/company/_models";

type Props = {
  column: ColumnInstance<any>
}

const fixTables:any= {
    num: 0,
    contractNumber: 50,
    contractDate: 175
}

const keys = Object.keys(fixTables)

const CustomHeaderColumn: FC<Props> = ({column}) => {

  return (
      <>
        {column.Header && typeof column.Header === 'string' ? (
            <th {...column.getHeaderProps()}
                style={keys.includes(cell.column.id)?{position:'sticky', backgroundColor: 'var(--kt-card-bg)', left: fixTables[cell.column.id]||0}:{}}>{column.render('Header')}</th>
        ) : (
            column.render('Header')
        )}
      </>
  )
}

export {CustomHeaderColumn}
