import {useEffect, useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'

import {usersColumns} from './columns/_columns'
import {KTCardBody} from '@metronic/helpers'
import {observer} from "mobx-react";
import {filial} from "@store/filial";
import {FilialResponse, ICashbox} from "@providers/filial/_models";
import {Filial} from "@providers/filial/_request";
import {useParams} from "react-router-dom";
import {useErrors} from "@providers/context/ErrorsProvider";
import {useIntl} from "react-intl";

const Table = observer(()=>{
    const {companyId} = useParams()
    const data: ICashbox[] = useMemo(() => filial.getFilial, [filial.getFilial])
    const columns = useMemo(() => usersColumns, [filial.getFilial])
    const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
        columns,
        data,
    })
    const {setError} = useErrors()
    const intl = useIntl()




    useEffect(()=>{
        if (companyId){
            Filial.getFilial(companyId)
                .then(response=>{
                    filial.setFilial(response?.data?.result.content)
                })
                .catch(err=>{
                    setError({status: err?.response?.status, message: err?.response?.status, isError: true})
                })
        }
    },[companyId])

    return (
        <KTCardBody className='py-4'>
            <div className='table-responsive'>
                <table
                    id='kt_table_users'
                    className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                    {...getTableProps()}
                >
                    <thead>
                    <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                        {headers.map((column: ColumnInstance<ICashbox>) => (
                            <CustomHeaderColumn key={column.id} column={column} />
                        ))}
                    </tr>
                    </thead>
                    <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                    {rows.length > 0 ? (
                        rows.map((row: Row<ICashbox>, i) => {
                            prepareRow(row)
                            return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                        })
                    ) : (
                        <tr>
                            <td colSpan={7}>
                                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                    {intl.formatMessage({id: 'NOT_MATCHING'})}
                                </div>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>

        </KTCardBody>
    )
})

export {Table}
