import React, {FC} from "react";
import {useIntl} from "react-intl";

type Props = {
    status: string
}
const classStatus:any = {
    UNVERIFIED: "badge-light-warning",
    ACTIVE: "badge-light-success",
    default_status: "badge-light-dark",
}

const icons:any = {
    ACTIVE: '/media/svg/approved.svg',
    OUTDATED: '/media/icons/duotune/general/status_calendar.svg',
    TERMINATED: null,
    UNVERIFIED: '/media/icons/duotune/general/status_calendar.svg',
    CASH_PAID: null,
    FINISHED: null
}


const StatusAutopay: FC<Props> = ({status}) => {

    const intl = useIntl()
    const status_style:any = classStatus[status]?classStatus[status]:classStatus['default_status']
    const status_icon:any = icons[status]?icons[status]:null

    return (
        <div>
            {/*@ts-ignore*/}
            <div className={`badge rounded-4 ${status_style} justify-content-start text-left align-content-center`} style={{fontSize: "12px", fontWeight: 500}}>
                {status_icon&&<img src={status_icon} className="me-1" style={{objectFit: 'contain'}}/>}
                <span className={status === "FAILED" ? "opacity-75" : ""} style={{fontWeight:'500'}}>{intl.formatMessage({id: status})}</span>
            </div>
        </div>
    )
}

export {StatusAutopay}