import React, {useEffect, useState} from 'react';
import {useErrors} from "@providers/context/ErrorsProvider";
import {useIntl} from "react-intl";
import {IWebPaymentResponse, IWebPaymentSend, IWebPaymentsFilters} from "@providers/webPayments/models";
import {web_payments} from "@store/web-payments/web-payments";
import {webPaymentsColumns} from "./columns/columns";
import {ColumnInstance, Row, useTable} from "react-table";
import CustomHeaderColumn from "./columns/CustomHeaderColumn/CustomHeaderColumn";
import CustomRow from "./columns/CustomRow/CustomRow";
import {getWebPayments} from "@providers/webPayments/request";
import {useParams} from "react-router-dom";
import {UsersListLoading} from "@metronic/partials/widgets/tables/_modals/components/loading/UserListLoading";
import {Pagination} from "../../../../modules/Pagination";
import {observer} from "mobx-react";
import {roleRoutingAdmin} from "@utils/index";
import {employeeStore} from "@store/employee";

interface props {

}

const Table = observer((props: props) => {
    const {id, companyId} = useParams()
    const data = web_payments.getWebPayments
    const columns = webPaymentsColumns

    const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
        columns,
        data,
    })
    const [loaded, setLoaded] = useState<boolean>(false)
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
    const {setError} = useErrors()
    const intl = useIntl()


    useEffect(() => {
        webPaymentsRecieve({})
    }, [])

    const webPaymentsRecieve = async (props: any) => {
        const data: IWebPaymentsFilters = {
            page: props?.currentPage || 1,
            cashBoxId: id ?? '',
            size: 20,
        }

        if (roleRoutingAdmin('action', employeeStore.getRole, 'is_user_admin')) {
            data.companyId = companyId
        }

        setConfirmLoading(true)
        try {
            const response: IWebPaymentResponse = await getWebPayments(data).then((response) => response.data)
            web_payments.setWebPayments(response.result.content)
            web_payments.setPagination({
                currentPage: props?.currentPage || 1,
                pageSize: 20,
                pages: response.result.totalPages,
                last: props?.currentPage === response.result.totalPages,
                first: props?.currentPage === 1,
                items: response.result.totalElements
            })
        } catch (err: any) {
            setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
        } finally {
            setLoaded(true)
            setConfirmLoading(false)
        }
    }


    if (!loaded) return <UsersListLoading/>


    return (
        <div className='table-responsive'>
            {confirmLoading && <UsersListLoading/>}
            <table
                id='kt_table_users'
                className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                {...getTableProps()}
            >
                <thead>
                <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                    {headers.map((column: ColumnInstance<IWebPaymentSend>) => (
                        <CustomHeaderColumn column={column} key={column.id}/>
                    ))}
                </tr>
                </thead>
                <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                {rows.length > 0 ? (
                    rows.map((row: Row<IWebPaymentSend>, i) => {
                        prepareRow(row)
                        return <CustomRow row={row} key={`row-${i}-${row.id}`}/>
                    })
                ) : (
                    <tr>
                        <td colSpan={7}>
                            <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                {intl.formatMessage({id: 'NOT_MATCHING'})}
                            </div>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
            <Pagination withInfo position='end' infoText={intl.formatMessage({id: 'webPayments'})}
                        pagination={web_payments.getPagination}
                        updatePage={webPaymentsRecieve}/>
        </div>
    );
});

export default Table;