import {Statistic} from "@providers/statistic/_request";
import React, {useEffect, useState} from "react";
import {useErrors} from "@providers/context/ErrorsProvider";
import {useIntl} from "react-intl";
import {isDemo, NumReplace} from "@utils/index";
import moment from "moment";
import {ActivityDrawerAdmin} from "./_modals/ActivityDrawerAdmin";
import {WarningAlert} from "../../../modules/alerts/WarningAlert";
import {DatePicker} from "antd";
import 'moment/locale/ru';
import 'moment/locale/uz-latn';
import 'moment/locale/en-au';
import localeRu from 'antd/es/date-picker/locale/ru_RU';
import localeEn from 'antd/es/date-picker/locale/en_US';
import {MinusOutlined} from "@ant-design/icons";
import {ChartWidgetMixed} from "../_modals/ChartWidgetMixed";
import {card_transactions, statuses_transactions} from "@utils/constants";
import {IStatistic, statisticStatus} from "@providers/statistic/_modals";
import {divideByHundred} from "@utils/numbersOperations";
import {UsersListLoading} from "@metronic/partials/widgets/tables/_modals/components/loading/UserListLoading";
import { formatDateRequest } from "@utils/formatDateRequest";

interface CustomizedState {
    statuses?: Array<string>
    ppc_types?: Array<string>
    from_date?: string
    to_date?: string
}


export default function AdminDashboard() {
    const [loading, setLoading] = useState(true)
    const {setError} = useErrors()
    const [statistic_data, setStatistic_data] = useState<any>({})
    const [dataDiagram, setDataDiagram] = useState<any[]>([])
    const [warningAlert, setWarningAlert] = useState<string | null>(null)
    const [dataFilters, setDataFilters] = useState<any>(null)
    const [filters, setFilters] = useState<any>({
        from_date: moment().subtract(1, 'month').format('YYYY-MM-DD'),
        to_date: moment().format('YYYY-MM-DD')
    })


    const [currentDate, setCurrentDate] = useState('month')

    const intl = useIntl()


    const lang = JSON.parse(localStorage.getItem('i18nConfig') ?? '{}')?.selectedLang

    if (lang === "uz") {
        localeEn.lang.locale = "uz-latn"
    }


    useEffect(() => {
        getData()
    }, [filters])


    async function getData(values: any = {}, statistic = true) {

        setLoading(true)
        const data: IStatistic = {
            statuses: statuses_transactions,
            processingTypes: card_transactions,
            fromDate: formatDateRequest(filters.from_date, 'fromDate'),
            toDate: formatDateRequest(filters.to_date, 'toDate'),
        }


        await Statistic.getAdminStatistic(data)
            .then(result => {
                setStatistic_data(result?.data?.result)
            }).catch(err => setError({
                status: err?.response?.stat,
                message: err?.response?.data?.message,
                isError: true
            }))


        if (!statistic) setDataFilters(values)


        setLoading(false)

    }


    function getCountStatuses({type = statisticStatus.approved, currency = 'UZS'}: {
        type: statisticStatus,
        currency?: string
    }) {

        let result: any = []

        if (statistic_data && statistic_data[type]) {
            const data = statistic_data[type]

            if (data) {

                card_transactions.forEach((item: any) => {

                    let card_name = item.toLowerCase()

                    let sum = data[`${card_name}Amount`] !== undefined ? data[`${card_name}Amount`] : 0,
                        count = data[`${card_name}Count`] !== undefined ? data[`${card_name}Count`] : 0

                    result.push({
                        card: item,
                        sum: NumReplace(sum / 100 + '') + ` ${currency}`,
                        count: count

                    })

                })
            }

        } else {

            result = card_transactions.map((item: any) => {
                return {
                    card: item,
                    sum: 0,
                    count: 0
                }
            })
        }

        return result

    }


    const sumAmount = (status: statisticStatus) => {
        if (statistic_data[status]) {
            return divideByHundred(statistic_data[status].totalAmount)
        }

        return 0
    }


    const dataChartsRender = async (filter: string[] = [], show = false) => {

        const params_filter: any = {}

        if (!filter.find(item => item.match(/тех. поддержку/i))) {

            const from_date = filter.find(item => item.match('from_date'))
            const to_date = filter.find(item => item.match('to_date'))
            params_filter['fromDate'] = from_date ? from_date.split('=')[1] : filters.from_date
            params_filter['toDate'] = to_date ? to_date.split('=')[1] : filters.to_date


            filter.forEach(item => {

                let key, value, elem = item.split('=');
                if (key !== 'from_date' && key !== 'to_date') {
                    key = elem[0]
                    value = (elem[1] || '').split(',')
                    params_filter[key] = value
                }
            })


            await getData(params_filter, !show)


        } else {
            setWarningAlert(filter[0])

        }
    }


    function countDays() {
        let a = moment(filters.from_date),
            b = moment(filters.to_date)
        return b.diff(a, 'days')
    }


    function replaceFormat(date: any, formatFrom: string, formatTo: string) {
        let newDate = moment(date, formatFrom).format(formatTo)
        return moment(newDate, formatTo)
    }


    return (
        <div>
            {loading && <UsersListLoading/>}
            <div className='toolbar toolbar-filter justify-content-end px-10 align-items-center'>
                <p className='mb-0'>{intl.formatMessage({id: 'filter_by'})}</p>

                <div className='filter_buttons_group'>
                    <button
                        disabled={isDemo()}
                        className={`btn btn-sm btn-active-light-primary ${currentDate === 'day' ? 'btn-active' : "fw-normal"}`}
                        onClick={() => {
                            if (countDays() > 0) {
                                setCurrentDate('day')
                                const from_date = moment().format('YYYY-MM-DD')
                                const to_date = moment().format('YYYY-MM-DD')
                                setFilters((prevState: any) => {
                                    const fromDate = {from_date: from_date}
                                    const toDate = {to_date: to_date}
                                    return {...prevState, ...fromDate, ...toDate}
                                })
                            }

                        }}>
                        {intl.formatMessage({id: 'filter_by_day'})}
                    </button>
                    <button
                        disabled={isDemo()}
                        className={`btn btn-sm btn-active-light-primary ${currentDate === 'week' ? 'btn-active' : "fw-normal"}`}
                        onClick={() => {
                            if (countDays() !== 7) {
                                setCurrentDate('week')
                                const from_date = moment().subtract(1, 'week').format('YYYY-MM-DD')
                                const to_date = moment().format('YYYY-MM-DD')


                                setFilters((prevState: any) => {
                                    const fromDate = {from_date: from_date}
                                    const toDate = {to_date: to_date}
                                    return {...prevState, ...fromDate, ...toDate}
                                })
                            }

                        }}>
                        {intl.formatMessage({id: 'filter_by_week'})}
                    </button>
                    <button
                        className={`btn btn-sm btn-active-light-primary ${currentDate === 'month' ? 'btn-active' : "fw-normal"}`}
                        onClick={() => {
                            if (countDays() !== 30 || countDays() !== 31) {
                                setCurrentDate('month')
                                const from_date = moment().subtract(1, 'month').format('YYYY-MM-DD')
                                const to_date = moment().format('YYYY-MM-DD')


                                setFilters((prevState: any) => {
                                    const fromDate = {from_date: from_date}
                                    const toDate = {to_date: to_date}
                                    return {...prevState, ...fromDate, ...toDate}
                                })
                            }

                        }}>
                        {intl.formatMessage({id: 'filter_by_month'})}
                    </button>
                </div>
                <div className="mx-4" style={{backgroundColor: "rgba(235, 237, 242, 1)", width: "1px", height: "40%"}}/>
                <div>
                    <DatePicker.RangePicker
                        style={{border: "none"}}
                        separator={<MinusOutlined/>}
                        locale={lang === 'ru' ? localeRu : localeEn}
                        placeholder={[intl.formatMessage({id: 'calendar_start'}), intl.formatMessage({id: 'calendar_end'})]}
                        key={`${filters.from_date}-${filters.to_date}`}
                        className='datepicker_form form-control-lg form-control-solid'
                        defaultValue={[replaceFormat(filters.from_date, 'YYYY-MM-DD', 'DD-MM-YYYY'),
                            replaceFormat(filters.to_date, 'YYYY-MM-DD', 'DD-MM-YYYY')]}
                        format='DD-MM-YYYY'
                        disabledDate={current => {
                            return current && current > moment().endOf('day');
                        }}
                        onKeyDown={(val) => {
                            if (val.code.toLowerCase().includes("key")) {
                                val.preventDefault()
                                val.stopPropagation()
                                return
                            }
                        }}
                        //@ts-ignore
                        onChange={(dates: [any, any], dateStrings: [string, string]) => {
                            if (dates) {
                                setCurrentDate('')
                                const [start, end] = dateStrings
                                setFilters((prevState: any) => {
                                    return {
                                        ...prevState,
                                        from_date: replaceFormat(start, 'DD-MM-YYYY', 'YYYY-MM-DD').format(),
                                        to_date: replaceFormat(end, 'DD-MM-YYYY', 'YYYY-MM-DD').format(),
                                    }
                                })
                            }

                        }}
                    />
                </div>
            </div>
            <ActivityDrawerAdmin open={!!dataFilters}
                                 dataStatistic={dataDiagram}
                                 params={dataFilters}
                                 setDataCharts={(val: any) => setDataFilters(val)}
                                 onDateChange={(dates: [any, any], dateStrings: [string, string]) => {
                                     if (dateStrings) {
                                         const [start, end] = dateStrings
                                         setCurrentDate('')
                                         setFilters((prev: any) => ({
                                             ...prev,
                                             to_date: end,
                                             from_date: start
                                         }))

                                     }
                                 }}/>
            <WarningAlert isAlert={!!warningAlert} message={warningAlert} setShow={(val: any) => setWarningAlert(val)}/>

            <div className='row g-5 g-xl-8'>

                <div className='col-xl-3 col-lg-4 col-md-4'>
                    <ChartWidgetMixed isAvailable={true}
                                      tooltip={intl.formatMessage({id: 'tooltip_all'})}
                                      dataChartsRender={(filter: string[], showBar) => dataChartsRender(filter, showBar)}
                                      className='card-xl-stretch mb-xl-8' color='dark-blue' data={{
                        status: 'GENERAL',
                        sum: [`${sumAmount(statisticStatus.general) ? NumReplace(sumAmount(statisticStatus.general) + '') : 0} UZS`],
                        details: getCountStatuses({type: statisticStatus.general})
                    }} cards={card_transactions}/>
                </div>


                <div className='col-xl-3 col-lg-4 col-md-4'>
                    <ChartWidgetMixed isAvailable={true}
                                      tooltip={intl.formatMessage({id: 'tooltip_success'})}
                                      dataChartsRender={(filter: string[], showBar) => dataChartsRender(filter, showBar)}
                                      className='card-xl-stretch mb-xl-8' color='success' data={{
                        status: 'APPROVED',
                        sum: [`${sumAmount(statisticStatus.approved) ? NumReplace(sumAmount(statisticStatus.approved) + '') : 0} UZS`],
                        details: getCountStatuses({type: statisticStatus.approved})
                    }} cards={card_transactions}/>
                </div>

                <div className='col-xl-3 col-lg-4 col-md-4'>
                    <ChartWidgetMixed isAvailable={true}
                                      tooltip={intl.formatMessage({id: 'tooltip_declined'})}
                                      dataChartsRender={(filter: string[], showBar) => dataChartsRender(filter, showBar)}
                                      className='card-xl-stretch mb-xl-8' color='warning' data={{
                        status: 'FAILED',
                        sum: [`${sumAmount(statisticStatus.failed) ? NumReplace(sumAmount(statisticStatus.failed) + '') : 0} UZS`],
                        details: getCountStatuses({type: statisticStatus.failed})
                    }} cards={card_transactions}/>
                </div>

                <div className='col-xl-3 col-lg-4 col-md-4'>
                    <ChartWidgetMixed isAvailable={true}
                                      tooltip={intl.formatMessage({id: 'tooltip_canceled'})}
                                      dataChartsRender={(filter: string[], showBar) => dataChartsRender(filter, showBar)}
                                      className='card-xl-stretch mb-xl-8' color='danger' data={{
                        status: 'REVERT',
                        sum: [`${sumAmount(statisticStatus.revert) ? NumReplace(sumAmount(statisticStatus.revert) + '') : 0} UZS`],
                        details: getCountStatuses({type: statisticStatus.revert})
                    }} cards={card_transactions}/>
                </div>

            </div>

        </div>
    )
}