import React from 'react';
import {IWebPayment} from "@providers/webPayments/models";
import InvoiceDelete from "./invoice-delete/invoice-delete";

interface props {
    invoice: IWebPayment
}


const Actions = ({invoice}: props) => {


    return (
        <span className={'d-flex align-items-center justify-content-end'}>
               {invoice.id ?  <InvoiceDelete invoiceId={invoice.id} title={invoice.name}/> : null}
        </span>
    );
};

export default Actions;