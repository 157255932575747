import React, {FC, useEffect, useRef, useState} from 'react'
import {KTSVG} from '@metronic/helpers'
import {Step1} from './steps/Step1'
import {Step2} from './steps/Step2'
import {Step3} from './steps/Step3'
import {Step4} from './steps/Step4'
import {StepperComponent} from '@metronic/assets/ts/components'
import {Form, Formik, FormikValues} from 'formik'
import {PreviewModal} from '../../_modals/PreviewModal'

import {ContractShema, inits} from './CreateAccountWizardHelper'


import Alert from 'react-bootstrap/Alert';
import {observer} from "mobx-react";
import {useErrors} from "@providers/context/ErrorsProvider";
import {useIntl} from "react-intl";
import {filterObject, isDemo, NumReplace} from "@utils/index";
import {Tooltip} from "antd";
import {ICreateContractSchema} from "@providers/autopay/_interfaces";
import moment from "moment";
import {PaymentGraphicChart} from "../../createContractChart";
import {Nav} from "react-bootstrap";
import {TableTab} from "../../_modals/TableTab";
import {ContractsService} from "@providers/autopay/requests/contracts.service";
import {useNavigate, useParams} from "react-router-dom";


const Vertical: FC = observer(() => {

    const contractshema = ContractShema()
    const {companyId} = useParams()
    const stepperRef = useRef<HTMLDivElement | null>(null)
    const stepper = useRef<StepperComponent | null>(null)
    const [currentSchema, setCurrentSchema] = useState(contractshema[0])
    const [initValues, setInitValues] = useState<ICreateContractSchema>(inits)
    const [alert, setAlert] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)
    const [schedules, setSchedules] = useState<any>({
        schedules: [],
        totalPrice: 0,
        last_payment_date: ''

    })
    const [activeKey, setActiveKey] = useState<string|null>('dashboard')


    const {setError} = useErrors()
    const intl = useIntl()
    const [showModal, setShowModal] = useState(false)
    const [orderIdCreated, setOrderIdCreated] = useState(null)
    const [completedSteps, setCompletedSteps] = useState(0)
    const navigate = useNavigate()



    const loadStepper = () => {
        stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
    }

    const prevStep = () => {
        if (!stepper.current) {
            return
        }
        stepper.current.goPrev()

        setCurrentSchema(contractshema[stepper.current.currentStepIndex - 1])
    }


    const submitStep = async (values: ICreateContractSchema, actions: FormikValues) => {
        if (stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber! && isDemo()) return

        if (!stepper.current) {
            return
        }

        setCurrentSchema(contractshema[stepper.current.currentStepIndex])

        if (stepper.current.currentStepIndex === 4) {
            setShowModal(true)

        }

        if (stepper.current.currentStepIndex === 3) {
            calcCreditDays(values)
        }

        if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
            setCompletedSteps(stepper.current?.currentStepIndex)
            stepper.current.goNext()
        } else {

        }


    }

    function calcCreditDays(values:ICreateContractSchema) {
        const payments:any[] = []

        const percent:number = values.schedules_percent?+values.schedules_percent:0
        const priceToPay = (+values.productPrice.replace(/\s+/g, '') - +values.prepaymentPrice.replace(/\s+/g, '') - +values.discountPrice.replace(/\s+/g, ''))/+values.scheduleDays;
        const monthlyPercent = percent/100/+values.scheduleDays
        const monthlyPrice = Math.round((priceToPay * (monthlyPercent + (monthlyPercent / (Math.pow(1 + monthlyPercent, +values.scheduleDays) - 1)))))
        const totalPrice = Math.round((monthlyPrice * +values.scheduleDays)) + +values.prepaymentPrice.replace(/\s+/g, '') + +values.discountPrice.replace(/\s+/g, '')

        const last_date = moment(values.scheduleDate_first).add(+values.scheduleDays, 'months').format('YYYY-MM-DD')

        for(let i=0; i<+values.scheduleDays; i++){
            const data = {
                scheduleDate: moment(values.scheduleDate_first).add(i, 'months').format('YYYY-MM-DD'),
                paymentSum: priceToPay
            }

            if(i===0) data.scheduleDate = moment(values.scheduleDate_first).format('YYYY-MM-DD')
            payments.push(data)
        }
        setSchedules({
            schedules: payments,
            totalPrice: totalPrice,
            last_payment_date: last_date
        })



    }

    useEffect(() => {
        if (!stepperRef.current) {
            return
        }

        loadStepper()
    }, [stepperRef])



    async function createContract(values: ICreateContractSchema) {
        const checkValue = values.phoneNumber.replace(/ /g, '').replace('+', '')
        setLoading(true)

        const client_keys = ["pinfl", "passportSeries", "passportNumber", "firstName", "lastName", "middleName", "issuedBy"]
        const type_number = ["productPrice", "prepaymentPrice", "discountPrice", "overdueLimit", "pinfl"]
        const data_keys = ["contractNumber", "merchantId", "contractDate", "productName", "serviceId", "productPrice", "prepaymentPrice", "discountPrice", "overdueLimit", "cards"]

        const client:any = {}
        const data:any = {}

        for(let key in values){

            if(client_keys.includes(key)) {
                {

                    if(type_number.includes(key)) {
                        //@ts-ignore
                        if(key.toLowerCase().match(/price/g)) client[key] = +values[key].replace(/\s+/g, '')*100
                        //@ts-ignore
                        else client[key] = +values[key]
                    }
                    //@ts-ignore
                    else client[key] = values[key]
                }
            }

            else if (data_keys.includes(key)) {
                //@ts-ignore
                if(type_number.includes(key)) {
                    //@ts-ignore
                    if(key.toLowerCase().match(/price/g)) data[key] = +values[key].replace(/\s+/g, '')*100
                    //@ts-ignore
                    else data[key] = +values[key]
                }
                //@ts-ignore
                else data[key] = values[key]
            }
        }
        const request_data:any = {
            clientCreationRequest: {...client, phoneNumber: checkValue},
            schedules: schedules.schedules.map((item:any)=>{return {...item, paymentSum:item.paymentSum*100}}),
            merchantId: companyId,
            ...data
        }






        await ContractsService.postContract(request_data)
            .then((result:any)=>{

                const contractId = result.data.data
                setOrderIdCreated(contractId)

            })
            .catch((err:any)=>{
                setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
            })

        setLoading(false)


    }

    async function generateUrl(){

        if(orderIdCreated){
            setLoading(true)
            await ContractsService.generateUniqueLink(orderIdCreated)
                .then((result:any)=>{
                    setLoading(false)
                    setAlert('Контракт успешно создан, ссылка отправлена пользователю')
                    window.scroll({
                        top: 0,
                        behavior: 'smooth'
                    })
                    setTimeout(()=>{
                        navigate(`/${companyId}/autopay/contracts`)
                    }, 3000)



                })
                .catch((err:any)=>{
                    setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
                })
            setCompletedSteps(0)
            setLoading(false)
            setShowModal(false)
        }
    }





    const customValidate = (e: any, values: any) => {
        const isNotRequired = ['email', 'nds', 'bank_filial', 'logo_file']
        const errors = filterObject(values, (val: any, key: string) => isNotRequired.includes(key) ? false : val === "" || val === null || val === 0)

        let err_message = ''
        if (Object.keys(errors).length) {
            const firstErrKey = Object.keys(errors)[0]
            //@ts-ignore
            const firstErrorSchemaIndex = currentSchema.findIndex(schema => {
                const arrSchemaKeys = Object.keys(schema.fields)
                return arrSchemaKeys.includes(firstErrKey)
            })
            stepper.current?.goto(firstErrorSchemaIndex + 1)
            setCurrentSchema(currentSchema[firstErrorSchemaIndex])
            for (let err in errors) {
                //@ts-ignore
                err_message += `* ${intl.formatMessage({id: err})} - ${intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})} ` + "\n"
            }
            setError({status: '', message: err_message, isError: true})
            e.preventDefault()
            return false
        }
        return true
    }


    return (
        <>
            <div
                ref={stepperRef}
                className='stepper stepper-pills stepper-column   d-flex flex-column flex-xl-row flex-row-fluid'
                id='kt_create_account_stepper'
            >
                {alert && (
                    <Alert className='position-absolute' style={{left: '60%'}} key={'success'} variant={'success'}>
                        {alert}
                    </Alert>
                )}

                <div
                    className='d-flex justify-content-center bg-body rounded justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
                    <div className='px-6 px-lg-10 px-xxl-15 py-20'>
                        <div className='stepper-nav'>
                            <div className='stepper-item current' data-kt-stepper-element='nav'>

                                <div className="d-flex align-items-center cursor-pointer" onClick={() => {
                                    if (!stepper.current) {
                                        return
                                    }
                                    setCurrentSchema(contractshema[0])

                                    stepper.current?.goto(1)
                                    stepper.current?.steps.forEach((item, index) => {
                                        if (completedSteps > index) return
                                        item.classList.remove(('completed'))
                                    })
                                }}>
                                    <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <span className='stepper-number'>1</span>
                                    </div>

                                    <div className="d-flex flex-column">
                                        <div className='stepper-label'>
                                            <h3 className='stepper-title'>{intl.formatMessage({id: 'step1_contract_form'})}</h3>
                                        </div>
                                        <div className='stepper-label'>
                                            <span
                                                className='stepper-subtitle fw-normal'>{intl.formatMessage({id: 'step1_contract_form_subtitle'})}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='stepper-item' data-kt-stepper-element='nav'>
                                <div className='stepper-line h-40px'></div>
                                <div className="d-flex align-items-center cursor-pointer" onClick={() => {
                                    if (!stepper.current) {
                                        return
                                    }
                                    setCurrentSchema(contractshema[1])

                                    stepper.current?.goto(2)
                                    stepper.current?.steps.forEach((item, index) => {
                                        if (completedSteps > index) return
                                        item.classList.remove(('completed'))
                                    })
                                }}>
                                    <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <span className='stepper-number'>2</span>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <div className='stepper-label'>
                                            <h3 className='stepper-title'>{intl.formatMessage({id: 'step2_contract_form'})}</h3>
                                        </div>
                                        <div className='stepper-label'>
                                            <span
                                                className='stepper-subtitle fw-normal'>{intl.formatMessage({id: 'step2_contract_form_subtitle'})}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='stepper-item' data-kt-stepper-element='nav'>
                                <div className='stepper-line h-40px'></div>
                                <div className="d-flex align-items-center cursor-pointer" onClick={() => {
                                    if (!stepper.current) {
                                        return
                                    }
                                    setCurrentSchema(contractshema[2])

                                    stepper.current?.goto(3)
                                    stepper.current?.steps.forEach((item, index) => {
                                        if (completedSteps > index) return
                                        item.classList.remove(('completed'))
                                    })
                                }}>
                                    <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <span className='stepper-number'>3</span>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <div className='stepper-label'>
                                            <h3 className='stepper-title'>{intl.formatMessage({id: 'step3_contract_form'})}</h3>
                                        </div>
                                        <div className='stepper-label'>
                                            <span
                                                className='stepper-subtitle fw-normal'>{intl.formatMessage({id: 'step3_contract_form_subtitle'})}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='stepper-item' data-kt-stepper-element='nav'>
                                <div className='stepper-line h-40px'></div>
                                <div className="d-flex align-items-center cursor-pointer" onClick={() => {
                                    if (!stepper.current) {
                                        return
                                    }
                                    setCurrentSchema(contractshema[3])

                                    stepper.current?.goto(4)
                                    stepper.current?.steps.forEach((item, index) => {
                                        if (completedSteps > index) return
                                        item.classList.remove(('completed'))
                                    })
                                }}>
                                    <div className='stepper-icon w-40px h-40px'>
                                        <i className='stepper-check fas fa-check'></i>
                                        <span className='stepper-number'>4</span>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <div className='stepper-label'>
                                            <h3 className='stepper-title'>{intl.formatMessage({id: 'step4_contract_form'})}</h3>
                                        </div>
                                        <div className='stepper-label'>
                                            <span
                                                className='stepper-subtitle fw-normal'>{intl.formatMessage({id: 'step4_contract_form_subtitle'})}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

                <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
                    <Formik enableReinitialize={true} validationSchema={currentSchema} initialValues={initValues}
                            onSubmit={submitStep}>
                        {(props) => {
                            const {
                                values,
                                errors,
                                handleChange,
                                setFieldValue,
                                setFieldTouched,
                                submitForm,
                                setErrors,
                                validateField,
                            } = props

                            return (
                                <>
                                <PreviewModal shouldShow={showModal}
                                              onCreate={()=>createContract(values)}
                                              onGenerate={()=>generateUrl()}
                                              orderId={orderIdCreated}
                                              isLoading={loading}
                                              closeModal={()=>setShowModal(false)}>
                                    <>

                                    <p className="fs-3 fw-bold">{intl.formatMessage({id: "CONTRACT"})} №{values.contractNumber}
                                    {/*    <span className='fs-5 text-gray-600 fw-normal' style={{paddingLeft:'15px'}}>*/}
                                    {/*    <KTSVG*/}
                                    {/*    path='/media/icons/duotune/general/calendar.svg'*/}
                                    {/*    className='svg-icon-4 me-1'*/}
                                    {/*/> {moment(values.contractDate).format('YYYY.MM.DD')}</span>*/}
                                    </p>
                                        <div className=''>
                                            <div className='d-flex flex-wrap flex-stack'>
                                                <div className='d-flex flex-column flex-grow-1 pe-8'>
                                                    <div className='d-flex flex-wrap'>
                                                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                                            <div className='d-flex align-items-center justify-content-between'>
                                                                <div className='fs-2 fw-bolder me-5'>{values.lastName} {values.firstName} {values.middleName}</div>

                                                            </div>

                                                            <div className='fw-bold fs-6 text-gray-400'>{intl.formatMessage({id:'fio'})}</div>
                                                        </div>

                                                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                                            <div className='d-flex align-items-center justify-content-between'>
                                                                <div className='fs-2 fw-bolder me-5'>{values.phoneNumber}</div>
                                                            </div>

                                                            <div className='fw-bold fs-6 text-gray-400'>{intl.formatMessage({id:'clientNumber'})}</div>
                                                        </div>

                                                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                                            <div className='d-flex align-items-center justify-content-between'>
                                                                <div className='fs-2 fw-bolder me-5'>{values.passportSeries} {values.passportNumber}</div>
                                                            </div>

                                                            <div className='fw-bold fs-6 text-gray-400'>{intl.formatMessage({id:'PASSPORT'})}</div>
                                                        </div>

                                                        <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                                            <div className='d-flex align-items-center justify-content-between'>
                                                                <div className='fs-2 fw-bolder me-5'>{values.pinfl}</div>
                                                            </div>

                                                            <div className='fw-bold fs-6 text-gray-400'>{intl.formatMessage({id:'pinfl'})}</div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="mt-5 h2">
                                                <div className='d-flex'>
                                                    <p className='fw-lighter fs-5 text-gray-600 w-50'>
                                                        {intl.formatMessage({id: "NAME_PRODUCT"})}:
                                                    </p>
                                                    <p className='fw-bolder fs-5 text-gray-900 w-100'>
                                                        {values.productName}
                                                    </p>
                                                </div>
                                                <div className='d-flex'>
                                                    <p className='fw-lighter fs-5 text-gray-600 w-50'>
                                                        {intl.formatMessage({id: "FILIAL_ID"})}:
                                                    </p>
                                                    <p className='fw-bolder fs-5 text-gray-900 w-100'>
                                                        {values.issuedBy}
                                                    </p>
                                                </div>
                                                <div className='d-flex'>
                                                    <p className='fw-lighter fs-5 text-gray-600 w-50'>
                                                        {intl.formatMessage({id: "PRICE_PRODUCT"})}:
                                                    </p>
                                                    <p className='fw-bolder fs-5 text-gray-900 w-100'>
                                                        {NumReplace(values.productPrice||'0')} UZS
                                                    </p>
                                                </div>

                                                <div className='d-flex'>
                                                    <p className='fw-lighter fs-5 text-gray-600 w-50'>
                                                        {intl.formatMessage({id: "PRICE_PREPAY"})}:
                                                    </p>
                                                    <p className='fw-bolder fs-5 text-gray-900 w-100'>
                                                        {NumReplace(values.prepaymentPrice||'0')} UZS
                                                    </p>
                                                </div>

                                                <div className='d-flex'>
                                                    <p className='fw-lighter fs-5 text-gray-600 w-50'>
                                                        {intl.formatMessage({id: "SUMM_SKID"})}:
                                                    </p>
                                                    <p className='fw-bolder fs-5 text-gray-900 w-100'>
                                                        {NumReplace(values.discountPrice||'0')} UZS
                                                    </p>
                                                </div>

                                                <div className='d-flex'>
                                                    <p className='fw-lighter fs-5 text-gray-600 w-50'>
                                                        {intl.formatMessage({id: "LIMM_DAY1"})}:
                                                    </p>
                                                    <p className='fw-bolder fs-5 text-gray-900 w-100'>
                                                        {values.overdueLimit}
                                                    </p>
                                                </div>


                                                <div className='d-flex'>
                                                    <p className='fw-lighter fs-5 text-gray-600 w-50'>
                                                        {intl.formatMessage({id: "contract_type"})}:
                                                    </p>
                                                    <p className='fw-bolder fs-5 text-gray-900 w-100'>
                                                        {intl.formatMessage({id: "credit"})}
                                                    </p>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="pt-5 h2">

                                            <Nav fill variant="tabs" className="nav nav-stretch  nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap mx-auto" style={{width:
                                            "30%"}}  onSelect={(e)=>setActiveKey(e)}>

                                                <Nav.Item>
                                                    <Nav.Link eventKey="dashboard" className={`nav-link d-block text-active-primary me-6 text-gray-800 text-center ${activeKey==='dashboard'?'active':''}`}>{intl.formatMessage({id:"grafik"})}</Nav.Link>
                                                </Nav.Item>

                                                <Nav.Item>
                                                    <Nav.Link eventKey="transactions" className={`nav-link d-block text-active-primary text-gray-800 text-center me-6 ${activeKey==='table'?'active':''}`}>{intl.formatMessage({id:"table"})}</Nav.Link>
                                                </Nav.Item>

                                            </Nav>

                                            {
                                                activeKey==='dashboard'?(<div>
                                                    <PaymentGraphicChart className='card-xl-stretch'
                                                                         schedules={schedules.schedules} shouldShowButtons={false}/>
                                                    <p className='fs-6 mb-3 text-center position-absolute' style={{top:'95%', left:0, right:0}}>Период</p>
                                                </div>):<TableTab schedules={schedules.schedules}/>
                                            }
                                        </div>
                                    </>
                                 </PreviewModal>
                                    <Form className='py-20 w-100 w-xl-700px px-9' noValidate id='kt_create_account_form'>
                                        <div className='current' data-kt-stepper-element='content'>
                                            {/*@ts-ignore*/}
                                            <Step1 values={values} handleChange={handleChange} setFieldValue={setFieldValue}
                                                   errors={errors} />
                                        </div>

                                        <div data-kt-stepper-element='content'>
                                            {/*@ts-ignore*/}
                                            <Step2
                                                values={values}
                                                handleChange={handleChange}
                                                setFieldValue={setFieldValue}
                                                setErrors={setErrors}
                                            />
                                        </div>

                                        <div data-kt-stepper-element='content'>
                                            {/*@ts-ignore*/}
                                            <Step3 handleChange={handleChange} setFieldValue={setFieldValue}
                                                   values={values}/>
                                        </div>

                                        <div data-kt-stepper-element='content'>
                                            {/*@ts-ignore*/}
                                            <Step4  handleChange={handleChange}
                                                   setFieldValue={setFieldValue} schedules={schedules} values={values}/>
                                        </div>


                                        <div className='d-flex flex-stack'>
                                            <div className='mr-2'>
                                                <button
                                                    onClick={prevStep}
                                                    type='button'
                                                    className='btn btn-lg btn-light-primary me-3'
                                                    data-kt-stepper-action='previous'
                                                >
                                                    <KTSVG
                                                        path='/media/icons/duotune/arrows/arr063.svg'
                                                        className='svg-icon-4 me-1'
                                                    />
                                                    {intl.formatMessage({id: 'BACK'})}
                                                </button>
                                            </div>
                                            <Tooltip
                                                zIndex={(stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber! && isDemo()) ? 1 : -1}
                                                color='var(--kt-body-bg)' title={() => <span
                                                style={{color: 'var(--kt-text-gray-600)'}}>{(stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber! && isDemo()) ? intl.formatMessage({id: "DEMO_DISABLED"}) : ""}</span>}>
                                                <div>
                                                    <button onClick={(e) => {
                                                        if (stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber! && isDemo()) return

                                                        if (stepper.current?.currentStepIndex === 4) {
                                                            // const canContinue = customValidate(e, values)
                                                            // if (!canContinue) return
                                                        }

                                                        let err_message = ''
                                                        if (Object.keys(errors).length) {

                                                            for (let err in errors) {
                                                                //@ts-ignore
                                                                err_message += `* ${intl.formatMessage({id: err})} - ${errors[err]} ` + "\n"
                                                            }
                                                            setError({status: '', message: err_message, isError: true})
                                                        }
                                                    }
                                                    } type='submit' className='btn btn-lg btn-primary me-3'
                                                            disabled={loading || (stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber! && isDemo())}>
                                                <span className='indicator-label'>
                                            {stepper.current?.currentStepIndex === stepper.current?.totatStepsNumber ? intl.formatMessage({id: 'CREATE'}) : stepper.current?.currentStepIndex !==
                                                stepper.current?.totatStepsNumber! - 1 && intl.formatMessage({id: 'THEN'})}
                                                    {stepper.current?.currentStepIndex ===
                                                        stepper.current?.totatStepsNumber! - 1 && intl.formatMessage({id: 'SEND'})}
                                                    <KTSVG
                                                        path='/media/icons/duotune/arrows/arr064.svg'
                                                        className='svg-icon-3 ms-2 me-0'
                                                    />
                                                </span>
                                                    </button>
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </Form>
                                </>
                            )
                        }
                        }
                    </Formik>
                </div>
            </div>
        </>
    )
})

export {Vertical}
