import {FC} from 'react'
import {PageTitle} from "@metronic/layout/core";
import {Navigate, useOutletContext} from "react-router-dom";
import {roleRouting} from "@utils/index";
import {AnalyticDashboard} from "./analytic_dashboard";
import {ErrorProvider} from "@providers/context/ErrorsProvider";

const Analytic:FC = ()=>{
    return(
        <div>
            <AnalyticDashboard/>
        </div>
    )
}

export default function Analytics(){
    //@ts-ignore
    const {role} = useOutletContext()

    if(roleRouting('analytics', role)){
        return(
            <ErrorProvider>
                <PageTitle>Analytic</PageTitle>
                <Analytic/>
            </ErrorProvider>
        )
    }

    return <Navigate to='/error/404' />

}