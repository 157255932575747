import React, {FC, useEffect, useState} from 'react'
import {PageTitle} from "@metronic/layout/core";
import Alert from "react-bootstrap/Alert";
import {ErrorMessage, Field, Form, Formik, FormikHelpers} from "formik";
import {useNavigate, useParams} from "react-router-dom";
import {CreateCashbox} from "@providers/filial/_models";
import {Filial} from "@providers/filial/_request";
import {filial} from "@store/filial";
import {FilialSettingsSchema, inits} from "./CompanyAboutHelper";
import {ErrorProvider, useErrors} from "@providers/context/ErrorsProvider";
import {useIntl} from "react-intl";
import {isDemo, roleRouting, roleRoutingAdmin} from "@utils/index";
import {Tooltip} from "antd";
import {employeeStore} from "@store/employee";
import {observer} from "mobx-react";
import {KTCard, KTCardBody} from "@metronic/helpers";
import {Modal} from "react-bootstrap";
import * as Yup from "yup";
import {UsersListLoading} from "@metronic/partials/widgets/tables/_modals/components/loading/UserListLoading";
import {divideByHundred, multiplyByHundred} from "@utils/numbersOperations";
import {priceformatter} from "@utils/priceformatter";
import {textPattern} from "@utils/constants";
import {Company} from "@providers/company/_request";
import {DropImages} from "@metronic/partials/DropImages";
import {openNotificationSuccess} from "@metronic/partials/notification";
import { serviceCodeOptions } from '../filials/forms/wizards/components/serviceCodeOptions.data';

const Settings: FC = observer(() => {

    const [currentSchema, setCurrentSchema] = useState(FilialSettingsSchema())
    const {companyId, id} = useParams()
    const [initValues, setInitValues] = useState<CreateCashbox>(inits)
    const [deactivate, setDeactivate] = useState(false)
    const [alert, setAlert] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const {setError} = useErrors()
    const [formShow, setFormShow] = useState(0)
    const intl = useIntl()
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [selfEmployee] = useState(false)
    const [readFile, setReadFile] = useState<any | null>(null)


    const onHideDeleteModal = () => setShowDeleteModal(false)


    const [loaded, setLoaded] = useState<boolean>(false)


    useEffect(() => {
        if (selfEmployee) {
            currentSchema.shape({
                pinfl: Yup.string()
                    .min(14, 'Не достаточно символов')
                    .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
            })

        } else {
            currentSchema.shape({
                legalName: Yup.string()
                    .required('Обязательное поле')
                    .max(50, 'Слишком много символов').required('Обязательное поле'),
                brandName: Yup.string()
                    .max(50, 'Слишком много символов'),
                nds: Yup.string()
                    .min(12, 'Не достаточно символов'),
            })
        }

        setCurrentSchema(currentSchema)

    }, [selfEmployee])


    useEffect(() => {
        getFilial()
    }, [])


    function setInit(data: any) {
        setInitValues({
            ...data,
            minAmount: divideByHundred(data.minAmount),
            maxAmount: divideByHundred(data.maxAmount)
        })
    }


    function getFilial() {
        if (companyId && id) {
            Filial.getFilialById(id)
                .then(response => {
                    if (response?.data?.result) {
                        setInit(response?.data?.result)
                    }
                }).catch(err => {
                setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
            }).finally(() => setLoaded(true))
        }
    }


    const onSub = async (values: CreateCashbox, actions: FormikHelpers<CreateCashbox>) => {
        if (isDemo()) return
        await updateFilial(values, actions)
        setFormShow(0)
    }


    const updateFilial = async (values: CreateCashbox, actions: FormikHelpers<CreateCashbox>) => {
        const spacesRegEx = /\s/g

        const value_replace: any = {
            id: Number(id),
            maxAmount: values.maxAmount ? multiplyByHundred(Number(values.maxAmount.toString().replace(spacesRegEx, ''))) : 0,
            minAmount: values.minAmount ? multiplyByHundred(Number(values.minAmount.toString().replace(spacesRegEx, ''))) : 0
        }

        const isNotAdded = ['minAmount', 'maxAmount', 'logoId']

        for (let key in values) {
            //@ts-ignore
            if (values[key] && values[key].length && !isNotAdded.includes(key)) {
                //@ts-ignore
                value_replace[key] = values[key]
            }
        }


        const files: any = {}

        if (typeof values.logoId !== 'string' && values.logoId) {
            files.logo_file = values.logoId
        } else {
            delete value_replace.logoFile
        }


        if (!values.billingUrl) {
            value_replace.billingUrl = ''
        }

        const formData = new FormData()

        for (let val in files) {
            if (files[val]) {
                formData.append(val, files[val])
            }
        }


        setLoaded(true)
        await Filial.patchFilial(companyId ?? '', id ?? '', value_replace)
            .then(async () => {
                if (Object.keys(files).length > 0)
                    await Filial.postFilialFiles(formData, id ?? "0")
            })
            .then(() => {
                setAlert('Касса успешно отредактирована')
                updateData()
                if (!roleRoutingAdmin('action', employeeStore.getRole, 'is-admin')) {
                    setTimeout(() => {
                        navigate(`/${companyId}/filial`)
                    }, 3000)
                } else {
                    setInitValues({...value_replace})
                    setTimeout(() => {
                        setAlert(null)
                    }, 3000)

                    window.scroll({
                        top: 0,
                        behavior: 'smooth'
                    })
                }

            }).catch(err => {
                actions.resetForm()
                setInitValues(initValues)
                setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
            }).finally(() => setLoading(false))
    }


    function updateData() {
        if (companyId && id) {
            Filial.getFilialById(id)
                .then(result => {
                    filial.setFilialOne(result?.data?.result)
                    setInit(result?.data?.result)
                }).catch(err => {
                setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
            })
        }
    }

    const deleteItem = (id: string) => {
        if (deactivate) {
            setLoading(true)
            Filial.deleteFilial(id)
                .then(() => {
                    openNotificationSuccess('Касса успешна удалена')
                    navigate(`/companies/${companyId}`)
                }).catch(err => {
                setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
            }).finally(() => setLoading(false))
        }
    }


    if (!loaded) return <UsersListLoading/>

    return (
        <div>
            {loading && <UsersListLoading/>}

            <div className='d-flex flex-row-fluid flex-center position-relative'>
                {alert && (
                    <Alert className='position-fixed' style={{zIndex: "3", top: "16%"}} key={'success'}
                           variant={'success'}>
                        {alert}
                    </Alert>
                )}
                <Formik enableReinitialize={true} validationSchema={currentSchema} initialValues={initValues}
                        onSubmit={onSub}>
                    {(props) => {
                        const {
                            setFieldValue,
                            errors,
                            values
                        } = props
                        const requisites =
                            [
                                {
                                    label: 'cashbox__name',
                                    value: values.name
                                },
                                {
                                    label: 'legal_name',
                                    value: values.legalName
                                },
                                {
                                    label: 'pinfl',
                                    value: values.pinfl
                                },
                                {
                                    label: 'gnk_spic',
                                    value: values.gnkSpic
                                },
                                {
                                    label: 'package_code',
                                    value: values.packageCode
                                },
                                {
                                    label: 'service_code',
                                    value: values.serviceCode
                                },
                                {
                                    label: 'nds_rate',
                                    value: `${values.ndsRate}%`
                                },
                                {
                                    label: 'min_amount',
                                    value: priceformatter(values.minAmount)
                                },
                                {
                                    label: 'max_amount',
                                    value: priceformatter(values.maxAmount)
                                }
                            ]

                        return (
                            <Form className='w-100'>
                                <KTCard className='mt-5'>
                                    <div className='card-header d-flex py-5 px-10 align-items-center'>
                                        <h3 className='mb-0'>{intl.formatMessage({id: "settings_business"})}</h3>
                                        {roleRoutingAdmin('action', employeeStore.getRole, 'is-admin') &&
                                            formShow !== 3 && <button onClick={() => {
                                                setFormShow(3)
                                            }} type='button' className='btn btn-md btn-primary'>
                                              <span className='indicator-label'>
                                                {intl.formatMessage({id: "EDIT_BUTTON"})}
                                              </span>
                                            </button>
                                        }
                                    </div>
                                    <KTCardBody>
                                        {
                                            formShow === 3 ? (<div className='w-100'>
                                                    <div className='w-100'>

                                                        <div className='d-flex'>
                                                            <label
                                                                className='form-label mb-3 col-lg-3'>{intl.formatMessage({id: "cashbox_logo"})}</label>
                                                            <div className='d-flex align-items-center col-lg-9'>
                                                                <div className='logo-form-upload mb-3 w-126px h-126px'>
                                                                    {readFile ? <img alt={''} className='w-100 h-100'
                                                                                     src={readFile}/> : initValues.logoId ?
                                                                        <img alt={''} className='w-100 h-100'
                                                                             src={Company.getFiles(typeof initValues?.logoId === 'string' ? initValues?.logoId : '')}/> :
                                                                        <img alt={''} className='w-100 h-100'
                                                                             src="https://via.placeholder.com/150/FAFAFA/000000/?text=Пусто"/>}
                                                                </div>

                                                                <DropImages {...props}
                                                                            setFile={(file: any) => setReadFile(file)}
                                                                            fieldName='logoId'/>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='w-100'>
                                                        <div className='mb-10 w-100'>
                                                            <div className='d-flex'>
                                                                <label
                                                                    className='form-label mb-3 required col-lg-3'>{intl.formatMessage({id: "cashbox__name"})}</label>
                                                                <div className='col-lg-9'>
                                                                    <Field
                                                                        type='text'
                                                                        className='form-control form-control-lg'
                                                                        name='name'
                                                                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                                            if (!e.currentTarget.value.length || e.currentTarget.value.match(textPattern)) {
                                                                                props.handleChange(e)
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='d-flex'>
                                                                <div className='col-lg-3'/>
                                                                <div className='text-danger mt-2'>
                                                                    <ErrorMessage name='name'/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='mb-10 w-100'>
                                                            <div className='d-flex'>
                                                                <label
                                                                    className='form-label mb-3 required col-lg-3'>{intl.formatMessage({id: "legal_name"})}</label>
                                                                <div className='col-lg-9'>
                                                                    <Field
                                                                        type='text'
                                                                        className='form-control form-control-lg'
                                                                        name='legalName'
                                                                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                                            if (!e.currentTarget.value.length || e.currentTarget.value.match(textPattern)) {
                                                                                props.handleChange(e)
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='d-flex'>
                                                                <div className='col-lg-3'/>
                                                                <div className='text-danger mt-2'>
                                                                    <ErrorMessage name='legalName'/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='mb-10 w-100'>
                                                            <div className='d-flex'>
                                                                <label
                                                                    className='form-label mb-3 required col-lg-3'>{intl.formatMessage({id: "pinfl"})}</label>
                                                                <div className='col-lg-9'>
                                                                    <Field
                                                                        type='text'
                                                                        className='form-control form-control-lg'
                                                                        name='pinfl'
                                                                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                                            if (e.currentTarget.value.match(/^\d{0,14}$/)) props.handleChange(e)
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='d-flex'>
                                                                <div className='col-lg-3'/>
                                                                <div className='text-danger mt-2'>
                                                                    <ErrorMessage name='pinfl'/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='mb-10 w-100'>
                                                            <div className='d-flex'>
                                                                <label
                                                                    className='form-label mb-3 required col-lg-3'>{intl.formatMessage({id: "gnk_spic"})}</label>
                                                                <div className='col-lg-9'>
                                                                    <Field
                                                                        type='text'
                                                                        className='form-control form-control-lg'
                                                                        name='gnkSpic'
                                                                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                                            props.setFieldValue('gnkSpic', e.currentTarget.value.replace(/[^\d]/g, ''))
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='d-flex'>
                                                                <div className='col-lg-3'/>
                                                                <div className='text-danger mt-2'>
                                                                    <ErrorMessage name='gnkSpic'/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='mb-10 w-100'>
                                                            <div className='d-flex'>
                                                                <label className='form-label mb-3 required col-lg-3'>{intl.formatMessage({id: "package_code"})}</label>
                                                                <div className='col-lg-9'>
                                                                    <Field
                                                                        type='tel'
                                                                        className='form-control form-control-lg'
                                                                        name='packageCode'
                                                                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                                            props.setFieldValue('packageCode', e.currentTarget.value.replace(/[^\d]/g, ''))
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='d-flex'>
                                                                <div className='col-lg-3'/>
                                                                <div className='text-danger mt-2'>
                                                                    <ErrorMessage name='packageCode'/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {roleRoutingAdmin('action', employeeStore.getRole, 'edit-nds-rate') &&
                                                            <div className='mb-10 w-100'>
                                                                <div className='d-flex'>
                                                                    <label
                                                                        className='form-label required mb-3 col-lg-3'>{intl.formatMessage({id: "nds_rate"})}</label>
                                                                    <div className='col-lg-9'>
                                                                        <Field
                                                                            type='number'
                                                                            className='form-control form-control-lg'
                                                                            name='ndsRate'
                                                                            onChange={props.handleChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className='d-flex'>
                                                                    <div className='col-lg-3'/>
                                                                    <div className='text-danger mt-2'>
                                                                        <ErrorMessage name='ndsRate'/>
                                                                    </div>
                                                                </div>
                                                            </div>}
                                                        <div className='mb-10 w-100'>
                                                            <div className='d-flex'>
                                                                <label
                                                                    className='form-label mb-3 required col-lg-3'>{intl.formatMessage({id: 'min_amount'})}</label>
                                                                <div className='col-lg-9'>
                                                                    <Field
                                                                        type='text'
                                                                        name='minAmount'
                                                                        placeholder=''
                                                                        className='form-control form-control-lg'
                                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                            setFieldValue('minAmount', e.target.value.replace(/ /g, '').replace(/[^0-9]/g, '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "))
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='text-danger mt-2'>
                                                                <ErrorMessage name='minAmount'/>
                                                            </div>
                                                        </div>

                                                        <div className='mb-10 w-100'>
                                                            <div className='d-flex'>
                                                                <label
                                                                    className='form-label required mb-3 col-lg-3'>{intl.formatMessage({id: 'max_amount'})}</label>
                                                                <div className='col-lg-9'>
                                                                    <Field
                                                                        type='text'
                                                                        name='maxAmount'
                                                                        className='form-control form-control-lg'
                                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                            setFieldValue('maxAmount', e.target.value.replace(/ /g, '').replace(/[^0-9]/g, '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "))
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='text-danger mt-2'>
                                                                <ErrorMessage name='maxAmount'/>
                                                            </div>
                                                        </div>
                                                        <div className='mb-10 w-100'>
                                                            <div className='d-flex'>
                                                                <label
                                                                    className='form-label mb-3 col-lg-3'>HUMO {intl.formatMessage({id: 'terminal_id'})}</label>
                                                                <div className='col-lg-9'>
                                                                    <Field
                                                                        type='text'
                                                                        name='humoTid'
                                                                        className='form-control form-control-lg'
                                                                    />
                                                                </div>
                                                                <div className='text-danger mt-2'>
                                                                    <ErrorMessage name='humoTid'/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='mb-10 w-100'>
                                                            <div className='d-flex'>
                                                                <label
                                                                    className='form-label mb-3 col-lg-3'>HUMO {intl.formatMessage({id: 'merchant_id'})}</label>
                                                                <div className='col-lg-9'>
                                                                    <Field
                                                                        type='text'
                                                                        name='humoMid'
                                                                        className='form-control form-control-lg'
                                                                    />
                                                                </div>
                                                                <div className='text-danger mt-2'>
                                                                    <ErrorMessage name='humoMid'/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='mb-10 w-100'>
                                                            <div className='d-flex'>
                                                                <label
                                                                    className='form-label mb-3 col-lg-3'>UZCARD {intl.formatMessage({id: 'terminal_id'})}</label>
                                                                <div className='col-lg-9'>
                                                                    <Field
                                                                        type='text'
                                                                        name='uzcardTid'
                                                                        className='form-control form-control-lg'

                                                                    />
                                                                </div>
                                                                <div className='text-danger mt-2'>
                                                                    <ErrorMessage name='uzcardTid'/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='mb-10 w-100'>
                                                            <div className='d-flex'>
                                                                <label
                                                                    className='form-label mb-3 col-lg-3'>UZCARD {intl.formatMessage({id: 'merchant_id'})}</label>
                                                                <div className='col-lg-9'>
                                                                    <Field
                                                                        type='text'
                                                                        name='uzcardMid'
                                                                        className='form-control form-control-lg'

                                                                    />
                                                                </div>
                                                                <div className='text-danger mt-2'>
                                                                    <ErrorMessage name='uzcardMid'/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* === */}
                                                        <div className='mb-10 w-100'>
                                                            <div className='d-flex'>
                                                                <label
                                                                    className='form-label mb-3 col-lg-3'>{intl.formatMessage({id: "service_code"})}</label>
                                                                <div className='col-lg-9'>
                                                                    <Field
                                                                        as="select"
                                                                        name="serviceCode"
                                                                        className='form-control form-control-lg'
                                                                        onChange={(e: React.FormEvent<HTMLSelectElement>) => {
                                                                            props.setFieldValue('serviceCode', e.currentTarget.value)
                                                                        }}
                                                                    >
                                                                            {serviceCodeOptions.map((option) => (
                                                                                <option key={option.value} value={option.value}>
                                                                                    {intl.formatMessage({id: option.label})}
                                                                                </option>
                                                                            ))}
                                                                    </Field>
                                                                </div>
                                                            </div>
                                                            <div className='d-flex'>
                                                                <div className='col-lg-3'/>
                                                                <div className='text-danger mt-2'>
                                                                    <ErrorMessage name='serviceCode'/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* === */}
                                                        <div className='mb-10 w-100'>
                                                            <div className='d-flex'>
                                                                <label
                                                                    className='form-label mb-3 col-lg-3'>{intl.formatMessage({id: "url"})}</label>
                                                                <div className='col-lg-9'>
                                                                    <Field
                                                                        type='text'
                                                                        className='form-control form-control-lg'
                                                                        name='billingUrl'
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='d-flex'>
                                                                <div className='col-lg-3'/>
                                                                <div className='text-danger mt-2'>
                                                                    <ErrorMessage name='billingUrl'/>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            ) : (
                                                <div>
                                                    {loaded ? requisites.map(({
                                                                                  label,
                                                                                  value
                                                                              }) => (
                                                        <div key={label} className='d-flex align-items-center mb-5'>
                                                            <p className='mb-0 text-gray-600 fs-6 col-lg-4'>{intl.formatMessage({id: label})}</p>
                                                            <p className='mb-0 text-gray-800 fw-bold fs-5'>{value}</p>
                                                        </div>
                                                    )) : null}
                                                    {roleRoutingAdmin('action', employeeStore.getRole, 'is-admin') && <>
                                                        <div className='d-flex align-items-center mb-5'>
                                                            <p className='mb-0 text-gray-600 fs-6 col-lg-4'>HUMO {intl.formatMessage({id: 'terminal_id'})}</p>
                                                            <p className='mb-0 text-gray-800 fw-bold fs-5'>{values.humoTid}</p>
                                                        </div>
                                                        <div className='d-flex align-items-center mb-5'>
                                                            <p className='mb-0 text-gray-600 fs-6 col-lg-4'>HUMO {intl.formatMessage({id: 'merchant_id'})}</p>
                                                            <p className='mb-0 text-gray-800 fw-bold fs-5'>{values.humoMid}</p>
                                                        </div>
                                                        <div className='d-flex align-items-center mb-5'>
                                                            <p className='mb-0 text-gray-600 fs-6 col-lg-4'>UZCARD {intl.formatMessage({id: 'terminal_id'})}</p>
                                                            <p className='mb-0 text-gray-800 fw-bold fs-5'>{values.uzcardTid}</p>
                                                        </div>
                                                        <div className='d-flex align-items-center mb-5'>
                                                            <p className='mb-0 text-gray-600 fs-6 col-lg-4'>UZCARD {intl.formatMessage({id: 'merchant_id'})}</p>
                                                            <p className='mb-0 text-gray-800 fw-bold fs-5'>{values.uzcardMid}</p>
                                                        </div>
                                                    </>}
                                                    <div className='d-flex align-items-center mb-5'>
                                                        <p className='mb-0 text-gray-600 fs-6 col-lg-4'>{intl.formatMessage({id: 'url'})}</p>
                                                        <a href={values.billingUrl} target={'_blank'} rel={'noreferrer'}
                                                           className='mb-0 text-gray-800 fw-bold fs-5 text-hover-primary'>{values.billingUrl}</a>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </KTCardBody>

                                    {
                                        formShow === 3 ? (
                                            <div className='card-footer py-5 px-10 d-flex justify-content-end'>
                                                <button className='btn btn-light me-3'
                                                        onClick={() => setFormShow(0)}>{intl.formatMessage({id: 'Cancel'})}</button>
                                                <button className='btn btn-primary' type='submit'
                                                        onClick={() => {
                                                            let err_message = ''
                                                            if (Object.keys(errors).length) {
                                                                for (let err in errors) {
                                                                    //@ts-ignore
                                                                    err_message += `* ${intl.formatMessage({id: err})} - ${errors[err]} ` + "\n"
                                                                }
                                                                setError({
                                                                    status: '',
                                                                    message: err_message,
                                                                    isError: true
                                                                })
                                                            }
                                                        }}>{intl.formatMessage({id: 'SAVE_EDITION'})}</button>
                                            </div>) : ''
                                    }
                                </KTCard>


                            </Form>
                        )
                    }}
                </Formik>
            </div>
            {
                (roleRoutingAdmin('action', employeeStore.getRole, 'is-admin') ||
                    roleRouting('filial/delete', employeeStore.getRole)) ? (<div className="card mt-10">
                    <div className="card-header border-0 cursor-pointer" role="button"
                         data-bs-toggle="collapse" data-bs-target="#kt_account_deactivate"
                         aria-expanded="true" aria-controls="kt_account_deactivate">
                        <div className="card-title m-0">
                            <h3 className="fw-bold m-0">{intl.formatMessage({id: "DELETE_CASHBOX"})}</h3>
                        </div>
                    </div>
                    <div id="kt_account_settings_deactivate" className="collapse show">
                        <div id="kt_account_deactivate_form" className="form">
                            <div className="card-body border-top p-9">
                                <div
                                    className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6">
                                              <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
																<rect opacity="0.3" x="2" y="2" width="20" height="20"
                                                                      rx="10" fill="currentColor"/>
																<rect x="11" y="14" width="7" height="2" rx="1"
                                                                      transform="rotate(-90 11 14)"
                                                                      fill="currentColor"/>
																<rect x="11" y="17" width="2" height="2" rx="1"
                                                                      transform="rotate(-90 11 17)"
                                                                      fill="currentColor"/>
															</svg>
														</span>
                                    <div className="d-flex flex-stack flex-grow-1">
                                        <div className="fw-semibold">
                                            <h4 className="text-gray-900 fw-bold">{intl.formatMessage({id: "DELETE_CASHBOX1"})}</h4>
                                            <div
                                                className="fs-6 text-gray-700">{intl.formatMessage({id: "WARNING_CASHBOX"})}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-check form-check-solid fv-row">
                                    <input onChange={(e) => {
                                        setDeactivate(e.target.checked)
                                    }} name="deactivate" className="form-check-input" type="checkbox"
                                           value="" id="deactivate"/>
                                    <label
                                        className="form-check-label fw-semibold ps-2 fs-6">{intl.formatMessage({id: "confirm"})}</label>
                                </div>
                            </div>
                            <div className="card-footer d-flex justify-content-end py-6 px-9">
                                {
                                    isDemo() ? (<Tooltip zIndex={1} color='var(--kt-body-bg)'
                                                         title={() => <span
                                                             style={{color: 'var(--kt-text-gray-600)'}}>{intl.formatMessage({id: "DEMO_DISABLED"})}</span>}>
                                        <div>
                                            <button
                                                id="kt_account_deactivate_account_submit"
                                                type="button"
                                                className="btn btn-danger fw-semibold"
                                                disabled={!deactivate || isDemo()}>
                                                {intl.formatMessage({id: "DELETE_CASHBOX"})}
                                            </button>
                                        </div>
                                    </Tooltip>) : (<button
                                        id="kt_account_deactivate_account_submit"
                                        type="button"
                                        className="btn btn-danger fw-semibold"
                                        disabled={!deactivate}
                                        onClick={() => {
                                            setShowDeleteModal(true)
                                        }}>
                                        {intl.formatMessage({id: "DELETE_CASHBOX"})}
                                    </button>)
                                }

                            </div>
                        </div>
                    </div>
                </div>) : ''
            }

            <DeleteCompanyModal onCancel={onHideDeleteModal}
                                setIsLoading={(val: boolean) => setLoading(val)}
                                onDelete={() => {
                                    setShowDeleteModal(false)
                                    deleteItem(id ?? '')
                                }}
                                shouldShow={showDeleteModal}/>
        </div>
    )
})

type DeleteCompanyModal = {
    onCancel: Function
    setIsLoading: (val: boolean) => void,
    onDelete: Function,
    shouldShow: boolean
}


export const DeleteCompanyModal: FC<DeleteCompanyModal> = ({
                                                               onCancel,
                                                               setIsLoading,
                                                               onDelete,
                                                               shouldShow
                                                           }) => {
    const {setError} = useErrors()


    const handleClose = () => {
        onCancel()
    }

    const intl = useIntl()

    const handleDelete = async () => {
        try {
            setIsLoading(true)
            onDelete()
        } catch (error: Error | any) {
            setError({
                status: error?.response?.status,
                message: error?.response?.data?.message,
                isError: true
            })
        } finally {
            setIsLoading(false)
        }
    }


    return <Modal show={shouldShow} onHide={handleClose}>
        <Modal.Header closeButton>
            <h2>{intl.formatMessage({id: "CHECK_DELETE"})}</h2>
        </Modal.Header>
        <Modal.Body>
            <div className='fs-3'>{intl.formatMessage({id: "DELETE_APPROVE_CASHBOX"})}</div>
        </Modal.Body>
        <Modal.Footer className='justify-content-between'>
            <button type="button" className="swal2-confirm btn btn-secondary" aria-label=""
                    style={{display: 'inline-block'}} onClick={handleClose}>
                {intl.formatMessage({id: "CANCEL_BUTTON"})}
            </button>
            <button onClick={handleDelete} className="swal2-confirm btn btn-danger" aria-label=""
                    style={{display: 'inline-block'}}>
                {intl.formatMessage({id: "delete"})}
            </button>
        </Modal.Footer>
    </Modal>
}

export default function FilialSettingsMerchant() {
    const intl = useIntl()
    return (
        <ErrorProvider>
            <PageTitle shouldShowDemoWarning={false}>{intl.formatMessage({id: "settings"})}</PageTitle>
            <Settings/>
        </ErrorProvider>
    )
}