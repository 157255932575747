import {FC} from 'react'
import moment from "moment/moment";
import {EmployeeLogs} from "@providers/employee/_models";
import {useIntl} from "react-intl";

type Props = {
  logs: EmployeeLogs
}

const UserLastLoginCell: FC<Props> = ({logs}) => {

  const intl = useIntl()


  function parserLogs(){

      const objKeys = Object.keys(logs.changed_from||logs.changed_to)

      if(logs.entity==='EMPLOYEE_BRANCHES'&&(Array.isArray(logs?.changed_from)||Array.isArray(logs?.changed_to))){
          let filial = ''
          let action = ''

          if(!logs?.changed_from.length&&logs?.changed_to.length){
              filial = logs?.changed_to.join(', ')
              action = intl.formatMessage({id: 'ADD_TO_FILIAL'})
          }else if(logs?.changed_from.length&&logs?.changed_to.length){
              logs?.changed_to.forEach((item:any, index:number, arr: any)=>{
                  if(!logs?.changed_from.includes(item)){
                      filial += index!==arr.length-1?`${item}, `: `${item} `
                      action = intl.formatMessage({id: 'ADD_TO_FILIAL'})
                  }
              })
          }

          if(logs?.changed_from.length&&!logs?.changed_to.length){
              filial = logs?.changed_from.join(', ')
              action = intl.formatMessage({id: 'DELETED_FROM_FILIAL'})
          }else if(logs?.changed_from.length&&logs?.changed_to.length){
              logs?.changed_from.forEach((item:any, index:number, arr: any)=>{
                  if(!logs?.changed_to.includes(item)){
                      filial += index!==arr.length-1?`${item}, `: `${item} `
                      action = intl.formatMessage({id: 'DELETED_FROM_FILIAL'})
                  }
              })
          }


          return (
              <>
                  <p>{action}</p>
                  <p>id: {filial}</p>
              </>
          )
      }


    let test:any[] = []

    test =  objKeys.map((item:string)=>{

      if(!item.match('date')){

        if(logs?.changed_from[item]!==logs?.changed_to[item]){

          return (
              <>
                <p>{intl.formatMessage({id: 'CHANGED'})}</p>
                <p>{intl.formatMessage({id:item})}</p>
                <p><span style={{color: 'var(--bs-danger)'}}>{`${logs.changed_from[item]} -> `}</span> <span style={{color: 'var(--bs-success)'}}>{logs.changed_to[item]}</span></p>
              </>
          )
        }
      }


    })

    return test
  }

  function switchLogsActions(){
    if(logs.changed_from&&logs.changed_to){
      return parserLogs()
    }

    return ''
  }



  return (
      <div className='fw-bolder'>{switchLogsActions()}</div>
  )
}

export {UserLastLoginCell}
