import {Navigate, useOutletContext, useParams} from "react-router-dom";
import {PageTitle} from "@metronic/layout/core";
import {Nav} from "react-bootstrap";
import React, {useState} from "react";

//Components

import {observer} from "mobx-react";
import {employeeStore} from "@store/employee";
import {roleRouting, roleRoutingAdmin} from "@utils/index";
import EmployeesLogs from "./logs";
import {useIntl} from "react-intl";
import {ADMIN} from "@utils/constants";




const EmployeeView = observer(()=>{
    const {id} = useParams ()
    const [activeKey, setActiveKey] = useState<string|null>('logs')
    const {companyId} = useParams()
    const intl = useIntl()

    const switchTabs = ()=>{
        switch(activeKey){
            case 'logs':
                return <EmployeesLogs />
        }
    }

    const switchPageTitle = ()=>{
        switch(activeKey){
            case 'logs':
                return intl.formatMessage({id: 'LOGS_ACTION'})
        }
    }
    return (
        <>
            <PageTitle breadcrumbs={[]}>{switchPageTitle()}</PageTitle>
            {/*<Nav fill variant="tabs" defaultActiveKey="statistics" onSelect={(e)=>setActiveKey(e)}>*/}
            {/*    {*/}
            {/*        roleRouting('employee/logs', employeeStore.getRole)&& <Nav.Item>*/}
            {/*            <Nav.Link eventKey="logs">{intl.formatMessage({id: 'LOGS_ACTION'})}</Nav.Link>*/}
            {/*        </Nav.Item>*/}
            {/*    }*/}
            {/*</Nav>*/}

            <div className='mt-5'>{switchTabs()}</div>
        </>
    )
})


export default function EmployeeViewWrapper(){
    //@ts-ignore
    const {role} = useOutletContext()


    if(roleRouting('employees/view', role)||roleRoutingAdmin('page', role, 'employees-view')){
        return (
            <>
                <EmployeeView />
            </>
        )
    }

    return <Navigate to='/error/404'/>

}