/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from "react-router-dom";
import {observer} from "mobx-react";
import {useIntl} from "react-intl";
import {roleRoutingAdmin} from "@utils/index";
import {employeeStore} from "@store/employee";

type Props = {
    id: number
    text: string
}

const AgentLink: FC<Props> = observer(({id, text}) => {


    if(roleRoutingAdmin('action', employeeStore.getRole, 'link-agent')){
        return (
            <div className='fs-6 text-gray-600 fw-normal'>
                <Link to={`/agents/view/${id}`} className='text-gray-600'>
                    {text}
                </Link>
            </div>
        )
    }

    return (
        <div className='fs-6 text-gray-600 fw-normal'>
            <span>
                {text}
            </span>
        </div>
    )



})

export {AgentLink}
