import React from 'react';
import {Row} from "react-table";
import {IWebPayment, IWebPaymentSend} from "@providers/webPayments/models";
import clsx from "clsx";

interface props {
    row: Row<IWebPaymentSend>
}

const CustomRow = ({row}: props) => {
    return (
        <tr {...row.getRowProps()}>
            {row.cells.map((cell) => {
                return (
                    <td
                        {...cell.getCellProps()}
                        className={clsx({'text-end min-w-100px': cell.column.id === 'actions'})}
                    >
                        {cell.render('Cell')}
                    </td>
                )
            })}
        </tr>
    );
};

export default CustomRow;