// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserLastLoginCell} from './UserLastLoginCell'
import {TableCustomHeader} from './TableCustomHeader'
import {EmployeeLogs} from "@providers/employee/_models";
import {EmployeeLogsActions} from "./EmployeeLogsEctions";
import {EmployeeLogsDate} from "./EmployeeLogsDate";
import {useIntl} from "react-intl";


const usersColumns: ReadonlyArray<Column<EmployeeLogs>> = [
  {
    Header: (props) =>{
      const intl = useIntl()
      return <TableCustomHeader tableProps={props} title={intl.formatMessage({id: 'ENTITY'})} className='min-w-125px' />
    },
    id: 'entity',
    Cell: ({...props}) => <UserInfoCell entity={props.data[props.row.index].entity} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return <TableCustomHeader tableProps={props} title={intl.formatMessage({id: 'ACTIONS'})} className='min-w-125px'/>
    },
    id: 'actions',
    Cell: ({...props}) =><EmployeeLogsActions logs={props.data[props.row.index]}/>

  },
  {
    Header: (props) => {
      const intl = useIntl()
      return <TableCustomHeader tableProps={props} title={intl.formatMessage({id: 'EDITED'})} className='min-w-125px'/>
    },
    id: 'changed',
    Cell: ({...props}) => <UserLastLoginCell logs={props.data[props.row.index]}/>

  },
  {
    Header: (props) => {
      const intl = useIntl()
      return <TableCustomHeader tableProps={props} title={intl.formatMessage({id: 'DATE'})} className='min-w-125px' />
    },
        id: 'created_date',
      Cell: ({...props}) => <EmployeeLogsDate created_date={props.data[props.row.index].created_date} />,
  },
]

export {usersColumns}
