import { useIntl } from "react-intl"
import {useErrors} from "@providers/context/ErrorsProvider";
import {useCallback} from "react";
import {FILE_SIZE, SUPPORTED_FORMATS} from "@utils/constants";
import {useDropzone} from "react-dropzone";
import {ConvertToBase64} from "@utils/index";


export function DropImages(props:any){
    const intl = useIntl()
    const {setError} = useErrors()


    const onDrop = useCallback(async(acceptedFiles:any) => {

        if(acceptedFiles.length) {

            const size_kb = +(acceptedFiles[0].size/ 1024).toFixed(1)
            if(size_kb>FILE_SIZE){
                return setError({status: 0, message: intl.formatMessage(({id:'ERROR_FILE_SIZE'})), isError: true})
            }
            if(!SUPPORTED_FORMATS.includes(acceptedFiles[0].type)){
                return setError({status: 0, message: intl.formatMessage(({id:'ERROR_FILE_TYPE'})), isError: true})
            }

            if(props.setFile){
                props.setFile(await ConvertToBase64(acceptedFiles[0]))
            }

            props.setFieldValue(props.fieldName, acceptedFiles[0])

        }
        else props.setFieldValue(props.fieldName, undefined)
    }, [])

    const {getRootProps, getInputProps} = useDropzone({onDrop, multiple:false})

    return(
        <div className="dropzone" {...getRootProps()} style={{flexGrow:1}}>
            <input {...getInputProps()} />
            <div className="dz-message needsclick position-relative flex-column justify-content-center align-items-center">
                                                <span className="svg-icon svg-icon-3hx svg-icon-primary">
                                                   <img src='/media/svg/upload-info.svg'/>
                                                </span>
                <div className="ms-4" style={{marginTop:'12px'}}>
                    <h3 className="dfs-3 text-gray-600 mb-1 text-center" style={{fontWeight:'400', lineHeight:'1.6'}}>
                        <span className='text-primary fw-bold'>Click to upload </span>
                        or drag and drop <br/>
                        SVG, PNG, JPG or GIF (max. 800x400px)
                    </h3>
                </div>
            </div>
            <div className='text-danger mt-2'>
                {/*<ErrorMessage name='logotype' />*/}
            </div>
        </div>
    )
}