import {Table} from "./_modals/Table";
import {KTCard} from "@metronic/helpers";
import {UsersListHeader} from "./_modals/table-components/header/UsersListHeader";
import React, {useState} from "react";
import {ErrorProvider} from "@providers/context/ErrorsProvider";
import {observer} from "mobx-react";
import {useIntl} from "react-intl";
import {PageTitleCustom} from "@metronic/partials/pageTitleCustom";
import {useParams} from "react-router-dom";
import {TableMerchants} from "./_modals/TableMerchants";


const AgentPage = () => {

    return (
        <>

            <KTCard>
                <Table />
            </KTCard>

        </>
    )
}

const AgentPageMerchant = () => {

    return (
        <>

            <KTCard>
                <TableMerchants />
            </KTCard>

        </>
    )
}





const AgentStatisticPage = observer(() => {
    const intl = useIntl()
    const {path} = useParams()

    if(path==='statistic'){
        return(
            <ErrorProvider>
                <PageTitleCustom title={intl.formatMessage({id:'DASHBOARD_CHART_TITLE'})} breadCrumbs={[]}/>
                <AgentPage />
            </ErrorProvider>
        )
    }

    return(
        <ErrorProvider>
            <PageTitleCustom title={intl.formatMessage({id:'agent_merchants_tab'})} breadCrumbs={[]}/>
            <AgentPageMerchant />
        </ErrorProvider>
    )

})

export {AgentStatisticPage}



