import {Column} from "react-table";
import Header from "./header";
import IdCell from "./idCell";
import {ISchedule} from "@providers/autopay/models/_models";
import {TableText} from "../../../contractsList/_modals/columns/TableText";
import PaymentStatus from "./paymentStatus";
import {divideByHundred} from "@utils/numbersOperations";
import {priceformatter} from "@utils/priceformatter";


const columns: ReadonlyArray<Column<ISchedule>> = [
    {
        Header: (props) => <Header tableProps={props} title={'№'} className='min-w-30px fw-bold text-gray-700'/>,
        id: 'num',
        Cell: ({...props}) => <IdCell text={props.data[props.row.index]?.num}/>,
    },
    {
        Header: (props) => <Header tableProps={props} title={'date_payments'} translate={true} className='min-w-30px fw-bold text-gray-700'/>,
        id: 'scheduleDate',
        Cell: ({...props}) => <TableText isDate={true} text={props.data[props.row.index]?.scheduleDate || props.data[props.row.index]?.paymentDate}/>,
    },
    {
        Header: (props) => <Header tableProps={props} title={'payment_by_day'} translate={true} className='min-w-30px fw-bold text-gray-700'/>,
        id: 'paymentSum',
        Cell: ({...props}) => <TableText text={priceformatter(divideByHundred(props.data[props.row.index]?.paymentSum)).toString()}/>,
    },
    {
        Header: (props) => <Header tableProps={props} title={'STATUS'} translate={true} className='min-w-30px fw-bold text-gray-700'/>,
        id: 'STATUS',
        Cell: ({...props}) => <PaymentStatus isPaid={!!props.data[props.row.index]?.paymentDate}/>,
    }
]


export {columns}