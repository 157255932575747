import * as Yup from 'yup'
import {ICreateCompanyForm} from "@providers/company/_models";
import {FILE_SIZE, REGEXP_PHONE, SUPPORTED_FORMATS, SUPPORTED_LOGO_FORMATS} from "@utils/constants";
import {useIntl} from "react-intl";



function CompanySchema() {
    const intl = useIntl()
    let createCompanySchemas: any = Yup.object({
        name: Yup.string().max(50, 'Слишком много символов').required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
        addressRegistry: Yup.string()
            .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
            .max(500, 'Слишком много символов').required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
        brandName: Yup.string()
            .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
            .max(100, 'Слишком много символов').required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
        firstName: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
            .max(100, 'Слишком много символов').required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
        lastName: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
            .max(100, 'Слишком много символов').required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),

        phone: Yup.string().matches(
            REGEXP_PHONE,
            intl.formatMessage({id: 'ERROR_VALIDATION_PHONE'})
        ).required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
        email: Yup.string()
            .email('Не верный формат Email'),
        inn: Yup.string()
            .min(9, 'Не достаточно символов')
            .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
        logoFile: Yup.mixed().test('fileSize', intl.formatMessage({id: 'file_size'}), value => {
            if (value !== null && typeof value === 'object') {
                const size_kb = +(value.size / 1024).toFixed(1)
                return size_kb <= FILE_SIZE
            }
            return true

        }).test('fileType', intl.formatMessage({id: 'type_file'}), value => {
            if (value !== null && typeof value === 'object') {
                return value ? SUPPORTED_LOGO_FORMATS.includes(value.type) : true
            }
            return true
        })

    })


    return createCompanySchemas
}


const inits: ICreateCompanyForm = {
    addressRegistry: '',
    brandName: '',
    active: true,
    logoFile: undefined,
    inn: '',
    details: undefined,
    name: '',
    email: '',
    phone: '',
    firstName: '',
    lastName: ''
};


export {CompanySchema, inits}
