import {FC} from 'react'
import {useIntl} from "react-intl";

type Props = {
    text?: string
}

const TableText: FC<Props> = ({text}) => {
    const intl = useIntl()
    return (
        <div className='fw-bolder'>{text}</div>
    )
}

export {TableText}
