import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useErrors} from "@providers/context/ErrorsProvider";
import moment from "moment/moment";
import {useIntl} from "react-intl";
import {Filial} from "@providers/filial/_request";
import {CANCELED, DECLINED, HUMO, statuses_card, statuses_const, SUCCESSFUL, UZCARD} from "@utils/constants";
import {Statistic} from "@providers/statistic/_request";
import {NumReplace} from "@utils/index";
import {ChartWidgetBar} from "./_modals/ChartWidgetBar";
import {DatePicker} from "antd";
import {ChartsWidget} from "./_modals/ChartWidget";
import {ChartWidgetBar2} from "./_modals/ChartWidgetBar2";
import localeRu from "antd/es/date-picker/locale/ru_RU";
import localeEn from "antd/es/date-picker/locale/en_US";
import {MinusOutlined} from "@ant-design/icons";
import { formatDateRequest } from "@utils/formatDateRequest";


export  const AnalyticDashboard = ()=>{
    const [loading, setLoading] = useState(true)
    const {companyId} = useParams()
    const [cashboxes, setCashboxes] = useState<number[]>([])
    const {setError} = useErrors()
    const [statistic_data, setStatistic_data] = useState([])
    const [renderData, setRenderData] = useState<any|null>(null)
    const [renderData1, setRenderData1] = useState<any|null>(null)
    const [renderData2, setRenderData2] = useState<any|null>(null)
    const [statisticDate, setStatisticDate] = useState(moment().subtract(1, 'month').format('YYYY-MM-DD'))
    const [statisticDateTo, setStatisticDateTo] = useState(moment().subtract(1, 'day').format('YYYY-MM-DD'))
    const lang = JSON.parse(localStorage.getItem('i18nConfig') ?? '{}')?.selectedLang

    const intl = useIntl()


    useEffect(()=>{
        getFilial()
    }, [])

    useEffect(()=>{
        getData()
    }, [cashboxes, statisticDate, statisticDateTo])




    async function getFilial (){
        if(companyId){

            await Filial.getFilial(companyId)
                .then(result=>{
                    setCashboxes((result?.data?.result.content||[]).map((item:any)=>item.id))
                }).catch(err=>setError({status: err?.response?.stat, message: err?.response?.data?.message, isError: true}))
        }
    }


    async function getData(){
        if(companyId){

            setLoading(true)


            const data = {
                cashboxes,
                companyId: +companyId,
                // statuses: [CANCELED, DECLINED, SUCCESSFUL],
                // ppc_types: [HUMO, UZCARD],
                fromDate: formatDateRequest(statisticDate, "fromDate"),
                toDate: formatDateRequest(statisticDateTo, "toDate"), 
            }


            await Statistic.getStatistic(data)
                .then(result=>{
                    setStatistic_data(result?.data?.result||[])

                    let series:any[] = []

                    statuses_const.forEach((item:string)=>{
                        const name = item
                        const data = result?.data?.result.map((res:any)=>{
                            return res.statuses[item]?.sum?res.statuses[item]?.sum/100:0
                        })
                        series.push({name, data})
                    })

                    const card_data = statuses_card.map(item=>{

                        return {
                            name: item,
                            data: result?.data?.result.map((res:any)=>{
                                return res.details[item].sum?res.details[item].sum /100:0
                            })
                        }
                    })

                    const card_data2 = statuses_card.map(item=>{

                        return {
                            name: item,
                            data: result?.data?.result.map((res:any)=>{
                                return res.details[item].count??0
                            })
                        }
                    })




                    const categories:any[] = result?.data?.result.map((item:any)=>item.date)
                    setRenderData({series, categories})
                    setRenderData1({series: card_data, categories})
                    setRenderData2({series: card_data2, categories})

                }).catch(err=>setError({status: err?.response?.stat, message: err?.response?.data?.message, isError: true}))
            setLoading(false)
        }
    }

    function getCountStatuses(type:string='successful'){
        const statuses = statistic_data.map(((item:any)=>{
            return item.statuses[type]
        }))

        if(statuses.length){
            return statuses.reduce((previousValue:any, currentValue:any)=>{
                return{
                    count: previousValue.count+currentValue.count,
                    sum: (previousValue.sum+currentValue.sum)
                }
            })
        }

        return {count: 0, sum:0}

    }

    const statisticFilterHandler = (type:string, value:string)=>{

        if(type==='fromDate'&&value.length){
            setStatisticDate(value)
        }

        if(type==='toDate'&&value.length){
            setStatisticDateTo(value)
        }
    }
    return (
        <>

            <div className='mb-8'>
                <DatePicker.RangePicker
                    style={{border: "none"}}
                    separator={<MinusOutlined />}
                    locale={lang === 'ru' ? localeRu : localeEn}
                    placeholder={["Start", "End"]}
                    className='datepicker_form form-control-lg form-control-solid'
                    defaultValue={[moment(statisticDate, 'YYYY-MM-DD'), moment(statisticDateTo, 'YYYY-MM-DD')]}
                    format='YYYY-MM-DD'
                    //@ts-ignore
                    onChange={(dates: [any, any], dateStrings: [string, string])=>{
                        statisticFilterHandler('fromDate', dateStrings[0])
                        statisticFilterHandler('toDate', dateStrings[1])
                    }}
                />
            </div>
            <div className='row g-5 g-xl-8'>


                {/*<div className='col-xl-4'>*/}
                {/*    <div className='card bg-success hoverable card-xl-stretch mb-xl-8'>*/}
                {/*        <div className='card-body'>*/}
                {/*            <p className='card-title fw-bold fs-5'>{intl.formatMessage({id:'SUCCESSFUL'})}</p>*/}
                {/*            <p>кол.во: {getCountStatuses()?.count??''}</p>*/}
                {/*            <p>сумма: {getCountStatuses()?.sum?NumReplace(getCountStatuses()?.sum/100+'')+' UZS':''}</p>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className='col-xl-4'>
                    <div className='card bg-warning hoverable card-xl-stretch mb-xl-8'>
                        <div className='card-body'>
                            <p className='card-title fw-bold fs-5'>{intl.formatMessage({id:'CANCELED'})}</p>
                            <p>кол.во: {getCountStatuses('canceled')?.count??''}</p>
                            <p>сумма: {getCountStatuses('canceled')?.sum?NumReplace(getCountStatuses('canceled')?.sum/100+'')+' UZS':''}</p>
                        </div>
                    </div>
                </div>

                <div className='col-xl-4'>
                    <div className='card bg-danger hoverable card-xl-stretch mb-xl-8'>
                        <div className='card-body'>
                            <p className='card-title fw-bold fs-5'>{intl.formatMessage({id:'DECLINED'})}</p>
                            <p>кол.во: {getCountStatuses('declined')?.count??''}</p>
                            <p>сумма: {getCountStatuses('declined')?.sum?NumReplace(getCountStatuses('declined')?.sum/100+'')+' UZS':''}</p>
                        </div>
                    </div>
                </div>
            </div>

            {
                !loading&&(<div className='mt-3'>

                    <ChartsWidget className='card-xl-stretch mb-5 mb-xl-8' data={renderData}  />

                    <ChartWidgetBar className='card-xl-stretch mb-5 mb-xl-8' data={renderData1}   />
                    <ChartWidgetBar2 className='card-xl-stretch mb-5 mb-xl-8' data={renderData2}/>

                </div>)
            }
        </>
    )
}