import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useErrors} from '@providers/context/ErrorsProvider'
import {TransactionsInfo} from '@providers/adminTransactions/_request'
import {IFiscalCheque, IFiscalChequeRequest, ILabelData} from '@providers/adminTransactions/_models'
import {priceformatter} from '@utils/priceformatter'
import {divideByHundred} from '@utils/numbersOperations'
import {UsersListLoading} from '@metronic/partials/widgets/tables/_modals/components/loading/UserListLoading'

interface props {
  id: string
  companyId: string
  amount: number
  isOpen: boolean
}

const ReceiptModal: React.FC<props> = ({id, companyId, amount, isOpen}) => {
  const [open, setOpen] = useState<boolean>(false)
  const {setError} = useErrors()
  const intl = useIntl()
  const [fiscalCheque, setFiscalCheque] = useState<IFiscalCheque | undefined>()
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)

  const onOpen = () => {
    if (isOpen) setOpen(true)
  }

  const onClose = () => {
    setFiscalCheque(undefined)
    setOpen(false)
  }
  useEffect(() => {
    if (open) {
      const getTransactionCheque = async () => {
        setConfirmLoading(true)
        const data: IFiscalChequeRequest = {
          id: id,
          companyId: companyId,
        }
        try {
          const fiscalCheque: IFiscalCheque = await TransactionsInfo.getTransactionCheque(
            data
          ).then((response) => response.data.result)
          setFiscalCheque(fiscalCheque)
        } catch (err: any) {
          setIsError(true)
          setError({
            status: err?.response?.status,
            message: err?.response?.data?.message,
            isError: true,
          })
        } finally {
          setConfirmLoading(false)
        }
      }
      getTransactionCheque()
    }
  }, [open])

  const fiscalChequeData: ILabelData[] = [
    {
      label: 'ID',
      value: id,
    },
    {
      label: 'SUM',
      value: priceformatter(divideByHundred(amount)),
    },
    {
      label: 'STATUS',
      value: fiscalCheque && fiscalCheque.success ? 'success' : 'error',
    },
    {
      label: 'QR_CODE_URL',
      value: fiscalCheque ? fiscalCheque?.qrcodeUrl : '',
    },
  ]

  return (
    <>
      <button
        onClick={onOpen}
        style={{
          background: 'none',
        }}
        className={`border-0 bg-none link  ${
          isOpen ? 'text-primary' : 'text-gray-800'
        } mb-1 text-left d-flex align-items-start`}
      >
        {id}
      </button>
      <Modal show={open} onHide={onClose}>
        {confirmLoading && <UsersListLoading />}
        <Modal.Header closeButton>
          <Modal.Title>{!confirmLoading && intl.formatMessage({id: 'fiscal_cheque'})}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!confirmLoading && fiscalCheque
            ? fiscalChequeData.map(({label, value}) => (
                <div key={label} className='d-flex align-items-center mb-5'>
                  <p className='mb-0 text-gray-600 col-auto fs-6 me-3'>
                    {intl.formatMessage({id: label})}:
                  </p>
                  {label === 'QR_CODE_URL' ? (
                    <a
                      href={value}
                      target={'_blank'}
                      rel={'noreferrer'}
                      className={'mb-0 text-primary fw-bold fs-5'}
                    >
                      {intl.formatMessage({id: 'cheque'})}
                    </a>
                  ) : (
                    <p className='mb-0 text-gray-800 fw-bold fs-5'>
                      {label === 'STATUS' ? intl.formatMessage({id: value}) : value}
                    </p>
                  )}
                </div>
              ))
            : null}
          {isError && (
            <div>
              <div className='fv-plugins-message-container text-danger fs-5'>
                <span role='alert'>Чек не найден</span>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button onClick={onClose} className={'btn btn-primary'}>
            Закрыть
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ReceiptModal
