import {Column} from 'react-table'
import {TableCustomHeader} from './TableCustomHeader'
import {TableText} from "./TableText";
import {PhoneClient} from "./PhoneClient";
import {AgentDate} from "./AgentDate";
import {NumReplace} from "@utils/index";
import {IContractsResponse} from "@providers/autopay/_interfaces";
import {StatusAutopay} from "./StatusAutopay";
import {ClientsName} from "./ClientsName";

const arr_columns_clients = [
  {
    type: 'num',
    disable: false,
    renderType: 'text',
    className: 'min-w-30px'
  },
  {
    type: 'firstName',
    disable: false,
    renderType: 'fio',
    className: 'min-w-125px'
  },
  // {
  //   type: 'status',
  //   disable: false,
  //   renderType: 'status',
  //   className: 'min-w-125px'
  // },
  {
    type: 'pinfl',
    disable: false,
    renderType: 'text',
    className: 'min-w-125px'
  },
  {
    type: 'phoneNumber',
    disable: false,
    renderType: 'phone',
    className: 'min-w-125px'
  },
  // {
  //   type: 'productPrice',
  //   disable: false,
  //   renderType: 'price',
  //   className: 'min-w-125px'
  // },
  // {
  //   type: 'prepaymentPrice',
  //   disable: false,
  //   renderType: 'price',
  //   className: 'min-w-125px'
  // },
  // {
  //   type: 'discountPrice',
  //   disable: false,
  //   renderType: 'price',
  //   className: 'min-w-125px'
  // },
  // {
  //   type: 'overdueLimit',
  //   disable: false,
  //   renderType: 'price',
  //   className: 'min-w-125px'
  // },
  // {
  //   type: 'paidSum',
  //   disable: false,
  //   renderType: 'price',
  //   className: 'min-w-125px'
  // }
]


function clientsColumnsGet (columns:any[]){
  return columns.map((item:any)=>{
    if(item.renderType==='text'){
      return {
        Header: (props:any) => <TableCustomHeader tableProps={props} title={item.type} className={`fw-bold text-gray-700 ${item.className}`} />,
        id: item.type,
        Cell: ({...props}) => <TableText text={props.data[props.row.index][item.type]} translate={false}/>,
      }
    }else if(item.renderType==='fio'){
      return {
        Header: (props:any) => (
            <TableCustomHeader tableProps={props} title={item.type}  className={`fw-bold text-gray-700 ${item.className}`} />
        ),
        id: item.type,
        Cell: ({...props}) => <ClientsName data={props.data[props.row.index]} />,
      }
    }else if(item.renderType==='status'){
      return {
        Header: (props:any) => (
            <TableCustomHeader tableProps={props} title={item.type}  className={`fw-bold text-gray-700 ${item.className}`} />
        ),
        id: item.type,
        Cell: ({...props}) => <StatusAutopay status={`${props.data[props.row.index][item.type]}`} />,
      }
    }else if(item.renderType==='phone'){
      return {
        Header: (props:any) => (
            <TableCustomHeader tableProps={props} title={item.type}  className={`fw-bold text-gray-700 ${item.className}`} />
        ),
        id: item.type,
        Cell: ({...props}) => <PhoneClient text={props.data[props.row.index][item.type]}  />,
      }
    }else if(item.renderType==='price'){
      return {
        Header: (props:any) => (
            <TableCustomHeader tableProps={props} title={item.type}  className={`fw-bold text-gray-700 ${item.className}`} />
        ),
        id: item.type,
        Cell: ({...props}) => <TableText text={`${NumReplace((props.data[props.row.index][item.type]>0?props.data[props.row.index][item.type]:0)+'')} UZS`} translate={false}/>,
      }
    }else{
      return {
        Header: (props:any) => <TableCustomHeader tableProps={props} title={item.type} className={`fw-bold text-gray-700 ${item.className}`} />,
        id: item.type,
        Cell: ({...props}) => <TableText text={props.data[props.row.index][item.type]} translate={false}/>,
      }
    }


  })

}

const contractColumns: ReadonlyArray<Column<IContractsResponse>> = [

  {
    Header: (props) => <TableCustomHeader tableProps={props} title='№' className='min-w-30px fw-bold text-gray-700' />,
    id: 'num',
    Cell: ({...props}) => <TableText text={props.data[props.row.index]?.num} translate={false}/>,
  },

  {
    Header: (props) => <TableCustomHeader tableProps={props} title='contractNumber' className='min-w-125px fw-bold text-gray-700 ' />,
    id: 'contractNumber',
    Cell: ({...props}) => <TableText text={props.data[props.row.index]?.contractNumber} translate={false} />,
  },

  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='contractDate' className='min-w-125px fw-bold text-gray-700' />
    ),
    id: 'contractDate',
    Cell: ({...props}) => <AgentDate date={props.data[props.row.index].contractDate}  />,
  },
 

  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='clientPinfl' className='min-w-125px fw-bold text-gray-700' />
    ),
    id: 'clientPinfl',
    Cell: ({...props}) => <TableText text={props.data[props.row.index].clientPinfl} translate={false} />,
  },
  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='productName' className='min-w-125px fw-bold text-gray-700' />
    ),
    id: 'productName',
    Cell: ({...props}) => <TableText text={props.data[props.row.index].productName} translate={false} />,
  },
  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='clientNumber' className='min-w-125px fw-bold text-gray-700' />
    ),
    id: 'clientNumber',
    Cell: ({...props}) => <PhoneClient text={props.data[props.row.index].clientNumber}  />,
  },

  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='productPrice' className='min-w-150px fw-bold text-gray-700' />
    ),
    id: 'productPrice',
    Cell: ({...props}) => <TableText text={`${NumReplace((props.data[props.row.index].productPrice>0?props.data[props.row.index].productPrice:0)+'')} UZS`} translate={false} />,
  },
  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='prepaymentPrice' className='min-w-150px fw-bold text-gray-700' />
    ),
    id: 'prepaymentPrice',
    Cell: ({...props}) => <TableText text={`${NumReplace((props.data[props.row.index].prepaymentPrice>0?props.data[props.row.index].prepaymentPrice:0)+'')} UZS`} translate={false} />,
  },
  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='discountPrice' className='min-w-150px fw-bold text-gray-700' />
    ),
    id: 'discountPrice',
    Cell: ({...props}) => <TableText text={`${NumReplace((props.data[props.row.index].discountPrice>0?props.data[props.row.index].discountPrice:0)+'')} UZS`} translate={false} />,
  },
  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='overdueLimit' className='min-w-150px fw-bold text-gray-700' />
    ),
    id: 'overdueLimit',
    Cell: ({...props}) => <TableText text={`${NumReplace((props.data[props.row.index].overdueLimit>0?props.data[props.row.index].overdueLimit:0)+'')} UZS`} translate={false} />,
  },
  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='paidSum' className='min-w-150px fw-bold text-gray-700' />
    ),
    id: 'paidSum',
    Cell: ({...props}) => <TableText text={`${NumReplace((props.data[props.row.index].paidSum>0?props.data[props.row.index].paidSum:0)+'')} UZS`} translate={false} />,
  },
  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='status' className='min-w-150px fw-bold text-gray-700' />
    ),
    id: 'status',
    Cell: ({...props}) => <TableText text={`${props.data[props.row.index].status}`} translate={false} />,
  },

]




export {contractColumns, clientsColumnsGet, arr_columns_clients}
