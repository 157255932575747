import {ErrorProvider, useErrors} from "@providers/context/ErrorsProvider";
import {ErrorMessage, Field, Form, Formik} from "formik";
import React, {useEffect, useState} from "react";
import {Account} from "@providers/account/_request";
import {AccountMe, AccountPut} from "@providers/account/_models";
import * as Yup from "yup";
import Alert from "react-bootstrap/Alert";
import {useIntl} from "react-intl";
import {PageTitle} from "@metronic/layout/core";
import {Accordion} from "react-bootstrap";
import clsx from "clsx";
import {company} from "@store/company";
import {observer} from "mobx-react";
import {isDemo} from "@utils/index";
import {Tooltip} from "antd";
import {useAuth} from "../../../modules/auth";
import {openNotificationSuccess} from "@metronic/partials/notification";


const account: AccountPut = {
    first_name: '',
    last_name: '',
    middle_name: ''
}
const AccountPage = observer(() => {
    const [initValues, setInitValues] = useState(account)
    const [me, setMe] = useState<AccountMe>()
    const {setError} = useErrors()
    const [alertSuccess, setAlertSuccess] = useState(false)
    const intl = useIntl()
    const [hiddenPass, setHiddenPass] = useState(true)
    const [password, setPassword] = useState('')
    const [passwordRepeat, setPasswordRepeat] = useState('')
    const [showPasswordError, setShowPasswordError] = useState(false)
    const [passLastDate, setPassLastDate] = useState<Date>(new Date())
    const {logout} = useAuth()

    const accountSettings = Yup.object({
        first_name: Yup.string()
            .max(45, intl.formatMessage({id: "ERROR_VALIDATION_SYMBOLS_MAX"})).required(intl.formatMessage({id: "ERROR_VALIDATION_REQUIRED"})),
        last_name: Yup.string()
            .max(45, intl.formatMessage({id: "ERROR_VALIDATION_SYMBOLS_MAX"})).required(intl.formatMessage({id: "ERROR_VALIDATION_REQUIRED"})),
        middle_name: Yup.string()
            .max(45, intl.formatMessage({id: "ERROR_VALIDATION_SYMBOLS_MAX"})).required(intl.formatMessage({id: "ERROR_VALIDATION_REQUIRED"})),
    })


    const onReset = () => {
        setPassword('')
        setPasswordRepeat('')
    }

    useEffect(() => {
        accountGet()
    }, [])


    useEffect(() => {
        setPassLastDate(me?.password_changed_date ? new Date(me?.password_changed_date) : new Date())
    }, [me])


    const onSub = (values: AccountPut) => {
        if (isDemo()) return
        accountPut(values)
    }

    const accountGet = () => {
        Account.getMe()
            .then((result) => {
                setMe(result.data.result)
                setInitValues(result.data.result)
            }).catch(err => {
            setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
        })
    }

    const accountPut = (values: AccountPut) => {
        Account.putMe(values)
            .then((result) => {
                setAlertSuccess(true)
                window.scroll({top: 0, behavior: 'smooth'})
                company.setMe({...company.getCompany, ...values})
                setTimeout(() => {
                    setAlertSuccess(false)
                }, 3000)
            }).catch(err => {
            setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
        })
    }
    const savePassword = async (e: any) => {
        if (isDemo()) return;
        e.preventDefault()
        if (password !== passwordRepeat) {
            setShowPasswordError(true)
        } else if (password.length < 8) {
            setShowPasswordError(true)
        } else {
            setShowPasswordError(false)
            try {
                const {data: {result}} = await Account.putPassword(password)
                openNotificationSuccess(intl.formatMessage({id: 'passwordChanged'}))
                onReset()
            } catch (err: any) {
                setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
            }
        }
    }


    return (
        <>
            <Accordion defaultActiveKey={["0", "1"]} alwaysOpen>
                <Accordion.Item eventKey="0" className="mb-5">
                    <Accordion.Header>{me?.has_password ? "Смена пароля" : "Установить пароль"}</Accordion.Header>
                    <Accordion.Body className='bg-body rounded w-100 me-9'>
                        {me?.password_changed_date && <div className='form-label mb-3'>Последняя дата смены
                            пароля: {`${passLastDate?.toLocaleDateString()} - ${passLastDate?.getHours() > 9 ? passLastDate?.getHours() : `0${passLastDate?.getHours()}`}:${passLastDate?.getMinutes() > 9 ? passLastDate?.getMinutes() : `0${passLastDate?.getMinutes()}`}:${passLastDate?.getSeconds() > 9 ? passLastDate?.getSeconds() : `0${passLastDate?.getSeconds()}`}`}</div>}
                        <form className="w-100 bg-body me-9 px-10 pt-10 pb-5">
                            {alertSuccess && (
                                <Alert className='position-fixed' style={{zIndex: "3", top: "16%"}}
                                       key={'success'}
                                       variant={'success'}>
                                    {intl.formatMessage({id: "alert_success"})}
                                </Alert>
                            )}
                            {/*<div className="w-lg-75">*/}
                            <div className="w-100">


                                <div className='fv-row mb-10'>
                                    <label className='form-label mb-3 required'>
                                        {intl.formatMessage({id: 'ENTER_PASSWORD'})}
                                    </label>
                                    <div
                                        className='d-flex align-items-center justify-content-end position-relative'>
                                        <input
                                            type={hiddenPass ? "password" : "text"}
                                            value={password}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid'
                                            )}
                                            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                setPassword(e.currentTarget.value)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='fv-row mb-10'>
                                    <label className='form-label mb-3 required'>
                                        {intl.formatMessage({id: 'REPEAT_PASSWORD'})}
                                    </label>
                                    <div
                                        className='d-flex align-items-center justify-content-end position-relative'>
                                        <input
                                            type={hiddenPass ? "password" : "text"}
                                            value={passwordRepeat}
                                            className={clsx(
                                                'form-control form-control-lg form-control-solid'
                                            )}
                                            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                setPasswordRepeat(e.currentTarget.value)
                                            }}
                                        />

                                        <span className='bi-eye-fill align-middle ms-2 cursor-pointer'
                                              style={{position: 'absolute', right: '-20px'}}
                                              onMouseDown={() => setHiddenPass(false)}
                                              onMouseUp={() => setHiddenPass(true)}/>

                                    </div>
                                </div>
                            </div>

                            {showPasswordError &&
                                <div className='fv-plugins-message-container text-danger mb-3'>
                                    <span role='alert'>
                                        {password !== passwordRepeat ?
                                            intl.formatMessage({id: 'PASSWORDS_NOT_SAME'}) :
                                            password.length < 8 || passwordRepeat.length < 8 ? intl.formatMessage({id: 'AT_LEAST_8_CHAR'}) : ''

                                        }
                                    </span>
                                </div>
                            }

                            <div className='d-flex flex-stack mt-5 justify-content-end'>
                                <Tooltip zIndex={isDemo() ? 1 : -1} color='var(--kt-body-bg)' title={() => <span
                                    style={{color: 'var(--kt-text-gray-600)'}}>{intl.formatMessage({id: "DEMO_DISABLED"})}</span>}>
                                    <div>
                                        <button type="button" disabled={isDemo()} className='btn btn-lg btn-primary'
                                                onClick={(e) => savePassword(e)}>
                                  <span className='indicator-label'>
                                    {intl.formatMessage({id: "SAVE_EDITION"})}
                                  </span>
                                        </button>
                                    </div>
                                </Tooltip>
                            </div>
                        </form>
                    </Accordion.Body>
                </Accordion.Item>

                {/*<Accordion.Item eventKey="1" className="mb-5">*/}
                {/*    <Accordion.Header>Личные данные</Accordion.Header>*/}
                {/*    <Accordion.Body*/}
                {/*        className='bg-body rounded w-100 me-9'>*/}
                {/*        <Formik initialValues={initValues} validationSchema={accountSettings} onSubmit={onSub}*/}
                {/*                enableReinitialize={true}>*/}
                {/*            {(props) => {*/}
                {/*                const {*/}
                {/*                    handleChange,*/}
                {/*                } = props*/}

                {/*                return (*/}
                {/*                    <Form className="w-100 bg-body me-9 px-10 pt-10 pb-5">*/}
                {/*                        {alertSuccess && (*/}
                {/*                            <Alert className='position-fixed' style={{zIndex: "3", top: "16%"}}*/}
                {/*                                   key={'success'}*/}
                {/*                                   variant={'success'}>*/}
                {/*                                {intl.formatMessage({id: "alert_success"})}*/}
                {/*                            </Alert>*/}
                {/*                        )}*/}
                {/*                        /!*<div className="w-lg-75">*!/*/}
                {/*                        <div className="w-100">*/}
                {/*                            <div className='mb-10 fv-row'>*/}
                {/*                                <label*/}
                {/*                                    className='form-label mb-3 required'>{intl.formatMessage({id: "ceo_first_name"})}</label>*/}
                {/*                                <Field*/}
                {/*                                    type='text'*/}
                {/*                                    className='form-control form-control-lg form-control-solid'*/}
                {/*                                    name='first_name'*/}
                {/*                                    onChange={(e: React.FormEvent<HTMLInputElement>) => {*/}
                {/*                                        if (!e.currentTarget.value.length || e.currentTarget.value.match(/^[Aa-zZ-Аа-яЯ]+$/i))*/}
                {/*                                            handleChange(e)*/}
                {/*                                    }}*/}
                {/*                                />*/}
                {/*                                <div className='text-danger mt-2'>*/}
                {/*                                    <ErrorMessage name='first_name'/>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                            <div className='mb-10 fv-row'>*/}
                {/*                                <label*/}
                {/*                                    className='form-label mb-3 required'>{intl.formatMessage({id: "ceo_last_name"})}</label>*/}
                {/*                                <Field*/}
                {/*                                    type='text'*/}
                {/*                                    className='form-control form-control-lg form-control-solid'*/}
                {/*                                    name='last_name'*/}
                {/*                                    onChange={(e: React.FormEvent<HTMLInputElement>) => {*/}
                {/*                                        if (!e.currentTarget.value.length || e.currentTarget.value.match(/^[Aa-zZ-Аа-яЯ]+$/i))*/}
                {/*                                            handleChange(e)*/}
                {/*                                    }}*/}
                {/*                                />*/}
                {/*                                <div className='text-danger mt-2'>*/}
                {/*                                    <ErrorMessage name='ceo_last_name'/>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                            <div className='mb-10 fv-row'>*/}
                {/*                                <label*/}
                {/*                                    className='form-label mb-3 required'>{intl.formatMessage({id: "ceo_middle_name"})}</label>*/}
                {/*                                <Field*/}
                {/*                                    type='text'*/}
                {/*                                    className='form-control form-control-lg form-control-solid'*/}
                {/*                                    name='middle_name'*/}
                {/*                                    onChange={(e: React.FormEvent<HTMLInputElement>) => {*/}
                {/*                                        if (!e.currentTarget.value.length || e.currentTarget.value.match(/^[Aa-zZ-Аа-яЯ]+$/i))*/}
                {/*                                            handleChange(e)*/}
                {/*                                    }}*/}
                {/*                                />*/}
                {/*                                <div className='text-danger mt-2'>*/}
                {/*                                    <ErrorMessage name='ceo_middle_name'/>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                            <div className='d-flex flex-stack mt-5 justify-content-end'>*/}
                {/*                                <Tooltip zIndex={isDemo() ? 1 : -1} color='var(--kt-body-bg)'*/}
                {/*                                         title={() => <span*/}
                {/*                                             style={{color: 'var(--kt-text-gray-600)'}}>{intl.formatMessage({id: "DEMO_DISABLED"})}</span>}>*/}
                {/*                                    <div>*/}
                {/*                                        <button disabled={isDemo()} type='submit'*/}
                {/*                                                className='btn btn-lg btn-primary'>*/}
                {/*                                          <span*/}
                {/*                                              className='indicator-label'>{intl.formatMessage({id: "SAVE_EDITION"})}*/}
                {/*                                          </span>*/}
                {/*                                        </button>*/}
                {/*                                    </div>*/}
                {/*                                </Tooltip>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </Form>*/}
                {/*                )*/}
                {/*            }}*/}
                {/*        </Formik>*/}
                {/*    </Accordion.Body>*/}
                {/*</Accordion.Item>*/}
            </Accordion>
        </>
    )
})


const AccountWrapper = () => {
    const intl = useIntl()
    return (
        <ErrorProvider>

            <PageTitle>{intl.formatMessage({id: "ACCOUNT_SETTINGS"})}</PageTitle>
            <AccountPage/>
        </ErrorProvider>
    )
}

export
{
    AccountWrapper
}



