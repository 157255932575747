/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useIntl} from "react-intl";
import {observer} from "mobx-react";
import {Company} from "@providers/company/_request";


type Props = {
    data: any
    className?: string
    icon?: any
    isBranchName?: boolean
    company?: boolean
}


const UserInfoCell: FC<Props> = observer(({data, company, className, icon, isBranchName}) => {
    const intl = useIntl()

    let iconStr = `/media/svg/${icon}.svg`;



    return (
        <div className="d-flex align-items-center justify-content-between">

            <div className='d-flex flex-column'>
                <p className={`text-gray-800 text-hover-primary mb-1 text-left d-flex align-items-center ${className ? className : ""}`}>
                    {(icon || isBranchName) &&
                        <img
                            alt={''}
                            src={isBranchName || company ? (Company.getFiles(icon)) : iconStr} className="me-4"
                            style={{
                                objectFit: "cover",
                                width: "32px",
                                height: "32px",
                            }}/>}
                    <span style={{
                        textAlign: "left",
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                    }}>{data ?? intl.formatMessage({id: "NO_DATA"})}</span>
                </p>
            </div>
        </div>
    )
})

export {UserInfoCell}
