import {instantse} from "@providers/request";
import {AgentCreate, AgentRequestGet} from "./_models";

export const Agent = {

    createAgent(data:AgentCreate){
        return instantse.post('/v1/gs/agent', data)
            .then(result=>result)
    },
    editAgent(id:number, data:AgentCreate){
        return instantse.put(`/v1/gs/agent/${id}`, data)
            .then(result=>result)
    },

    getAgents(dataRequest:AgentRequestGet){
        return instantse.post('/v1/gs/agent/statistics', dataRequest)
            .then(result=>result)
    },
    getAgentsStatisticPersonal(dataRequest:AgentRequestGet){
        return instantse.post('/v1/gs/agent/statistics/personal', dataRequest)
            .then(result=>result)
    },
    getAgentById(id:number){
        return instantse.get(`/v1/gs/agent/${id}`)
            .then(result=>result)
    },
    getStatisticById(id:string, dataRequest:any){
        return instantse.post(`/v1/gs/agent/${id}/statistics`, dataRequest)
            .then(result=>result)
    },

    getAgentsGeneral(dataRequest:any){
        return instantse.post('/v1/gs/agent/statistics/general', dataRequest)
            .then(result=>result)
    },
    getAgentsGeneralById(id:string, dataRequest:any){
        return instantse.post(`/v1/gs/agent/${id}/statistics/general`, dataRequest)
            .then(result=>result)
    },
    getAgentsGeneralPersonal(dataRequest:any){
        return instantse.post('/v1/gs/agent/statistics/personal/general', dataRequest)
            .then(result=>result)
    },

    getMerchants(){
        return instantse.get('/v1/company/agents')
            .then(result=>result)
    },

    deleteAgent(id:number){
        return instantse.delete(`/v1/gs/agent/${id}`)
            .then(result=>result)
    }


}