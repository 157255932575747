import axios, {AxiosResponse} from 'axios'
import * as authHelper from '../app/modules/auth/core/AuthHelpers'

export const instantse = axios.create({
    baseURL: process.env.REACT_APP_PUBLIC_URL
})

instantse.interceptors.request.use(
    (config: any) => {
        const token = localStorage.getItem('kt-auth-react-v') ? JSON.parse(localStorage.getItem('kt-auth-react-v') ?? '') : null
        if (token) {
            config.headers.Authorization = `Bearer ${token.access_token}`
        }
        return config
    },
    (error) => {
        Promise.reject(error)
    }
)

instantse.interceptors.response.use(
    (response: AxiosResponse) => {
        return response
    },
    function (error) {
        const originalRequest = error.config
        let status = (error.response && error.response.status) || 0
        if (status === 401 && !originalRequest._retry) {

            originalRequest._retry = true
            authHelper.removeAuth()

            throw error
        } else {
            throw error
        }
    }
)
