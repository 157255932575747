import React, {useState} from 'react';
import {useModal} from "../../../../../hooks/useModal";
import {KTSVG} from "@metronic/helpers";
import {useIntl} from "react-intl";
import {Modal} from "react-bootstrap";
import {UsersListLoading} from "@metronic/partials/widgets/tables/_modals/components/loading/UserListLoading";
import {ErrorMessage, Field, Form, Formik, FormikHelpers} from "formik";
import {IWebPayment, IWebPaymentResponse, IWebPaymentSend, IWebPaymentsFilters} from "@providers/webPayments/models";
import {inits, WebPaymentsSchema} from "./validation-schema";
import {useParams} from "react-router-dom";
import {multiplyByHundred} from "@utils/numbersOperations";
import {roleRoutingAdmin} from "@utils/index";
import {employeeStore} from "@store/employee";
import {createWebPayment, getWebPayments} from "@providers/webPayments/request";
import {web_payments} from "@store/web-payments/web-payments";
import {useErrors} from "@providers/context/ErrorsProvider";
import {openNotificationSuccess} from "@metronic/partials/notification";


const InvoiceForm = () => {
    const {open, onOpen, onClose} = useModal()
    const {id, companyId} = useParams()
    const intl = useIntl()
    const pagination = web_payments.pagination
    const {setError} = useErrors()
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false)

    const onSubmit = (values: IWebPayment, actions: FormikHelpers<IWebPayment>) => {
        const data: IWebPaymentSend = {
            name: values.name,
            description: values.description,
            companyId: Number(companyId),
            externalServiceId: Number(id),
            clientId: 1,
            requestFields: [
                {
                    name: 'amount',
                    value: multiplyByHundred(Number(values.price!.replace(/ /g, ''))).toString()
                },
                {
                    name: 'currency',
                    value: 'uzs'
                }
            ]
        }
        createWebPaymentHandler(data)
    }

    const createWebPaymentHandler = async (data: IWebPaymentSend) => {
        setConfirmLoading(true)
        try {
            const response: IWebPaymentSend = await createWebPayment(data).then((response) => response.data.result)
            if (response.id) {
                openNotificationSuccess(intl.formatMessage({id: 'webPaymentCreated'}))
                webPaymentsRecieve()
                onClose()
            }
        } catch (err: any) {
            setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
        } finally {
            setConfirmLoading(false)
        }
    }

    const webPaymentsRecieve = async () => {
        const data: IWebPaymentsFilters = {
            page: pagination?.currentPage || 1,
            cashBoxId: id ?? '',
            size: 20,
        }
        try {
            const response: IWebPaymentResponse = await getWebPayments(data).then((response) => response.data)
            web_payments.setWebPayments(response.result.content)
            web_payments.setPagination({
                currentPage: pagination?.currentPage || 1,
                pageSize: 20,
                pages: response.result.totalPages,
                last: pagination?.currentPage === response.result.totalPages,
                first: pagination?.currentPage === 1,
                items: response.result.totalElements
            })
        } catch (err: any) {
            setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
        }
    }

    return (
        <>
            {confirmLoading && <UsersListLoading/>}
            {roleRoutingAdmin('action', employeeStore.getRole, 'create-checkout') &&
                <button onClick={onOpen} className={`btn btn-primary d-flex align-items-center `}>
                    <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2 me-2'/>
                    <span>
                    {intl.formatMessage({id: 'addWebPayment'})}
                </span>
                </button>}
            <Modal
                show={open}
                onHide={onClose}

            >
                {confirmLoading && <UsersListLoading/>}
                <Modal.Header closeButton>
                    <h2>
                        {intl.formatMessage({id: 'creatingWebPayment'})}
                    </h2>
                </Modal.Header>

                <Formik enableReinitialize initialValues={inits}
                        validationSchema={WebPaymentsSchema()}
                        onSubmit={onSubmit}>
                    {({setFieldValue, submitForm, errors}) => {
                        return (
                            <Form>
                                <Modal.Body>
                                    <div className='mb-10 fv-row'>
                                        <label
                                            className='form-label mb-3'>{intl.formatMessage({id: "webPayments.name"})}</label>
                                        <Field
                                            type='text'
                                            className={`form-control form-control-lg ${errors.name ? 'form-danger' : ''}`}
                                            placeholder={intl.formatMessage({id: "webPayments.name"})}
                                            name='name'
                                        />
                                        <div className='text-danger mt-2'>
                                            <ErrorMessage name='name'/>
                                        </div>
                                    </div>
                                    <div className='mb-10 fv-row'>
                                        <label
                                            className='form-label mb-3'>{intl.formatMessage({id: "webPayments.price"})}</label>
                                        <Field
                                            type='text'
                                            className='form-control form-control-lg '
                                            name='price'
                                            placeholder={intl.formatMessage({id: "webPayments.price"})}
                                            maxLength={10}
                                            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                setFieldValue('price', e.currentTarget.value.replace(/ /g, '').replace(/[^0-9]/g, '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "))
                                            }}
                                        />
                                        <div className='text-danger mt-2'>
                                            <ErrorMessage name='price'/>
                                        </div>
                                    </div>
                                    <div className='mb-10 fv-row'>
                                        <label
                                            className='form-label mb-3'>{intl.formatMessage({id: "webPayments.description"})}</label>
                                        <Field
                                            type='text'
                                            placeholder={intl.formatMessage({id: "webPayments.description"})}
                                            className='form-control form-control-lg '
                                            name='description'
                                        />
                                        <div className='text-danger mt-2'>
                                            <ErrorMessage name='description'/>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button type="button" className="swal2-confirm btn btn-secondary" aria-label=""
                                            style={{display: 'inline-block'}} onClick={onClose}>
                                        {intl.formatMessage({id: "CANCEL_BUTTON"})}
                                    </button>
                                    <button type={'submit'}
                                            className="swal2-confirm btn btn-primary" aria-label=""
                                            style={{display: 'inline-block'}}>
                                        {intl.formatMessage({id: "CREATE_BUTTON"})}
                                    </button>
                                </Modal.Footer>
                            </Form>
                        )
                    }}
                </Formik>
            </Modal>
        </>
    );
};

export default InvoiceForm;