/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from "react-intl";
import {ReplacePhone} from "@utils/index";
import moment from "moment";

type Props = {
    date: string
}

const AgentDate: FC<Props> = ({date}) => {

    if(!date) return (
        <div className='fs-6 text-gray-600 fw-semibold'>
           Нет данных
        </div>
    )
    return (
        <div className='fs-6 text-gray-600 fw-semibold'>
            {moment(date).format('DD/MM/YYYY')}
        </div>
    )
}

export {AgentDate}
