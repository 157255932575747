/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {ModSession} from "@providers/session/_modals";
import moment from "moment";


type Props = {
    date: any
}


const TableDate: FC<Props> = ({date}) => {
    return (
        <div className='d-flex align-items-center justify-content-start'>

            <div className='d-flex flex-column'>
                <div className='text-gray-800 mb-1 text-left'>
                    <div>{moment(date).format('DD/MM/YYYY HH:mm:ss')}</div>
                </div>
            </div>
        </div>
    )
}

export {TableDate}
