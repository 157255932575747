import React, {FC, useEffect, useState} from 'react'
import {Field, ErrorMessage, FormikProps} from 'formik'
import {CreateCompany} from "@providers/company/_models";
import AsyncSelect from 'react-select/async';
import {customStyles} from "@metronic/assets/ts/select.style";
import {useIntl} from "react-intl";
import moment from "moment/moment";
import InputMask from "react-input-mask";
import {useParams} from "react-router-dom";

interface IProps extends FormikProps<CreateCompany> {
    handleChange(arg: any): void
    values:any,
    setFieldValue(name: string, val: any): void,
    touched: any,
    errors:any,
    isSubmitting:boolean,
    setFieldTouched: (val:any)=>void
}

const Step2: FC<IProps> = (props) => {

    const intl = useIntl()
    const {type} = useParams()
    const {touched, errors, setFieldTouched, values, isSubmitting} = props





    return (
        <div className='w-100'>
            <div className="pb-14">
                <h1 className="fw-bold text-dark">{intl.formatMessage({id: 'agent-step2.title'})}</h1>
            </div>




            {type==='company'?(<div className='mb-8 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id: 'inn-agent'})}</label>
                <Field
                    type='text'
                    placeholder='123456789'
                    className='form-control form-control-lg form-control-solid'
                    name='inn'
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        if (e.currentTarget.value.match(/^\d{0,9}$/)) {
                            props.handleChange(e)
                        }
                    }}
                />
                <div className='text-danger mt-2'>
                    {errors.inn && values.inn>1 ? (
                        <div>{errors.inn}</div>
                    ) : null}

                </div>
            </div>):( <div className='mb-8 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id: 'pinfl'})}</label>
                <Field
                    type='text'
                    placeholder='12345678910111'
                    className='form-control form-control-lg form-control-solid'
                    name='pinfl'
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        if (e.currentTarget.value.match(/^\d{0,14}$/)) props.handleChange(e)
                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='pinfl'/>
                </div>
            </div>)}


            {type==='company'?(<div className='mb-8 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id: 'certificate_id'})}</label>
                <Field
                    type='text'
                    placeholder='12345678910111'
                    className='form-control form-control-lg form-control-solid'
                    name='certificate_id'
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        if (e.currentTarget.value.match(/^\d{0,9}$/)) props.handleChange(e)
                    }}
                />
                <div className='text-danger mt-2'>
                    {errors.certificate_id && touched.certificate_id ? (
                        <div>{errors.certificate_id}</div>
                    ) : null}

                </div>
            </div>):''}

            {type==='company'?( <div className='mb-8 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id:'company_name'})}</label>
                <Field
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='company_name'
                    onChange={(e: React.FormEvent<HTMLInputElement>)=>{
                        if(!e.currentTarget.value.length||e.currentTarget.value.match(/^[Aa-zZ-Аа-яЯ\s]*$/i))
                            props.handleChange(e)
                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='company_name' />
                </div>
            </div>):''}

            <div className='mb-12 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id:'phone'})}</label>
                <Field
                    type='text'
                    placeholder='+998 90 999 99 99'
                    component={InputMask}
                    mask="+\9\9\8 99 999 99 99"
                    className='form-control form-control-lg form-control-solid'
                    name='phone'
                    value={props.values['phone']}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        const value = e.currentTarget.value || '';
                        props.setFieldValue('phone', value)
                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='phone' />
                </div>
            </div>

            {
                type==='individual'?(<><div className='mb-8 fv-row'>
                    <label className='form-label mb-3 required'>{intl.formatMessage({id:'ceo_passport_id'})}</label>
                    <Field
                        type='text'
                        placeholder='AA1234567'
                        className='form-control form-control-lg form-control-solid'
                        name='passport_id'
                        onChange={(e: React.FormEvent<HTMLInputElement>)=>{
                            const passportRegExp = new RegExp(/^(([aA-zZ]\D{0,1})([0-9]{0,7}))$/)
                            if(passportRegExp.test(e.currentTarget.value)||!e.currentTarget.value.length){
                                props.handleChange(e)
                                props.setFieldValue('passport_id', e.currentTarget.value.replace(/^[aA-zZ]{2}/, (match)=>match.toUpperCase()))
                            }
                        }}

                    />
                    <div className='text-danger mt-2'>
                        <ErrorMessage name='passport_id' />
                    </div>
                </div>

                    <div className='mb-8 fv-row'>
                        <label className='form-label mb-3 required'>{intl.formatMessage({id:'ceo_first_name'})}</label>
                        <Field
                            type='text'
                            className='form-control form-control-lg form-control-solid'
                            name='first_name'
                            onChange={(e: React.FormEvent<HTMLInputElement>)=>{
                                if(!e.currentTarget.value.length||e.currentTarget.value.match(/^[Aa-zZ-Аа-яЯ]+$/i))
                                    props.handleChange(e)
                            }}
                        />
                        <div className='text-danger mt-2'>
                            <ErrorMessage name='first_name' />
                        </div>
                    </div>


                    <div className='mb-8 fv-row'>
                        <label className='form-label mb-3 required'>{intl.formatMessage({id:'ceo_last_name'})}</label>
                        <Field
                            type='text'
                            className='form-control form-control-lg form-control-solid'
                            name='last_name'
                            onChange={(e: React.FormEvent<HTMLInputElement>)=>{
                                if(!e.currentTarget.value.length||e.currentTarget.value.match(/^[Aa-zZ-Аа-яЯ]+$/i))
                                    props.handleChange(e)
                            }}
                        />
                        <div className='text-danger mt-2'>
                            <ErrorMessage name='last_name' />
                        </div>
                    </div>

                    <div className='mb-8 fv-row'>
                        <label className='form-label mb-3'>{intl.formatMessage({id:'ceo_middle_name'})}</label>
                        <Field
                            type='text'
                            className='form-control form-control-lg form-control-solid'
                            name='middle_name'
                            onChange={(e: React.FormEvent<HTMLInputElement>)=>{
                                if(!e.currentTarget.value.length||e.currentTarget.value.match(/^[Aa-zZ-Аа-яЯ]+$/i))
                                    props.handleChange(e)
                            }}
                        />
                        <div className='text-danger mt-2'>
                            <ErrorMessage name='middle_name' />
                        </div>
                    </div></>):''
            }

            <div className='mb-8 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id:'contract_id'})}</label>
                <Field
                    type='text'
                    placeholder='AA-1234567'
                    className='form-control form-control-lg form-control-solid'
                    name='contract_id'
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='contract_id' />
                </div>
            </div>




            <div className='mb-8 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id:'contract_expiry_date'})}</label>
                <Field
                    type='date'
                    className='form-control form-control-lg form-control-solid'
                    name='contract_expiry_date'
                    min='1899-01-01' max='2125-01-01'

                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='contract_expiry_date' />
                </div>
            </div>






        </div>
    )
}

export {Step2}
