import {FC} from 'react'
import {PageTitle} from "../../../_metronic/layout/core";

const Report:FC = ()=>{
    return(
        <div>
            <h1>Reports</h1>
        </div>
    )
}

export default function Reports(){
    return(
        <>
            <PageTitle>Reports</PageTitle>
            <Report/>
        </>
    )
}