import {QueryRequestProvider} from "@providers/QueryRequestProvider";
import {PageTitle} from "@metronic/layout/core";
import React from "react";
import {KTCard} from "@metronic/helpers";
import {UsersListHeader} from "./_modals/table-components/header/UsersListHeader";
import {Table} from "./_modals/Table";
import {ErrorProvider} from "@providers/context/ErrorsProvider";
import {useIntl} from "react-intl";
import {PageTitleCustom} from "@metronic/partials/pageTitleCustom";


function Filials(){
    return(
         <KTCard>
            <UsersListHeader />
            <Table />
         </KTCard>
    )
}


export default function FilialsWrapper(){
    const intl = useIntl()
    return(
        <>
        <QueryRequestProvider>
            <ErrorProvider>
                <PageTitleCustom title={intl.formatMessage({id:'cashboxes'})} breadCrumbs={[]}/>
                <Filials />
            </ErrorProvider>
        </QueryRequestProvider>
        </>
    )
}