import React from 'react';
import {useIntl} from "react-intl";

interface props {
    text: string
    translate?: boolean
}

const IdCell: React.FC<props> = ({
    text,
    translate
                                 }) => {
    const intl = useIntl()
    return (
        <div className='fs-6 text-gray-600 fw-normal'>
            {translate?intl.formatMessage({id:text}):text}
        </div>
    );
};

export default IdCell;