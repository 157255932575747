import {toAbsoluteUrl} from "@metronic/helpers";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Auth} from "@providers/auth/_request";
import {InviteResponse} from "@providers/auth/_models";
import {useNavigate} from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import {AUTH_LOCAL_STORAGE_KEY} from "./core/AuthHelpers";
import {ErrorProvider, useErrors} from "@providers/context/ErrorsProvider";
import {useIntl} from "react-intl";


function Invite(){
    const {code} = useParams()
    const [data, setData] = useState<InviteResponse|null>(null)
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    const {error, setError} = useErrors()
    const intl = useIntl()


    useEffect(()=>{
        if(code){
            Auth.getInvite(code)
                .then(result=>{
                    setLoading(false)
                    setData(result?.data?.result)
                }).catch(err=>{
                    setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
                    setLoading(false)
                })
        }
    }, [])

    async function onOk(){
        setLoading(true)
        if(code){

            await Auth.postInvite(code)
                .then(()=>{}).catch(err=>{
                setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
                setLoading(false)
            })

            if(!Object.keys(error).length){
                await confirmAlert({
                    title: intl.formatMessage({id:'WARNING'}),
                    message: intl.formatMessage({id:'STAY_SYSTEM'}),
                    buttons: [
                        {
                            label: 'Yes',
                            onClick: () => {navigate(`/companies`)}
                        },
                        {
                            label: 'No',
                            onClick: async () => {
                                localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
                                navigate(`/auth`)
                            }
                        }
                    ]
                });

            }


        }
    }




    return(
        <>
        <div className="d-flex flex-column flex-root" id="kt_app_root" style={{
            backgroundImage: `url(${toAbsoluteUrl('/media/auth/bg8.jpg')})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
            <div className="d-flex flex-column flex-center flex-column-fluid">
                <div className="d-flex flex-column flex-center text-center p-10">

                    <div className="card card-flush w-md-650px py-5">
                        {
                            !loading&&!data?(<div>
                                {error?.isError?(<div className="fw-semibold fs-6 text-white-500 mb-7">{intl.formatMessage({id:'STATUS_ERROR'})} {error?.status}
                                    <br /> {error?.message} <br /></div>):''}
                            </div>):(<div className="card-body py-15 py-lg-20">


                                <h1 className="fw-bolder text-gray-900 mb-5">{intl.formatMessage({id:'WELCOME'})} Global Pay</h1>

                                <div className="fw-semibold fs-6 text-white-500 mb-7">{intl.formatMessage({id:'INVITE_COMPANY'})} {data?.company_name}
                                    <br /> {intl.formatMessage({id:'DOL'})} {data?.position&&intl.formatMessage({id:data?.position})}  <br /></div>


                                <div className="mb-0">
                                    <button className="btn btn-sm btn-primary" disabled={loading} onClick={()=>onOk()}>{intl.formatMessage({id:'CHECK'})}</button>
                                </div>

                                {error?.isError?(<div className="fw-semibold fs-6 text-white-500 mb-7">{intl.formatMessage({id:'STATUS_ERROR'})} {error?.status}
                                    <br /> {error?.message} <br /></div>):''}

                            </div>)
                        }
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}


export default function InviteWrapper(){
    return(
        <ErrorProvider>
            <Invite />
        </ErrorProvider>
    )
}