import {FC} from 'react'
import {PageLink, PageTitle} from "@metronic/layout/core";
import {Vertical} from "./wizards/components/Vertical";
import { useParams } from 'react-router-dom';
import {ErrorProvider} from "@providers/context/ErrorsProvider";
import {useIntl} from "react-intl";
import { PageTitleCustom } from '@metronic/partials/pageTitleCustom';




const AgentForm:FC = ()=>{
    return(
        <ErrorProvider>
            <Vertical/>
        </ErrorProvider>
    )
}

export default function AgentFormWrapper(){
    const {type} = useParams()
    const intl = useIntl()
    const createAgentsBreadCrumbs: Array<PageLink> = [
        {
            title: 'Агенты',
            path: `/agents/${type}`,
            isSeparator: false,
            isActive: false,
        }, {
            title: type==='individual'?intl.formatMessage({id:'AGENTS-INDIVIDUAL'})
                :intl.formatMessage({id:'AGENTS-YURIDIC'}),
            path: `/agents/${type}`,
            isSeparator: false,
            isActive: false,
        },

        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
    ]

    return(
        <>
            <PageTitleCustom title={intl.formatMessage({id:'Agent-title-add'})} breadCrumbs={createAgentsBreadCrumbs} />
            <AgentForm/>

        </>
    )
}