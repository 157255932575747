/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {observer} from "mobx-react";
import moment from "moment";
import {NumReplace} from "@utils/index";

type Props = {
    data: any
}

const Income: FC<Props> = observer(({data}) => {

    return (
        <div >
            <p className='fs-6 text-gray-800 fw-semibold mb-0'>{NumReplace(data?.income||'0')} UZS</p>
            <p className='fs-6 text-gray-600 fw-normal'>{data?.commission?data?.commission/1000:0} %</p>
        </div>
    )
})

export {Income}
