/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from "react-router-dom";
import {CompanyResponse} from "@providers/company/_models";
import {observer} from "mobx-react";
import {image_default, JUNIOR_CASHIER} from "@utils/constants";
import {employeeStore} from "@store/employee";
import {Company} from "@providers/company/_request";
import {roleRoutingAdmin} from "@utils/index";
import {useIntl} from "react-intl";

type Props = {
    text: string
    translate?: boolean
}

const TableText: FC<Props> = observer(({text, translate=false}) => {

    const intl = useIntl()
    return (
        <div className='fs-6 text-gray-600 fw-normal'>
            {translate?intl.formatMessage({id:text}):text}
        </div>
    )
})

export {TableText}
