import {ContractStatus} from "@providers/autopay/models/_models";

export function isDisabledContract (status: ContractStatus) {
    let isDisabled = false

    if (status === 'FINISHED' || status === 'TERMINATED') {
        isDisabled = true
    }

    return isDisabled
}