import clsx from 'clsx'
import React, {FC} from 'react'
import {HeaderUserMenu, ThemeModeSwitcher,} from '../../../partials'
import {CompanySwitcher} from "../../../../app/modules/header/Company-switcher";
import {observer} from "mobx-react";
import {useParams} from "react-router-dom";
import {roleRoutingAdmin} from "@utils/index";
import {employeeStore} from "@store/employee";

const toolbarButtonMarginClass = 'ms-1 ms-lg-5',
    toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
    toolbarButtonHeightClass1 = 'h-30px h-md-40px'

const Topbar: FC = observer(() => {
    const {companyId} = useParams()


    return (
        <div className='d-flex align-items-center flex-shrink-0'>

            {/* begin::Theme mode */}
            <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
                <ThemeModeSwitcher
                    toggleBtnClass={clsx('btn-active-light-primary btn-custom', toolbarButtonHeightClass)}
                />
            </div>
            {/* end::Theme mode */}

            {
                companyId && !roleRoutingAdmin('action', employeeStore.getRole, 'is-admin') ? (<>
                    {/* begin:: Companies switcher */}

                    <div className={clsx('d-flex align-items-center', toolbarButtonHeightClass1)}>
                        <CompanySwitcher
                            toggleBtnClass={clsx('btn-active-light-primary btn-custom', toolbarButtonHeightClass1)}
                        />
                    </div>
                </>) : ''
            }

            {/* begin::User */}
            <div
                className={clsx('d-flex align-items-center user-list__block', toolbarButtonMarginClass)}
                id='kt_header_user_menu_toggle'
            >
                <HeaderUserMenu/>
            </div>
        </div>
    )
})

export {Topbar}
