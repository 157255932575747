import {PageLink} from "@metronic/layout/core";
import React, {FC, useEffect, useMemo, useState} from 'react';
import {MerchantService} from "@providers/autopay/requests/merchant.service";
import {useNavigate, useParams} from "react-router-dom";
import {KTCard, KTCardBody, KTSVG} from "@metronic/helpers";
import {ErrorProvider, useErrors} from "@providers/context/ErrorsProvider";
import {ColumnInstance, Row, useTable} from "react-table";
import {CustomHeaderColumn} from "../../../companies/_modals/columns/CustomHeaderColumn";
import {CustomRow} from "../../../companies/_modals/columns/CustomRow";
import {Pagination} from "../../../../modules/Pagination";
import {useIntl} from "react-intl";
import {arr_columns_clients, clientsColumnsGet} from "./_modals/columns/_columns";
import {isDemo} from "@utils/index";
import moment from "moment";
import {ModSession} from "@providers/session/_modals";
import {PageTitleCustom} from "@metronic/partials/pageTitleCustom";

const tableHeaders: Array<Object> = [
    {"firstName": "ФИО"},
    {"passportSerie": "Паспортные данные"},
    {"issuedBy": "Кем выдан паспорт"},
    {"pinfl": "ПИНФЛ"},
    {"phoneNumber": "Тел. номер"},
]


const ClientsListInner: FC = () => {
    const [clientsData, setClientsData] = useState<any[]>([])
    // const [tableBody, setTableBody] = useState<Array<ClientItemResponse>>([])
    // const [totalPagesCount, setTotalPagesCount] = useState<number>(1)
    // const [currentPage, setCurrentPage] = useState<number>(1)
    const [filtersDay, setFiltersDay] = useState('month')
    const [filtersCount, setFiltersCount] = useState(0)
    const [filters, setFilters] = useState<any>({
        from_date: isDemo() ? '2022-11-04' : moment().subtract(1, 'month').format('YYYY-MM-DD'),
        to_date: isDemo() ? '2022-12-04' : moment().format('YYYY-MM-DD')

    })
    const [showTableSort, setShowTableSort] = useState(false)
    const [showFilter, setShowFilter] = useState(false)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const {setError} = useErrors()
    const data = useMemo(() => clientsData, [clientsData])
    const navigate = useNavigate()
    const [tableSort, setTableSort] = useState<any[]>([])
    const [pagination, setPagination] = useState<any>(null)
    const intl = useIntl()
    const columns = useMemo(() => clientsColumnsGet(tableSort), [clientsData, tableSort])
    const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
        columns,
        data,
    })
    const {companyId} = useParams()

    const lang = JSON.parse(localStorage.getItem('i18nConfig') ?? '{}')?.selectedLang


    // let pages: Array<string> = [];
    // if (totalPagesCount > 10) {
    //     if (currentPage > 5) {
    //         pages.push("1", "...");
    //         for (let i = currentPage - 4; i <= currentPage + 5; i++) {
    //             pages.push(i.toString());
    //             if (i === currentPage + 5) pages.push("...", totalPagesCount.toString());
    //             if (i === totalPagesCount) break;
    //         }
    //     } else {
    //         for (let i = 1; i <= 10; i++) {
    //             pages.push(i.toString());
    //             if (i === 10) pages.push("...", totalPagesCount.toString());
    //             if (i === totalPagesCount) break;
    //         }
    //     }
    // } else {
    //     for (let i = 1; i <= totalPagesCount; i++) {
    //         pages.push(i.toString());
    //     }
    // }


    function countDays() {
        let a = moment(filters.from_date),
            b = moment(filters.to_date)

        return b.diff(a, 'days')
    }

    function replaceFormat(date: any, formatFrom: string, formatTo: string) {
        let newDate = moment(date, formatFrom).format(formatTo)
        return moment(newDate, formatTo)
    }

    async function getClients(page?: string, sortBy?: string) {
        try {
            const {data} = await MerchantService.getMerchantClients(companyId || '', page, undefined)
            if (!data.success) throw new Error(data.message)
            const dataResponse = data.data
            setClientsData((dataResponse?.data || []).map((item: any, index: number) => {
                if (item) {
                    if (dataResponse.currentPage && dataResponse.currentPage > 2) {
                        item['num'] = ((dataResponse.currentPage - 1) * 20) + index + 1
                    } else if (dataResponse.currentPage == 2) {
                        item['num'] = 21 + index
                    } else {
                        item['num'] = index + 1
                    }
                    return item
                }

            }).filter((item: any) => item !== undefined))
            setPagination({
                currentPage: dataResponse.currentPage,
                pages: dataResponse.totalPages,
                page: dataResponse.currentPage,
                last: dataResponse.currentPage === dataResponse.totalPages,
                first: dataResponse.currentPage === 1,
                items: dataResponse.totalRows
            })
        } catch (err: any) {
            setError({message: err?.response?.data?.message, isError: true, status: err?.response?.status})
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        setTableSort(arr_columns_clients)
        getClients()
    }, [])

    const breadCrumbsClientMain: Array<PageLink> = [
        {
            title: "Автосписание",
            path: "",
            isSeparator: false,
            isActive: false
        },
        {
            title: "Клиенты",
            path: "",
            isSeparator: true,
            isActive: false
        }
    ]

    return (
        <>
            <PageTitleCustom title={'Список пользователей'} breadCrumbs={breadCrumbsClientMain}/>
            <KTCard>
                <KTCardBody>
                    <div className='table-responsive'>
                        <div className='d-flex align-items-center'>
                            {/*<div className='filter_buttons_group my-4 me-4'>*/}

                            {/*    <a*/}
                            {/*        href='#'*/}
                            {/*        className='badge bg-light bg-active-primary fw-medium text-primary px-4 py-2'*/}
                            {/*        data-kt-menu-trigger='click'*/}
                            {/*        data-kt-menu-placement='bottom-end'*/}
                            {/*        data-kt-menu-flip='top-end'*/}
                            {/*        onClick={() => setShowFilter(!showFilter)}*/}
                            {/*    >*/}
                            {/*        <KTSVG*/}
                            {/*            path='/media/icons/duotune/general/gen031.svg'*/}
                            {/*            className='svg-icon-5 text-primary me-1'*/}
                            {/*        />*/}
                            {/*        {filtersCount===0?"Фильтр":filtersCount > 1 ? "Применено" : "Применен"} {filtersCount||''} {filtersCount===0?'':filtersCount > 1 ? "фильтра" : filtersCount === 1 ? "фильтр" : "фильтров"}*/}
                            {/*    </a>*/}

                            {/*    <Modal size="sm" show={showFilter} onHide={() => setShowFilter(false)}>*/}
                            {/*        <FilterDropDown*/}
                            {/*            show={showFilter} filters={filters} updateFilter={(val: any) => {*/}
                            {/*            setShowFilter(false)*/}
                            {/*        }} handleClose={() => setShowFilter(false)}/>*/}
                            {/*    </Modal>*/}

                            {/*    /!*{!isProd() && <span className="badge px-4 py-2 ms-4"*!/*/}
                            {/*    /!*                    style={{border: "1px solid #F5F8FA", color: "rgba(76, 76, 102, 1)"}}><span*!/*/}
                            {/*    /!*    style={{color: "rgba(126, 130, 153, 1)"}}>{intl.formatMessage({id: 'PLATFORMS'})}: &nbsp;</span> {*!/*/}
                            {/*    /!*    <ArrayToSpanFormatter items={statuses_platforms.map(item => item.toUpperCase())}/>}</span>}*!/*/}
                            {/*</div>*/}
                            {/*<div className='filter_buttons_group my-4'>*/}
                            {/*    <a*/}
                            {/*        href='#'*/}
                            {/*        className='badge bg-light bg-active-primary fw-medium text-primary px-4 py-2'*/}
                            {/*        data-kt-menu-trigger='click'*/}
                            {/*        data-kt-menu-placement='bottom-end'*/}
                            {/*        data-kt-menu-flip='top-end'*/}
                            {/*        onClick={() => setShowTableSort(!showTableSort)}*/}
                            {/*    >*/}
                            {/*        <KTSVG*/}
                            {/*            path='/media/icons/duotune/general/icon-settings.svg'*/}
                            {/*            className='svg-icon-5 text-primary'*/}
                            {/*        />*/}

                            {/*    </a>*/}

                            {/*    <Modal size="sm" show={showTableSort} onHide={() => setShowTableSort(false)}>*/}
                            {/*        <TableSort*/}
                            {/*            show={showTableSort} columns={arr_columns_clients} updateFilter={(val: any) => {*/}
                            {/*            setTableSort(val)*/}
                            {/*            setShowTableSort(false)*/}

                            {/*        }} columns_state={tableSort} handleClose={() => setShowTableSort(false)}/>*/}
                            {/*    </Modal>*/}


                            {/*</div>*/}
                        </div>
                        {
                            isLoading ? <span className="d-block" style={{padding: "50px 0px", textAlign: 'center'}}>
        Загрузка... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
    </span> : (<>{
                                columns.length ? <table
                                    id='kt_table_users'
                                    className='table fw-normal align-middle table-row-bordered fs-6 gy-5 dataTable no-footer table-hover'
                                    {...getTableProps()}
                                >
                                    <thead>
                                    <tr className='text-start text-muted fw-semibold fs-7 text-uppercase gs-0'>
                                        {headers.map((column: ColumnInstance<ModSession>) => {

                                                return (
                                                    <CustomHeaderColumn key={column.id} column={column}/>
                                                )
                                            }
                                        )}
                                    </tr>
                                    </thead>
                                    <tbody className='text-gray-600 fw-normal' {...getTableBodyProps()}>
                                    {rows.length > 0 ? (
                                        rows.map((row: Row<ModSession>, i) => {
                                            prepareRow(row)
                                            return <CustomRow row={row} key={`row-${i}-${row.id}`}/>
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan={7}>
                                                <div
                                                    className='d-flex text-center w-100 align-content-center justify-content-center'>
                                                    {intl.formatMessage({id: 'NO_MATCHING'})}
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table> : <div style={{height: "55vh"}}
                                                className="d-flex justify-content-center align-items-center">
                                    <a href="https://demo.globalpay.uz" target="_blank"
                                       className="btn btn-success text-white fs-3">
                                        {intl.formatMessage({id: "WATCH_DEMO_COMPANY1"})}
                                        <KTSVG path='/media/icons/duotune/arrows/arr001.svg'
                                               className='svg-icon-3 ms-3'/>
                                    </a>
                                </div>
                            }</>)
                        }


                    </div>
                    {
                        rows.length ?
                            <Pagination withInfo={true} position='end' infoText='агентов' pagination={pagination}
                                        updatePage={(values) => {
                                            getClients(values.currentPage + '')
                                        }
                                        }/> : ''
                    }
                </KTCardBody>
            </KTCard>
        </>
    );
};


const ClientsList: FC = () => {
    return (
        <ErrorProvider>
            <ClientsListInner/>
        </ErrorProvider>
    );
};

export default ClientsList;