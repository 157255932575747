import {useEffect, useState} from 'react'
import {Outlet, useLocation, useNavigate, useParams} from 'react-router-dom'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {Toolbar} from './components/toolbar/Toolbar'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
import {ThemeModeProvider,} from '../partials'
import {MenuComponent} from '../assets/ts/components'
import {observer} from "mobx-react";
import {employeeStore} from "@store/employee";
import {useErrors} from "@providers/context/ErrorsProvider";
import {company} from "@store/company";
import {Account} from "@providers/account/_request";
import {AsideAdmin} from "@metronic/layout/components/aside/admin/AsideAdmin";
import {roleRoutingAdmin, showToolBar} from "@utils/index";
import {Roles} from "@providers/roles/_requests";
import {rolesStore} from "@store/roles";
import {Employee} from "@providers/employee/_request";
import {IEmployeeType} from "@providers/employee/_models";

const MasterLayout = observer(() => {

    const {companyId} = useParams()
    const [role, setRole] = useState<string | null>(null)
    const {setError} = useErrors()
    const navigate = useNavigate()


    const roles = rolesStore


    const location = useLocation()
    useEffect(() => {
        setTimeout(() => {
            MenuComponent.reinitialization()
        }, 500)
    }, [])

    useEffect(() => {
        setTimeout(() => {
            MenuComponent.reinitialization()
        }, 500)
    }, [location.key])


    useEffect(() => {
        getDataCustomer()
    }, [companyId])


    function getRoles() {
        Roles.getAll().then((response) => {
            roles.setRoles(response.data.result)
        }).catch((err) =>  setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})).finally(() => {

        })
    }

    async function getEmployeeTypes () {
        try {
            const response: IEmployeeType[]  = await Employee.getEmployeeTypes().then((response) => response.data.result)
            employeeStore.setEmployeeTypes(response)
        }catch (err: any) {
        setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
        }
    }

    async function getDataCustomer() {
        await Account.getMe()
            .then(result => {
                employeeStore.setRole(result?.data?.result?.role)
                if (roleRoutingAdmin('action', result.data.result.role, 'is-admin')) {
                    getEmployeeTypes()
                }
                setRole(result?.data?.result?.role)
                company.setMe(result?.data?.result)
                getRoles()
            })
    }


    return (
        <PageDataProvider>
            <ThemeModeProvider>
                <div className='page d-flex flex-row flex-column-fluid'>

                    {
                        employeeStore.getRole ? (<>
                            {
                                roleRoutingAdmin('action', employeeStore.getRole, 'is-admin') ? (<AsideAdmin/>) :
                                    <AsideDefault/>
                            }
                        </>) : ''
                    }


                    <div
                        className={`${showToolBar(location.pathname) ? '' : 'no-toolbar'} wrapper d-flex flex-column flex-row-fluid`}
                        id='kt_wrapper'>
                        <HeaderWrapper/>

                        <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
                            {showToolBar(location.pathname) ? <Toolbar/> : ''}

                            <div className='post d-flex flex-column-fluid' id='kt_post'>
                                <Content>
                                    {(!companyId || companyId && role) && <Outlet context={{role}}/>}
                                </Content>
                            </div>
                        </div>
                        <Footer/>
                    </div>
                </div>


                <ScrollTop/>
            </ThemeModeProvider>
        </PageDataProvider>
    )
})

export {MasterLayout}
