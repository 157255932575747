// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserLastLoginCell} from './UserLastLoginCell'
import {TableCustomHeader} from './TableCustomHeader'
import {ModSession} from "@providers/session/_modals";
import {UserTwoStepsCell} from "./UserTwoStepsCell";


const usersColumns: ReadonlyArray<Column<ModSession>> = [
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='USE_AGENT' className='min-w-125px' />,
    id: 'userAgent',
    Cell: ({...props}) => <UserInfoCell userAgent={props.data[props.row.index].useragent} />,
  },
  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='LAST_SESSIONS' className='min-w-125px' />
    ),
    id: 'last_access_date',
    Cell: ({...props}) => <UserLastLoginCell last_access_date={props.data[props.row.index].last_access_date}/>

  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='STATUS' className='min-w-125px' />,
        id: 'current',
      Cell: ({...props}) => <UserTwoStepsCell current={props.data[props.row.index].current} />,
  },
]

export {usersColumns}
