import * as Yup from 'yup'
import {CompanyResponseOne, CreateCompany} from "@providers/company/_models";
import moment from "moment/moment";
import {useIntl} from "react-intl";
import {FILE_SIZE, SUPPORTED_FORMATS, SUPPORTED_FORMATS_IMAGES} from "@utils/constants";
import {ICreateAccount, userRoles} from "@providers/account/_models";

function SettingsMerchantSchema() {
    const intl = useIntl()

    return Yup.object({

        logoFile: Yup.mixed().test('fileSize', intl.formatMessage({id: 'file_size'}), value => {
            if (value !== null && typeof value === 'object') {
                const size_kb = +(value.size / 1024).toFixed(1)
                return size_kb <= FILE_SIZE
            }
            return true

        }).test('fileType', intl.formatMessage({id: 'type_file'}), value => {
            if (value !== null && typeof value === 'object') {
                return value ? SUPPORTED_FORMATS.includes(value.type) : true
            }
            return true
        }),
        name: Yup.string().required('Обязательное поле')
            .max(100, 'Слишком много символов').required('Обязательное поле'),
        addressRegistry: Yup.string().required('Обязательное поле')
            .max(500, 'Слишком много символов').required('Обязательное поле'),

        inn: Yup.string()
            .min(9, 'Не достаточно символов').required('Обязательное поле'),
        clientId: Yup.number()
    })


}


function SettingsAccountSchema() {
    const intl = useIntl()

    return Yup.object({

        firstName: Yup.string().required('Обязательное поле')
            .max(50, 'Слишком много символов').required('Обязательное поле'),
        lastName: Yup.string().required('Обязательное поле')
            .max(50, 'Слишком много символов').required('Обязательное поле'),
        email: Yup.string().max(50, 'Слишком много символов'),
        phone: Yup.string().required('Обязательное поле')
            .max(50, 'Слишком много символов').required('Обязательное поле')
    })


}


const inits: CreateCompany = {
    ceo_first_name: '',
    ceo_last_name: '',
    ikpu: '',
    ceo_passport_id: '',
    ceo_passport_date: '',
    type: 0,
    name: '',
    phone: "",
    email: "",
    nds: '',
    postal_code: '',
    legal_name: '',
    address: '',
    inn: '',
    pinfl: '',
    oked: '',
    mfo: '',
    bank: '',
    bank_filial: '',
    bank_account_id: '',
    brand_name: '',
    field_of_activity: 0,
    logo_file: undefined,
    license_file: undefined,
    ceo_passport_file: undefined,
    sub_field_of_activity: 0,
    commission: 0,
}

const initsCompany: CompanyResponseOne = {
    name: '',
    inn: '',
    active: false,
    addressRegistry: '',
    logoFile: '',
    brandName: '',
    id: 0,
    totalCount: 0,
    totalAmount: 0,
    style: []
}


const initsAccount: ICreateAccount = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    role: userRoles.USER_ADMIN
}


export {SettingsMerchantSchema, inits, initsCompany, SettingsAccountSchema, initsAccount}


