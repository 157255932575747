/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useMemo} from 'react'
import {initialQueryState, KTSVG, useDebounce} from '@metronic/helpers'
import {useQueryRequest} from '@providers/QueryRequestProvider'
import {observer} from "mobx-react";
import {Agent} from "@providers/agents/_request";
import {agentStore} from "@store/agents";
import {toIsoStringHelper} from "../../../../autopay/dateHelper";
import moment from "moment";
import {useParams} from "react-router-dom";
import {COMPANY, INDIVIDUAL} from "@utils/constants";

const UsersListSearchComponent = observer(() => {
    const {updateState} = useQueryRequest()
    const [searchTerm, setSearchTerm] = useState<string>('')
    const sort = useMemo(()=>agentStore.getSorting, [agentStore.getSorting])
    const {type} = useParams()


    async function searchItems(){

        let data:any = {
            page: sort.page,
            count: 20,
            from_date: sort.from_date,
            to_date: sort.to_date,
            sort_by: sort.sortBy,
            sort_order: sort.sortFromMax?'DESC':'ASC',
            types: sort.types
        }
        if(searchTerm.trim().length>0){
            data['query'] = searchTerm
            agentStore.setSorting({...agentStore.getSorting, query: data?.query})
        }else{
            agentStore.setSorting({...agentStore.getSorting, query: ''})
        }

        await Agent.getAgents(data)
            .then(result=>{
                agentStore.setSorting({...result?.data?.meta, ...sort,  page:agentStore.getSorting.page, currentPage: agentStore.getSorting.page})
                agentStore.setAgent(result?.data?.result.map((item: any, index: number) => {
                    if (sort.page && sort.page > 2) {
                        item['num'] = ((sort.page - 1) * 20) + index + 1
                    } else if (sort.page == 2) {
                        item['num'] = 21 + index
                    } else {
                        item['num'] = index + 1
                    }
                    return item
                }))
            })

        await Agent.getAgentsGeneral({from_date:data?.from_date,
            to_date:data?.to_date,
            types:data?.types, query: data?.query})
            .then(result=>{
                agentStore.setGeneralStatistics(result?.data?.result)
            })


    }



    return (
        <div className='card-title'>
            {/* begin::Search */}
            <div className='d-flex align-items-center position-relative my-1'>

                <input
                    type='text'
                    data-kt-user-table-filter='search'
                    className='form-control w-400px ps-5 fs-7 fw-bold'
                    placeholder='Поиск по названию'
                    style={{height: "44px",border: "none", borderRadius: "6px"}}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <button type="button"
                        onClick={()=>searchItems()}
                        style={{borderTopRightRadius: "6px", borderBottomRightRadius: "6px", width: "44px"}}
                        className='border-0 text-hover-secondary cursor-pointer position-absolute end-0 bg-primary h-100 d-flex justify-content-center align-items-center'>
                    <KTSVG
                        path='/media/icons/duotune/general/gen021.svg'
                        className='svg-icon-1 text-white'
                    />
                </button>
            </div>
            {/* end::Search */}
        </div>
    )
})

export {UsersListSearchComponent}
