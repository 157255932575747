import React, {useMemo} from "react";
import {grafikColumns} from "./columns/_columns";
import {ColumnInstance, Row, useTable} from "react-table";
import {Schedule} from "@providers/autopay/models/_models";
import {ModSession} from "@providers/session/_modals";
import {CustomHeaderColumn} from "../../contractsList/_modals/columns/CustomHeaderColumn";
import {CustomRow} from "../../contractsList/_modals/columns/CustomRow";
import {useIntl} from "react-intl";

type IProps = {
    schedules: Schedule[]
}

export function TableTab(props:IProps){
    const intl = useIntl()
    const data = useMemo(()=>props.schedules.map((item:any, index:number)=>{
        return{
            num: index+1,
            ...item
        }
    })||[], [props.schedules])
    const columns = useMemo(() => grafikColumns, [props.schedules])
    const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
        columns,
        data,
    })
    return(
        <table
            id='kt_table_users'
            className='table fw-normal align-middle table-row-bordered fs-6 gy-5 dataTable no-footer table-hover cursor-pointer'
            {...getTableProps()}
        >
            <thead>
            <tr className='text-start text-muted fw-semibold fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<ModSession>) => {

                        return (
                            <CustomHeaderColumn key={column.id} column={column}/>
                        )
                    }
                )}
            </tr>
            </thead>
            <tbody className='text-gray-600 fw-normal' {...getTableBodyProps()}>
            {rows.length > 0 ? (
                rows.map((row: Row<ModSession>, i) => {
                    prepareRow(row)
                    return <CustomRow row={row} key={`row-${i}-${row.id}`}/>
                })
            ) : (
                <tr>
                    <td colSpan={7}>
                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                            {intl.formatMessage({id: 'NO_MATCHING'})}
                        </div>
                    </td>
                </tr>
            )}
            </tbody>
        </table>
    )
}