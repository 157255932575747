import {Navigate, useOutletContext, useParams} from "react-router-dom";
import {PageLink} from "@metronic/layout/core";
import {Nav} from "react-bootstrap";
import React, {useEffect, useMemo, useState} from "react";

//Components
import {StatisticModule} from "../statistic";
import EmployeesMerchant from "../employee/employees";
import PaymentsMerchant from "../payments/payments";
import FilialSettingsMerchant from "../settings/settings";
import {observer} from "mobx-react";
import {employeeStore} from "@store/employee";
import {NumReplace, roleRouting, roleRoutingAdmin} from "@utils/index";
import {useIntl} from "react-intl";
import {ErrorProvider, useErrors} from "@providers/context/ErrorsProvider";
import {KTCard, KTSVG} from "@metronic/helpers";
import {Company} from "@providers/company/_request";
import {Dropdown, Menu, Tooltip} from "antd";
import {Filial} from "@providers/filial/_request";
import {PageTitleCustom} from "@metronic/partials/pageTitleCustom";
import {filial} from "@store/filial";
import {ICashbox} from "@providers/filial/_models";
import {UsersListLoading} from "@metronic/partials/widgets/tables/_modals/components/loading/UserListLoading";
import {openNotificationSuccess} from "@metronic/partials/notification";
import WebPayments from "../webPayments/webPayments";
import {company} from "@store/company";
import {priceformatter} from "@utils/priceformatter";
import {divideByHundred} from "@utils/numbersOperations";


const classStatus: any = {
    DRAFT: 'secondary',
    ACTIVE: 'light-success',
    DISABLED: 'light-danger',
}

const iconsStatus = {
    DRAFT: "/media/svg/revert.svg",
    ACTIVE: "/media/svg/approved.svg",
    DISABLED: "/media/svg/failed.svg",
}


const FilialView = observer(() => {
    const [activeKey, setActiveKey] = useState<string | null>('statistics')
    const {id, companyId} = useParams()
    const intl = useIntl()
    const companyData: ICashbox = useMemo(() => filial.getFilialOne, [filial.getFilialOne])
    const [staticticInfo, setStatisticInfo] = useState<any>({})
    const {setError} = useErrors()
    const [statusChecked, setStatusChecked] = useState<boolean>(companyData.active)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [ready, setReady] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false)

  const companyItem = company.getOneCompany


    const createCompanyBreadCrumbsAdmin: Array<PageLink> = [
        {
            title: 'Компании',
            path: `/companies`,
            isSeparator: false,
            isActive: false,
        },
        {
            title: company.getOneCompany?.name || '',
            path: `/companies/${companyId}`,
            isSeparator: false,
            isActive: false,
        },
        {
            title:  companyData.name ?? '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
    ]


    useEffect(() => {
        if (id && companyId) {
            Promise.all([getСompanyInfo(), getFilial()])
                .then(() => setReady(true))
        }
    }, [])

    const widgetMenu = (
        <Menu className='p-7 change_status_group'>
            <p className='text-gray-800 fw-bold'>Изменить статус</p>
            <div>

                <div className={`item_check_status d-flex align-items-center justify-content-between border border-gray-300 mb-3
               ${statusChecked ? 'active' : ''}`}

                     onClick={() => {
                         setStatusChecked(true)
                     }}
                >
                    <div className='d-flex  align-items-center'>
                       <span className='me-5'>
                          <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <rect width="28" height="28" rx="6" fill="#ECFDF3"/>
                            <path d="M18 11L12.5 16.5L10 14" stroke="#12B76A" strokeWidth="1.5" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                          </svg>

                       </span>
                        <p className='fw-bold fs-6 text-gray-800 mb-0'>Активный</p>
                    </div>
                    <div>
                        <div className="form-check form-check-custom form-check-solid">
                            <input className="form-check-input"
                                   type="radio"
                                   checked={statusChecked}
                            />
                        </div>
                    </div>
                </div>
                <div className={`item_check_status d-flex align-items-center justify-content-between border border-gray-300 
               ${!statusChecked ? 'active' : ''}`}
                     onClick={() => {
                         setStatusChecked(false)
                     }}
                >
                    <div className='d-flex  align-items-center'>
                       <span className='me-5'>
                          <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <rect width="28" height="28" rx="6" fill="#FEF3F2"/>
                            <path d="M17 11L11 17M11 11L17 17" stroke="#F04438" strokeWidth="1.5" strokeLinecap="round"
                                  strokeLinejoin="round"/>
                          </svg>


                       </span>
                        <p className='fw-bold fs-6 text-gray-800 mb-0'>Неактивный</p>
                    </div>
                    <div>
                        <div className="form-check form-check-custom form-check-solid">
                            <input className="form-check-input"
                                   type="radio"
                                   checked={!statusChecked}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className='mt-8'>
                <button className='btn btn-light fw-bold me-3' onClick={() => setModalIsOpen(false)}>
                    Отмена
                </button>
                <button className='btn btn-primary fw-bold' onClick={() => changeStatusSubmit()}>
                    Применить
                </button>
            </div>
        </Menu>
    );


    async function getFilial() {
        await Filial.getFilialById(id ?? '')
            .then((result) => {
                setStatusChecked(result?.data?.result?.active)
                filial.setFilialOne(result?.data?.result)
            })
    }

    function changeStatusSubmit() {
        setModalIsOpen(false)
        setConfirmLoading(true)
        Filial.patchFilial(companyId ? companyId : '', id ? id : '',
            {active: statusChecked, id: Number(id)})
            .then((result) => {
                setStatusChecked(result?.data?.result?.active)
                openNotificationSuccess(intl.formatMessage({id: 'cashboxEdited'}))
                filial.setFilialOne(result?.data?.result)
            })
            .catch(err => {
                setStatusChecked(companyData.active)
                setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
            }).finally(() => setConfirmLoading(false))

    }

    async function getСompanyInfo() {
        await Company.getById(companyId ?? '')
            .then(result => {
                company.setCompanyItem(result.data.result)
            }).catch(err => {
                setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
            })
    }



    const switchTabs = () => {
        switch (activeKey) {
            case 'statistics':
                return <StatisticModule setStatisticGeneral={(val) => setStatisticInfo(val)}/>
            case 'employee':
                return <EmployeesMerchant/>
            case 'payments':
                return <PaymentsMerchant/>
            case 'settings':
                return <FilialSettingsMerchant/>
            default:
               return <WebPayments/>
        }
    }


    if (!ready) return <UsersListLoading/>


    return (
        <>
            <PageTitleCustom title={companyData?.name || ''}
                             breadCrumbs={createCompanyBreadCrumbsAdmin}/>
            <KTCard className='mb-5'>
                {confirmLoading && <UsersListLoading/>}
                <div className='card mb-5 mb-xl-10'>
                    <div className='card-body pt-9 pb-0'>
                        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                            <div className='me-7 mb-4'>
                                <div
                                    className='p-3 d-flex flex-column justify-content-center symbol logo-project bg-secondary symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                                    {
                                        ready ? <> {
                                            !companyData?.logoId ? (<p style={{fontSize: '4rem'}}
                                                                       className='text-center mb-0 fw-bolder text-primary'>{(companyData?.name || '').split(' ').map((item: string) => item.length ? item[0].toUpperCase() : item).join('')}</p>)
                                                : (<img className='w-100 h-100' style={{objectFit: 'contain'}}
                                                        src={Company.getFiles(companyData.logoId)} alt=''/>)
                                        }</> : ''
                                    }
                                </div>
                            </div>

                            <div className='flex-grow-1'>
                                <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                                    <div className='d-flex flex-column'>
                                        <div className='d-flex align-items-center mb-2'>
                                            <p className='text-gray-800 fs-2 fw-bolder me-1 mb-0'>
                                                {companyData?.name}
                                            </p>
                                            {/*@ts-ignore*/}
                                            <div
                                                className={`badge badge-${classStatus[companyData?.active ? 'ACTIVE' : 'DISABLED']} mx-5`}>
                                                <>{roleRoutingAdmin('action', employeeStore.getRole, 'is-admin')
                                                    ? (<Dropdown overlay={widgetMenu} open={modalIsOpen}>
                                                        <a onClick={(e) => {
                                                            e.preventDefault()
                                                            setModalIsOpen(!modalIsOpen)
                                                        }}>
                                                            {/*@ts-ignore*/}
                                                            <div
                                                                className={`badge badge-${classStatus[companyData?.active ? 'ACTIVE' : 'DISABLED']} fw-bolder`}>
                                                                {/*@ts-ignore*/}
                                                                <KTSVG
                                                                    path={iconsStatus[companyData?.active ? 'ACTIVE' : 'DISABLED']}
                                                                    className={`svg-icon-${classStatus[companyData?.active ? 'ACTIVE' : 'DISABLED']} svg-icon-2hx me-2`}/>
                                                                {intl.formatMessage({id: companyData?.active ? 'ACTIVE' : 'DISABLED'})}
                                                            </div>
                                                        </a>
                                                    </Dropdown>) : (
                                                        <div
                                                            className={`badge badge-${classStatus[companyData?.active ? 'ACTIVE' : 'DISABLED']} fw-bolder`}>
                                                            {/*@ts-ignore*/}
                                                            <KTSVG
                                                                path={iconsStatus[companyData?.active ? 'ACTIVE' : 'DISABLED']}
                                                                className={`svg-icon-${classStatus[companyData?.active ? 'ACTIVE' : 'DISABLED']} svg-icon-2hx me-2`}/>
                                                            {intl.formatMessage({id: companyData?.active ? 'ACTIVE' : 'DISABLED'})}
                                                        </div>)
                                                }
                                                </>

                                            </div>
                                        </div>

                                        <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                                            {companyData?.billingUrl ? <a
                                                href={companyData.billingUrl}
                                                target={'_blank'}
                                                rel={'noreferrer'}
                                                className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                                            >
                                                <KTSVG
                                                    path='/media/svg/site.svg'
                                                    className='svg-icon-4 me-1'
                                                />
                                                {companyData.billingUrl}
                                            </a> : null}
                                            {companyData.token && <span
                                                className='d-flex align-items-center text-break text-gray-400 me-5 mb-2'
                                            >
                                                  <KTSVG
                                                      path='/media/svg/site.svg'
                                                      className='svg-icon-4 me-1'
                                                  />
                                                {companyData?.token}
                                            </span>}
                                        </div>
                                    </div>

                                </div>

                                <div className='d-flex flex-wrap flex-stack'>
                                    <div className='d-flex flex-column flex-grow-1 pe-8'>
                                        <div className='d-flex flex-wrap'>
                                            <div
                                                className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <div
                                                        className='fs-2 fw-bolder me-5'>{companyData.totalCount}</div>
                                                    <Tooltip color='var(--kt-body-bg)' placement="topLeft"
                                                             title={() => <span
                                                                 style={{color: 'var(--kt-text-gray-800)'}}>{intl.formatMessage({id: 'transactions_info_count'})}</span>}>
                                                        <i style={{fontSize: "16px"}}
                                                           className="fa-sharp fa-solid fa-circle-info"/>
                                                    </Tooltip>
                                                </div>

                                                <div className='fw-bold fs-6 text-gray-400'>Все транзакции</div>
                                            </div>

                                            <div
                                                className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <div
                                                        className='fs-2 fw-bolder me-5'>{priceformatter(divideByHundred(companyData.totalAmount))}
                                                    </div>
                                                    <Tooltip color='var(--kt-body-bg)' placement="topLeft"
                                                             title={() => <span
                                                                 style={{color: 'var(--kt-text-gray-800)'}}>{intl.formatMessage({id: 'amount_from_register'})}</span>}>
                                                        <i style={{fontSize: "16px"}}
                                                           className="fa-sharp fa-solid fa-circle-info"/>
                                                    </Tooltip>
                                                </div>

                                                <div className='fw-bold fs-6 text-gray-400'>Общая сумма</div>
                                            </div>


                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className='d-flex h-55px'>

                            <Nav fill variant="tabs"
                                 className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap"
                                 onSelect={(e) => setActiveKey(e)}>

                                {
                                    (roleRouting('filial/statistic', employeeStore.getRole)
                                        || roleRoutingAdmin('page', employeeStore.getRole, 'filial/statistic')) &&
                                    <Nav.Item>
                                        <Nav.Link
                                            className={`nav-link text-active-primary me-6 text-gray-800 ${activeKey === 'statistics' ? 'active' : ''}`}
                                            eventKey="statistics">{intl.formatMessage({id: "DASHBOARD_MERCHANT"})}</Nav.Link>
                                    </Nav.Item>
                                }

                                {/*{*/}
                                {/*    (roleRouting('filial/employee', employeeStore.getRole) ||*/}
                                {/*        roleRoutingAdmin('page', employeeStore.getRole, 'filial/employee')) &&*/}
                                {/*    <Nav.Item>*/}
                                {/*        <Nav.Link*/}
                                {/*            className={`nav-link text-active-primary me-6 text-gray-800 ${activeKey === 'employee' ? 'active' : ''}`}*/}
                                {/*            eventKey="employee">{intl.formatMessage({id: "employee"})}</Nav.Link>*/}
                                {/*    </Nav.Item>*/}
                                {/*}*/}

                                {
                                    (roleRouting('filial/payments', employeeStore.getRole) ||
                                        roleRoutingAdmin('page', employeeStore.getRole, 'filial/payments')) &&
                                    <Nav.Item>
                                        <Nav.Link
                                            className={`nav-link text-active-primary me-6 text-gray-800 ${activeKey === 'payments' ? 'active' : ''}`}
                                            eventKey="payments">{intl.formatMessage({id: "transactions"})}</Nav.Link>
                                    </Nav.Item>
                                }
                                {companyItem.clientId &&
                                    (roleRouting('filial/web-payments', employeeStore.getRole) ||
                                        roleRoutingAdmin('page', employeeStore.getRole, 'filial/web-payments')) &&
                                    <Nav.Item>
                                        <Nav.Link
                                            className={`nav-link text-active-primary me-6 text-gray-800 ${activeKey === 'web-payments' ? 'active' : ''}`}
                                            eventKey="web-payments">{intl.formatMessage({id: "webPayments"})}</Nav.Link>
                                    </Nav.Item>
                                }
                                {
                                    (roleRouting('filial/settings', employeeStore.getRole) ||
                                        roleRoutingAdmin('page', employeeStore.getRole, 'filial/settings')) &&
                                    <Nav.Item>
                                        <Nav.Link
                                            className={`nav-link text-active-primary me-6 text-gray-800 ${activeKey === 'settings' ? 'active' : ''}`}
                                            eventKey="settings">{intl.formatMessage({id: "settings"})}</Nav.Link>
                                    </Nav.Item>
                                }


                            </Nav>
                        </div>

                    </div>
                </div>
            </KTCard>

            <div>
                {switchTabs()}
            </div>

        </>
    )
})


export default function FilialViewWrapper() {
    //@ts-ignore
    const {role} = useOutletContext()


    if (roleRouting('filial', role) || roleRoutingAdmin('action', role, 'is-admin')) {
        return (
            <ErrorProvider>
                <FilialView/>
            </ErrorProvider>
        )
    }

    return <Navigate to={`/error/404`}/>

}