import {Column} from 'react-table'
import {TableCustomHeader} from './TableCustomHeader'
import {TableText} from "./TableText";
import {PhoneAgent} from "./PhoneAgent";
import {AgentDate} from "./AgentDate";
import {NumReplace} from "@utils/index";
import {IContractsResponse} from "@providers/autopay/_interfaces";
import {StatusAutopay} from "./StatusAutopay";
import {ContractLink} from "./AgentLink";
import {AgentService} from "./UserInfoCell";

const arr_columns = [
  {
    type: 'num',
    disable: false,
    renderType: 'text',
    className: 'min-w-50px'
  },
  {
    type: 'contractNumber',
    disable: true,
    renderType: 'link',
    className: 'min-w-125px'
  },
  {
    type: 'phoneNumber',
    disable: true,
    renderType: 'phone',
    className: 'min-w-150px'
  },
  {
    type: 'productPrice',
    disable: true,
    renderType: 'price',
    className: 'min-w-125px'
  },
  {
    type: 'status',
    disable: true,
    renderType: 'status',
    className: 'min-w-185px'
  },
  {
    type: 'contractDate',
    disable: true,
    renderType: 'date',
    className: 'min-w-125px'
  },
  {
    type: 'clientPinfl',
    disable: false,
    renderType: 'text',
    className: 'min-w-150px'
  },
  {
    type: 'productName',
    disable: false,
    renderType: 'text',
    className: 'min-w-150px'
  },
  {
    type: 'serviceDetails',
    disable: false,
    renderType: 'service',
    className: 'min-w-150px'
  },

  {
    type: 'prepaymentPrice',
    disable: false,
    renderType: 'price',
    className: 'min-w-125px'
  },
  {
    type: 'discountPrice',
    disable: false,
    renderType: 'price',
    className: 'min-w-125px'
  },
  {
    type: 'overdueLimit',
    disable: false,
    renderType: 'price',
    className: 'min-w-125px'
  },
  {
    type: 'paidSum',
    disable: false,
    renderType: 'price',
    className: 'min-w-125px'
  },
  {
    type: 'contractVersion',
    disable: false,
    renderType: 'schedules',
    className: 'min-w-125px'
  },
  {
    type: 'remainingSum',
    disable: false,
    renderType: 'price',
    className: 'min-w-125px'
  }


]


function contractColumnsGet (columns:any[]){
  return columns.map((item:any)=>{
    if(item.renderType==='text'){
      return {
        Header: (props:any) => <TableCustomHeader tableProps={props} title={item.type} className={`fw-bold text-gray-700 ${item.className}`} />,
        id: item.type,
        Cell: ({...props}) => <TableText text={props.data[props.row.index][item.type]} translate={false}/>,
      }
    }else if(item.renderType==='date'){
      return {
        Header: (props:any) => (
            <TableCustomHeader tableProps={props} title={item.type}  className={`fw-bold text-gray-700 ${item.className}`} />
        ),
        id: item.type,
        Cell: ({...props}) => <AgentDate date={props.data[props.row.index][item.type]}  />,
      }
    }else if(item.renderType==='schedules'){
      return {
        Header: (props:any) => (
            <TableCustomHeader tableProps={props} title={item.type}  className={`fw-bold text-gray-700 ${item.className}`} />
        ),
        id: item.type,
        Cell: ({...props}) => {
          let next_payment_date:any = {}
          if(props.data[props.row.index][item.type]){
            const {schedules} = props.data[props.row.index][item.type]
            next_payment_date = schedules.find((item:any)=>!item.paidDate)
          }
          return (<AgentDate date={next_payment_date?.scheduleDate}  />)
        },
      }
    }
    else if(item.renderType==='service'){
      return {
        Header: (props:any) => (
            <TableCustomHeader tableProps={props} title={item.type}  className={`fw-bold text-gray-700 ${item.className}`} />
        ),
        id: item.type,
        Cell: ({...props}) => <AgentService serviceDetails={props.data[props.row.index][item.type]}  />,
      }
    }else if(item.renderType==='link'){
      return {
        Header: (props:any) => <TableCustomHeader tableProps={props} title={item.type} className={`fw-bold text-gray-700 ${item.className}`} />,
        id: item.type,
        Cell: ({...props}) => <ContractLink text={props.data[props.row.index][item.type]} id={props.data[props.row.index].contractId}/>,
      }
    }else if(item.renderType==='phone'){
      return {
        Header: (props:any) => (
            <TableCustomHeader tableProps={props} title={item.type}  className={`fw-bold text-gray-700 ${item.className}`} />
        ),
        id: item.type,
        Cell: ({...props}) => <PhoneAgent text={props.data[props.row.index][item.type]}  />,
      }
    }else if(item.renderType==='price'){
      return {
        Header: (props:any) => (
            <TableCustomHeader tableProps={props} title={item.type}  className={`fw-bold text-gray-700 ${item.className}`} />
        ),
        id: item.type,
        Cell: ({...props}) => <TableText text={`${NumReplace((props.data[props.row.index][item.type]>0?props.data[props.row.index][item.type]/100:0)+'')} UZS`} translate={false}/>,
      }
    }else if(item.renderType==='status'){
      return {
        Header: (props:any) => (
            <TableCustomHeader tableProps={props} title={item.type}  className={`fw-bold text-gray-700 ${item.className}`} />
        ),
        id: item.type,
        Cell: ({...props}) => <StatusAutopay status={`${props.data[props.row.index][item.type]}`} />,
      }
    }else{
      return {
        Header: (props:any) => <TableCustomHeader tableProps={props} title={item.type} className={`fw-bold text-gray-700 ${item.className}`} />,
        id: item.type,
        Cell: ({...props}) => <TableText text={props.data[props.row.index][item.type]} translate={false}/>,
      }
    }


  })

}

const contractColumns: ReadonlyArray<Column<IContractsResponse>> = [

  {
    Header: (props) => <TableCustomHeader tableProps={props} title='№' className='min-w-30px fw-bold text-gray-700' />,
    id: 'num',
    Cell: ({...props}) => <TableText text={props.data[props.row.index]?.num} translate={false}/>,
  },

  {
    Header: (props) => <TableCustomHeader tableProps={props} title='contractNumber' className='min-w-125px fw-bold text-gray-700 ' />,
    id: 'contractNumber',
    Cell: ({...props}) => <TableText text={props.data[props.row.index]?.contractNumber} translate={false} />,
  },

  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='contractDate' className='min-w-125px fw-bold text-gray-700' />
    ),
    id: 'contractDate',
    Cell: ({...props}) => <AgentDate date={props.data[props.row.index].contractDate}  />,
  },
 

  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='clientPinfl' className='min-w-125px fw-bold text-gray-700' />
    ),
    id: 'clientPinfl',
    Cell: ({...props}) => <TableText text={props.data[props.row.index].clientPinfl} translate={false} />,
  },
  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='productName' className='min-w-125px fw-bold text-gray-700' />
    ),
    id: 'productName',
    Cell: ({...props}) => <TableText text={props.data[props.row.index].productName} translate={false} />,
  },
  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='clientNumber' className='min-w-125px fw-bold text-gray-700' />
    ),
    id: 'clientNumber',
    Cell: ({...props}) => <PhoneAgent text={props.data[props.row.index].clientNumber}  />,
  },

  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='productPrice' className='min-w-150px fw-bold text-gray-700' />
    ),
    id: 'productPrice',
    Cell: ({...props}) => <TableText text={`${NumReplace((props.data[props.row.index].productPrice>0?props.data[props.row.index].productPrice:0)+'')} UZS`} translate={false} />,
  },
  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='prepaymentPrice' className='min-w-150px fw-bold text-gray-700' />
    ),
    id: 'prepaymentPrice',
    Cell: ({...props}) => <TableText text={`${NumReplace((props.data[props.row.index].prepaymentPrice>0?props.data[props.row.index].prepaymentPrice:0)+'')} UZS`} translate={false} />,
  },
  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='discountPrice' className='min-w-150px fw-bold text-gray-700' />
    ),
    id: 'discountPrice',
    Cell: ({...props}) => <TableText text={`${NumReplace((props.data[props.row.index].discountPrice>0?props.data[props.row.index].discountPrice:0)+'')} UZS`} translate={false} />,
  },
  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='overdueLimit' className='min-w-150px fw-bold text-gray-700' />
    ),
    id: 'overdueLimit',
    Cell: ({...props}) => <TableText text={`${NumReplace((props.data[props.row.index].overdueLimit>0?props.data[props.row.index].overdueLimit:0)+'')} UZS`} translate={false} />,
  },
  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='paidSum' className='min-w-150px fw-bold text-gray-700' />
    ),
    id: 'paidSum',
    Cell: ({...props}) => <TableText text={`${NumReplace((props.data[props.row.index].paidSum>0?props.data[props.row.index].paidSum:0)+'')} UZS`} translate={false} />,
  },
  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='status' className='min-w-150px fw-bold text-gray-700' />
    ),
    id: 'status',
    Cell: ({...props}) => <TableText text={`${props.data[props.row.index].status}`} translate={false} />,
  },

]




export {contractColumns, contractColumnsGet, arr_columns}
