import {UserEditModalForm} from './UserEditModalForm'
import {useListView} from '../providers/ListViewProvider'
import {useEffect, useState} from "react";
import {useErrors} from "@providers/context/ErrorsProvider";
import {Agent} from "@providers/agents/_request";

const UserEditModalFormWrapper = () => {
  const {itemIdForUpdate} = useListView()
  const {error,setError} = useErrors()
  const [isLoading, setLoading] = useState(false)






  if (!itemIdForUpdate) {
    return <UserEditModalForm isUserLoading={isLoading} agent={{id: undefined}} />
  }



  if (!isLoading && !Object.keys(error).length && itemIdForUpdate) {
    return <UserEditModalForm isUserLoading={isLoading} agent={itemIdForUpdate} />
  }




  return null
}

export {UserEditModalFormWrapper}
