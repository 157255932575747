import React, {useState} from 'react';
import {Modal} from "react-bootstrap";
import {KTSVG} from "@metronic/helpers";
import {useIntl} from "react-intl";
import {ContractsService} from "@providers/autopay/requests/contracts.service";
import {
    UsersListLoading
} from "../../../../../../modules/apps/user-management/users-list/components/loading/UsersListLoading";
import {useParams} from "react-router-dom";
import {useErrors} from "@providers/context/ErrorsProvider";
import {contract} from "@store/contract/contract";
import {ContractStatus} from "@providers/autopay/models/_models";
import {isDisabledContract} from "@utils/isDisabledContract";


interface props {
    isApproved: boolean
    status: ContractStatus
}

const ContractStopModal: React.FC<props> = ({
                                                isApproved,
                                                status
                                            }) => {
    const [show, setShow] = useState<boolean>(false)

    const {setError, error} = useErrors()

    const {contractId} = useParams()

    const [confirmLoading, setConfirmLoading] = useState<boolean>(false)

    const onShow = () => setShow(true)

    const getContactDetails = async () => {
        if (contractId) {
            try {
                let {data} = await ContractsService.getContractById(contractId)
                contract.setContract(data.data)
            } catch (e: any) {
                if (e.response.status === 404) {
                    setError({message: intl.formatMessage({id: 'contract_not_found'}), isError: true, status: 404})
                }
                if (e?.response?.data?.error || e?.response?.data?.message) {
                    setError({
                        message: e?.response?.data?.error || e?.response?.data?.message,
                        isError: true,
                        status: e?.response?.status
                    })
                    contract.setContractError(e.response.data.error || e?.response?.data?.message)
                }
            }
        }
    }

    const handleStop = () => {
        setConfirmLoading(true)

        if (contractId) {
            ContractsService.stopContractById(contractId.toString())
                .then((response) => {
                    if (response && response.data) {
                        getContactDetails()
                        onHide()
                    }
                }).catch((e) => {
                if (e?.response?.data?.error) {
                    setError({message: e?.response?.data?.error, isError: true, status: e?.response?.status})
                }

            }).finally(() => setConfirmLoading(false))
        }
    }


    const onHide = () => setShow(false)
    const intl = useIntl()
    return (
        <>
            {confirmLoading && <UsersListLoading/>}
            <button disabled={!isApproved || isDisabledContract(status)} onClick={onShow}
                    className={`btn btn-danger ms-auto d-flex align-items-center ${!isApproved ? 'disabled' : ''}`}>
                <span className={"text-white"}>
                    {intl.formatMessage({id: 'stop_contract'})}
                </span>
            </button>
            <Modal
                show={show}
                onHide={onHide}
            >
                <Modal.Header closeButton>
                    <h2>{intl.formatMessage({id: "stop"})}</h2>
                </Modal.Header>
                <Modal.Body>
                    <div className='fs-3'>{intl.formatMessage({id: "stop_approve_contract"})}</div>
                </Modal.Body>
                <Modal.Footer className='justify-content-between'>
                    <button disabled={confirmLoading || isDisabledContract(status)} type="button"
                            className={`swal2-confirm btn btn-secondary ${confirmLoading  || isDisabledContract(status)? 'disabled' : ''}`}
                            aria-label=""
                            style={{display: 'inline-block'}} onClick={onHide}>
                        {intl.formatMessage({id: "CANCEL_BUTTON"})}
                    </button>
                    <button disabled={confirmLoading || isDisabledContract(status)} onClick={handleStop}
                            className={`swal2-confirm btn btn-danger ${confirmLoading || isDisabledContract(status) ? 'disabled' : ''}`} aria-label=""
                            style={{display: 'inline-block'}}>
                        {intl.formatMessage({id: "CHECK"})}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ContractStopModal;