/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link, useParams} from "react-router-dom";
import {FilialResponse, ICashbox} from "@providers/filial/_models";
import {Company} from "@providers/company/_request";
import {image_default} from "@utils/constants";

type Props = {
    filial: ICashbox
}

const UserInfoCell: FC<Props> = ({filial}) => {
    const {companyId} = useParams()

    return (
        <div className='d-flex align-items-center w-200px'>
            <div className='me-3'>
                <Link to={`/${companyId}/cashboxes/${filial.id}`}>
                    <div className='d-flex align-items-center'>
                        <div className='logo-table'>
                            <img src={`/${image_default}`}/>
                        </div>
                        <span style={{textOverflow:'ellipsis', overflow:'hidden', whiteSpace:'nowrap'}}>{filial.name}</span>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export {UserInfoCell}
