import {FC} from 'react'
import moment from "moment";

type Props = {
    value: string
}

const CustomField: FC<Props> = ({value}) => (
 <div className='fw-bolder'>{value}</div>
)

export {CustomField}