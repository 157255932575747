import {Table} from "../_modals/Table";
import {KTCard, KTSVG} from "@metronic/helpers";
import {UsersListHeader} from "../_modals/table-components/header/UsersListHeader";
import React, {useState, useEffect, useMemo} from "react";
import {ErrorProvider, useErrors} from "@providers/context/ErrorsProvider";
import {observer} from "mobx-react";
import {useIntl} from "react-intl";
import {PageTitleCustom} from "@metronic/partials/pageTitleCustom";
import {useParams} from "react-router-dom";
import {Merchants} from "./tabs/Merchants";
import {Settings} from "./tabs/Settings";
import {NumReplace, ReplacePhone, roleRoutingAdmin} from "@utils/index";
import {Dropdown, Tooltip} from "antd";
import {Nav} from "react-bootstrap";
import {Agent} from "@providers/agents/_request";
import {toIsoStringHelper} from "../../autopay/dateHelper";
import moment from "moment";
import {PageLink} from "@metronic/layout/core";
import {ListViewProvider, useListView} from "./_modals/providers/ListViewProvider";
import {UserEditModal} from "./_modals/merchant-add/UserEditModal";
import {agentStore} from "@store/agents";


export interface IMerchants {
    "id": number,
    "type": string,
    "phone": string,
    "contract_id": string,
    "contract_expiry_date": string,
    "created_date": string,
    "first_name": string,
    "last_name": string,
    "middle_name": string,
    "pinfl": string,
    "company_name": string,
    "inn": string,
    "certificate_id": string,
    "passport_id": string
}

export interface IStatistic {
    merchants: number
    income: number
}





const AgentPage = observer(() => {

    const [activeKey, setActiveKey] = useState<string|null>('merchants')
    const merchantData:IMerchants = useMemo(()=>agentStore.getAgentData, [agentStore.getAgentData])
    const staticticInfo:IStatistic = useMemo(()=>agentStore.getStatisticGeneralPage, [agentStore.getStatisticGeneralPage])
    const {itemIdForUpdate} = useListView()
    const {setError} = useErrors()
    const {id} = useParams()
    const intl = useIntl()


    const createCompanyBreadCrumbs: Array<PageLink> = [
        {
            title: intl.formatMessage({id:'agents'}),
            path: '/agents/statistic',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
    ]


    useEffect(()=>{
        getMerchant()
    }, [id])


    async function getMerchant(){
        if(id){
            await Agent.getAgentById(+id)
                .then(result=>{
                    agentStore.setAgentData(result?.data?.result)
                    getStatisticInfo()
                })
        }
    }


    async function getStatisticInfo(){

        if(id){
            await Agent.getAgentsGeneralById(id, {
                "from_date": toIsoStringHelper(moment('2020-01-01').startOf('day').toDate()),
                "to_date": toIsoStringHelper(moment().endOf('day').toDate())
            }) .then(result=>{
                agentStore.setStatisticGeneralPage(result?.data?.result)
            }).catch((err:any)=>{
                setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
            })
        }

    }

    function updateData(values:IMerchants){
        agentStore.setAgentData({...merchantData, ...values})
    }


    function renderTabs(){
        switch(activeKey){
            case 'settings':
                if(merchantData){
                    return <Settings updateData={(value:IMerchants)=>updateData(value)} merchantData={merchantData}/>
                }
                return
            case 'merchants':
                if(staticticInfo){
                    return <Merchants general={staticticInfo} agent={merchantData}/>
                }
                return
            default:
                return
        }
    }

    function createAbriviature(string:string){
        if(string){
            let arr_str = string.split(' ').filter((item:string)=>item.replace(/' '/g, '').length)
            return arr_str.map((item:any)=>item[0].toUpperCase()).join('')
        }
        return ''

    }



    if(!merchantData){
        return <>Нет данных</>
    }

    return (
        <>
            <PageTitleCustom title={merchantData?.type==='INDIVIDUAL'?`${merchantData?.first_name} ${merchantData?.last_name}`:merchantData?.company_name} breadCrumbs={createCompanyBreadCrumbs}/>
            <KTCard>
                <div className='card'>
                    <div className='card-body pt-9 pb-0'>
                        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                            <div className='me-7 mb-4'>
                                <div className='p-3 d-flex flex-column justify-content-center symbol logo-project bg-secondary symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                                    <p style={{fontSize: '4rem'}} className='text-center mb-0 fw-bolder text-primary'>
                                        {merchantData?.type==='INDIVIDUAL'?`${createAbriviature(`${merchantData?.first_name} ${merchantData?.last_name}`)}`:createAbriviature(merchantData?.company_name)}
                                    </p>
                                </div>
                            </div>

                            <div className='flex-grow-1'>
                                <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                                    <div className='d-flex flex-column'>
                                        <div className='d-flex align-items-center mb-2'>

                                            <p className='text-gray-800 fs-2 fw-bolder me-1 mb-0'>
                                                {merchantData?.type==='INDIVIDUAL'?`${merchantData?.first_name} ${merchantData?.last_name}`:merchantData?.company_name}
                                            </p>
                                        </div>

                                        <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                                            <span
                                                className='d-flex align-items-center text-gray-400 me-5 mb-2'
                                            >
                                                <KTSVG
                                                    path='/media/icons/build.svg'
                                                    className='svg-icon-4 me-1'
                                                />

                                                {
                                                    merchantData?.type?intl.formatMessage({id:`${merchantData?.type}_type`}):''
                                                }
                                            </span>
                                            <span
                                                className='d-flex align-items-center text-gray-400 me-5 mb-2'
                                            >
                                                <KTSVG
                                                    path='/media/icons/duotune/general/gen005.svg'
                                                    className='svg-icon-4 me-1'
                                                />

                                                {merchantData?.contract_id}
                                            </span>

                                            <a
                                                href={merchantData?.phone?`tel:${merchantData?.phone}`:'/'}
                                                className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                                            >
                                                <KTSVG
                                                    path='/media/icons/phone.svg'
                                                    className='svg-icon-4 me-1'
                                                />

                                                {ReplacePhone(merchantData?.phone||'')}
                                            </a>


                                            <span
                                                className='d-flex align-items-center text-gray-400 me-5 mb-2'
                                            >
                                                <KTSVG
                                                    path='/media/icons/duotune/general/calendar.svg'
                                                    className='svg-icon-4 me-1'
                                                />

                                                {merchantData?.contract_expiry_date?
                                                    moment(merchantData?.contract_expiry_date).format('DD/MM/YYYY'):''}
                                            </span>


                                        </div>
                                    </div>

                                </div>

                                <div className='d-flex flex-wrap flex-stack'>
                                    <div className='d-flex flex-column flex-grow-1 pe-8'>
                                        <div className='d-flex flex-wrap'>
                                            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <div className='fs-2 fw-bolder me-5'>{staticticInfo?.merchants||0}</div>
                                                    <Tooltip  color='var(--kt-body-bg)'  placement="topLeft" title={()=><span style={{color: 'var(--kt-text-gray-800)'}}>{intl.formatMessage({id:'transactions_info_count'})}</span>}>
                                                        <i style={{fontSize:"16px"}} className="fa-sharp fa-solid fa-circle-info"/>
                                                    </Tooltip>
                                                </div>

                                                <div className='fw-bold fs-6 text-gray-400'>Все мерчанты</div>
                                            </div>

                                            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <div className='fs-2 fw-bolder me-5'>{NumReplace(staticticInfo?.income?staticticInfo?.income/100+'':'0')} UZS</div>
                                                    <Tooltip  color='var(--kt-body-bg)'  placement="topLeft" title={()=><span style={{color: 'var(--kt-text-gray-800)'}}>Общая сумма с момента регистрации</span>}>
                                                        <i style={{fontSize:"16px"}} className="fa-sharp fa-solid fa-circle-info"/>
                                                    </Tooltip>
                                                </div>

                                                <div className='fw-bold fs-6 text-gray-400'>Общий доход</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className='d-flex overflow-auto h-55px'>

                            <Nav fill variant="tabs" className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap"  onSelect={(e)=>setActiveKey(e)}>

                                <Nav.Item>
                                    <Nav.Link eventKey="merchants" className={`nav-link text-active-primary me-6 text-gray-800 ${activeKey==='merchants'?'active':''}`}>{intl.formatMessage({id:"merchants"})}</Nav.Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Nav.Link eventKey="settings" className={`nav-link text-active-primary text-gray-800 me-6 ${activeKey==='settings'?'active':''}`}>{intl.formatMessage({id:"settings"})}</Nav.Link>
                                </Nav.Item>


                            </Nav>
                        </div>

                    </div>
                </div>
            </KTCard>

            <div>
                {renderTabs()}
            </div>

            {itemIdForUpdate !== undefined && <UserEditModal />}

        </>
    )
})





const AgentView = observer(() => {

    return(
        <ErrorProvider>

            <ListViewProvider>
                <AgentPage />
            </ListViewProvider>
        </ErrorProvider>
    )
})

export {AgentView}



