import {useEffect, useMemo, useState} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'


import {adminColumns, usersColumns} from './columns/_columns'
import {KTCardBody} from '@metronic/helpers'
import {observer} from "mobx-react";
import {employeeStore} from "@store/employee";
import {Employee} from "@providers/employee/_request";
import {useParams} from "react-router-dom";
import {useErrors} from "@providers/context/ErrorsProvider";
import {useIntl} from "react-intl";
import {Admins} from "@providers/admins/_request";
import {admins} from "@store/admins";
import {ReplacePhone} from "@utils/index";
import {UsersListLoading} from "@metronic/partials/widgets/tables/_modals/components/loading/UserListLoading";

export const AdminTable = observer(() => {

    const [isLoading, setIsLoading] = useState(true)
    const intl = useIntl()
    const data = useMemo(() => admins.getAdmins, [admins.getAdmins])
    const columns = useMemo(() => adminColumns, [])
    const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
        columns,
        data,
    })


    const {setError} = useErrors()


    useEffect(() => {
        Admins.getAdmins()
            .then(result => {

                admins.setAdmins((result?.data).map((item: any) => {
                    return {
                        ...item,
                        phone: ReplacePhone(item.phone || ''),
                        role: intl.formatMessage({id: item.role}),
                    }
                }))
                setIsLoading(false)
            })
            .catch(err => {
                setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
                setIsLoading(false)
            })
    }, [])


    return (
        <KTCardBody className='py-4'>
            {isLoading && <UsersListLoading/>}
            <div className='table-responsive'>
                <table
                    id='kt_table_users'
                    className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                    {...getTableProps()}
                >
                    <thead>
                    <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                        {headers.map((column: ColumnInstance) => (
                            <CustomHeaderColumn key={column.id} column={column}/>
                        ))}
                    </tr>
                    </thead>
                    <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                    {rows.length > 0 ? (
                        rows.map((row: Row, i) => {
                            prepareRow(row)
                            return <CustomRow row={row} key={`row-${i}-${row.id}`}/>
                        })
                    ) : (
                        <tr>
                            <td colSpan={7}>
                                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                    {intl.formatMessage({id: 'NOT_MATCHING'})}
                                </div>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
            {isLoading && ''}
        </KTCardBody>
    )
})


