import {useIntl} from "react-intl";
import * as Yup from "yup";
import {ADMIN, REGEXP_PHONE} from "@utils/constants";
import {IAccount} from "../../../../../modules/profile/components/account/AccountModel";
import {ICreateAccount} from "@providers/account/_models";


function AdminSchema() {
    const intl = useIntl()
    const schema = Yup.object({
        firstName: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
            .max(100, 'Слишком много символов').required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
        lastName: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
            .max(100, 'Слишком много символов').required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),

        phone: Yup.string().matches(
            REGEXP_PHONE,
            intl.formatMessage({id: 'ERROR_VALIDATION_PHONE'})
        ).required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
        email: Yup.string()
            .email('Не верный формат Email'),
    })
    return schema
}


const inits: ICreateAccount = {
    firstName: '',
    lastName: '',
    phone: '',
    employeeTypeId: undefined,
    role: ADMIN
}


export {AdminSchema, inits}