import {useIntl} from "react-intl";
import * as Yup from "yup";
import {IWebPayment} from "@providers/webPayments/models";





const WebPaymentsSchema = () => {
    const intl = useIntl()


    return Yup.object({
        name: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
            .max(255, intl.formatMessage({id: 'ERROR_VALIDATION_SYMBOLS_MAX'})).required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
        price: Yup.string()
            .max(10, intl.formatMessage({id: 'ERROR_VALIDATION_SYMBOLS_MAX'}))
            .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
        description: Yup.string()
            .max(255, intl.formatMessage({id: 'ERROR_VALIDATION_SYMBOLS_MAX'}))
    })
}




const inits:IWebPayment = {

    price: '',
    description: '',
    name: '',
    token: ''
}

export {WebPaymentsSchema, inits}