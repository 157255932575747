import React from 'react';
import {ColumnInstance} from "react-table";
import {IWebPayment, IWebPaymentSend} from "@providers/webPayments/models";

interface props {
    column: ColumnInstance<IWebPaymentSend>
}

const CustomHeaderColumn = ({column}: props) => {
    return (
        <>
            {column.Header && typeof column.Header === 'string' ? (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ) : (
                column.render('Header')
            )}
        </>
    );
};

export default CustomHeaderColumn;