import {FC} from 'react'
import {PageLink, PageTitle} from "@metronic/layout/core";
import {Vertical} from "./forms/wizards/components/Vertical";
import { useParams } from 'react-router-dom';
import {ErrorProvider} from "@providers/context/ErrorsProvider";
import {useIntl} from "react-intl";

const createCompanyBreadCrumbs: Array<PageLink> = [
    {
        title: 'Компании',
        path: '/companies',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]


const CompanyForm:FC = ()=>{
    return(
        <ErrorProvider>
            <Vertical/>
        </ErrorProvider>
    )
}

export default function CompanyFormWrapper(){
    const {id} = useParams()
    const intl = useIntl()
    return(
        <>
            <PageTitle breadcrumbs={createCompanyBreadCrumbs}>{id?intl.formatMessage({id:'EDIT_COMPANY'}): intl.formatMessage({id:'ADD_COMPANY'})}</PageTitle>
            <CompanyForm/>

        </>
    )
}