import {Table} from "./_modals/Table";
import {KTCard} from "@metronic/helpers";
import {UsersListHeader} from "./_modals/table-components/header/UsersListHeader";
import React, {useState} from "react";
import {ErrorProvider} from "@providers/context/ErrorsProvider";
import {observer} from "mobx-react";
import {useIntl} from "react-intl";
import {PageTitleCustom} from "@metronic/partials/pageTitleCustom";
import {useParams} from "react-router-dom";


const AgentPage = () => {

    return (
        <>
            <UsersListHeader />
            <KTCard>
                <Table />
            </KTCard>

        </>
    )
}





const AgentListWrapper = observer(() => {
    const intl = useIntl()
    const {type} = useParams()

    return(
        <ErrorProvider>
            <PageTitleCustom title={intl.formatMessage({id:`agent_merchants_title_${type}`})} breadCrumbs={[]}/>
            <AgentPage />
        </ErrorProvider>
    )
})

export {AgentListWrapper}



