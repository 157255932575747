import {Table} from "./_modals/Table";
import {KTCard} from "@metronic/helpers";
import {UsersListHeader} from "./_modals/table-components/header/UsersListHeader";
import React, {useEffect, useState} from "react";
import {ErrorProvider, useErrors} from "@providers/context/ErrorsProvider";
import {observer} from "mobx-react";
import {employeeStore} from "@store/employee";
import {roleRoutingAdmin} from "@utils/index";
import {AdminTable} from "./_modals/AdminTable";
import {useIntl} from "react-intl";
import {PageTitleCustom} from "@metronic/partials/pageTitleCustom";
import {ListViewProvider} from "./_modals/providers/ListViewProvider";
import {Company} from "@providers/company/_request";
import {company} from "@store/company";
import {UsersListLoading} from "@metronic/partials/widgets/tables/_modals/components/loading/UserListLoading";


const CompaniesPage = () => {

  return (
      <>

            <KTCard>
                <UsersListHeader />
                <Table />
            </KTCard>

        </>
  )
}


const AdminCompaniesPage = () => {


    return (
        <>
            <UsersListHeader />
            <KTCard>
                <AdminTable />
            </KTCard>

        </>
    )
}



const ProductsListWrapper = observer(() => {
    const role = employeeStore.getRole
    const intl = useIntl()
    const {setError} = useErrors()

    const [loaded, setLoaded] = useState<boolean>(false)

    useEffect(() => {
       if (role) {
           if (roleRoutingAdmin('action', role, 'is-admin')) {
               getAllCompanies()
           } else {
               getMyCompanies()
           }
       }
    }, [role])

    const getMyCompanies = () => {
        Company.getMyCompanies({page: 1, size: 20})
            .then((response) => {
                if (response.data.result) {
                    company.setCompany(response.data?.result?.content)
                }
            }).catch(err => {
            setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
        }).finally(() => setLoaded(true))
    }

    const getAllCompanies = () => {
            Company.getCompany({page: 1, size: 20})
                .then(result => {
                    if (result.data.result) {
                        company.setCompany(result.data.result.content)
                        company.setPagination({...result.data?.meta, currentPage: 1})
                    }
                }).catch(err => {
                setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
            }).finally(() => setLoaded(true))
    }

    if (!loaded) return <UsersListLoading/>

    return(
        <ListViewProvider>
            <ErrorProvider>
                <PageTitleCustom title={intl.formatMessage({id:'COMPANIES'})} breadCrumbs={[]}/>
                {roleRoutingAdmin('action', role, 'is-admin')?<AdminCompaniesPage />:<CompaniesPage />}
            </ErrorProvider>
        </ListViewProvider>
    )
})

export {ProductsListWrapper}



