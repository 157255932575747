import {instantse} from "@providers/request";
import {IDetaildStatisticResponse, IStatistic, ITransactions} from "@providers/statistic/_modals";
import {ITransactionCompanyResponse, ITransactionResponse} from "@providers/adminTransactions/_models";
import {AxiosResponse} from "axios";
import {card_transactions, statuses_transactions} from "@utils/constants";


export const Statistic = {

    getStatistic(data: IStatistic) {
        return instantse.post(`/v1/statistics`, data)
            .then(response => response)
    },
    getDetailedStatistic(data: IStatistic) {
        return instantse.post(`/v1/statistics/graph`, data)
            .then(response => response)
    },
    getAdminStatistic(data: IStatistic) {
        return instantse.post(`/v1/statistics/token`, data )
            .then(response => response)
    },

    getAdminDetaildStatistic(data: IStatistic):Promise<AxiosResponse<IDetaildStatisticResponse>> {
        return instantse.post(`/v1/statistics/report`, data)
            .then(response => response)
    },

    getTransactions(data: ITransactions):Promise<AxiosResponse<ITransactionResponse>> {
        const newTransactions = {
            ...data
        }
        if (!data.processingTypes.length){
            newTransactions.processingTypes = card_transactions
        }
        if (!data.statuses.length){
            newTransactions.statuses = statuses_transactions
        }

        return instantse.post(`/v1/company/transactions`, newTransactions)
            .then(response => response)
    },



    getIncomeGs(data: any) {
        return instantse.post(`/v1/gs/company/statistics/income`, data)
            .then(response => response)
    },

    getTransactionsById(companyId: string, data: IStatistic) {
        return instantse.post(`/v1/company/${companyId}/transactions/by-id`, data)
            .then(response => response)
    },

    getTransactionFiscalCheque(companyId: string, transactionId: string) {
        return instantse.get(`/v1/company/${companyId}/transactions/${transactionId}/receipt`)
    },

    exportTransactions(companyId: string, data: ITransactionCompanyResponse) {
        return instantse.post(`/v1/company/transactions/export`, data, {
            responseType: 'arraybuffer',
        })
            .then(response => response)
    },

    getStatisticsGeneral(companyId: string, data: IStatistic) {
        return instantse.post(`/v1/company/${companyId}/statistics/general`, data)
            .then(response => response)
    },

    getStatisticsAdmin(data: any) {
        return instantse.post(`/v1/gs/company/transactions/detailed`, data)
            .then(response => response)
    }
}