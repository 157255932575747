import React, {FC} from "react";
import {useIntl} from "react-intl";

type Props = {
    status: string
}
const classStatus:any = {
    OUTDATED: "badge-light-dark",
    ACTIVE: "badge-light-success",
    TERMINATED: "badge-light-danger",
    FINISHED: "badge-light-primary",
    CASH_PAID: "badge-light-info",
    UNVERIFIED: "badge-light-warning",
    default_status: "badge-light-dark"
}

const icons:any = {
    ACTIVE: '/media/svg/approved.svg',
    UNVERIFIED: '/media/icons/duotune/general/load.svg',
    TERMINATED: '/media/icons/duotune/general/close.svg',
    OUTDATED: '/media/icons/duotune/general/status_calendar.svg',
    CASH_PAID: '/media/icons/duotune/general/pay.svg',
    FINISHED: '/media/icons/duotune/general/check.svg'
}


const StatusAutopay: FC<Props> = ({status}) => {

    const intl = useIntl()
    const status_style:any = classStatus[status]?classStatus[status]:classStatus['default_status']
    const status_icon:any = icons[status]?icons[status]:null


    return (
        <div>
            <div className={`badge rounded-4 ${status_style} justify-content-start text-left align-content-center p-3`} style={{fontSize: "12px", fontWeight: 500}}>
                {status_icon&&<img src={status_icon} className="me-1" style={{objectFit: 'contain'}}/>}
                <span className={`${status === "FAILED" ? "opacity-75" : ""} ms-1 fw-bold`}>{intl.formatMessage({id: status})}</span>
            </div>
        </div>
    )
}

export {StatusAutopay}