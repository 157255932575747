/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from "react-intl";

type Props = {
  entity: string
}



const UserInfoCell: FC<Props> = ({entity}) => {

    const intl = useIntl()


    return (
        <div className='d-flex align-items-center'>

            <div className='d-flex flex-column'>
                <p className='text-gray-800 text-hover-primary mb-1'>
                    {intl.formatMessage({id: entity??''})}
                </p>
            </div>
        </div>
    )
}

export {UserInfoCell}
