import React, {PropsWithChildren} from 'react';
import {HeaderProps} from "react-table";
import {useIntl} from "react-intl";

interface props {
    className?: string
    title: string
    tableProps: PropsWithChildren<HeaderProps<any>>
    translate?: boolean
}

const Header: React.FC<props> = ({
                                     tableProps,
                                     title,
                                     className,
                                     translate
                                 }) => {
    const intl = useIntl()
    return (
        <th
            {...tableProps.column.getHeaderProps()}
            className={`${className}`}
            style={{cursor: 'pointer', textTransform: "capitalize"}}
        >
            {translate ? intl.formatMessage({id: title}) : title}
        </th>
    );
};

export default Header;