import React, {FC, useEffect, useState} from 'react'
import {KTCard, KTCardBody, KTSVG} from "@metronic/helpers";
import {ErrorProvider, useErrors} from "@providers/context/ErrorsProvider";
import {Navigate, useNavigate, useOutletContext, useParams} from "react-router-dom";
import {NumReplace, roleRouting, roleRoutingAdmin} from "@utils/index";
import {useIntl} from "react-intl";
import {Statistic} from "@providers/statistic/_request";
import {IStatistic} from "@providers/statistic/_modals";
import {AdminTransactions} from "./admin";
import {PageTitleCustom} from "@metronic/partials/pageTitleCustom";

const Transactions: FC = () => {
    const [isLoading, setIsLoading] = useState(false)
    const {companyId} = useParams()
    const navigate = useNavigate()
    const intl = useIntl()
    const [navigateData, setNavigateData] = useState({})
    const [exportData, setExportData] = useState<IStatistic>()
    const [searchText, setSearchText] = useState('')
    const [searchTextController, setSearchTextController] = useState('')
    const [generalData, setGeneralData] = useState({})
    const [generalDataRequest, setGeneralDataRequest] = useState<IStatistic>()
    const {setError} = useErrors()

    const fetchGeneralData = async () => {
        try {
            const response = await Statistic.getStatisticsGeneral(companyId!, generalDataRequest!)
            setGeneralData(response.data.result)
        } catch (err: any) {
            setError({
                status: err?.response?.status,
                message: err?.response?.data?.message,
                isError: true
            })
        }
    }

    useEffect(() => {
        if (generalDataRequest)
            fetchGeneralData().then()
    }, [generalDataRequest])

    const exportTransactions = async () => {
        // try {
        //     setIsLoading(true)
        //     const response = await Statistic.exportTransactions(companyId!, exportData!)
        //     const url = window.URL.createObjectURL(new Blob([response.data]));
        //     const link = document.createElement('a');
        //     link.href = url;
        //     link.setAttribute('download', 'transactions.xlsx');
        //     document.body.appendChild(link);
        //     link.click();
        //     window.URL.revokeObjectURL(url)
        // } catch (err: any) {
        //     setError({
        //         status: err?.response?.status,
        //         message: err?.response?.data?.message,
        //         isError: true
        //     })
        // } finally {
        //     setIsLoading(false)
        // }
    }
    return (
        <>
            <div className='d-flex justify-content-between mb-5' data-kt-user-table-toolbar='base'>
                <div>
                    <button type='button' className='btn btn-primary px-4 fw-bolder fs-5'
                            onClick={() => {
                                navigate(`/${companyId}/dashboard-merchant`, {state: navigateData})
                            }}>
                        <KTSVG path='/media/icons/duotune/graphs/gra001.svg' className='svg-icon-2'/>
                        Статистика
                    </button>
                    <button disabled={isLoading} type='button'
                            className='btn bg-white text-muted ms-3 px-4 fw-bolder fs-5'
                            onClick={exportTransactions}>
                        <KTSVG path='/media/icons/duotune/files/fil009.svg' className='svg-icon-2'/>
                        {intl.formatMessage({id: 'EXCEL'})}
                    </button>
                </div>
                <div className='card-title'>
                    <form onSubmit={(e) => {
                        e.preventDefault()
                        setSearchText(searchTextController)
                    }} className='d-flex align-items-center position-relative my-1'>
                        <input
                            style={{border: "none", borderRadius: "6px", height: "44px", paddingRight: '80px'}}
                            type='text'
                            data-kt-user-table-filter='search'
                            className='form-control w-400px'
                            placeholder='Поиск по ID или сумме'
                            value={searchTextController}
                            onChange={(e) => setSearchTextController(e.target.value)}
                        />
                        {searchTextController.length > 0 &&
                            <span className='position-absolute end-0 me-8 pe-8' onClick={() => {
                                setSearchTextController("")
                                setSearchText("")
                            }}>
                            <KTSVG
                                path='/media/icons/duotune/general/gen034.svg'
                                className='svg-icon-1 text-hover-primary'
                            />
                        </span>}
                        <button type="submit"
                                style={{borderTopRightRadius: "6px", borderBottomRightRadius: "6px", width: "44px"}}
                                className='border-0 text-hover-secondary cursor-pointer position-absolute end-0 bg-primary h-100 d-flex justify-content-center align-items-center'>
                            <KTSVG
                                path='/media/icons/duotune/general/gen021.svg'
                                className='svg-icon-1 text-white'
                            />
                        </button>
                    </form>
                </div>
            </div>
            <KTCard>

            </KTCard>
            <KTCard className='mt-5'>
                <KTCardBody>
                    <h2 className='mb-4'>{intl.formatMessage({id: 'TRANSACTION_GENERAL_DATA'})}</h2>

                    <div className="d-flex justify-content-start align-items-center">
                        {Object.entries(generalData).map((data: any, index) =>
                            <div className="me-2 border-dashed rounded-4 p-3 d-flex flex-column text-dark">
                                <span style={{
                                    fontSize: "24px",
                                    lineHeight: "24px",
                                    fontWeight: 800
                                }}>
                                    {data[0] === 'amount' ? NumReplace((data[1] / 100).toString()) + " сум" : data[1]}
                                </span>
                                <span style={{fontWeight: 500, fontSize: "14px", color: "rgba(181, 181, 195, 1)"}}>
                                    {data[0] === 'amount' ? intl.formatMessage({id: 'sum'}) : intl.formatMessage({id: 'trans'})}
                                </span>
                            </div>
                        )}
                    </div>
                </KTCardBody>
            </KTCard>
        </>
    )
}


export default function PaymentsTransactions() {
    //@ts-ignore
    const {role} = useOutletContext()
    const intl = useIntl()

    if (role) {
        if (roleRouting('payments', role) || roleRoutingAdmin('page', role, 'payments')) {
            return (
                <>
                    <PageTitleCustom title={intl.formatMessage({id: 'trans'})} breadCrumbs={[]}/>
                    <ErrorProvider>
                        <AdminTransactions/>
                    </ErrorProvider>
                </>
            )
        }

        return <Navigate to='/error/404'/>
    }

    return (<></>)


}