import {UsersListToolbar} from './UserListToolbar'
import {UsersListSearchComponent} from "./UsersListSearchComponent";
import {employeeStore} from "@store/employee";
import {observer} from "mobx-react";
import {roleRouting} from "@utils/index";



const UsersListHeader = observer(() => {

    const role = employeeStore.getRole


    return (
        <div className='card-header border-0 pt-6'>
            {/*<UsersListSearchComponent />*/}
            {/* begin::Card toolbar */}
            <div className='card-toolbar'>
                {/* begin::Group actions */}
                {roleRouting('add-company', role, 'actions')?<UsersListToolbar />:''}
                {/* end::Group actions */}
            </div>
            {/* end::Card toolbar */}
        </div>
    )
})

export {UsersListHeader}
