import {ErrorMessage, Field} from "formik";
import React from "react";
import InputMask from "react-input-mask";
import moment from "moment";
import {useIntl} from "react-intl";



interface IProps {
    handleChange: (val:any)=>void
    setFieldValue: (field:string, elem:any)=>void
    values: any

}


export default function FormTypeCompany(props:IProps){
    const {handleChange, values, setFieldValue} = props

    const intl = useIntl()

    return(
        <>
            <div className='mb-8 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id: 'inn-agent'})}</label>
                <Field
                    type='text'
                    placeholder='123456789'
                    className='form-control form-control-lg form-control-solid'
                    name='inn'
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        if (e.currentTarget.value.match(/^\d{0,9}$/)) handleChange(e)
                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='inn'/>
                </div>
            </div>


            <div className='mb-8 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id:'company_name'})}</label>
                <Field
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='company_name'
                    onChange={(e: React.FormEvent<HTMLInputElement>)=>{
                        if(!e.currentTarget.value.length||e.currentTarget.value.match(/^[Aa-zZ-Аа-яЯ]+$/i))
                            handleChange(e)
                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='company_name' />
                </div>
            </div>

            <div className='mb-8 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id: 'certificate_id'})}</label>
                <Field
                    type='text'
                    placeholder='12345678910111'
                    className='form-control form-control-lg form-control-solid'
                    name='certificate_id'
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        if (e.currentTarget.value.match(/^\d{0,9}$/)) handleChange(e)
                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='certificate_id'/>
                </div>
            </div>



            <div className='mb-12 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id:'phone'})}</label>
                <Field
                    type='text'
                    placeholder='+998 90 999 99 99'
                    component={InputMask}
                    mask="+\9\9\8 99 999 99 99"
                    className='form-control form-control-lg form-control-solid'
                    name='phone'
                    value={values['phone']}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        const value = e.currentTarget.value || '';
                        setFieldValue('phone', value)
                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='phone' />
                </div>
            </div>

            <div className='mb-8 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id:'contract_id'})}</label>
                <Field
                    type='text'
                    placeholder='AA-1234567'
                    className='form-control form-control-lg form-control-solid'
                    name='contract_id'
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='contract_id' />
                </div>
            </div>


            <div className='mb-8 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id:'contract_expiry_date'})}</label>
                <Field
                    type='date'
                    className='form-control form-control-lg form-control-solid'
                    name='contract_expiry_date'
                    min='1899-01-01' max='2125-01-01'
                    value={moment(values['contract_expiry_date']).format('yyyy-MM-DD')}
                />

                <div className='text-danger mt-2'>
                    <ErrorMessage name='contract_expiry_date' />
                </div>
            </div>
        </>
    )

}