import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserLastLoginCell} from './UserLastLoginCell'
import {UserTwoStepsCell} from './UserTwoStepsCell'
import {UserActionsCell} from './UserActionsCell'
import {TableCustomHeader} from './TableCustomHeader'
import {CompanyResponse} from "@providers/company/_models";
import {TableText} from "./TableText";
import {PhoneAgent} from "./PhoneAgent";
import {AgentDate} from "./AgentDate";
import {NumReplace} from "@utils/index";
import {AgentContract} from "./AgentContract";
import {AgentLink} from "./AgentLink";


const agentColumns: ReadonlyArray<Column<CompanyResponse>> = [

  {
    Header: (props) => <TableCustomHeader tableProps={props} title='№' className='min-w-30px fw-bold text-gray-700' />,
    id: 'num',
    Cell: ({...props}) => <TableText text={props.data[props.row.index]?.num} translate={false}/>,
  },

  {
    Header: (props) => <TableCustomHeader tableProps={props} title='agent' className='min-w-125px fw-bold text-gray-700 ' />,
    id: 'first_name',
    Cell: ({...props}) => <AgentLink id={props.data[props.row.index]?.id} text={`${props.data[props.row.index]?.first_name} ${props.data[props.row.index]?.last_name}`} />,
  },
 
  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='pinfl' className='min-w-125px fw-bold text-gray-700' />
    ),
    id: 'pinfl',
    Cell: ({...props}) => <TableText text={props.data[props.row.index].pinfl} translate={false} />,
  },
  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='contract_id' className='min-w-125px fw-bold text-gray-700' />
    ),
    id: 'contract_id',
    Cell: ({...props}) => <TableText text={props.data[props.row.index].contract_id} translate={false} />,
  },
  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='phone' className='min-w-125px fw-bold text-gray-700' />
    ),
    id: 'phone',
    Cell: ({...props}) => <PhoneAgent text={props.data[props.row.index].phone}  />,
  },
  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='created_date' className='min-w-125px fw-bold text-gray-700' />
    ),
    id: 'created_date',
    Cell: ({...props}) => <AgentDate date={props.data[props.row.index].created_date}  />,
  },
  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='contract_expiry_date_table' className='min-w-150px fw-bold text-gray-700' />
    ),
    id: 'contract_expiry_date',
    Cell: ({...props}) => <AgentDate date={props.data[props.row.index].contract_expiry_date}  />,
  },
  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='merchants' className='min-w-100px fw-bold text-gray-700' />
    ),
    id: 'merchants_count',
    Cell: ({...props}) => <TableText text={props.data[props.row.index].merchants_count} translate={false} />,
  },
  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='agent_income' className='min-w-125px fw-bold text-gray-700' />
    ),
    id: 'agent_income',
    Cell: ({...props}) => <TableText text={`${NumReplace((props.data[props.row.index].agent_income>0?props.data[props.row.index].agent_income/100:0)+'')} UZS`} translate={false} />,
  }
]

const agentColumnsCompany: ReadonlyArray<Column<CompanyResponse>> = [

  {
    Header: (props) => <TableCustomHeader tableProps={props} title='№' className='min-w-30px fw-bold text-gray-700' />,
    id: 'num',
    Cell: ({...props}) => <TableText text={props.data[props.row.index]?.num} translate={false}/>,
  },

  {
    Header: (props) => <TableCustomHeader tableProps={props} title='agent' className='min-w-125px fw-bold text-gray-700' />,
    id: 'company_name',
    Cell: ({...props}) => <AgentLink id={props.data[props.row.index].id} text={`${props.data[props.row.index]?.company_name}`}  />,
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='contract_id' className='min-w-125px fw-bold text-gray-700' />,
    id: 'contract_id',
    Cell: ({...props}) => <TableText text={`${props.data[props.row.index]?.contract_id}`} translate={false} />,
  },

  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='inn' className='min-w-100px fw-bold text-gray-700' />
    ),
    id: 'inn',
    Cell: ({...props}) => <TableText text={props.data[props.row.index].inn} translate={false} />,
  },

  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='phone' className='min-w-150px fw-bold text-gray-700' />
    ),
    id: 'phone',
    Cell: ({...props}) => <PhoneAgent text={props.data[props.row.index].phone}  />,
  },
  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='contract_expiry_date_table' className='min-w-150px fw-bold text-gray-700' />
    ),
    id: 'contract_expiry_date',
    Cell: ({...props}) => <AgentDate date={props.data[props.row.index].contract_expiry_date}  />,
  },

  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='merchants' className='min-w-100px fw-bold text-gray-700' />
    ),
    id: 'merchants_count',
    Cell: ({...props}) => <TableText text={props.data[props.row.index].merchants_count} translate={false} />,
  },
  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='agent_income' className='min-w-125px fw-bold text-gray-700' />
    ),
    id: 'agent_income',
    Cell: ({...props}) => <TableText text={`${NumReplace((props.data[props.row.index].agent_income>0?props.data[props.row.index].agent_income/100:0)+'')} UZS`} translate={false} />,
  }
]

const agentStatisticColumns: ReadonlyArray<Column<CompanyResponse>> = [

  {
    Header: (props) => <TableCustomHeader tableProps={props} title='№' className='min-w-30px fw-bold text-gray-700' />,
    id: 'num',
    Cell: ({...props}) => <TableText text={props.data[props.row.index]?.num} translate={false}/>,
  },

  {
    Header: (props) => <TableCustomHeader tableProps={props} title='agent' className='min-w-150px fw-bold text-gray-700' />,
    id: 'first_name',
    Cell: ({...props}) => <AgentLink id={props.data[props.row.index].id} text={`${props.data[props.row.index]?.type==='INDIVIDUAL'?`${props.data[props.row.index]?.first_name} ${props.data[props.row.index]?.last_name}`:props.data[props.row.index].company_name}`}  />,
  },

  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='company_income' className='min-w-125px fw-bold text-gray-700' />
    ),
    id: 'company_income',
    Cell: ({...props}) => <TableText text={`${NumReplace((props.data[props.row.index].company_income>0?props.data[props.row.index].company_income/100:0)+'')} UZS`} translate={false} />,
  },

  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='agent_income' className='min-w-125px fw-bold text-gray-700' />
    ),
    id: 'agent_income',
    Cell: ({...props}) => <TableText text={`${NumReplace((props.data[props.row.index].agent_income>0?props.data[props.row.index].agent_income/100:0)+'')} UZS`} translate={false} />,
  },
  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='agent-statistic.contract' className='min-w-150px fw-bold text-gray-700' />
    ),
    id: 'contract_id',
    Cell: ({...props}) => <AgentContract contract={{
      contract_id: props.data[props.row.index].contract_id,
      contract_expiry_date: props.data[props.row.index].contract_expiry_date
    }}  />,
  },

  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='merchants' className='min-w-100px text-gray-700 fw-bold' />
    ),
    id: 'merchants_count',
    Cell: ({...props}) => <TableText text={props.data[props.row.index].merchants_count} translate={false} />,
  },

  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='' className='min-w-125px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index]?.id} />,
  },

]



export {agentColumns, agentStatisticColumns, agentColumnsCompany}
