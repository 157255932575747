import React, {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, FieldArray, Form, Formik, useFormik} from 'formik'
import {useListView} from '../providers/ListViewProvider'
import {EmployeeM} from "@providers/employee/_models";
import {Employee} from "@providers/employee/_request"
import {useParams} from "react-router-dom";
import { image_default} from "@utils/constants";
import {observer} from "mobx-react";
import {employeeStore} from "@store/employee";
import {useIntl} from "react-intl";
import {useErrors} from "@providers/context/ErrorsProvider";
import {isDemo, localErrorsMessage} from "@utils/index";
import {KTSVG} from "@metronic/helpers";
import {Col, Row, Tooltip} from "antd";
import AsyncSelect from "react-select/async";
import {customStyles} from "@metronic/assets/ts/select.style";
import moment from "moment/moment";
import {Agent} from "@providers/agents/_request";
import {Company} from "@providers/company/_request";
import {agentStore} from "@store/agents";
import {toIsoStringHelper} from "../../../../autopay/dateHelper";

type Props = {
  isUserLoading: boolean
  agent: any
}



const UserEditModalForm: FC<Props> = observer(({agent, isUserLoading}) => {
  const {setItemIdForUpdate} = useListView()
  const {setError} = useErrors()
  const [loading,setLoading] = useState(false)
  const [merchants, setMerchants] = useState<any[]>([])
  const [merchantsAll, setMerchantsAll] = useState<any[]>([])
  const {companyId} = useParams()
  const intl = useIntl()

  useEffect(()=>{
    let elem:any = document.body
    elem.addEventListener('click', (e:any)=>{
      if(e.target.getAttribute('id')==='kt_modal_add_user'){
        setItemIdForUpdate(undefined)
      }
    })
  },[])


  useEffect(()=>{
    getMerchantsData()
  }, [])


  async function getMerchantsData(){
    await Agent.getMerchants()
        .then(result=>{

          setMerchants(result?.data?.result.map((item:any)=>{

            return {
              value:item.id,
              label:<div className='d-flex align-items-center'>
                <div style={{borderRadius:'6px', overflow:'hidden', marginRight:'8px', width:24, height:24}}>
                  {item.logo_file?<img src={item.logo_file?Company.getFiles(item.logo_file):image_default} className='w-100 h-100'/>:''}
                </div>
                {item.name}
              </div>,
              name: item.name
            }
          }))


          setMerchantsAll([...result?.data?.result.map((item:any)=>{

            return {
              value:item.id,
              label:<div className='d-flex align-items-center'>
                <div style={{borderRadius:'6px', overflow:'hidden', marginRight:'8px', width:24, height:24}}>
                  {item?.logo_file? <img src={item.logo_file?Company.getFiles(item.logo_file):image_default} className='w-100 h-100'/>:''}
                </div>
                {item.name}
              </div>
            }
          }), ...agent.companies.map((item:any)=>{

            return {
              value:item.company_id,
              label:<div className='d-flex align-items-center'>
                <div style={{borderRadius:'6px', overflow:'hidden', marginRight:'8px', width:24, height:24}}>
                  {item?.logo_file? <img src={item.logo_file?Company.getFiles(item.logo_file):image_default} className='w-100 h-100'/>:''}
                </div>
                {item.company_name}
              </div>
            }
          })])
        })
        .catch(err=>{
          setError({status: err?.response?.status, message:  localErrorsMessage('post-company', 'message-match', err?.response?.data?.message, intl), isError: true})
        })
  }




  const createUserSchema = Yup.object().shape({
    companies: Yup.array(Yup.object({
      contract_expiry_date: Yup.date().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
      commission: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
      contract_id: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
      company_id: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
    }))
  })



  const [userForEdit] = useState<any>({
    companies: [{
      contract_expiry_date: '',
      commission: '',
      contract_id: '',
      company_id:0
    }]})

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh&&companyId) {
      Employee.getEmployee(companyId).then(response=>employeeStore.setEmployee(response?.data?.result||[]))
    }
    setItemIdForUpdate(undefined)
  }



  async function submitForm(values:any){
    const merchants = values.companies.map((item:any)=>{
      return {
        ...item,
        commission: item.commission*1000
      }
    })

    await Agent.editAgent(agent?.id, {...agent, companies: [...agent.companies, ...merchants]})
        .then(async()=>{
          agentStore.setAgentData({...agent, ...values})
          await Agent.getAgentsGeneralById(agent.id, {
            "from_date": toIsoStringHelper(new Date(moment('2020-01-01').format('YYYY-MM-DD'))),
            "to_date": toIsoStringHelper(new Date(moment().format('YYYY-MM-DD')))
          }).then((result)=>{
                agentStore.setStatisticGeneralPage(result?.data?.result)
              })

          await Agent.getStatisticById(agent.id, {
            page: agentStore.getSorting.page,
            count: 20,
            from_date: agentStore.getSorting?.from_date,
            to_date: agentStore.getSorting?.to_date
          })
              .then(result=>{

                agentStore.setStatisticPersonal(result?.data?.result.map((item: any, index: number) => {
                  if (agentStore.getSorting.page.page && agentStore.getSorting.page.page > 2) {
                    item['num'] = ((agentStore.getSorting.page.page - 1) * 20) + index + 1
                  } else if (agentStore.getSorting.page.page == 2) {
                    item['num'] = 21 + index
                  } else {
                    item['num'] = index + 1
                  }
                  return item
                }))
                agentStore.setSorting({...agentStore.getSorting,...result?.data?.meta, })

              })
        })
    setItemIdForUpdate(undefined)


  }



  return (
      <>
        <Formik enableReinitialize={true} validationSchema={createUserSchema} initialValues={userForEdit}
                onSubmit={submitForm}>
          {(props) => {
            const {
              values,
              errors,
              handleChange,
              setFieldValue,
              submitForm,
              validateField,
            } = props

            return (

                <Form className='py-5 w-100 px-6' noValidate id='kt_create_account_form'>
                  <div className='current' data-kt-stepper-element='content'>
                    <FieldArray
                        name="companies"
                        render={arrayHelpers=>{

                          return (
                              //@ts-ignore
                              <div >{
                                //@ts-ignore
                                props.values.companies.map((req, index, arr)=>{


                                  return (
                                      <div key={index} className='border border-gray-300 mb-10'
                                           style={{padding:'16px 16px 56px 16px', borderRadius:'12px'}}>

                                        <div className='position-relative'>


                                          <Row gutter={[12,12]} className='mb-5'>
                                            <Col lg={props.values.companies[index]?.company_id?18:24} className='fv-row'>
                                              <label className='form-label required'>{intl.formatMessage({id:'add-merchant.label'})}</label>
                                              <Field
                                                  component={AsyncSelect}
                                                  styles={customStyles}
                                                  defaultOptions={merchants}
                                                  value={merchantsAll.find((item:any)=>item.value===props.values['companies'][index]['company_id'])||null}
                                                  placeholder={intl.formatMessage({id:"select"})}
                                                  name={`companies.${index}.company_id`}
                                                  noOptionsMessage={({inputValue}:{inputValue:any}) => !inputValue ? 'Нет данных' : "Нет данных"}
                                                  onChange={(e:any)=>{
                                                    let companies = props.values.companies.map((comp:any, indexComp:number)=>{
                                                      if(index===indexComp){

                                                        return{
                                                          ...comp,
                                                          company_id: e.value

                                                        }
                                                      }

                                                      return comp

                                                    })



                                                    props.setFieldValue('companies', companies)
                                                    const ids = companies.map((item:any)=>item.company_id)
                                                    setMerchants(merchantsAll.filter((item:any)=>!ids.includes(item.value)))

                                                  }}


                                              />

                                              <div className='text-danger mt-2'>
                                                <ErrorMessage name={`companies.${index}.company_id`} />
                                              </div>
                                            </Col>


                                            {
                                              props.values.companies[index].company_id!==0?
                                                  (<Col lg={6} className='position-relative'>
                                                    <label className='form-label required'>%</label>

                                                    <Field
                                                        type='text'
                                                        className='form-control form-control-lg form-control-solid'
                                                        name={`companies.${index}.commission`}
                                                        onChange={(e:any)=>{
                                                          if (e.currentTarget.value.match(/^\s*\d*[\.]?\d*\s*$/)&&e.currentTarget.value<=100) props.handleChange(e)
                                                        }}

                                                    />

                                                    <Tooltip placement="topLeft" title='Процент агента с мерчанта'>
                                                      <div style={{right:'15px', top:'40px'}} className='position-absolute'>
                                                        <KTSVG path={'/media/svg/icon-info2.svg'}
                                                               className={`svg-icon-2hx me-2 `} />
                                                      </div>

                                                    </Tooltip>
                                                    <div className='text-danger mt-2'>
                                                      <ErrorMessage name={`companies.${index}.commission`}/>
                                                    </div>
                                                  </Col>):''
                                            }
                                          </Row>

                                          <div>
                                            {
                                              props.values.companies[index].company_id!==0?
                                                  (<>
                                                    <div className='mb-5'>
                                                      <label className='form-label mb-3 required'>{intl.formatMessage({id: 'add-merchant.label1'})}</label>
                                                      <Field
                                                          type='text'
                                                          className='form-control form-control-lg form-control-solid'
                                                          name={`companies.${index}.contract_id`}
                                                      />
                                                      <div className='text-danger mt-2'>
                                                        <ErrorMessage name={`companies.${index}.contract_id`}/>
                                                      </div>
                                                    </div>
                                                    <div className='mb-5'>
                                                      <label className='form-label mb-3 required'>{intl.formatMessage({id: 'contract_expiry_date'})}</label>
                                                      <Field
                                                          type='date'
                                                          className='form-control form-control-lg form-control-solid'
                                                          name={`companies.${index}.contract_expiry_date`}
                                                          min='1899-01-01' max='2125-01-01'
                                                          value={moment(props.values.companies[index]['contract_expiry_date']).format('YYYY-MM-DD')}
                                                      />

                                                      <div className='text-danger mt-2'>
                                                        <ErrorMessage name={`companies.${index}.contract_expiry_date`}/>
                                                      </div>
                                                    </div>
                                                  </>):''
                                            }
                                          </div>




                                          <Tooltip placement="topLeft" title='Удалить'>
                                            <button
                                                type="button"
                                                className={`btn btn-sm btn-icon btn-light-danger me-3 position-absolute end-0 ${index===0?'d-none':''}`}
                                                onClick={() => arrayHelpers.remove(index)}
                                            >
                                              <KTSVG path='/media/svg/basket.svg' className='svg-icon-2' />
                                            </button>

                                          </Tooltip>


                                        </div>


                                      </div>
                                  )
                                })
                              } <button className='btn btn-sm btn-primary me-3 mb-10' type="button" onClick={() => {

                                return arrayHelpers.push({
                                  contract_expiry_date: '',
                                  commission: '',
                                  contract_id: '',
                                  company_id:0
                                })
                              }}>
                                <KTSVG
                                    path='/media/icons/duotune/general/gen041.svg'
                                    className='svg-icon-4 me-1'
                                />
                                {intl.formatMessage({id:'add-merchant'})}
                              </button></div>
                          )

                        }}></FieldArray>
                  </div>


                  <div className={`d-flex justify-content-end modal-footer pb-0 px-0`}>

                    <div>
                      <button type='button' className='btn btn-lg btn-primary me-3'
                              disabled={loading} onClick={()=>cancel()}>
                                                <span className='indicator-label'>
                                                {intl.formatMessage({id: 'CANCEL'})}
                                                </span>
                      </button>
                    </div>

                    <div>
                      <button type='submit' className='btn btn-lg btn-primary me-3'
                              disabled={loading}>
                                                <span className='indicator-label'>
                                                {intl.formatMessage({id: 'SAVE'})}

                                                  <KTSVG
                                                      path='/media/icons/duotune/arrows/arr064.svg'
                                                      className='svg-icon-3 ms-2 me-0'
                                                  />
                                                </span>
                      </button>
                    </div>
                  </div>
                </Form>
            )
          }
          }
        </Formik>
      </>
  )
})



export {UserEditModalForm}
