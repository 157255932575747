/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from "react-router-dom";
import {CompanyResponse} from "@providers/company/_models";
import {observer} from "mobx-react";
import {image_default, JUNIOR_CASHIER} from "@utils/constants";
import {employeeStore} from "@store/employee";
import {Company} from "@providers/company/_request";
import {roleRoutingAdmin} from "@utils/index";

type Props = {
  serviceDetails: {
      name: string
      user_service_id: number
      logo_file: string
  }
}

const AgentService: FC<Props> = observer(({serviceDetails}) => {

    if(!serviceDetails){
        return (
            <div className='d-flex align-items-center'>
                {/* begin:: Avatar */}
                <div className='me-3'>

                    <div className='d-flex align-items-center w-200px'>
                        Нет данных
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='d-flex align-items-center'>
            {/* begin:: Avatar */}
            <div className='me-3'>

                <div className='d-flex align-items-center w-200px'>
                    <div className='logo-table'>
                        <img src={serviceDetails?.logo_file?serviceDetails?.logo_file:`/${image_default}`}/>
                    </div>
                    <span style={{textOverflow:'ellipsis', overflow:'hidden', whiteSpace:'nowrap'}}>{serviceDetails.name}</span>
                </div>
            </div>
        </div>
    )
})

export {AgentService}
