import React, {useState} from "react";
import {IStyle} from "@providers/company/_models";
import {Company} from "@providers/company/_request";
import {UsersListLoading} from "@metronic/partials/widgets/tables/_modals/components/loading/UserListLoading";
import {useErrors} from "@providers/context/ErrorsProvider";
import {useParams} from "react-router-dom";
import {useIntl} from "react-intl";
import {openNotificationSuccess} from "@metronic/partials/notification";


export interface IProps {
    options: Options
}

type Options = {
    theme: string
    setTheme: (val: string) => void
    onReset: () => void
    currentTheme: IStyle | undefined
    onUpdate: () => void
}

export function CustomiseToolbar({options}: IProps) {
    const intl = useIntl()
    const {theme, setTheme, onReset, currentTheme, onUpdate} = options
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
    const {setError} = useErrors()
    const {id} = useParams()
    const onChangeTheme = () => {
        if (currentTheme) {
            onUpdateTheme()
            return
        }
        onCreateTheme()
    }


    const onUpdateTheme = async () => {
        setConfirmLoading(true)
        try {
            const response = await Company.updateTheme({
                ...currentTheme!,
                value: theme
            }).then((response) => response.data)
            if (response) {
                openNotificationSuccess(intl.formatMessage({id: 'theme.changed'}))
                onUpdate()
            }
        } catch (err: any) {
            setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
        } finally {
            setConfirmLoading(false)
        }
    }


    const onCreateTheme = async () => {
        setConfirmLoading(true)
        try {
            if (id) {
                const response = await Company.createTheme({
                    companyId: Number(id),
                    name: 'theme',
                    value: theme
                }).then((response) => response.data)
                if (response) {
                    openNotificationSuccess(intl.formatMessage({id: 'theme.changed'}))
                    onUpdate()
                }
            }
        } catch (err: any) {
            setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
        } finally {
            setConfirmLoading(false)
        }
    }

    return (
        <div className={`settings-toolbar col-5`}>
            {confirmLoading && <UsersListLoading/>}
            <p className={`block-title fw-semibold fs-4`}>Кастомизация блока</p>

            <div className={`toolbar-panel`}>
                <p className={`sub-title fw-semibold fs-6`}>Тема</p>

                <div className={`d-flex mb-7`}>
                    <button className={`switcher-theme ${theme === 'light' ? 'switch-active' : ''}`}
                            onClick={() => setTheme('light')}>
                                    <span>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M12 19.25C8 19.25 4.75 16 4.75 12C4.75 8 8 4.75 12 4.75C16 4.75 19.25 8 19.25 12C19.25 16 16 19.25 12 19.25ZM12 6.25C8.83 6.25 6.25 8.83 6.25 12C6.25 15.17 8.83 17.75 12 17.75C15.17 17.75 17.75 15.17 17.75 12C17.75 8.83 15.17 6.25 12 6.25Z"
                                            fill="currentcolor"/>
                                        <path
                                            d="M12 22.96C11.45 22.96 11 22.55 11 22V21.92C11 21.37 11.45 20.92 12 20.92C12.55 20.92 13 21.37 13 21.92C13 22.47 12.55 22.96 12 22.96ZM19.14 20.14C18.88 20.14 18.63 20.04 18.43 19.85L18.3 19.72C17.91 19.33 17.91 18.7 18.3 18.31C18.69 17.92 19.32 17.92 19.71 18.31L19.84 18.44C20.23 18.83 20.23 19.46 19.84 19.85C19.65 20.04 19.4 20.14 19.14 20.14ZM4.86 20.14C4.6 20.14 4.35 20.04 4.15 19.85C3.76 19.46 3.76 18.83 4.15 18.44L4.28 18.31C4.67 17.92 5.3 17.92 5.69 18.31C6.08 18.7 6.08 19.33 5.69 19.72L5.56 19.85C5.37 20.04 5.11 20.14 4.86 20.14ZM22 13H21.92C21.37 13 20.92 12.55 20.92 12C20.92 11.45 21.37 11 21.92 11C22.47 11 22.96 11.45 22.96 12C22.96 12.55 22.55 13 22 13ZM2.08 13H2C1.45 13 1 12.55 1 12C1 11.45 1.45 11 2 11C2.55 11 3.04 11.45 3.04 12C3.04 12.55 2.63 13 2.08 13ZM19.01 5.99C18.75 5.99 18.5 5.89 18.3 5.7C17.91 5.31 17.91 4.68 18.3 4.29L18.43 4.16C18.82 3.77 19.45 3.77 19.84 4.16C20.23 4.55 20.23 5.18 19.84 5.57L19.71 5.7C19.52 5.89 19.27 5.99 19.01 5.99ZM4.99 5.99C4.73 5.99 4.48 5.89 4.28 5.7L4.15 5.56C3.76 5.17 3.76 4.54 4.15 4.15C4.54 3.76 5.17 3.76 5.56 4.15L5.69 4.28C6.08 4.67 6.08 5.3 5.69 5.69C5.5 5.89 5.24 5.99 4.99 5.99ZM12 3.04C11.45 3.04 11 2.63 11 2.08V2C11 1.45 11.45 1 12 1C12.55 1 13 1.45 13 2C13 2.55 12.55 3.04 12 3.04Z"
                                            fill="currentcolor"/>
                                        </svg>
                                    </span>
                        Светлая
                    </button>
                    <button className={`switcher-theme ${theme === 'dark' ? 'switch-active' : ''}`}
                            onClick={() => setTheme('dark')}>
                                    <span>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12.4599 22.7501C12.2899 22.7501 12.1199 22.7501 11.9499 22.7401C6.34995 22.4901 1.66995 17.9801 1.27995 12.4801C0.939948 7.76011 3.66995 3.35011 8.06995 1.50011C9.31995 0.980114 9.97995 1.38011 10.2599 1.67011C10.5399 1.95011 10.9299 2.60011 10.4099 3.79011C9.94995 4.85011 9.71995 5.98011 9.72995 7.14011C9.74995 11.5701 13.4299 15.3301 17.9199 15.5101C18.5699 15.5401 19.2099 15.4901 19.8299 15.3801C21.1499 15.1401 21.6999 15.6701 21.9099 16.0101C22.1199 16.3501 22.3599 17.0801 21.5599 18.1601C19.4399 21.0601 16.0699 22.7501 12.4599 22.7501ZM2.76995 12.3701C3.10995 17.1301 7.16995 21.0301 12.0099 21.2401C15.2999 21.4001 18.4199 19.9001 20.3399 17.2801C20.4899 17.0701 20.5599 16.9201 20.5899 16.8401C20.4999 16.8301 20.3399 16.8201 20.0899 16.8701C19.3599 17.0001 18.5999 17.0501 17.8499 17.0201C12.5699 16.8101 8.24995 12.3801 8.21995 7.16011C8.21995 5.78011 8.48995 4.45011 9.03995 3.20011C9.13995 2.98011 9.15995 2.83011 9.16995 2.75011C9.07995 2.75011 8.91995 2.77011 8.65995 2.88011C4.84995 4.48011 2.48995 8.30011 2.76995 12.3701Z"
                                                fill="currentcolor"/>
                                        </svg>
                                    </span>
                        Тёмная
                    </button>
                </div>
            </div>

            <div className={`d-flex`}>
                <button type={'button'} disabled={confirmLoading} onClick={onChangeTheme}
                        className={`btn btn-primary col-7 fs-7`}>Сохранить изменения
                </button>
                <button onClick={onReset} type={'button'}
                        className={`btn btn-bg-white fw-semibold fs-7 text-gray-700`}>Сбросить
                </button>
            </div>
        </div>
    )
}