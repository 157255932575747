import {FC, useState} from 'react'
import {useLayout} from '../core'
import {useIntl} from "react-intl";
import {Modal} from "react-bootstrap";
import {Row, Col, Form, Input, Button, Alert} from 'antd';
import InputMask from "react-input-mask";

const Footer: FC = () => {
  const [contactShow,setContactShow] = useState(false)
  const {classes} = useLayout()
  const intl = useIntl()
  return (
      <>
        <Modal show={contactShow} onHide={()=>setContactShow(false)} size={"sm"}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h1>{intl.formatMessage({id:"CONTACTS"})}</h1>
            <div className='mt-5'>
              <div className='mb-3'>
                <p className='mb-0'>{intl.formatMessage({id:'call-us'})}</p>
                <a href='tel:+998909517327'>+998 90 951 73 27</a>
              </div>

              <div className='mb-3'>
                <p className='mb-0'>{intl.formatMessage({id:'mail-us'})}</p>
                <a href="mailto:contact@gs.uz">contact@gs.uz</a>
              </div>

              <div className='mb-3'>
                <p className='mb-0'>{intl.formatMessage({id:'ofice'})}</p>
                <p className='mb-0'>{intl.formatMessage({id:'Address-ofice'})}</p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <div className='footer py-4 d-flex flex-lg-column' id='kt_footer'>
          {/* begin::Container */}
          <div
              className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
          >
            {/* begin::Copyright */}
            <div className='text-dark order-2 order-md-1'>
              <span className='text-muted fw-bold me-2'>{new Date().getFullYear()} &copy;</span>
              <span className='text-gray-800'>
                Global Pay
              </span>
            </div>
            {/* end::Copyright */}

            {/* begin::Nav */}
            <ul className='menu menu-gray-600 menu-hover-primary fw-bold order-1'>

              <li className='menu-item'>
                <a href='@metronic/layout/components/Footer/Footer#' className='menu-link pe-0 pe-2' onClick={(e)=>{
                  e.preventDefault()
                  setContactShow(true)
                }}>{intl.formatMessage({id:"CONTACTS"})}</a>
              </li>

            </ul>
            {/* end::Nav */}
          </div>
          {/* end::Container */}
        </div>
      </>
  )
}

export {Footer}
