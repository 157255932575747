import React, {ChangeEvent, useState} from 'react';
import {useIntl} from "react-intl";
import InputMask from "react-input-mask";
import {UsersListLoading} from "@metronic/partials/widgets/tables/_modals/components/loading/UserListLoading";
import {Account} from "@providers/account/_request";
import {useErrors} from "@providers/context/ErrorsProvider";
import {IAccountInfo} from "@providers/account/_models";

interface props {
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
    setId: (id: number | undefined) => void
}

const UserSearchInput: React.FC<props> = ({
                                              setFieldValue,
                                              setId
                                          }) => {
    const intl = useIntl()

    const {setError} = useErrors()
    const [text, setText] = useState<string>('')

    const [confirmLoading, setConfirmLoading] = useState<boolean>(false)

    const resetForm = () => {
        setId(undefined)
        setFieldValue('lastName', '')
        setFieldValue('firstName', '')
        setFieldValue('email', '')
        setFieldValue('phone', '')
    }

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setText(value)
        const phone = value.replace(/\s/g, '').replace('+', '').replaceAll('_', '')
        if (phone.length === 12) {
            setConfirmLoading(true)
            Account.findAccount(phone)
                .then((response) => {
                    if (response.data.result.length) {
                        const user: IAccountInfo = response.data.result[0]
                        setId(user.id)
                        setFieldValue('lastName', user.lastName)
                        setFieldValue('firstName', user.firstName)
                        setFieldValue('email', user.email)
                        setFieldValue('phone', user.phone)

                    }
                }).catch((e) => {
                setError({status: e?.response?.status, message: e?.response?.data?.message, isError: true})
            }).finally(() => setConfirmLoading(false))

        }
    }

    const onClearValue = () => {
        setText('')
        resetForm()
    }


    return (
        <div className='mb-8 fv-row'>
            {confirmLoading && <UsersListLoading/>}
            <label
                className='form-label mb-3'>{intl.formatMessage({id: 'searchByPhone'})}</label>
            <div className={'d-flex align-items-center justify-content-between'}>
                <InputMask mask="+\9\9\8 99 999 99 99"
                           value={text}
                           type={'search'}
                           className='form-control form-control-lg form-control-solid'
                           onChange={onChange}
                />
                <div onClick={onClearValue} className={'ms-4 btn btn-primary'}>
                    {intl.formatMessage({id: 'reset'})}
                </div>
            </div>
        </div>
    );
};

export default UserSearchInput;