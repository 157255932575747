import {Column} from "react-table";
import {IRequestedFields, IWebPayment, IWebPaymentSend} from "@providers/webPayments/models";
import TableCustomHeader from "./TableCustomHeader/TableCustomHeader";
import DefaultCell from "./defaultCell/defaultCell";
import {priceformatter} from "@utils/priceformatter";
import Token from "./token/token";
import Actions from "./actions/actions";
import {divideByHundred} from "@utils/numbersOperations";


export const webPaymentsColumns: ReadonlyArray<Column<IWebPaymentSend>> = [

    {
        Header: (props) => <TableCustomHeader tableProps={props} title='ID' className='min-w-50px'/>,
        id: 'id',
        Cell: ({...props}) => <DefaultCell classNames={'fw-medium'} title={props.data[props.row.index].id}/>,
    },
    {
        Header: (props) => <TableCustomHeader tableProps={props} title='webPayments.name' className='min-w-50px'/>,
        id: 'name',
        Cell: ({...props}) => <DefaultCell classNames={'fw-normal '}
                                           title={props.data[props.row.index].name}/>,
    },
    {
        Header: (props) => <TableCustomHeader tableProps={props} title='productPrice' className='min-w-50px'/>,
        id: 'price',
        Cell: ({...props}) => {
            const price = props.data[props.row.index].requestFields.find((item: IRequestedFields) => item.name === 'amount')?.value
            return (
                <DefaultCell classNames={'fw-normal '}
                             title={price ? priceformatter(divideByHundred(Number(price))) : ''}/>
            )
        },
    },
    {
        Header: (props) => <TableCustomHeader tableProps={props} title='description' className='min-w-50px'/>,
        id: 'description',
        Cell: ({...props}) => <DefaultCell classNames={'fw-normal '} title={props.data[props.row.index].description}/>,
    },
    {
        Header: (props) => <TableCustomHeader tableProps={props} title='token' className='min-w-50px'/>,
        id: 'token',
        Cell: ({...props}) => <Token token={props.data[props.row.index].id}/>,
    },
    {
        Header: (props) => <TableCustomHeader tableProps={props} title='' className='min-w-50px'/>,
        id: 'actions',
        Cell: ({...props}) => <Actions invoice={props.data[props.row.index]}/>,
    },

]