// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {TableCustomHeader} from './TableCustomHeader'
import {ModSession} from '@providers/session/_modals'
import {TableStatus} from './TableStatus'
import {TableDate} from './TableDate'
import {TableSum} from './TableSum'
import {TableIcon} from './TableIcon'
import {ITransaction} from '@providers/adminTransactions/_models'
import {RevertTransactionsActions} from './RevertTransactionsActions'
import {toAbsoluteUrl} from '@metronic/helpers'
import ReceiptModal from './receiptModal/receiptModal'
import {statuses_for_cheque} from '@utils/constants'
import CardInfoCell from './cardInfoCell'

const companyColumnsAdmin: ReadonlyArray<Column<ITransaction>> = [
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='NUM' className='min-w-25px' />,
    id: 'num',
    Cell: ({...props}) => <UserInfoCell data={props.data[props.row.index].num} />,
  },

  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='TRANSACTION_ID' className='min-w-150px' />
    ),
    id: 'id',
    Cell: ({...props}) => (
      <ReceiptModal
        id={props.data[props.row.index].id}
        isOpen={statuses_for_cheque.includes(props.data[props.row.index].status)}
        amount={props.data[props.row.index].amount}
        companyId={props.data[props.row.index].service.id.toString()}
      />
    ),
  },

  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='CREATED_DATE' className='min-w-150px' />
    ),
    id: 'created_at',
    Cell: ({...props}) => <TableDate date={props.data[props.row.index].createdAt} />,
  },

  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='CARD_NUMBER' className='min-w-150px' />
    ),
    id: 'card_number',
    Cell: ({...props}) => (
      <CardInfoCell
        data={props.data[props.row.index].card?.cardNumber}
        icon={
          props.data[props.row.index].card?.cardNumber
            ? props.data[props.row.index].processingType?.toLowerCase()
            : undefined
        }
      />
    ),
  },

  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='SYSTEM' className='min-w-150px' />
    ),
    id: 'platform',
    Cell: ({...props}) => <UserInfoCell data={props.data[props.row.index]?.processingType} />,
  },

  {
    Header: (props) => <TableCustomHeader tableProps={props} title='SUM' className='min-w-150px' />,
    id: 'amount',
    Cell: ({...props}) => <TableSum sum={props.data[props.row.index].amount} />,
  },

  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='STATUS' className='min-w-150px' />
    ),
    id: 'status',
    Cell: ({...props}) => (
      <TableStatus status={props.data[props.row.index].status} className='text-left' />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='cashboxes' className='min-w-150px' />
    ),
    id: 'cashbox_id',
    Cell: ({...props}) => (
      <UserInfoCell
        data={props.data[props.row.index].service.name}
        icon={
          props.data[props.row.index].service?.iconId
            ? toAbsoluteUrl(props.data[props.row.index].service?.iconId)
            : ''
        }
        company
      />
    ),
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='' className='min-w-150px' />,
    id: 'actions',
    Cell: ({...props}) => (
      <RevertTransactionsActions filialData={false} transactionData={props.data[props.row.index]} />
    ),
  },
]

const demoColumns: ReadonlyArray<Column<ModSession>> = [
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='NUM' className='min-w-25px' />,
    id: 'num',
    Cell: ({...props}) => <UserInfoCell data={props.data[props.row.index].num} />,
  },

  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='TRANSACTION_ID' className='min-w-150px' />
    ),
    id: 'id',
    Cell: ({...props}) => (
      <UserInfoCell className='transaction-id-overflow' data={props.data[props.row.index].id} />
    ),
  },

  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='CREATED_DATE' className='min-w-150px' />
    ),
    id: 'created_at',
    Cell: ({...props}) => <TableDate date={props.data[props.row.index].created_at} />,
  },

  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='CARD_NUMBER' className='min-w-150px' />
    ),
    id: 'card_number',
    Cell: ({...props}) => (
      <UserInfoCell
        data={props.data[props.row.index].card_number}
        icon={props.data[props.row.index].ppc_type}
      />
    ),
  },

  {
    Header: (props) => <TableCustomHeader tableProps={props} title='SUM' className='min-w-150px' />,
    id: 'sum',
    Cell: ({...props}) => <TableSum sum={props.data[props.row.index].sum} />,
  },

  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='STATUS' className='min-w-150px' />
    ),
    id: 'status',
    Cell: ({...props}) => <TableStatus status={props.data[props.row.index].status} />,
  },

  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='QR_CODE' className='min-w-75px' />
    ),
    id: 'QR_CODE',
    Cell: ({...props}) => (
      <TableIcon shouldShow={props.data[props.row.index].status === 'SUCCESSFUL'} />
    ),
  },

  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='FILIAL_ID' className='min-w-150px' />
    ),
    id: 'branch_id',
    Cell: ({...props}) => (
      <UserInfoCell data={props.data[props.row.index].branch_id} isBranchName />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='PPC_TYPE' className='min-w-150px' />
    ),
    id: 'ppc_type',
    Cell: ({...props}) => <UserInfoCell data={props.data[props.row.index].ppc_type} />,
  },
]

const usersColumns: ReadonlyArray<Column<ITransaction>> = [
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='NUM' className='min-w-25px' />,
    id: 'num',
    Cell: ({...props}) => <UserInfoCell data={props.data[props.row.index].num} />,
  },

  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='TRANSACTION_ID' className='min-w-150px' />
    ),
    id: 'id',
    Cell: ({...props}) => (
      <ReceiptModal
        id={props.data[props.row.index].id}
        isOpen={statuses_for_cheque.includes(props.data[props.row.index].status)}
        amount={props.data[props.row.index].amount}
        companyId={props.data[props.row.index].service.id}
      />
    ),
  },

  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='CREATED_DATE' className='min-w-150px' />
    ),
    id: 'created_at',
    Cell: ({...props}) => <TableDate date={props.data[props.row.index].createdAt} />,
  },

  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='CARD_NUMBER' className='min-w-150px' />
    ),
    id: 'card_number',
    Cell: ({...props}) => (
      <CardInfoCell
        data={props.data[props.row.index].card?.cardNumber}
        icon={
          props.data[props.row.index].card?.cardNumber
            ? props.data[props.row.index].processingType?.toLowerCase()
            : undefined
        }
      />
    ),
  },

  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='SYSTEM' className='min-w-150px' />
    ),
    id: 'platform',
    Cell: ({...props}) => <UserInfoCell data={props.data[props.row.index]?.processingType} />,
  },

  {
    Header: (props) => <TableCustomHeader tableProps={props} title='SUM' className='min-w-150px' />,
    id: 'amount',
    Cell: ({...props}) => <TableSum sum={props.data[props.row.index].amount} />,
  },

  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='STATUS' className='min-w-150px' />
    ),
    id: 'status',
    Cell: ({...props}) => (
      <TableStatus status={props.data[props.row.index].status} className='text-left' />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='cashboxes' className='min-w-150px' />
    ),
    id: 'cashbox_id',
    Cell: ({...props}) => (
      <UserInfoCell
        data={props.data[props.row.index].service.name}
        icon={toAbsoluteUrl(props.data[props.row.index].service?.iconId)}
        company
      />
    ),
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='' className='min-w-150px' />,
    id: 'actions',
    Cell: ({...props}) => (
      <RevertTransactionsActions filialData={true} transactionData={props.data[props.row.index]} />
    ),
  },
]

const usersColumnsCompany: ReadonlyArray<Column<ITransaction>> = [
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='NUM' className='min-w-25px' />,
    id: 'num',
    Cell: ({...props}) => <UserInfoCell data={props.data[props.row.index].num} />,
  },

  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='TRANSACTION_ID' className='min-w-150px' />
    ),
    id: 'id',
    Cell: ({...props}) => (
      <ReceiptModal
        id={props.data[props.row.index].id}
        isOpen={statuses_for_cheque.includes(props.data[props.row.index].status)}
        amount={props.data[props.row.index].amount}
        companyId={props.data[props.row.index].service.id}
      />
    ),
  },

  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='CREATED_DATE' className='min-w-150px' />
    ),
    id: 'created_at',
    Cell: ({...props}) => <TableDate date={props.data[props.row.index].createdAt} />,
  },

  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='CARD_NUMBER' className='min-w-150px' />
    ),
    id: 'card_number',
    Cell: ({...props}) => (
      <CardInfoCell
        data={props.data[props.row.index].card?.cardNumber}
        icon={
          props.data[props.row.index].card?.cardNumber
            ? props.data[props.row.index].processingType?.toLowerCase()
            : undefined
        }
      />
    ),
  },

  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='SYSTEM' className='min-w-150px' />
    ),
    id: 'platform',
    Cell: ({...props}) => <UserInfoCell data={props.data[props.row.index].processingType} />,
  },

  {
    Header: (props) => <TableCustomHeader tableProps={props} title='SUM' className='min-w-150px' />,
    id: 'amount',
    Cell: ({...props}) => <TableSum sum={props.data[props.row.index].amount} />,
  },

  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='STATUS' className='min-w-150px' />
    ),
    id: 'status',
    Cell: ({...props}) => (
      <TableStatus status={props.data[props.row.index].status} className='text-left' />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='cashboxes' className='min-w-150px' />
    ),
    id: 'cashbox_id',
    Cell: ({...props}) => (
      <UserInfoCell
        data={props.data[props.row.index].service.name}
        icon={props.data[props.row.index].service?.iconId}
        company
      />
    ),
  },

  {
    Header: (props) => <TableCustomHeader tableProps={props} title='' className='min-w-150px' />,
    id: 'actions',
    Cell: ({...props}) => (
      <RevertTransactionsActions filialData={false} transactionData={props.data[props.row.index]} />
    ),
  },
]

export {usersColumns, demoColumns, companyColumnsAdmin, usersColumnsCompany}
