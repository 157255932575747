import React, {FC, useEffect, useState} from 'react'
import {ErrorMessage, Field, FormikProps} from 'formik'
import InputMask from 'react-input-mask'
import {CreateCashbox} from "@providers/filial/_models";
import moment from "moment";
import AsyncSelect from "react-select/async";
import {customStyles} from "@metronic/assets/ts/select.style";
import {useIntl} from "react-intl";
import {textPattern} from "@utils/constants";
import { serviceCodeOptions } from '../serviceCodeOptions.data';

interface IProps extends FormikProps<CreateCashbox> {
    handleChange(arg: any): void,
    setFieldValue(arg: string, val: any): void
    values: any
    error: any
    type: { id: number, name: string }[] | []
    setSelfEmployee: (val: boolean) => void,
    selfEmployee: boolean
}



const Step2: FC<IProps> = (props) => {

    const [type, setType] = useState<any[]>([])
    const intl = useIntl()
    const {setSelfEmployee, selfEmployee} = props

    useEffect(() => {
        props.setFieldValue('type', undefined)
    }, [props.error])

    useEffect(() => {
        setType(props.type.map(item => ({value: item.id, label: item.name})))
    }, [type])


    function validateSite(value: string) {
        let error;
        if (value && !value.match(/[a-z]\.[a-z]/)) {
            error = intl.formatMessage({id: 'invalid_site'});
        }
        return error;
    }

    const filterOptions = (inputValue: string, values: any[]) => {
        return values.filter((i: any) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    }

    const loadOptionsType = (
        inputValue: string,
        callback: (options: any[]) => void
    ) => {
        callback(filterOptions(inputValue, type))
    }


    return (
        <div className='w-100'>
            <div className='mb-10 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id: "cashbox__name"})}</label>
                <Field
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='name'
                    onChange={(e: React.FormEvent<HTMLInputElement>)=>{
                        if(!e.currentTarget.value.length||e.currentTarget.value.match(textPattern)){
                            props.handleChange(e)
                        }
                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='name'/>
                </div>
            </div>
            <div className='mb-10 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id: "legal_name"})}</label>
                <Field
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='legalName'
                    onChange={(e: React.FormEvent<HTMLInputElement>)=>{
                        if(!e.currentTarget.value.length||e.currentTarget.value.match(textPattern)){
                            props.handleChange(e)
                        }
                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='legalName'/>
                </div>
            </div>
            <div className='mb-10 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id: "description"})}</label>
                <Field
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='description'
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='description'/>
                </div>
            </div>
            <div className='mb-10 fv-row'>
                <label className='form-label mb-3'>{intl.formatMessage({id: "pinfl"})}</label>
                <Field
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='pinfl'
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        if (e.currentTarget.value.match(/^\d{0,14}$/)) props.handleChange(e)
                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='pinfl'/>
                </div>
            </div>
            <div className='mb-10 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id: 'gnk_spic'})}</label>
                <Field
                    type='tel'
                    name='gnkSpic'
                    value={props.values['gnkSpic']}
                    className='form-control form-control-lg form-control-solid'
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        props.setFieldValue('gnkSpic', e.currentTarget.value.replace(/[^\d]/g, ''))
                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='gnkSpic'/>
                </div>
            </div>
            <div className='mb-10 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id: 'package_code'})}</label>
                <Field
                    type='tel'
                    name='packageCode'
                    value={props.values['packageCode']}
                    className='form-control form-control-lg form-control-solid'
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        props.setFieldValue('packageCode', e.currentTarget.value.replace(/[^\d]/g, ''))
                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='packageCode'/>
                </div>
            </div>
            <div className='mb-10 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id: 'min_amount'})}</label>
                <Field
                    type='text'
                    name='minAmount'
                    placeholder=''
                    className='form-control form-control-lg form-control-solid'
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        props.setFieldValue('minAmount', e.currentTarget.value.replace(/ /g, '').replace(/[^0-9]/g, '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "))
                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='minAmount'/>
                </div>
            </div>
            <div className='mb-10 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id: 'max_amount'})}</label>
                <Field
                    type='text'
                    name='maxAmount'
                    className='form-control form-control-lg form-control-solid'
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        props.setFieldValue('maxAmount', e.currentTarget.value.replace(/ /g, '').replace(/[^0-9]/g, '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "))
                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='maxAmount'/>
                </div>
            </div>
            <div className={'mb-10 d-flex flex-wrap align-items-center justify-content-between'}>
                <h3 className={`flex-100 mb-3`}>
                    HUMO
                </h3>
                <div className='fv-row flex-40'>
                    <label className='form-label mb-3 required'>{intl.formatMessage({id: 'terminal_id'})}</label>
                    <Field
                        type='text'
                        name='humoTid'
                        placeholder=''
                        className='form-control form-control-lg form-control-solid'
                    />
                    <div className='text-danger mt-2'>
                        <ErrorMessage name='humoTid'/>
                    </div>
                </div>
                <div className='fv-row flex-40'>
                    <label className='form-label mb-3 required'>{intl.formatMessage({id: 'merchant_id'})}</label>
                    <Field
                        type='text'
                        name='humoMid'
                        placeholder=''
                        className='form-control form-control-lg form-control-solid'
                    />
                    <div className='text-danger mt-2'>
                        <ErrorMessage name='humoMid'/>
                    </div>
                </div>
            </div>
            <div className={'mb-10 d-flex flex-wrap align-items-center justify-content-between'}>
                <h4 className={`flex-100 mb-3`}>
                    UZCARD
                </h4>
                <div className='fv-row flex-40'>
                    <label className='form-label mb-3 required'>{intl.formatMessage({id: 'terminal_id'})}</label>
                    <Field
                        type='text'
                        name='uzcardTid'
                        placeholder=''
                        className='form-control form-control-lg form-control-solid'
                    />
                    <div className='text-danger mt-2'>
                        <ErrorMessage name='uzcardTid'/>
                    </div>
                </div>
                <div className='fv-row flex-40'>
                    <label className='form-label mb-3 required'>{intl.formatMessage({id: 'merchant_id'})}</label>
                    <Field
                        type='text'
                        name='uzcardMid'
                        placeholder=''
                        className='form-control form-control-lg form-control-solid'
                    />
                    <div className='text-danger mt-2'>
                        <ErrorMessage name='uzcardMid'/>
                    </div>
                </div>
            </div>
            <div className='mb-10 fv-row'>
                <label className='form-label required mb-3'>{intl.formatMessage({id: 'nds_rate'})}</label>
                <Field
                    type='text'
                    name='ndsRate'
                    className='form-control form-control-lg form-control-solid'
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        props.setFieldValue('ndsRate', e.currentTarget.value.replace(/ /g, '').replace(/[^0-9]/g, '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "))
                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='ndsRate'/>
                </div>
            </div>

            <div className='mb-10 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id: 'service_code'})}</label>
                <Field
                    as="select"
                    name="serviceCode"
                    className='form-select form-select-lg form-select-solid'
                    onChange={(e: React.FormEvent<HTMLSelectElement>) => {
                        props.setFieldValue('serviceCode', e.currentTarget.value)
                    }}
                >
                        {serviceCodeOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {intl.formatMessage({id: option.label})}
                            </option>
                        ))}
                </Field>
                <div className='text-danger mt-2'>
                    <ErrorMessage name='serviceCode'/>
                </div>
            </div>

            <div className='mb-10 fv-row'>
                <label className='form-label mb-3'>{intl.formatMessage({id: 'url'})}</label>
                <Field
                    type='text'
                    name='billingUrl'
                    value={props.values['url']}
                    className='form-control form-control-lg form-control-solid'
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='billingUrl'/>
                </div>
            </div>
        </div>
    )
}

export {Step2}
