import {FC, PropsWithChildren, useMemo} from 'react'
import {HeaderProps} from 'react-table'
import {useIntl} from "react-intl";
import {observer} from "mobx-react";
import {agentStore} from "@store/agents";
import {Agent} from "@providers/agents/_request";
import {useParams} from "react-router-dom";
import {autopayStore} from "@store/autopay";

type Props = {
  className?: string
  title?: string
  tableProps: PropsWithChildren<HeaderProps<any>>
}

const fixTables_date:any= {
  num: 50,
  contractNumber: 125,
  phoneNumber: 150,
  productPrice: 125,
  status: 185,
  contractDate: 125,
}

const keys = Object.keys(fixTables_date)

const TableCustomHeader: FC<Props> = observer(({className, title, tableProps}) => {
  const id = tableProps.column.id
  const intl = useIntl()
  const sort = useMemo(() => autopayStore.getSortingContract, [autopayStore.getSortingContract])
  const {type} = useParams()

  const columns = tableProps.allColumns.map((item:any)=>item.id)
  const fix_column:any = {}
  let data_with = 0
  const nums:any = []

  columns.forEach((item:any)=>{
    if(fixTables_date[item]) {
      fix_column[item] = data_with
      nums.push(data_with)
      data_with+= fixTables_date[item]

    }
  })




  async function updateData(){

    // await Agent.getAgents(dataFilter)
    //     .then(result=>{
    //       agentStore.setAgent(result?.data?.result.map((item: any, index: number) => {
    //         if (sort.page && sort.page > 2) {
    //           item['num'] = ((sort.page - 1) * 20) + index + 1
    //         } else if (sort.page == 2) {
    //           item['num'] = 21 + index
    //         } else {
    //           item['num'] = index + 1
    //         }
    //         return item
    //       }))
    //     })
  }




  const sortColumn = () => {
    // avoid sorting for these columns
    if (id === 'actions' || id === 'selection') {
      return
    }

    if(id==='productPrice'||id==='prepaymentPrice'||id==='paidSum'||id==='status'){
      window.alert(id)
      autopayStore.setSortingContracts({...sort, sortFromMax:!sort.sortFromMax, sortBy:id.toUpperCase() })
      //updateData()
    }
  }



  return (
      <th
          {...tableProps.column.getHeaderProps()}
          className={`${className} ${(sort.sortBy||'').toLowerCase() === id ? sort.sortFromMax ? "table-sort-desc" : "table-sort-asc" : ""} text-left`}
          style={keys.includes(id)?{position:'sticky', backgroundColor: 'var(--kt-card-bg)', left: fix_column[id]||0, textTransform: "capitalize", cursor:'default'}:{textTransform: "capitalize", cursor:'default'}}
          // onClick={sortColumn}
      >
        {title&&title.length?intl.formatMessage({id:title}):''}
      </th>
  )
})

export {TableCustomHeader}
