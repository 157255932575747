import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserLastLoginCell} from './UserLastLoginCell'
import {UserTwoStepsCell} from './UserTwoStepsCell'
import {UserActionsCell} from './UserActionsCell'
import {TableCustomHeader} from './TableCustomHeader'
import {CompanyResponse} from "@providers/company/_models";
import {TableText} from "./TableText";
import {PhoneAgent} from "./PhoneAgent";
import {AgentDate} from "./AgentDate";
import {NumReplace} from "@utils/index";
import {AgentContract} from "./AgentContract";
import {MerchantName} from "./MerchantName";
import {Income} from "./Income";






const statisticPersonal: ReadonlyArray<Column<CompanyResponse>> = [

  {
    Header: (props) => <TableCustomHeader tableProps={props} title='№' className='min-w-30px fw-bold text-gray-700' />,
    id: 'num',
    Cell: ({...props}) => <TableText text={props.data[props.row.index]?.num} translate={false}/>,
  },

  {
    Header: (props) => <TableCustomHeader tableProps={props} title='merchant' className='min-w-170px fw-bold text-gray-700' />,
    id: 'company',
    Cell: ({...props}) => <MerchantName
        data={props.data[props.row.index]}
        />,
  },

  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='merchant_contract_id' className='min-w-125px fw-bold text-gray-700' />
    ),
    id: 'merchant_contract_id',
    Cell: ({...props}) => <AgentContract contract={{
      contract_id: props.data[props.row.index].merchant_contract_id,
      contract_expiry_date: props.data[props.row.index].merchant_contract_expiry_date
    }} />,
  },
  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='company_income' className='min-w-125px fw-bold text-gray-700' />
    ),
    id: 'company_income',
    Cell: ({...props}) => <Income data={{
      income: props.data[props.row.index]?.company_income/100,
      commission: props.data[props.row.index]?.company_commission
    }}   />,
  },
  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='agent_income_personal' className='min-w-125px fw-bold text-gray-700' />
    ),
    id: 'agent_income',
    Cell: ({...props}) => <Income data={{
      income: props.data[props.row.index]?.agent_income/100,
      commission: props.data[props.row.index]?.agent_commission
    }}   />,
  },
  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='agent_contract_id_personal' className='min-w-125px fw-bold text-gray-700' />
    ),
    id: 'agent_contract_id',
    Cell: ({...props}) =>  <AgentContract contract={{
      contract_id: props.data[props.row.index].agent_contract_id,
      contract_expiry_date: props.data[props.row.index].agent_contract_expiry_date
    }} />,
  }
]


const merchantsAgentPage: ReadonlyArray<Column<CompanyResponse>> = [

  {
    Header: (props) => <TableCustomHeader tableProps={props} title='№' className='min-w-30px fw-bold text-gray-700' />,
    id: 'num',
    Cell: ({...props}) => <TableText text={props.data[props.row.index]?.num} translate={false}/>,
  },

  {
    Header: (props) => <TableCustomHeader tableProps={props} title='merchant' className='min-w-125px fw-bold text-gray-700' />,
    id: 'company',
    Cell: ({...props}) => <MerchantName
        data={props.data[props.row.index]}
    />,
  },
  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='merchant_contract_id' className='min-w-150px fw-bold text-gray-700' />
    ),
    id: 'agent_contract_id',
    Cell: ({...props}) =>  <AgentContract contract={{
      contract_id: props.data[props.row.index].agent_contract_id,
      contract_expiry_date: props.data[props.row.index].agent_contract_expiry_date
    }} />,
  },
  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='gs-transaction' className='min-w-150px fw-bold text-gray-700' />
    ),
    id: 'company_income',
    Cell: ({...props}) =>  <Income data={{
      income: props.data[props.row.index]?.company_income/100,
      commission: props.data[props.row.index]?.company_commission
    }}   />,
  },


  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='agent_income' className='min-w-100px fw-bold text-gray-700' />
    ),
    id: 'agent_income',
    Cell: ({...props}) => <Income data={{
      income: props.data[props.row.index]?.agent_income/100,
      commission: props.data[props.row.index]?.agent_commission
    }}   />,
  },
  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='agent-statistic.contract' className='min-w-125px fw-bold text-gray-700' />
    ),
    id: 'merchant_contract_id',
    Cell: ({...props}) => <AgentContract contract={{
      contract_id: props.data[props.row.index].merchant_contract_id,
      contract_expiry_date: props.data[props.row.index].merchant_contract_expiry_date
    }} />,
  },

]


const merchantsPersonal: ReadonlyArray<Column<CompanyResponse>> = [

  {
    Header: (props) => <TableCustomHeader tableProps={props} title='№' className='min-w-30px fw-bold text-gray-700' />,
    id: 'num',
    Cell: ({...props}) => <TableText text={props.data[props.row.index]?.num} translate={false}/>,
  },

  {
    Header: (props) => <TableCustomHeader tableProps={props} title='merchant' className='min-w-125px fw-bold text-gray-700' />,
    id: 'company',
    Cell: ({...props}) => <MerchantName
        data={props.data[props.row.index]}
        />,
  },
  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='agent_contract_id_personal' className='min-w-150px fw-bold text-gray-700' />
    ),
    id: 'agent_contract_id',
    Cell: ({...props}) =>  <AgentContract contract={{
      contract_id: props.data[props.row.index].agent_contract_id,
      contract_expiry_date: props.data[props.row.index].agent_contract_expiry_date
    }} />,
  },


  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='agent_income_personal' className='min-w-100px fw-bold text-gray-700' />
    ),
    id: 'agent_income',
    Cell: ({...props}) => <Income data={{
      income: props.data[props.row.index]?.agent_income/100,
      commission: props.data[props.row.index]?.agent_commission
    }}   />,
  },
  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='merchant_contract_id' className='min-w-125px fw-bold text-gray-700' />
    ),
    id: 'merchant_contract_id',
    Cell: ({...props}) => <AgentContract contract={{
      contract_id: props.data[props.row.index].merchant_contract_id,
      contract_expiry_date: props.data[props.row.index].merchant_contract_expiry_date
    }} />,
  },

]





export {statisticPersonal, merchantsPersonal, merchantsAgentPage}
