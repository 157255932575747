import React, {useEffect, useState} from 'react';
import {contract} from "@store/contract/contract";
import {columns} from "../../_modals/columns";
import {ColumnInstance, Row, useTable} from "react-table";
import {ISchedule} from "@providers/autopay/models/_models";
import {ContractsService} from "@providers/autopay/requests/contracts.service";
import {useParams} from "react-router-dom";
import {AxiosResponse} from "axios";
import {UsersListLoading} from "@metronic/partials/widgets/tables/_modals/components/loading/UserListLoading";
import {useErrors} from "@providers/context/ErrorsProvider";
import {CustomHeaderColumn} from "../../../contractsList/_modals/columns/CustomHeaderColumn";
import {CustomRow} from "../../../contractsList/_modals/columns/CustomRow";
import {useIntl} from "react-intl";
import {KTSVG} from "@metronic/helpers";

interface props {

}

const PaymentsTable: React.FC<props> = () => {

    const {entity} = contract.getContract

    const [data, setData] = useState<ISchedule[]>(entity ? entity.contractVersion.schedules : [])

    const {contractId} = useParams()

    const [loaded, setLoaded] = useState<boolean>(false)

    const intl = useIntl()

    const {setError} = useErrors()
    const getPaidSchedules = async () => {
        if (contractId) {
            try {
                const response = await ContractsService.getHistoryOfContractPayments(contractId).then((response: AxiosResponse<{
                    data: {
                        paymentHistory: ISchedule[]
                    }
                }>) => response.data.data.paymentHistory)
                if (entity) {
                    const newData = response.concat(entity.contractVersion.schedules)
                    setData(newData)
                }
            } catch (e: any) {
                setError({
                    message: e?.response?.data?.error || e?.response?.data?.message,
                    isError: true,
                    status: e?.response?.status
                })
            } finally {
                setLoaded(true)
            }
        }
    }


    useEffect(() => {
        getPaidSchedules()
    }, [entity?.contractVersion.schedules])


    const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
        columns,
        data: data.map((item, index) => {
            return {
                ...item,
                num: index + 1
            }
        })
    })

    if (!loaded) return <UsersListLoading/>


    return (
        <div className={'mt-8'}>
            {
                data.length ? <table
                    id='kt_table_users'
                    className='table fw-normal align-middle table-row-bordered fs-6 gy-5 dataTable no-footer table-hover cursor-pointer'
                    {...getTableProps()}
                >
                    <thead>
                    <tr className='text-start text-muted fw-semibold fs-7 text-uppercase gs-0'>
                        {headers.map((column: ColumnInstance<ISchedule>) => {
                                return (
                                    <CustomHeaderColumn key={column.id} column={column}/>
                                )
                            }
                        )}
                    </tr>
                    </thead>
                    <tbody className='text-gray-600 fw-normal' {...getTableBodyProps()}>
                    {rows.length > 0 ? (
                        rows.map((row: Row<ISchedule>, i) => {
                            prepareRow(row)
                            return <CustomRow row={row} key={`row-${i}-${row.id}`}/>
                        })
                    ) : (
                        <tr>
                            <td colSpan={7}>
                                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                    {intl.formatMessage({id: 'NO_MATCHING'})}
                                </div>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table> : <div style={{height: "55vh"}} className="d-flex justify-content-center align-items-center">
                    <a href="https://demo.globalpay.uz" target="_blank" className="btn btn-success text-white fs-3">
                        {intl.formatMessage({id: "WATCH_DEMO_COMPANY1"})}
                        <KTSVG path='/media/icons/duotune/arrows/arr001.svg' className='svg-icon-3 ms-3'/>
                    </a>
                </div>
            }
        </div>
    );
};

export default PaymentsTable;