import * as Yup from 'yup'
import {CreateCompany, CreateCompanyFiles} from "@providers/company/_models";
import moment from "moment/moment";
import {COMPANY, INDIVIDUAL, REGEXP_PHONE} from "@utils/constants";
import {useIntl} from "react-intl";
import {useParams} from "react-router-dom";
import {AgentRequestCompany, AgentRequestIndividual} from "@providers/agents/_models";




function AgentSchema() {
    const intl = useIntl()
    const {type} = useParams()

    if(type===INDIVIDUAL.toLowerCase()){
        return [
            Yup.object({
                type: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
            }),
            Yup.object({

                contract_id: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
                    .min(9, 'Не достаточно символов')
                    .max(50, 'Слишком много символов'),
                pinfl: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
                    .min(14, 'Не достаточно символов').required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
                first_name: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
                    .max(50, 'Слишком много символов').required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
                last_name: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
                    .max(50, 'Слишком много символов').required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
                middle_name: Yup.string()
                    .max(50, 'Слишком много символов'),
                passport_id: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
                contract_expiry_date: Yup.date().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
                phone: Yup.string().matches(
                    REGEXP_PHONE,
                    intl.formatMessage({id: 'ERROR_VALIDATION_PHONE'})
                ).required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
            }),

            Yup.object({
                companies: Yup.array(Yup.object({
                    contract_expiry_date: Yup.date().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
                    commission: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
                    contract_id: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
                    company_id: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
                }))
            })
        ]
    }else{
      return  [
            Yup.object({
                type: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
            }),
            Yup.object({

                contract_id: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
                    .min(9, 'Не достаточно символов').required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
                company_name: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
                    .max(50, 'Слишком много символов').required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
                inn: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
                    .min(9, 'Не достаточно символов').required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
                certificate_id: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
                    .min(9, 'Не достаточно символов').required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
                contract_expiry_date: Yup.date().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
                phone: Yup.string().matches(
                    REGEXP_PHONE,
                    intl.formatMessage({id: 'ERROR_VALIDATION_PHONE'})
                ).required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
            }),

          Yup.object({
              companies: Yup.array(Yup.object({
                  contract_expiry_date: Yup.date().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
                  commission: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
                  company_id: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
                  contract_id: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
              }))
          })
        ]
    }

}


const initsIndividual: AgentRequestIndividual = {
    contract_expiry_date: '',
    companies: [
        {
            commission: '0',
            contract_id: '',
            company_id: 0,
            contract_expiry_date: ''
        }
    ],
    type: INDIVIDUAL,
    phone: '',
    contract_id: '',
    first_name: '',
    last_name: '',
    middle_name: '',
    passport_id: '',
    pinfl: ''

}


const initsCompany: AgentRequestCompany = {
    contract_expiry_date: '',
    companies: [
        {
            commission: '0',
            company_id: 0,
            contract_id: '',
            contract_expiry_date: ''
        }
    ],
    type: COMPANY,
    phone: '',
    contract_id: '',
    company_name: '',
    inn: '',
    certificate_id: '',

}




export {AgentSchema, initsIndividual, initsCompany}
