import {useIntl} from "react-intl";
import {ErrorMessage, Field} from "formik";
import React from "react";
import InputMask from "react-input-mask";
import moment from "moment";


interface IProps {
    handleChange: (val:any)=>void
    setFieldValue: (field:string, elem:any)=>void
    values: any

}

export default function FormTypeIndividual(props:IProps){

    const {handleChange, values, setFieldValue} = props

    const intl = useIntl()

    return(
        <>
            <div className='mb-8 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id: 'pinfl'})}</label>
                <Field
                    type='text'
                    placeholder='12345678910111'
                    className='form-control form-control-lg form-control-solid'
                    name='pinfl'
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        if (e.currentTarget.value.match(/^\d{0,14}$/)) handleChange(e)
                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='pinfl'/>
                </div>
            </div>

            <div className='mb-12 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id:'phone'})}</label>
                <Field
                    type='text'
                    placeholder='+998 90 999 99 99'
                    component={InputMask}
                    mask="+\9\9\8 99 999 99 99"
                    className='form-control form-control-lg form-control-solid'
                    name='phone'
                    value={values['phone']}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        const value = e.currentTarget.value || '';
                        setFieldValue('phone', value)
                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='phone' />
                </div>
            </div>


            <div className='mb-8 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id:'ceo_passport_id'})}</label>
                <Field
                    type='text'
                    placeholder='AA1234567'
                    className='form-control form-control-lg form-control-solid'
                    name='passport_id'
                    onChange={(e: React.FormEvent<HTMLInputElement>)=>{
                        const passportRegExp = new RegExp(/^(([aA-zZ]\D{0,1})([0-9]{0,7}))$/)
                        if(passportRegExp.test(e.currentTarget.value)||!e.currentTarget.value.length){
                            handleChange(e)
                            setFieldValue('passport_id', e.currentTarget.value.replace(/^[aA-zZ]{2}/, (match)=>match.toUpperCase()))
                        }
                    }}

                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='passport_id' />
                </div>
            </div>

            <div className='mb-8 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id:'ceo_first_name'})}</label>
                <Field
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='first_name'
                    onChange={(e: React.FormEvent<HTMLInputElement>)=>{
                        if(!e.currentTarget.value.length||e.currentTarget.value.match(/^[Aa-zZ-Аа-яЯ]+$/i))
                            handleChange(e)
                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='first_name' />
                </div>
            </div>


            <div className='mb-8 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id:'ceo_last_name'})}</label>
                <Field
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='last_name'
                    onChange={(e: React.FormEvent<HTMLInputElement>)=>{
                        if(!e.currentTarget.value.length||e.currentTarget.value.match(/^[Aa-zZ-Аа-яЯ]+$/i))
                            handleChange(e)
                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='last_name' />
                </div>
            </div>

            <div className='mb-8 fv-row'>
                <label className='form-label mb-3'>{intl.formatMessage({id:'ceo_middle_name'})}</label>
                <Field
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='middle_name'
                    onChange={(e: React.FormEvent<HTMLInputElement>)=>{
                        if(!e.currentTarget.value.length||e.currentTarget.value.match(/^[Aa-zZ-Аа-яЯ]+$/i))
                            handleChange(e)
                    }}
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='middle_name' />
                </div>
            </div>


            <div className='mb-8 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id:'contract_id'})}</label>
                <Field
                    type='text'
                    placeholder='AA-1234567'
                    className='form-control form-control-lg form-control-solid'
                    name='contract_id'
                />
                <div className='text-danger mt-2'>
                    <ErrorMessage name='contract_id' />
                </div>
            </div>


            <div className='mb-8 fv-row'>
                <label className='form-label mb-3 required'>{intl.formatMessage({id:'contract_expiry_date'})}</label>
                <Field
                    type='date'
                    className='form-control form-control-lg form-control-solid'
                    name='contract_expiry_date'
                    min='1899-01-01' max='2125-01-01'
                    value={moment(values['contract_expiry_date']).format('yyyy-MM-DD')}
                />

                <div className='text-danger mt-2'>
                    <ErrorMessage name='contract_expiry_date' />
                </div>
            </div>
        </>
    )
}