import {makeAutoObservable, toJS} from "mobx";
import {IContract, IContractState} from "@providers/autopay/models/_models";


class Contract {
    contract: IContractState = {
            entity: {} as IContract,
            loaded: false,
            errors: {
                isError: false,
                message: ''
            }
    }
    constructor() {
        makeAutoObservable(this)
    }


    setContract (data: IContract) {
        this.contract.entity = data
        this.contract.loaded = true
        this.contract.errors.isError = false
    }
    setContractError (msg: string) {
        this.contract.loaded = true
        this.contract.errors.isError = true
        this.contract.errors.message = msg
    }

    get getContract () {
        return toJS(this.contract)
    }
}


export const contract = new Contract()