
import clsx from 'clsx'
import {FC, MouseEventHandler} from 'react'
import {Row} from 'react-table'
import {ModSession} from "@providers/session/_modals";
import {TransactionsGeneralResponse} from "@providers/adminTransactions/_models";

type Props = {
  row: Row<any>
  onClick: MouseEventHandler
  getTransactionFiscalChequeData?: (val:any)=>void
}

const CustomRow: FC<Props> = ({row, onClick, getTransactionFiscalChequeData}) => (
  <tr {...row.getRowProps()} onClick={onClick}>
    {row.cells.map((cell) => {

      return (
        <td
          {...cell.getCellProps()}
          className={`${clsx({'text-end min-w-100px': cell.column.id === 'actions'})} text-left`}
          onClick={()=>{
            if(cell?.column?.id!=='actions'){

              if(getTransactionFiscalChequeData){
                getTransactionFiscalChequeData(row.original.id.toString())
              }

            }
          }}
        >
          {cell.render('Cell')}
        </td>
      )
    })}
  </tr>
)

export {CustomRow}
