import {KTSVG} from "@metronic/helpers";
import React, {useState} from "react";

type IProps = {
    setFilters: (val:string)=>void
}

export function TransactionsSearch(props:IProps){
    const {setFilters} = props
    const [searchTextController, setSearchTextController] = useState('')
    return(
        <form onSubmit={(e) => {
            e.preventDefault()
            setFilters(searchTextController)
        }} className='d-flex align-items-center position-relative mb-5'>

            <input
                style={{border: "none", borderRadius: "6px", height: "44px"}}
                type='text'
                data-kt-user-table-filter='search'
                className='form-control pe-5'
                placeholder='Поиск по ID'
                value={searchTextController}
                onChange={(e) => setSearchTextController(e.target.value)}
            />
            {searchTextController.length > 0 &&
                <span className='position-absolute end-0 me-8 pe-8' onClick={() => {
                    setSearchTextController('')
                    setFilters('')
                }}>
                            <KTSVG
                                path='/media/icons/duotune/general/gen034.svg'
                                className='svg-icon-1 text-hover-primary'
                            />
                        </span>}
            <button type="submit"
                    style={{borderTopRightRadius: "6px", borderBottomRightRadius: "6px", width: "44px"}}
                    className='border-0 text-hover-secondary cursor-pointer position-absolute end-0 bg-primary h-100 d-flex justify-content-center align-items-center'>
                <KTSVG
                    path='/media/icons/duotune/general/gen021.svg'
                    className='svg-icon-1 text-white'
                />
            </button>
        </form>
    )
}