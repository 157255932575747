import {FC} from 'react'
import {PageLink, PageTitle} from "@metronic/layout/core";
import {Vertical} from "./wizards/components/Vertical";
import { useParams } from 'react-router-dom';
import {ErrorProvider} from "@providers/context/ErrorsProvider";
import {useIntl} from "react-intl";
import {PageTitleCustom} from "@metronic/partials/pageTitleCustom";




const ContractForm:FC = ()=>{
    return(
        <ErrorProvider>
            <Vertical/>
        </ErrorProvider>
    )
}

export default function ContractFormWrapper(){
    const {companyId} = useParams()

    const intl = useIntl()

    const createCompanyBreadCrumbs: Array<PageLink> = [
        {
            title: 'Автосписание',
            path: `/${companyId}/autopay/contracts`,
            isSeparator: false,
            isActive: false,
        },
        {
            title: 'Контракты',
            path: `/${companyId}/autopay/contracts`,
            isSeparator: false,
            isActive: false,
        },
        {
            title: intl.formatMessage({id:'ADD_CONTRACT'}),
            path: ``,
            isSeparator: true,
            isActive: false,
        },
    ]
    return(
        <>
            <PageTitleCustom title={intl.formatMessage({id:'ADD_CONTRACT'})} breadCrumbs={createCompanyBreadCrumbs} />
            <ContractForm/>

        </>
    )
}