import React, {FC, useEffect, useMemo, useState} from 'react'
import {KTSVG} from '@metronic/helpers'
import {DatePicker, Drawer, Dropdown, Menu, Button, Space, Typography, MenuProps, Tooltip} from 'antd';
import {useIntl} from "react-intl";
import moment from "moment/moment";
import {useErrors} from "@providers/context/ErrorsProvider";
import "@metronic/assets/sass/style.scss"
import {
    CANCELED,
    DECLINED,
    statuses_card,
    statuses_const, statuses_transactions,
    SUCCESSFUL
} from "@utils/constants";
import {DownOutlined, MinusOutlined} from '@ant-design/icons';

import {CardsChartWidget} from "../../chart";
import {isDemo, NumReplace} from "@utils/index";
import {toIsoStringHelper} from "../../../autopay/dateHelper";
import localeRu from "antd/es/date-picker/locale/ru_RU";
import localeEn from "antd/es/date-picker/locale/en_US";
import {
    IDetaildStatisticResponse,
    IDetailedStatisticStatuses,
    IStatistic,
    statisticStatus
} from "@providers/statistic/_modals";
import {Statistic} from "@providers/statistic/_request";
import { formatDateRequest } from '@utils/formatDateRequest';


type Props = {
    open?: boolean
    dataStatistic: any
    setDataCharts: (val: any) => void
    params: any
    onDateChange: Function
}


const ActivityDrawerAdmin: FC<Props> = ({open, params, setDataCharts, onDateChange, dataStatistic}) => {

    const [from_date, setFromDate] = useState(moment().subtract(1, 'month').format('YYYY-MM-DD'))
    const [to_date, setToDate] = useState(moment().format('YYYY-MM-DD'))
    const [loading, setLoading] = useState(false)
    const [dataRender, setDataRender] = useState<any>(null)
    const [currentCard, setCurrentCard] = useState<string[]>([])
    const [currentStatus, setCurrentStatus] = useState<string>('')
    const [dataStatisticResponse, setDataStatisticResponse] = useState<any[]>([])
    const intl = useIntl()
    const lang = JSON.parse(localStorage.getItem('i18nConfig') ?? '{}')?.selectedLang
    const {setError} = useErrors()


    useEffect(() => {

        if (params && open && !loading) {

            if (params?.toDate === params?.fromDate) {
                setToDate(params?.toDate)
                setFromDate(moment(params?.fromDate).subtract(1, 'week').format('YYYY-MM-DD'))
            } else {
                setToDate(params?.toDate)
                setFromDate(params?.fromDate)
            }
            if (params.processingTypes.length > 0) {
                setCurrentCard(params.processingTypes.map((item: string) => item))
            } 
            setCurrentStatus(params.statuses.length > 1 ? 'GENERAL' : params.statuses[0])
            getDataStatistic({
                ...params,
                fromDate: formatDateRequest( from_date, "fromDate"),
                toDate: formatDateRequest( to_date, "toDate")
            })
        }
    }, [params])


    useEffect(() => {
        if (!loading && params) {
            const data: IStatistic = {
                processingTypes: currentCard,
                statuses: currentStatus === 'general' ? statuses_transactions : [currentStatus.toUpperCase()],
                fromDate: formatDateRequest( from_date, "fromDate"),
                toDate: formatDateRequest( to_date, "toDate"),
            }

            getDataStatistic(data)
        }
    }, [currentStatus, currentCard, from_date, to_date])


    async function getDataStatistic(value: IStatistic) {
        setLoading(true)
        await Statistic.getAdminDetaildStatistic(value)
            .then(response => {
                setDataStatisticResponse(response.data.result)
                dataParse(response.data.result, [...params.processingTypes, 'GENERAL'], currentStatus !== '' ? currentStatus.toUpperCase() : params.statuses.length > 1 ? 'general' : params.statuses[0])
            }).catch(err => setError({
                status: err?.response?.stat,
                message: err?.response?.data?.message,
                isError: true
            }))

        setLoading(false)
    }

    const onClose = () => {
        setDataCharts(null);
        setCurrentStatus('')
        setCurrentCard([])
    }


    function dataParse(data: any[], cards: string[], status: string) {

        let date_arr = data.map(item => item.date)
        let status_lower = status.toLowerCase()


        const series = cards.map((card: string) => {
            let name, dashboard
            let card_name = card.toLowerCase()
            name = `${card_name} - ${intl.formatMessage({id: status})}`
            dashboard = data.map(item => {

                if (card_name === 'general') {
                    return item['general'][`totalAmount`]
                }
                if (item[status_lower] && item[status_lower][`${card_name}Amount`]) {
                    return item[status_lower][`${card_name}Amount`]
                }
                return 0

            })

            return {name, data: dashboard}
        })

        setDataRender({series, categories: date_arr})


    }

    function getCountStatuses({type = statisticStatus.approved, currency = 'UZS'}: {
        type: statisticStatus,
        currency?: string
    }) {
        let result: any = []

        if (dataStatisticResponse.length) {
            let sum, count;

            if (dataStatisticResponse.length > 1) {
                sum = dataStatisticResponse.reduce((previousValue: any, currentValue: any) => {

                    if (currentValue[type]) {

                        if (typeof previousValue === 'object') {
                            return previousValue[type].totalAmount + currentValue[type].totalAmount
                        }

                        return previousValue + currentValue[type].totalAmount
                    }

                    return 0


                })


                count = dataStatisticResponse.reduce((previousValue: any, currentValue: any) => {
                    if (currentValue[type]) {
                        if (typeof previousValue === 'object') {
                            return previousValue[type].totalCount + currentValue[type].totalCount
                        }

                        return previousValue + currentValue[type].totalCount
                    }
                    return 0
                })

            } else {
                sum = dataStatisticResponse[0][type].totalAmount
                count = dataStatisticResponse[0][type].totalCount
            }


            result.push({
                sum: sum ? NumReplace(sum / 100 + '') + ' ' + currency : 0,
                count

            })

        } else {

            result = statuses_card.map((item: any) => {
                return {
                    card: item.toUpperCase(),
                    sum: 0,
                    count: 0
                }
            })
        }

        return result

    }


    const sumAmount = (status: statisticStatus) => {
        if (dataStatisticResponse.length) {
            if (dataStatisticResponse.length > 1) {
                let sum = dataStatisticResponse.reduce((previousValue: any, currentValue: any) => {

                    if (currentValue[status]) {
                        if (typeof previousValue === 'object') {
                            return previousValue[status].totalAmount + currentValue[status].totalAmount
                        }

                        return previousValue + currentValue[status].totalAmount
                    }
                    return 0

                });
                return sum / 100
            }

            return dataStatisticResponse[0][status].totalAmount / 100

        }

        return 0
    }


    const handleMenuClick: MenuProps['onClick'] = e => {
        setCurrentStatus(e.key)
    };

    const menu = (
        <Menu
            onClick={handleMenuClick}
            selectable
            defaultSelectedKeys={statuses_const.filter(item => item.toUpperCase() === currentStatus)}
            items={[
                {
                    key: 'general',
                    label: intl.formatMessage({id: 'GENERAL'}),
                },
                {
                    key: 'revert',
                    label: intl.formatMessage({id: "revert".toUpperCase()}),
                },
                {
                    key: 'approved',
                    label: intl.formatMessage({id: "approved".toUpperCase()}),
                },
                {
                    key: 'failed',
                    label: intl.formatMessage({id: "failed".toUpperCase()}),
                },
            ]}
        />
    );

    function replaceFormat(date: any, formatFrom: string, formatTo: string) {
        let newDate = moment(date, formatFrom).format(formatTo)
        return moment(newDate, formatTo)
    }


    return (
        <Drawer width={`70%`} placement="right" closable={false} onClose={onClose} open={open}>

            <div className={`card-header d-flex align-items-center justify-content-between `}
                 style={{padding: "0 24px", marginTop: "15px"}}>
                {
                    params?.statuses ? (
                        <h3 className='card-title fw-bolder text-dark'>{intl.formatMessage({id: `transaction-${params?.statuses ? `${params?.statuses.length > 1 ? 'all' : params?.statuses[0].toLowerCase() ?? ''}` : ''}`})} </h3>) : ''
                }

                <div className='card-toolbar'>
                    <button
                        type='button'
                        className='btn btn-sm btn-icon'
                        onClick={onClose}
                    >
                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                    </button>
                </div>
            </div>
            <div className="mt-5" style={{padding: "24px"}}>
                <div>
                    <div className='toolbar-filter'>
                        <div className='row align-items-center mb-3 justify-content-end'>
                            <div className='col-6 d-flex justify-content-start'>
                                {dataRender && dataRender.series && dataRender.series.filter((item: {
                                    name: string,
                                    data: number[]
                                }) => !item.name.includes('general'))?.map((item: {
                                    name: string,
                                    data: number[]
                                }) => {

                                    const data: any = {
                                        'uzcard': {
                                            icon: '/media/svg/uzcard.svg',
                                            background: '#233C7E'
                                        },
                                        'humo': {
                                            icon: '/media/svg/humo.svg',
                                            background: '#BEA645'
                                        },
                                        'visa': {
                                            icon: '/media/svg/visa.svg',
                                            background: '#F79E1B'
                                        },
                                        'mastercard': {
                                            icon: '/media/svg/mastercard.svg',
                                            background: '#f70e1b'
                                        }
                                    }

                                    const key = item.name.split('-')[0].replace(/ /g, '').toLowerCase()
                                    const currentCard = data[key]


                                    return <div key={key} className='d-flex align-items-center ms-2'>
                                        <div style={{
                                            width: "24px",
                                            height: "8px",
                                            backgroundColor: currentCard ? currentCard.background : 'inherit',
                                            marginRight: "8px",
                                            borderRadius: "5px"
                                        }}/>
                                        <div className='bg-lighten'
                                             style={{width: '25px', height: '25px'}}>
                                            <img
                                                src={currentCard ? currentCard.icon : ''}
                                                className='w-100 h-100'
                                                alt={'Card Icon'}
                                                style={{objectFit: 'contain'}}/>
                                        </div>
                                        <p className='mx-2 mb-0 fw-semibold'
                                           style={{color: 'var(--kt-gray-600)'}}>{item.name}</p>
                                    </div>
                                })}
                            </div>
                            <div className='col-6 d-flex justify-content-end'>
                                {
                                    params && params?.statuses.length > 0 && (<div className="pe-7">
                                        <Dropdown overlay={menu}>
                                            <Button className='rounded datepicker_form form-control-lg form-control-solid'>
                                                <Space>
                                                    {intl.formatMessage({id: currentStatus !== "" && currentStatus ? currentStatus.toUpperCase() : "GENERAL"})}
                                                    <DownOutlined
                                                        style={{fontSize: 10, display: "flex", justifyContent: "center"}}/>
                                                </Space>
                                            </Button>
                                        </Dropdown>
                                    </div>)}
                                <DatePicker.RangePicker
                                    style={{border: "none"}}
                                    separator={<MinusOutlined/>}
                                    locale={lang === 'ru' ? localeRu : localeEn}
                                    key={`${from_date}-${to_date}`}
                                    placeholder={[intl.formatMessage({id: 'calendar_start'}), intl.formatMessage({id: 'calendar_end'})]}
                                    className='datepicker_form form-control-lg form-control-solid'
                                    defaultValue={[replaceFormat(from_date, 'YYYY-MM-DD', 'YYYY-MM-DD'),
                                        replaceFormat(to_date, 'YYYY-MM-DD', 'YYYY-MM-DD')]}
                                    format='YYYY-MM-DD'
                                    disabledDate={current => {
                                        return isDemo() || current && current > moment().endOf('day');
                                    }}
                                    //@ts-ignore
                                    onChange={(dates: [any, any], dateStrings: [string, string]) => {
                                        if (dates) {
                                            const [start, end] = dateStrings
                                            setFromDate(start)
                                            setToDate(end)
                                            onDateChange(dates, dateStrings)
                                        }

                                    }}
                                />
                            </div>

                        </div>

                    </div>

                </div>
                <div>
                    {!loading && dataRender ? (
                        <>


                            {dataRender ?
                                <CardsChartWidget key={dataRender?.series.join(',')} className='card-xl-stretch mb-xl-8'
                                                  data={dataRender}/> : !loading &&
                                <h3 className='text-center'>{intl.formatMessage({id: 'no_data_statistic'})}</h3>}

                        </>
                    ) : <div className='mb-5 mt-15 p-3' style={{minHeight: '200px'}}>
                        {!loading && <h3 className='text-center'>{intl.formatMessage({id: 'no_data_statistic'})}</h3>}
                    </div>}
                </div>

                <div className="d-flex justify-content-between row mt-5 gx-3 row g-5 g-xl-8">
                    <ChartMiniWidgetMixed
                        className='col-xl-3 col-lg-3 mb-xl-8' color='dark-blue' data={{
                        status: 'general',
                        sum: [`${sumAmount(statisticStatus.general) ? NumReplace(sumAmount(statisticStatus.general) + '') : 0} UZS`],
                        details: getCountStatuses({type: statisticStatus.general})
                    }}/>

                    <ChartMiniWidgetMixed className='col-xl-3 col-lg-3 mb-xl-8' color='success' data={{
                        status: 'APPROVED',
                        sum: [`${sumAmount(statisticStatus.approved) ? NumReplace(sumAmount(statisticStatus.approved) + '') : 0} UZS`],
                        details: getCountStatuses({type: statisticStatus.approved})
                    }}/>

                    <ChartMiniWidgetMixed className='col-xl-3 col-lg-3 mb-xl-8' color='warning' data={{
                        status: 'FAILED',
                        sum: [`${sumAmount(statisticStatus.failed) ? NumReplace(sumAmount(statisticStatus.failed) + '') : 0} UZS`],
                        details: getCountStatuses({type: statisticStatus.failed})
                    }}/>

                    <ChartMiniWidgetMixed className='col-xl-3 col-lg-3 mb-xl-8' color='danger' data={{
                        status: 'REVERT',
                        sum: [`${sumAmount(statisticStatus.revert) ? NumReplace(sumAmount(statisticStatus.revert) + '') : 0} UZS`],
                        details: getCountStatuses({type: statisticStatus.revert})
                    }}/>
                </div>

            </div>

        </Drawer>
    )
}


type ChartMiniWidgetMixedProps = {
    className: string
    color: string
    data: {
        status: string
        sum: string[]
        details: {
            card: string
            sum: string
            count: number
        }[]

    }
    children?: any
}

export const ChartMiniWidgetMixed: FC<ChartMiniWidgetMixedProps> = ({className, color, children, data}) => {
    const intl = useIntl()


    return <div className={`${className} `}>
        <div className={`card shadow`} style={{padding: "20px"}}>
            <div className={`bg-${color} w-100`} style={{
                height: "8px",
                position: "absolute",
                top: "0",
                left: "0",
                borderRadius: "12px 12px 0 0"
            }}/>
            <div className='card-body p-0 position-relative'>
                <div className='d-flex flex-stack'>
                    <h3 className='m-0 pt-1 fw-bold fs-4'
                        style={{color: "#464E5F",}}>{intl.formatMessage({id: data?.status.toUpperCase()})}</h3>
                </div>

                <div className={`pt-5 card-rounded w-100`}>
                    {
                        data.sum.map((item, index) => {
                            return (
                                <span key={index} className='fw-bold fs-3 pt-1'>{item}</span>
                            )
                        })
                    }

                </div>
                <div className={`card-rounded w-100`} style={{color: "#8B8D99"}}>
                    <span className='fw-bold fs-7 pt-1'>Кол-во: {data.details.reduce(
                        (previousValue, currentValue) => previousValue + currentValue.count, 0)}
                    </span>
                </div>
            </div>
        </div>
    </div>
}

export {ActivityDrawerAdmin}
