/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {KTSVG} from '@metronic/helpers'
import {useParams} from "react-router-dom";
import {observer} from "mobx-react";
import {Modal, Tooltip} from "antd";
import {TransactionsInfo} from "@providers/adminTransactions/_request";
import {useErrors} from '@providers/context/ErrorsProvider';
import {roleRoutingAdmin} from "@utils/index";
import {Statistic} from "@providers/statistic/_request";
import {ITransactions} from "@providers/statistic/_modals";
import {transactions} from "@store/transactions/transactions";
import {employeeStore} from "@store/employee";
import {ITransaction} from "@providers/adminTransactions/_models";
import {UsersListLoading} from "@metronic/partials/widgets/tables/_modals/components/loading/UserListLoading";
import { formatDateRequest } from '@utils/formatDateRequest';

type Props = {
    transactionData: ITransaction
    filialData: boolean
}


const RevertTransactionsActions: FC<Props> = observer(({transactionData, filialData}) => {

    const {companyId, id} = useParams()
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const {setError} = useErrors()


    function submitRevert() {
        if (!confirmLoading) {
            setConfirmLoading(true)
            TransactionsInfo.revertTransactions(transactionData?.id)
                .then(() => {
                    setIsModalOpen(false)
                    updateData()
                })
                .catch(err => {
                    setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
                }).finally(() => setConfirmLoading(false))
        }
    }


    async function updateData() {
        const dataRequest: ITransactions = {
            page: transactions.getPagination?.currentPage ?? 1,
            size: 20,
            statuses: transactions.getFilters.statuses,
            processingTypes: transactions.getFilters.processingTypes,
            fromDate: formatDateRequest( transactions.getFilters.from_date, "fromDate"),
            toDate: formatDateRequest( transactions.getFilters.to_date, "toDate"),
        }

        if (filialData && id && companyId) {
            dataRequest.companyId = Number(companyId)
            dataRequest.cashboxes = [Number(id)]
        }
        if (!filialData && id) {
            dataRequest.companyId = Number(id)
        }
        await onGetTransactions(dataRequest)
    }


    const onGetTransactions = async (dataRequest: ITransactions) => {
         Statistic.getTransactions(dataRequest)
            .then((result) => {
                let data = result?.data?.result?.content || []
                data.forEach((item: any) => {
                    item.branch_id = item?.branch_name
                })
                if (data.length > 0) {
                    data.map((item: any, index: number) => {
                        if (dataRequest.page && dataRequest.page > 2) {
                            item['num'] = ((dataRequest.page - 1) * 20) + index + 1
                        } else if (dataRequest.page == 2) {
                            item['num'] = 21 + index
                        } else {
                            item['num'] = index + 1
                        }
                        return item
                    })
                    transactions.setTransactionsData(data)
                }

            }).catch((err) => {
                setError({
                    status: err?.response?.status,
                    message: err?.response?.data?.message,
                    isError: true
                })
            })
    }


    return (
        <>
            {confirmLoading && <UsersListLoading/>}
            <Modal title="" open={isModalOpen} footer={null} className='modal-revert-transactions'>
                {
                    (<div className='text-center'>
                        <span>
                            <svg width="66" height="66" viewBox="0 0 66 66" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect x="5" y="5" width="56" height="56" rx="28" fill="#FEE4E2"/>
                                <path d="M40 26L26 40M26 26L40 40" stroke="#D92D20" strokeWidth="2"
                                      strokeLinecap="round" strokeLinejoin="round"/>
                                <rect x="5" y="5" width="56" height="56" rx="28" stroke="#FEF3F2" strokeWidth="10"/>
                            </svg>

                            <p className='fw-bold fs-3 text-gray-800 mb-0 mt-10'>Отменить транзакцию?</p>
                            <p className='fw-semobold fs-5 text-gray-600 mb-0'>Это действие нельзя отменить. Деньги будут возвращены на карту плательщика.</p>

                            <div style={{marginTop: '50px'}}>
                                <button className='btn fw-bold btn-revert'
                                        disabled={confirmLoading}
                                        onClick={() => submitRevert()}>Отменить</button>
                                <button className='btn  text-gray-600 fw-bold'
                                        onClick={() => setIsModalOpen(false)}>Оставить</button>
                            </div>
                        </span>
                    </div>)
                }
            </Modal>
            <div className='d-flex justify-content-end'>

                {

                    roleRoutingAdmin('action', employeeStore.getRole, 'is-admin') ? (<Tooltip
                        placement="topLeft"
                        title='Отменить транзакцию'
                        overlayClassName='tooltip-payments'>
                        <a href="#" onClick={(e) => {
                            e.preventDefault()
                            if (transactionData?.status !== 'REVERT') {
                                setIsModalOpen(true)
                            }
                        }} style={transactionData?.status === 'REVERT' ? {opacity: '0.5'} : {}}
                           className="btn btn-icon-gray-500 btn-active-icon-danger p-5">
                            <KTSVG path='/media/svg/close-icon.svg'
                                   className="svg-icon-2 svg_icon_delete"/></a></Tooltip>) : ''
                }


            </div>
        </>
    )
})

export {RevertTransactionsActions}
