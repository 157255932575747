import axios, {AxiosResponse} from 'axios'
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers'


export const AUTOPAY_BASE_URL = 'https://test-recurrent-payment-sapi.globalpay.uz/recurrent-payment'
export const BASE_URL = 'https://sso-selectel-dev.globalpay.uz/realms/globalpay/protocol'


export const sso_selected = axios.create({
    baseURL: BASE_URL
})

export const autopayInstance = axios.create({
    baseURL: AUTOPAY_BASE_URL
})

autopayInstance.interceptors.request.use(
    (config: any) => {
        config.headers['Content-Type'] = 'application/json'
        const token = localStorage.getItem('kt-auth-react-v') ? JSON.parse(localStorage.getItem('kt-auth-react-v') ?? '') : null
        if (token) {
            config.headers.Authorization = `Bearer ${token.access_token}`
        }
        return config
    },
    (error) => {
        Promise.reject(error)
    }
)

sso_selected.interceptors.request.use(
    (config: any) => {
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
        config.headers['Authorization'] = 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJuVkZnM0c0eG9XNlRPYXVVRnNCaDBfWEd0RzhKbkktZXVlVGlEbGZNMFB3In0.eyJleHAiOjE2ODkxNTUxODcsImlhdCI6MTY4OTE1NDg4NywianRpIjoiMzRlMjZkODktODc5NC00ODYxLWFlYzEtNTRiNGRhZmVjNTJjIiwiaXNzIjoiaHR0cHM6Ly9zc28tc2VsZWN0ZWwtZGV2Lmdsb2JhbHBheS51ei9yZWFsbXMvZ2xvYmFscGF5IiwiYXVkIjoiYWNjb3VudCIsInN1YiI6ImY4ZDc1YzNjLWNjNGMtNDg4ZC04Yjg2LTYyNDhkZGY3MGU4MyIsInR5cCI6IkJlYXJlciIsImF6cCI6ImNhcmRzIiwiYWNyIjoiMSIsInJlYWxtX2FjY2VzcyI6eyJyb2xlcyI6WyJkZWZhdWx0LXJvbGVzLWdsb2JhbHBheSIsIm9mZmxpbmVfYWNjZXNzIiwidW1hX2F1dGhvcml6YXRpb24iXX0sInJlc291cmNlX2FjY2VzcyI6eyJjYXJkcyI6eyJyb2xlcyI6WyJ1bWFfcHJvdGVjdGlvbiJdfSwiYWNjb3VudCI6eyJyb2xlcyI6WyJtYW5hZ2UtYWNjb3VudCIsIm1hbmFnZS1hY2NvdW50LWxpbmtzIiwidmlldy1wcm9maWxlIl19fSwic2NvcGUiOiJvcGVuaWQgcHJvZmlsZSBlbWFpbCBncC1jbGllbnQiLCJjbGllbnRJZCI6ImNhcmRzIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJjbGllbnRIb3N0IjoiOTQuMTU4LjU1LjI0NCIsInByZWZlcnJlZF91c2VybmFtZSI6InNlcnZpY2UtYWNjb3VudC1jYXJkcyIsImNsaWVudEFkZHJlc3MiOiI5NC4xNTguNTUuMjQ0IiwiY2xpZW50X2lkIjoiMSJ9.LHB4ky6XA_p28ar901v9G_VL4hhKq4DagCqBmwTAMAiriShgT3vn8HIg6HfLewUfCGjhwzTzNHDC6jXZo-gj-_yDIHpyWo8PuW0IIPYWS0gYJT7xHAXc2vonpwML-ePi2HAfKDoWLGnfpvMkre1DYID-dV0MJ3cxVQOhoKN_fVK7vUAqMzAreEMNybp9htPCxoncbjF-ld4aRiYR1AAk--QZsshp383y4AEw5FX5t9dUonBj-39GLceH1d7uFLwIoNfpQasAIE6s2mNlvgvt4j6KNJO3dEbcCZXQTpVwP1qsGxFAT61MKPfStLaJ8asVPf5MeOwqTLnvScLhKHylkQ'

        //it breaks our backend, so I'll remove it for a while
        // const token = localStorage.getItem('kt-auth-react-v') ? JSON.parse(localStorage.getItem('kt-auth-react-v') ?? '') : null
        // if (token) {
        //     config.headers.Authorization = `Bearer ${token.access_token}`
        // }
        return config
    },
    (error) => {
        Promise.reject(error)
    }
)



autopayInstance.interceptors.response.use(
    (response: AxiosResponse) => {
        return response
    },
    function (error) {
        const originalRequest = error.config
        let status = (error.response && error.response.status) || 0


        if (status === 401 && !originalRequest._retry) {

            originalRequest._retry = true

            //it breaks our frontend, so I'll remove it for a while
            // authHelper.removeAuth()

            throw error
        } else {
            throw error
        }
    }
)
