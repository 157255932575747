import {FC, useState, createContext, useContext, useMemo, Dispatch, SetStateAction} from 'react'
import {
    ID,
    calculatedGroupingIsDisabled,
    calculateIsAllDataSelected,
    groupingOnSelect,
    groupingOnSelectAll,
    WithChildren,
} from '@metronic/helpers'
import {useError} from "@utils/hooks/error.hook";
import {observer} from "mobx-react";
import {employeeStore} from "@store/employee";
import {agentStore} from "@store/agents";
import {Agent} from "@providers/agents/_request";
import {toIsoStringHelper} from "../../../autopay/dateHelper";
import moment from "moment/moment";
import {COMPANY, INDIVIDUAL} from "@utils/constants";


export type ListViewContextProps = {
    selected: Array<ID>
    onSelect: (selectedId: ID) => void
    onSelectAll: () => void
    clearSelected: () => void
    // NULL => (CREATION MODE) | MODAL IS OPENED
    // NUMBER => (EDIT MODE) | MODAL IS OPENED
    // UNDEFINED => MODAL IS CLOSED
    itemIdForUpdate?: ID
    setItemIdForUpdate: Dispatch<SetStateAction<ID>>
    isAllSelected: boolean
    disabled: boolean
    updateData: ()=>void

}

export const initialListView: ListViewContextProps = {
    selected: [],
    onSelect: () => {},
    onSelectAll: () => {},
    clearSelected: () => {},
    setItemIdForUpdate: () => {},
    isAllSelected: false,
    updateData: ()=>{},
    disabled: false
}

const ListViewContext = createContext<ListViewContextProps>(initialListView)

const ListViewProvider: FC<WithChildren> = observer(({children}) => {
    const [selected, setSelected] = useState<Array<ID>>(initialListView.selected)
    const [itemIdForUpdate, setItemIdForUpdate] = useState<ID>(initialListView.itemIdForUpdate)
    const data = agentStore.getAgents
    const disabled = useMemo(() => calculatedGroupingIsDisabled(false, data), [data])
    const isAllSelected = useMemo(() => calculateIsAllDataSelected(data, selected), [data, selected])


    return (
        <ListViewContext.Provider
            value={{
                selected,
                itemIdForUpdate,
                setItemIdForUpdate,
                disabled,
                isAllSelected,
                updateData: async()=>{
                    await Agent.getAgents({
                        page: agentStore.getSorting.page,
                        count: 20,
                        from_date: agentStore.getSorting.from_date,
                        to_date: agentStore.getSorting.to_date,
                        types: [INDIVIDUAL, COMPANY]
                    }).then(result=>{
                        agentStore.setSorting({...agentStore.getSorting,...result?.data?.meta, })
                        agentStore.setAgent(result?.data?.result.map((item: any, index: number) => {
                            if (agentStore.getSorting.page && agentStore.getSorting.page > 2) {
                                item['num'] = ((agentStore.getSorting.page - 1) * 20) + index + 1
                            } else if (agentStore.getSorting.page == 2) {
                                item['num'] = 21 + index
                            } else {
                                item['num'] = index + 1
                            }
                            return item
                        }))
                    })

                    await Agent.getAgentsGeneral({
                        page: agentStore.getSorting.page,
                        count: 20,
                        query: agentStore.getSorting.query,
                        from_date: toIsoStringHelper(moment('2000-01-01').startOf('day').toDate()),
                        to_date: toIsoStringHelper(moment().endOf('day').toDate()),
                        types: [INDIVIDUAL, COMPANY]
                    }).then(result=>{
                            agentStore.setGeneralStatistics(result?.data?.result)
                        })
                },
                onSelect: (id: ID) => {
                    groupingOnSelect(id, selected, setSelected)
                },
                onSelectAll: () => {
                    groupingOnSelectAll(isAllSelected, setSelected, data)
                },
                clearSelected: () => {
                    setSelected([])
                },
            }}
        >
            {children}
        </ListViewContext.Provider>
    )
})

const useListView = () => useContext(ListViewContext)

export {ListViewProvider, useListView}
