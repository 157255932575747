import {makeAutoObservable, toJS} from "mobx";
import {CompanyResponseOne, ICompanyResponse} from "@providers/company/_models";

class Company{
    company:any[] = []
    me:any={}
    pagination:any= {}
    companyItem: CompanyResponseOne = {} as CompanyResponseOne

    constructor() {
        makeAutoObservable(this)
    }

    setCompany(data:any[]){
        this.company = data
    }

    setPagination(data:any){
        this.pagination = data
    }

    get getCompany(){
        return toJS(this.company)
    }

    get getPagination(){
        return toJS(this.pagination)
    }

    get getOneCompany () {
        return toJS(this.companyItem)
    }

    setCompanyItem  (company: CompanyResponseOne) {
        this.companyItem = company
    }

    setMe(data:any){
        this.me = data
    }

    get getMe(){
        return toJS(this.me)
    }
}



export const company = new Company()