import React, {useState} from 'react';
import {KTSVG} from "@metronic/helpers";
import {useModal} from "../../../../../hooks/useModal";
import {useIntl} from "react-intl";
import {Modal} from "react-bootstrap";
import {ErrorMessage, Field, Form, Formik, FormikHelpers} from "formik";
import {AdminSchema, inits} from "./admin-schema";
import {IAccountInfo, ICreateAccount} from "@providers/account/_models";
import InputMask from "react-input-mask";
import {Account} from "@providers/account/_request";
import {openNotificationError, openNotificationSuccess} from "@metronic/partials/notification";
import {useErrors} from "@providers/context/ErrorsProvider";
import {Admins} from "@providers/admins/_request";
import {admins} from "@store/admins";
import {ReplacePhone} from "@utils/index";
import {UsersListLoading} from "@metronic/partials/widgets/tables/_modals/components/loading/UserListLoading";

interface props {
    edit?: boolean
    admin?: IAccountInfo
}



const AdminForm = ({edit, admin}: props) => {
    const {open, onOpen, onClose} = useModal()
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
    const intl = useIntl()
    const {setError} = useErrors()
    const [init] = useState<any>(edit && admin ? admin : inits)


    const onSubmit = (values: ICreateAccount, actions: FormikHelpers<ICreateAccount>) => {
        const {resetForm} = actions
        const modifiedData: ICreateAccount = {
            ...values,
            phone: values.phone.replace(/ /g, '').replace('+', '')
        }
        if (!edit) {
            onCreate(modifiedData, resetForm)
            return
        }
        if (admin) {
            const data: IAccountInfo = {
                firstName: modifiedData.firstName,
                lastName: modifiedData.lastName,
                id: admin.id,
                phone: modifiedData.phone

            }
            if (values.email) {
                data.email = modifiedData.email
            }
            onUpdate(data, resetForm)
        }
    }

    const onCreate = (values: ICreateAccount, resetForm: () => void) => {
        setConfirmLoading(true)
        Account.createAccount(values).then((response) => {
            resetForm()
            openNotificationSuccess(intl.formatMessage({id: 'admin.created'}))
            onUpdateAdmins()
        }).catch((err) => {
            openNotificationError(err?.response?.data?.message)
        }).finally(() => {
            setConfirmLoading(false)
        })
    }

    const onUpdate = (values: IAccountInfo, resetForm: () => void) => {
        setConfirmLoading(true)
        Account.editAccount(values).then((response) => {
            resetForm()
            openNotificationSuccess(intl.formatMessage({id: 'admin.edited'}))
            onUpdateAdmins()
        }).catch((err) => {
            openNotificationError(err?.response?.data?.message)
        }).finally(() => setConfirmLoading(false))
    }


    const onUpdateAdmins = () => {
        Admins.getAdmins()
            .then(result => {
                admins.setAdmins((result?.data).map((item: any) => {
                    return {
                        ...item,
                        phone: ReplacePhone(item.phone || ''),
                        role: intl.formatMessage({id: item.role}),
                    }
                }))
                onClose()
            })
            .catch(err => {
                setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
            })
    }


    return (
        <>
            <button type='button' className={` ${edit ? "border-0 bg-transparent" : "btn btn-primary"}`}
                    onClick={onOpen}>
                <KTSVG
                    path={edit ? '/media/icons/duotune/general/gen055.svg' : `/media/icons/duotune/general/gen041.svg`}
                    className='svg-icon-2'/>
                {!edit && intl.formatMessage({id: "ADD_BUTTON"})}
            </button>
            <Modal
                show={open}
                onHide={onClose}
            >
                {confirmLoading && <UsersListLoading/>}

                <Modal.Header closeButton>
                    <h2>
                        {intl.formatMessage({id: 'admin.create'})}
                    </h2>
                </Modal.Header>
                <Formik enableReinitialize={true} validationSchema={AdminSchema()}
                        initialValues={init}
                        onSubmit={onSubmit}>
                    {({values, setFieldValue, submitForm}) => (
                        <Form>
                            <Modal.Body>
                                <div className='mb-8 fv-row'>
                                    <Field
                                        type='text'
                                        className={`form-control form-control-lg d-none`}
                                        placeholder={intl.formatMessage({id: "admin.name"})}
                                        name='id'
                                    />
                                    <label
                                        className='form-label mb-3'>{intl.formatMessage({id: "admin.name"})}</label>
                                    <Field
                                        type='text'
                                        className={`form-control form-control-lg`}
                                        placeholder={intl.formatMessage({id: "admin.name"})}
                                        name='firstName'
                                    />
                                    <div className='text-danger mt-2'>
                                        <ErrorMessage name='firstName'/>
                                    </div>
                                </div>
                                <div className='mb-8 fv-row'>
                                    <label
                                        className='form-label mb-3'>{intl.formatMessage({id: "admin.surname"})}</label>
                                    <Field
                                        type='text'
                                        className={`form-control form-control-lg`}
                                        placeholder={intl.formatMessage({id: "admin.surname"})}
                                        name='lastName'
                                    />
                                    <div className='text-danger mt-2'>
                                        <ErrorMessage name='lastName'/>
                                    </div>
                                </div>
                                <div className='mb-8 fv-row'>
                                    <label
                                        className='form-label mb-3 required'>{intl.formatMessage({id: 'phone'})}</label>
                                    <Field
                                        type='text'
                                        component={InputMask}
                                        mask="+\9\9\8 99 999 99 99"
                                        className={` form-control form-control-lg `}
                                        name='phone'
                                        placeholder={'+998'}
                                        value={values.phone}
                                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                            const value = e.currentTarget.value || '';
                                            setFieldValue('phone', value)

                                        }}
                                    />
                                    <div className='text-danger mt-2'>
                                        <ErrorMessage name='phone'/>
                                    </div>
                                </div>
                                <div className='mb-8 fv-row'>
                                    <label
                                        className='form-label mb-3'>{intl.formatMessage({id: 'email'})}</label>
                                    <Field
                                        type='email'
                                        className={`form-control form-control-lg`}
                                        name='email'
                                        placeholder={'example@gmail.com'}
                                    />
                                    <div className='text-danger mt-2'>
                                        <ErrorMessage name='email'/>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button disabled={confirmLoading} type="button"
                                        className="swal2-confirm btn btn-secondary"
                                        style={{display: 'inline-block'}} onClick={onClose}>
                                    {intl.formatMessage({id: "CANCEL_BUTTON"})}
                                </button>
                                <button disabled={confirmLoading} onClick={submitForm} type={'submit'}
                                        className="swal2-confirm btn btn-primary"
                                        style={{display: 'inline-block'}}>
                                    {intl.formatMessage({id: "CREATE_BUTTON"})}
                                </button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </>
    );
};

export default AdminForm;