import {observer} from "mobx-react";
import React, {FC, useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useIntl} from "react-intl";
import {useErrors} from "@providers/context/ErrorsProvider";
import {isDemo, roleRoutingAdmin} from "@utils/index";
import Alert from "react-bootstrap/Alert";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {KTCard, KTCardBody} from "@metronic/helpers";
import {employeeStore} from "@store/employee";
import {Tooltip} from "antd";
import {Modal} from "react-bootstrap";
import {IMerchants} from "../index";
import {AgentSchema} from "./form/CreateAccountWizardHelper";
import {Agent} from "@providers/agents/_request";
import FormTypeIndividual from "./form/form_individual";
import FormTypeCompany from "./form/form_company";
import moment from "moment";


type IProps = {
    updateData: (values:any)=>void
    merchantData: IMerchants
}


const Settings: FC<IProps> = observer((props) => {
    const {updateData, merchantData} = props
    const [currentShema, setCurrentShema] = useState(merchantData?AgentSchema({type: merchantData?.type}):{})
    const {id} = useParams()
    const inits = useMemo(()=>merchantData||{}, [merchantData])
    const [initValues, setInitValues] = useState<IMerchants>({...inits,
        phone:`+${(inits?.phone||'').replace(/(998)(\d{2})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5')}`})
    const [deactivate, setDeactivate] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [alert, setAlert] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [formShow, setFormShow] = useState(0)
    const intl = useIntl()


    const {setError} = useErrors()

    async function editAgent(values:any){
        if(id){
            await Agent.editAgent(+id, values)
                .then(result=>{
                    setFormShow(0)
                    updateData(values)
                    setInitValues({...initValues, ...values})
                }).catch(err => {
                    setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
                })
        }
    }





    const onSub = async (values: IMerchants) => {
        setLoading(true)
        await editAgent(values)
        setLoading(false)
    }



    const deleteItem = () => {
        if (deactivate&&id) {
            Agent.deleteAgent(+id)
                .then(() => {

                    setTimeout(() => {
                        navigate('/agents/statistic')
                    }, 1500)
                }).catch(err => {
                setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
            })
        }
    }

    function renderFields(){
        const field_is_show = merchantData?.type==='INDIVIDUAL'?['pinfl', 'phone', 'passport_id', 'first_name', 'last_name', 'middle_name', 'contract_id', 'contract_expiry_date']
            :['inn', 'company_name', 'certificate_id', 'phone', 'contract_id', 'contract_expiry_date']


        let fields = []
        let name = ''


        for(let key of field_is_show){
            {/*@ts-ignore*/}
            if(initValues[key]){
                if(key.match('date')){
                    fields.push(<div className='d-flex align-items-center mb-5'>
                        <p className='mb-0 text-gray-600 fs-6 col-lg-4'>{intl.formatMessage({id:key})}</p>
                        {/*@ts-ignore*/}
                        <p className='mb-0 text-gray-800 fw-bold fs-5'>{initValues[key]?moment(initValues[key]).format('DD/MM/YYYY'):'-'}</p>
                    </div>)
                }else if(key.match('name')){
                    //@ts-ignore
                    name+=`${initValues[key]} `
                    if(key==='middle_name'){
                        fields.push(<div className='d-flex align-items-center mb-5'>
                            <p className='mb-0 text-gray-600 fs-6 col-lg-4'>{intl.formatMessage({id:'fio'})}</p>
                            {/*@ts-ignore*/}
                            <p className='mb-0 text-gray-800 fw-bold fs-5'>{name}</p>
                        </div>)
                    }
                }else if(key==='passport_id'){
                    fields.push(<div className='d-flex align-items-center mb-5'>
                        <p className='mb-0 text-gray-600 fs-6 col-lg-4'>{intl.formatMessage({id:'passport_id'})}</p>
                        {/*@ts-ignore*/}
                        <p className='mb-0 text-gray-800 fw-bold fs-5'>{initValues[key].replace(/([A-Z])(\d)/g, '$1 $2')}</p>
                    </div>)
                }else if(key==='inn'){
                    fields.push(<div className='d-flex align-items-center mb-5'>
                        <p className='mb-0 text-gray-600 fs-6 col-lg-4'>{intl.formatMessage({id:'inn'})}</p>
                        {/*@ts-ignore*/}
                        <p className='mb-0 text-gray-800 fw-bold fs-5'>{initValues[key].replace(/(\d{3})(\d{3})(\d{3})/g, '$1 $2 $3')}</p>
                    </div>)
                }else{
                    fields.push(<div className='d-flex align-items-center mb-5'>
                        <p className='mb-0 text-gray-600 fs-6 col-lg-4'>{intl.formatMessage({id:key})}</p>
                        {/*@ts-ignore*/}
                        <p className='mb-0 text-gray-800 fw-bold fs-5'>{initValues[key]}</p>
                    </div>)
                }
            }
        }



        return fields
    }




    return (
        <div className='mt-10'>

            <div className='d-flex flex-row-fluid flex-center position-relative'>
                {alert && (
                    <Alert className='position-fixed' style={{zIndex: "4", top: "20%"}} key={'success'}
                           variant={'success'}>
                        {alert}
                    </Alert>
                )}
                <Formik enableReinitialize={true} validationSchema={currentShema}
                        initialValues={initValues} onSubmit={onSub}>
                    {(props) => {

                        const {
                            values,
                            errors,
                            setValues,
                            handleChange,
                            setFieldValue
                        } = props


                        return (
                            <Form className='w-100'>
                                <KTCard>
                                    <div className='card-header d-flex py-5 px-10 align-items-center'>
                                        <h3 className='mb-0'>{intl.formatMessage({id: "title_company_settings"})}</h3>
                                        {
                                            formShow!==1?(<button onClick={() => {
                                                setFormShow(1)

                                            }} type='button' className='btn btn-md btn-primary'>
                                  <span className='indicator-label'>
                                    {intl.formatMessage({id: "EDIT_BUTTON"})}
                                  </span>
                                            </button>):''
                                        }
                                    </div>
                                    <KTCardBody>
                                        {
                                            formShow===1?(
                                                <>{merchantData?.type==='INDIVIDUAL'?<FormTypeIndividual
                                                    handleChange={handleChange}
                                                    setFieldValue={setFieldValue}
                                                    values={values}
                                                />:<FormTypeCompany handleChange={handleChange}
                                                                    setFieldValue={setFieldValue}
                                                                    values={values}/>}</>
                                            ):(<div>

                                                {renderFields()}

                                            </div>)
                                        }
                                    </KTCardBody>
                                    {
                                        formShow===1?( <div className='card-footer py-5 px-10 d-flex justify-content-end'>
                                            <button className='btn btn-light me-3' onClick={()=>{
                                                setFormShow(0)
                                                setValues(initValues)
                                            }}>{intl.formatMessage({id:'Cancel'})}</button>
                                            <button className='btn btn-primary' type='submit' onClick={()=>{
                                                let err_message = ''
                                                if (Object.keys(errors).length) {
                                                    for (let err in errors) {
                                                        //@ts-ignore
                                                        err_message += `* ${intl.formatMessage({id: err})} - ${errors[err]} ` + "\n"
                                                    }
                                                    setError({status: '', message: err_message, isError: true})
                                                }
                                            }}>{intl.formatMessage({id:'SAVE_EDITION'})}</button>
                                        </div>):''
                                    }
                                </KTCard>




                                {
                                    roleRoutingAdmin('action', employeeStore.getRole, 'agent-delete')?( <div className="card mt-10">
                                        <div className="card-header border-0 cursor-pointer" role="button"
                                             data-bs-toggle="collapse" data-bs-target="#kt_account_deactivate"
                                             aria-expanded="true" aria-controls="kt_account_deactivate">
                                            <div className="card-title m-0">
                                                <h3 className="fw-bold m-0">{intl.formatMessage({id: "DELETE_AGENT"})}</h3>
                                            </div>
                                        </div>
                                        <div id="kt_account_settings_deactivate" className="collapse show">
                                            <div id="kt_account_deactivate_form" className="form">
                                                <div className="card-body border-top p-9">
                                                    <div
                                                        className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6">
                                              <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
																<rect opacity="0.3" x="2" y="2" width="20" height="20"
                                                                      rx="10" fill="currentColor"/>
																<rect x="11" y="14" width="7" height="2" rx="1"
                                                                      transform="rotate(-90 11 14)"
                                                                      fill="currentColor"/>
																<rect x="11" y="17" width="2" height="2" rx="1"
                                                                      transform="rotate(-90 11 17)"
                                                                      fill="currentColor"/>
															</svg>
														</span>
                                                        <div className="d-flex flex-stack flex-grow-1">
                                                            <div className="fw-semibold">
                                                                <h4 className="text-gray-900 fw-bold">{intl.formatMessage({id: "DELETE_AGENT_TEXT1"})}</h4>
                                                                <div
                                                                    className="fs-6 text-gray-700">{intl.formatMessage({id: "DELETE_AGENT_TEXT2"})}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-check form-check-solid fv-row">
                                                        <input onChange={(e) => {
                                                            setDeactivate(e.target.checked)
                                                        }} name="deactivate" className="form-check-input" type="checkbox"
                                                               value="" id="deactivate"/>
                                                        <label
                                                            className="form-check-label fw-semibold ps-2 fs-6">{intl.formatMessage({id: "accept_delete_agent"})}</label>
                                                    </div>
                                                </div>
                                                <div className="card-footer d-flex justify-content-end py-6 px-9">
                                                    {
                                                        isDemo()?(<Tooltip zIndex={1} color='var(--kt-body-bg)'
                                                                           title={() => <span
                                                                               style={{color: 'var(--kt-text-gray-600)'}}>{intl.formatMessage({id: "DEMO_DISABLED"})}</span>}>
                                                            <div>
                                                                <button
                                                                    id="kt_account_deactivate_account_submit"
                                                                    type="button"
                                                                    className="btn btn-danger fw-semibold"
                                                                    disabled={!deactivate || isDemo()}>
                                                                    {intl.formatMessage({id: "DELETE_COMPANY"})}
                                                                </button>
                                                            </div>
                                                        </Tooltip>):(<button
                                                            id="kt_account_deactivate_account_submit"
                                                            type="button"
                                                            className="btn btn-danger fw-semibold"
                                                            disabled={!deactivate}
                                                            onClick={() => {
                                                                deleteItem()
                                                            }}>{intl.formatMessage({id: "DELETE_AGENT_BUTTON"})}
                                                        </button>)
                                                    }

                                                </div>
                                            </div>
                                        </div>

                                    </div>) :''
                                }

                            </Form>
                        )
                    }}
                </Formik>
            </div>
            
        </div>
    )
})





export {Settings}