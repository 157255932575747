import {useMemo, useState} from 'react'
import {ColumnInstance, Row, useTable} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {companyColumns} from './columns/_columns'
import {KTCardBody, KTSVG} from '@metronic/helpers'
import {Company} from "@providers/company/_request";
import {CompanyResponse, ICompanyResponse} from "@providers/company/_models";
import {observer} from "mobx-react";
import {company} from "@store/company";
import {useIntl} from "react-intl";
import {Pagination} from "../../../modules/Pagination";
import {useErrors} from "@providers/context/ErrorsProvider";
import {AxiosResponse} from "axios";
import {UsersListLoading} from "@metronic/partials/widgets/tables/_modals/components/loading/UserListLoading";


const Table = observer(() => {

    const data = useMemo(() => company.getCompany, [company.getCompany])
    const pagination = useMemo(() => company.getPagination, [company.getPagination])
    const columns = useMemo(() => companyColumns, [company.getCompany])
    const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
        columns,
        data,
    })
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false)

    const {setError} = useErrors()


    const intl = useIntl()

     function updatePage(props:any){
        setConfirmLoading(true)
        Company.getMyCompanies({page: props?.currentPage || 1, size: 20})
            .then((result: AxiosResponse<ICompanyResponse>) => {
                company.setCompany(result?.data?.result.content)
                company.setPagination({
                    pages: result.data.result.totalPages,
                    currentPage: result.data.result.number + 1,
                    count: 20
                })
                window.scroll({
                    top: 0,
                    behavior: 'smooth'
                })
            }).catch(e => {
            setError({status: e?.response?.status, message: e?.response?.data?.message, isError: true})
        }).finally(() => setConfirmLoading(false))

    }


    return (
        <KTCardBody className='py-4'>
            {confirmLoading && <UsersListLoading/>}
            {columns.length ? <div className='table-responsive'>
                <table
                    id='kt_table_users'
                    className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                    {...getTableProps()}
                >
                    <thead>
                    <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                        {headers.map((column: ColumnInstance<CompanyResponse>) => (
                            <CustomHeaderColumn key={column.id} column={column}/>
                        ))}
                    </tr>
                    </thead>
                    <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                    {rows.length > 0 ? (
                        rows.map((row: Row<CompanyResponse>, i) => {
                            prepareRow(row)
                            return <CustomRow row={row} key={`row-${i}-${row.id}`}/>
                        })
                    ) : (
                        <tr>
                            <td colSpan={7}>
                                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                    {intl.formatMessage({id: 'NO_MATCHING'})}
                                </div>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div> : <div style={{height: "55vh"}} className="d-flex justify-content-center align-items-center">
                <a href="https://demo.globalpay.uz" target="_blank" className="btn btn-success text-white fs-3">
                    {intl.formatMessage({id: "WATCH_DEMO_COMPANY1"})}
                    <KTSVG path='/media/icons/duotune/arrows/arr001.svg' className='svg-icon-3 ms-3' />
                </a>
            </div>}
            <Pagination position={'end'} infoText='Компаний' pagination={pagination} updatePage={updatePage}/>

        </KTCardBody>
    )
})

export {Table}
