/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {MenuComponent} from '@metronic/assets/ts/components'
import {useListView} from '../providers/ListViewProvider'
import {observer} from "mobx-react";
import {company} from "@store/company";
import DeleteAdmin from "./delete-admin/delete-admin";
import AdminForm from "../components/admin-form/admin-form";
import {IAccountInfo} from "@providers/account/_models";


type Props = {
    id: number
    admin: IAccountInfo
}

const UserActionsCell: FC<Props> = observer(({id, admin}) => {
    const {setItemIdForUpdate} = useListView()


    const me = company.getMe

    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])

    const openEditModal = () => {
        setItemIdForUpdate(id)
    }




    return (
        <div className='d-flex justify-content-end'>
            <AdminForm edit admin={admin}/>
            <DeleteAdmin id={id}/>
        </div>
    )
})

export {UserActionsCell}
