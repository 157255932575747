import React, {useState} from 'react';
import {isDemo} from "@utils/index";
import moment from "moment";
import {MinusOutlined} from "@ant-design/icons";
import localeRu from "antd/es/date-picker/locale/ru_RU";
import localeEn from "antd/es/date-picker/locale/en_US";
import {toIsoStringHelper} from "../../../../../autopay/dateHelper";
import {useIntl} from "react-intl";
import {DatePicker} from 'antd';
import {transactions} from "@store/transactions/transactions";
import {IDatePicker} from "@providers/adminTransactions/_models";


interface props {

}

const DatePickerRange = (props: props) => {
    const intl = useIntl()
    const lang = JSON.parse(localStorage.getItem('i18nConfig') ?? '{}')?.selectedLang
    const filters = transactions.getFilters

    const [dates, setDates] = useState<IDatePicker>({
        startDate: filters.from_date,
        endDate:  filters.to_date
    })


    const [filtersDay, setFiltersDay] = useState('month')

    function replaceFormat(date: any, formatFrom: string, formatTo: string) {
        let newDate = moment(date, formatFrom).format(formatTo)
        return moment(newDate, formatTo)
    }

    function countDays() {
        let a = moment(dates.startDate),
            b = moment(dates.endDate)

        return b.diff(a, 'days')
    }

    return (
        <div className='toolbar toolbar-filter justify-content-end px-10 align-items-center'>
            <div className='filter_buttons_group'>
                <button
                    disabled={isDemo()}
                    className={`btn btn-sm btn-active-light-primary ${countDays() === 0 ? 'btn-active' : "fw-normal"}`}
                    onClick={() => {
                        if (filtersDay !== 'day') {

                            const from_date = moment().format('YYYY-MM-DD')
                            const to_date = moment().format('YYYY-MM-DD')
                            setDates({
                                startDate: from_date,
                                endDate: to_date
                            })
                            setFiltersDay('day')

                            transactions.setFilters({
                                ...filters,
                                from_date: from_date,
                                to_date: to_date
                            })
                        }

                    }}>
                    {intl.formatMessage({id: 'filter_by_day'})}
                </button>
                <button
                    disabled={isDemo()}
                    className={`btn btn-sm btn-active-light-primary ${countDays() === 7 ? 'btn-active' : "fw-normal"}`}
                    onClick={() => {
                        if (filtersDay !== 'week') {

                            const from_date = moment().subtract(1, 'week').format('YYYY-MM-DD')
                            const to_date = moment().format('YYYY-MM-DD')
                            setDates({
                                startDate: from_date,
                                endDate: to_date
                            })
                            setFiltersDay('week')

                            transactions.setFilters({
                                ...filters,
                                from_date: from_date,
                                to_date: to_date
                            })
                        }

                    }}>
                    {intl.formatMessage({id: 'filter_by_week'})}
                </button>
                <button
                    className={`btn btn-sm btn-active-light-primary ${(countDays() >= 28 && countDays() <= 31) ? 'btn-active' : 'fw-normal'}`}
                    onClick={() => {

                        if (filtersDay !== 'month') {
                            const from_date = moment().subtract(1, 'month').format('YYYY-MM-DD')
                            const to_date = moment().format('YYYY-MM-DD')

                            setDates({
                                startDate: from_date,
                                endDate: to_date
                            })
                            setFiltersDay('month')

                            transactions.setFilters({
                                ...filters,
                                from_date: from_date,
                                to_date: to_date
                            })
                        }

                    }}>
                    {intl.formatMessage({id: 'filter_by_month'})}
                </button>
            </div>
            <div className="mx-4" style={{backgroundColor: "rgba(235, 237, 242, 1)", width: "1px", height: "40%"}}/>
            <div>
                <DatePicker.RangePicker
                    style={{border: "none"}}
                    separator={<MinusOutlined/>}
                    locale={lang === 'ru' ? localeRu : localeEn}
                    placeholder={[intl.formatMessage({id: 'calendar_start'}), intl.formatMessage({id: 'calendar_end'})]}
                    key={dates.startDate}
                    className='datepicker_form form-control-lg form-control-solid'
                    defaultValue={[replaceFormat(dates.startDate, 'YYYY-MM-DD', 'DD-MM-YYYY'),
                        replaceFormat(dates.endDate, 'YYYY-MM-DD', 'DD-MM-YYYY')]}
                    format='DD-MM-YYYY'
                    disabledDate={current => {
                        return isDemo() || current && current > moment().endOf('day');
                    }}
                    onKeyDown={(val: any) => {
                        if (val.code.toLowerCase().includes("key")) {
                            val.preventDefault()
                            val.stopPropagation()
                            return
                        }
                    }}
                    //@ts-ignore
                    onChange={(dates: [any, any], dateStrings: [string, string]) => {
                        if (dates) {

                            let from_date = replaceFormat(dateStrings[0], 'DD-MM-YYYY', 'YYYY-MM-DD').format()
                            let to_date = replaceFormat(dateStrings[1], 'DD-MM-YYYY', 'YYYY-MM-DD').format()

                            setDates({
                                startDate: from_date,
                                endDate: to_date
                            })


                            transactions.setFilters({
                                ...filters,
                                from_date: from_date,
                                to_date: to_date
                            })

                        }

                    }}
                />
            </div>
        </div>
    );
};

export default DatePickerRange;