import {makeAutoObservable, toJS} from "mobx";
import {card_transactions, statuses_transactions} from "@utils/constants";
import moment from "moment/moment";
import {ITransaction} from "@providers/adminTransactions/_models";

type TransactionSortParams = { sortBy: string, sortFromMax: boolean }

class Transactions {
    transactions: TransactionSortParams = {sortBy: "CREATED_AT", sortFromMax: false}
    pagination:any = {}
    filters:any = {
        statuses: statuses_transactions,
        processingTypes: card_transactions,
        from_date: moment().subtract(1, 'month').format('YYYY-MM-DD'),
        to_date: moment().format('YYYY-MM-DD')
    }
    transactionsData:ITransaction[] = []

    constructor() {
        makeAutoObservable(this)
    }

    setTransactionSortBy(data: TransactionSortParams) {
        this.transactions = data
    }
    setPagination(data: any) {
        this.pagination = data
    }
    setFilters(data: any) {
        this.filters = data
    }
    setTransactionsData(data: any) {
        this.transactionsData = data
    }

    get getTransactionSortBy() {
        return toJS(this.transactions)
    }
    get getPagination() {
        return toJS(this.pagination)
    }
    get getFilters() {
        return toJS(this.filters)
    }
    get getTransactions() {
        return toJS(this.transactionsData)
    }
}


export const transactions = new Transactions()