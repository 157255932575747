import React from "react";
import {AdminTable} from "../_modals/AdminTable";


export function AdminTransactions(){


    return (
        <>
            <AdminTable />
        </>
    )
}