import {useEffect, useState} from "react";

type ErrorProps = {
    status?: number
    message?: string
    isError?: boolean
}

export const useError = (errorData:ErrorProps)=>{

    const [error, setError] = useState<ErrorProps>(errorData)

    useEffect(()=>{
        if(Object.keys(error).length){
           errorsHandler()
        }
    }, [])

    const errorsHandler = ()=>{

        if(error?.status && error?.message){
          return
        }else{
            switch (error.status){
                case 400:
                    setError({status: error.status, message: 'Ошибка 400', isError: true})
                    break
                case 500:
                    setError({status: error.status, message: 'Ошибка 500', isError: true})
                    break
                default:
                    setError({status: error.status, message: 'Что-то пошло не так', isError: true})
                    break
            }
        }
    }


    return {error, setError}

}