import {instantse} from "@providers/request";
import {
    AccountByIdResponse,
    AccountPut, IAccountInfo,
    IAssignAccount,
    ICreateAccount,
    ISearchAccount
} from "@providers/account/_models";
import {AxiosResponse} from "axios";


export const Account = {
    getMe() {
        return instantse.get(`/v1/auth/me`)
            .then((response) => response)
    },
    putMe(values: AccountPut) {
        return instantse.put('/v1/account', values)
            .then((response) => response)
    },
    editAccount(data: IAccountInfo) {
        return instantse.patch('/v1/account', data)
    },
    putPassword(password: string) {
        return instantse.put('/v1/auth/password', {password: password})
            .then((response) => response)
    },
    createAccount(values: ICreateAccount) {
        return instantse.post(`/v1/account`, values).then((response) => response)
    },
    assignUserToCompany(data: IAssignAccount) {
        return instantse.patch(`/v1/account/company`, data)
    },
    findAccount(phone: string) {
        const params = new URLSearchParams()

        params.set('phone', phone)

        return instantse.get('/v1/account/search', {
            params: params
        })
    },

    getAccountById(id: number): Promise<AxiosResponse<AccountByIdResponse>> {
        return instantse.get(`/v1/account/${id}`)
    }
}