/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useMemo, useRef, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import clsx from 'clsx'
import {useLayout} from '../../core'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {AsideMenu} from './AsideMenu'
import {useIntl} from "react-intl";
import {useThemeMode} from "@metronic/partials";
import SVG from "react-inlinesvg";
import {observer} from "mobx-react";
import {company} from "@store/company";
import {employeeStore} from "@store/employee";
import {Tooltip} from "antd";
import {isDemo, roleRouting} from "@utils/index";

const AsideDefault: FC = observer(() => {
    const {config, classes} = useLayout()
    const asideRef = useRef<HTMLDivElement | null>(null)
    const {aside} = config
    const {mode} = useThemeMode()
    const {companyId} = useParams()
    const [toggleAside, setToggleAside] = useState(false)
    const intl = useIntl()

    const data = useMemo(() => company.getCompany, [company.getCompany])
    const userRole = useMemo(() => employeeStore.getRole, [employeeStore.getRole])

    const minimize = () => {
        setToggleAside(!toggleAside)
        asideRef.current?.classList.add('animating')
        setTimeout(() => {
            asideRef.current?.classList.remove('animating')
        }, 300)
    }

    useEffect(() => {
        document.body.setAttribute('data-kt-aside-minimize', toggleAside ? 'on' : 'off')
    }, [toggleAside])


    return (

        <div
            id='kt_aside'
            className={`${clsx('aside', classes.aside.join(' '))} aside-light app-sidebar`}
            data-kt-drawer='true'
            data-kt-drawer-name='aside'
            data-kt-drawer-activate='{default: true, lg: false}'
            data-kt-drawer-overlay='true'
            data-kt-drawer-width="{default:'200px', '300px': '250px'}"
            data-kt-drawer-direction='start'
            data-kt-drawer-toggle='#kt_aside_mobile_toggle'
            ref={asideRef}
        >
            {/* begin::Brand */}
            <div className='aside-logo flex-column-auto position-relative' id='kt_aside_logo'>
                {/* begin::Logo */}
                {mode === 'dark' && (

                    <Link
                        to={data.length > 1 ? '/companies' : data.length === 1 ? (companyId ? `/${companyId}/dashboard-merchant` : `/${data[0].id}/dashboard-merchant`) : '/companies/add'}
                        style={{width: "125px"}}>

                        <img
                            alt='Logo'
                            className='w-100 h-100 logo-default'
                            // src={toAbsoluteUrl('/media/logos/logo-dark-default.svg')}
                            src={toAbsoluteUrl(`/media/logos/logo-default-dark-${isDemo() ? "demo" : "beta"}.svg`)}
                        />

                        <img
                            alt='Logo'
                            className='w-100 h-100 logo-minimize'
                            src={toAbsoluteUrl('/media/logos/logo-dark-small.svg')}
                        />
                    </Link>
                )}
                {mode === 'light' && (
                    <Link
                        to={data.length > 1 ? '/companies' : data.length === 1 ? (companyId ? `/${companyId}/dashboard-merchant` : `/${data[0].id}/dashboard-merchant`) : '/companies/add'}
                        style={{width: "125px"}}>
                        <img
                            alt='Logo'
                            className='w-100 h-100 logo-default'
                            // src={toAbsoluteUrl('/media/logos/logo-default-light.svg')}
                            src={toAbsoluteUrl(`/media/logos/logo-default-light-${isDemo() ? "demo" : "beta"}.svg`)}
                        />

                        <img
                            alt='Logo'
                            className='w-100 h-100  logo-minimize'
                            src={toAbsoluteUrl('/media/logos/logo-small.svg')}
                        />
                    </Link>
                )}
                {/* end::Logo */}

                {/* begin::Aside toggler */}
                {aside.minimize && (
                    <div
                        id='kt_aside_toggle'
                        className="rotate-arrow-sidebar app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate"
                        data-kt-toggle='true'
                        data-kt-toggle-state='active'
                        data-kt-toggle-target='body'
                        data-kt-toggle-name='aside-minimize'
                        onClick={minimize}
                    >
                        <KTSVG
                            path={'/media/icons/duotune/arrows/arr080.svg'}
                            className={'svg-icon-1 rotate-360 color-orange'}
                        />
                    </div>

                )}
                {/* end::Aside toggler */}
            </div>
            {/* end::Brand */}

            {/* begin::Aside menu */}
            <div className='aside-menu flex-column-fluid'>
                <AsideMenu asideMenuCSSClasses={classes.asideMenu}/>
            </div>
            {/* end::Aside menu */}


            {
                roleRouting('company/add', employeeStore.getRole)?(<div className='aside-footer flex-column-auto pt-5 pb-11 px-5 position-absolute bottom-0' id='kt_aside_footer'>
                    <Tooltip zIndex={isDemo() ? -1 : 9999} visible={isDemo() ? false : undefined} placement="right" color='var(--kt-body-bg)'
                             title={() =>
                                 <a href="https://demo.globalpay.uz" target="_blank" className="btn btn-success text-white"
                                    style={{color: 'var(--kt-text-gray-600)'}}>
                                     {intl.formatMessage({id: "WATCH_DEMO_COMPANY"})}
                                 </a>}>
                        <Link
                            className='btn w-100 btn_custom_primary'
                            to={`/companies/add`}
                            data-bs-toggle='tooltip'
                            data-bs-trigger='hover'
                            data-bs-dismiss-='click'
                        >
                    <span className='aside-footer-button-wrapper'>
                        <span className='aside-footer-button rounded-circle bg-white me-2'>
                          <SVG width={15} height={15} src={toAbsoluteUrl('/media/icons/duotune/arrows/arr075.svg')}
                               className='svg-icon-2 m-0 color-orange'/>
                        </span>
                    </span>
                            <span className='btn-label text-white'>{intl.formatMessage({id: "ADD_COMPANY"})}</span>
                            <span className='svg-icon btn-icon svg-icon-2 text-primary rounded-circle bg-white' style={{
                                width: 17,
                                height: 17,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                        <SVG width={15} height={15} src={toAbsoluteUrl('/media/icons/duotune/arrows/arr075.svg')}
                             className='svg-icon-2  m-0 color-orange'/>
                                {/*<KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2 m-0 text-white'/>*/}
                    </span>
                        </Link>
                    </Tooltip>
                </div>):''
            }


        </div>
    )
})

export {AsideDefault}
