import {setLanguage, useLang} from "@metronic/i18n/Metronici18n";
import {languages} from "@metronic/partials/layout/header-menus/Languages";
import clsx from "clsx";
import React from "react";


export const LanguageSelectDropdown = () => {
    const lang = useLang()
    const currentLanguage = languages.find((x) => x.lang === lang)

    return <div className="dropdown">
        <div
            className='btn btn-secondary dropdown-toggle fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0'
            data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
            data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-end"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {currentLanguage?.name}{' '}
            <img
                className='w-15px h-15px rounded-1 ms-2'
                src={currentLanguage?.flag}
                alt='metronic'
            />


        </div>
        <div
            className="menu menu-sub menu-sub-dropdown menu-column mt-8 menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px"
            data-kt-menu="true" data-popper-placement="bottom-end">
            {languages.map(l =>
                <div
                    className='menu-item px-3'
                    key={l.lang}
                    onClick={() => {
                        setLanguage(l.lang)
                    }}
                >
                    <a
                        href='#'
                        className={clsx('menu-link d-flex px-5', {active: l.lang === currentLanguage?.lang})}
                    >
                                        <span className='symbol symbol-20px me-4'>
                                            <img className='rounded-1' src={l.flag} alt='metronic'/>
                                        </span>
                        {l.name}
                    </a>
                </div>
            )}
        </div>
    </div>

}