import React, {FC, useContext} from "react";
import {WithChildren} from "@metronic/helpers";
import {useError} from "@utils/hooks/error.hook";
import {ErrorsResponseAlert} from "../../app/modules/alerts/ErrorsResponseAlert";

const initialErrorProps:{error: any, setError:(val:any)=>void} = {
    error: {},
    setError: (val:any)=>{}
}

export const ErrorContext = React.createContext(initialErrorProps);


const ErrorProvider: FC<WithChildren> = ({children}) => {
    const {error, setError} = useError({})


    return (
        //@ts-ignore
        <ErrorContext.Provider value={{error, setError}}>
            <ErrorsResponseAlert isError={error.isError} setError={setError} status={error.status} message={error.message} />
            {children}
        </ErrorContext.Provider>
    )
}

const useErrors = () => useContext(ErrorContext)
export {ErrorProvider, useErrors}