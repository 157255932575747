import React, {useEffect, useMemo, useState} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {agentColumns, agentStatisticColumns} from './columns/_columns'
import {KTCardBody, KTSVG} from '@metronic/helpers'
import {Company} from "@providers/company/_request";
import {CompanyResponse} from "@providers/company/_models";
import {observer} from "mobx-react";
import {company} from "@store/company";
import {useIntl} from "react-intl";
import {Pagination} from "../../../modules/Pagination";
import {useErrors} from "@providers/context/ErrorsProvider";
import {useParams} from "react-router-dom";
import {Agent} from "@providers/agents/_request";
import {AgentRequestGet} from "@providers/agents/_models";
import {agentStore} from "@store/agents";
import {toIsoStringHelper} from "../../autopay/dateHelper";
import moment from "moment";
import {isDemo, NumReplace} from "@utils/index";
import localeEn from "antd/es/date-picker/locale/en_US";
import {DatePicker} from "antd";
import {MinusOutlined} from "@ant-design/icons";
import localeRu from "antd/es/date-picker/locale/ru_RU";



const TableStatistics = observer(() => {
    const {type} = useParams()
    const data = useMemo(() => agentStore.getAgents, [agentStore.getAgents])
    const pagination = useMemo(() => agentStore.getSorting, [agentStore.getSorting])
    const columns = useMemo(() => agentStatisticColumns, [agentStore.getAgents])
    const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
        columns,
        data,
    })
    const [filtersDay, setFiltersDay] = useState('month')
    const general = useMemo(()=>agentStore.getGeneralStatistics, [agentStore.getGeneralStatistics])
    const {setError} = useErrors()

    const lang = JSON.parse(localStorage.getItem('i18nConfig') ?? '{}')?.selectedLang

    if (lang === "uz") {
        localeEn.lang.locale = "uz-latn"
    }



    useEffect(()=>{

        agentStore.setSorting({...agentStore.getSorting, page: pagination.page,
            count: 20,
            from_date: toIsoStringHelper(moment().subtract(1, 'month').startOf('day').toDate()),
            to_date: toIsoStringHelper(moment().endOf('day').toDate()),
            types: ['INDIVIDUAL', 'COMPANY']})

        getAgents()
        getGeneralInfoAgents()
    }, [type])



    async function getGeneralInfoAgents(){

        const dataFilter = {
            page: pagination.page,
            count: 20,
            from_date: agentStore.getSorting?.from_date,
            to_date: agentStore.getSorting?.to_date,
            type: agentStore.getSorting?.type,
        }
        await Agent.getAgentsGeneral(dataFilter)
            .then(result=>{
                agentStore.setGeneralStatistics(result?.data?.result)
            }).catch((err:any)=>{
                setError({status:err?.response?.status, message:err?.response?.data?.message, isError:true})
            })
    }


    async function getAgents(){
        const filterData = agentStore.getSorting

        await Agent.getAgents(filterData)
            .then(result=>{

                agentStore.setAgent(result?.data?.result.map((item: any, index: number) => {
                    if (filterData.page && filterData.page > 2) {
                        item['num'] = ((filterData.page - 1) * 20) + index + 1
                    } else if (filterData.page == 2) {
                        item['num'] = 21 + index
                    } else {
                        item['num'] = index + 1
                    }
                    return item
                }))
                agentStore.setSorting({...agentStore.getSorting,...result?.data?.meta,   currentPage: filterData.page})
                window.scroll({
                    top:0,
                    behavior: 'smooth'
                })

            }).catch((err:any)=>{
                setError({status:err?.response?.status, message:err?.response?.data?.message, isError:true})
            })
    }





    const intl = useIntl()

    async function updatePage(props:any){
        agentStore.setSorting({...agentStore.getSorting, page: props.currentPage})
        getAgents()

    }

    function replaceFormat(date:any, formatFrom:string, formatTo:string){
        let newDate = moment(date, formatFrom).format(formatTo)
        return moment(newDate, formatTo)
    }

    function countDays(){

        let a = moment(agentStore.getSorting.from_date),
            b = moment(agentStore.getSorting.to_date)

        return b.diff(a, 'days')
    }


    return (
        <KTCardBody className='py-4'>
            <div className='d-flex justify-content-end py-5'>
                <p className='fs-6 fw-semibold text-gray-600 me-10'>{intl.formatMessage({id:'agents'})}: <span className='text-gray-800 fs-3'>{general?.agents||0}</span></p>
                <p className='fs-6 fw-semibold text-gray-600 me-10'>{intl.formatMessage({id:'merchants'})}: <span className='text-gray-800 fs-3'>{general?.merchants}</span></p>
                <p className='fs-6 fw-semibold text-gray-600'>{intl.formatMessage({id:'income'})}: <span className='text-gray-800 fs-3'>{NumReplace(''+(general?.income>0?general?.income/100:0))} UZS</span></p>
            </div>
            <div className='toolbar toolbar-filter justify-content-end px-10 align-items-center'>


                <div className='filter_buttons_group'>

                    <button
                        className={`btn btn-sm btn-active-light-primary text-gray-600 ${filtersDay==='week' ? 'btn-active' : "fw-bold" }`}
                        onClick={() => {
                            if (filtersDay !== 'week') {
                                const from_date = toIsoStringHelper(moment().subtract(1, 'week').startOf('day').toDate())
                                const to_date = toIsoStringHelper(moment().endOf('day').toDate())
                                setFiltersDay('week')

                                agentStore.setSorting({...agentStore.getSorting, from_date:from_date, to_date:to_date})

                                getAgents()
                                getGeneralInfoAgents()

                            }

                        }}>
                        {intl.formatMessage({id: 'filter_by_week'})}
                    </button>
                    <button
                        className={`btn btn-sm btn-active-light-primary text-gray-600 ${filtersDay==='month' ? 'btn-active' : "fw-bold" }`}
                        onClick={() => {
                            if (filtersDay !== 'month') {
                                const from_date = toIsoStringHelper(moment().subtract(1, 'month').startOf('day').toDate())
                                const to_date = toIsoStringHelper(moment().endOf('day').toDate())
                                setFiltersDay('month')


                                agentStore.setSorting({...agentStore.getSorting, from_date:from_date, to_date:to_date})

                                getAgents()
                                getGeneralInfoAgents()

                            }

                        }}>
                        {intl.formatMessage({id: 'filter_by_month'})}
                    </button>
                </div>
                <div className="mx-4" style={{backgroundColor: "rgba(235, 237, 242, 1)", width: "1px", height: "40%"}}/>
                <div>

                    <DatePicker.RangePicker
                        style={{border: "none"}}
                        separator={<MinusOutlined/>}
                        locale={lang === 'ru' ? localeRu : localeEn}
                        placeholder={[intl.formatMessage({id: 'calendar_start'}), intl.formatMessage({id: 'calendar_end'})]}
                        key={agentStore.getSorting.from_date}
                        className='datepicker_form form-control-lg form-control-solid'
                        defaultValue={[replaceFormat(agentStore.getSorting.from_date, 'YYYY-MM-DD','DD-MM-YYYY'),
                            replaceFormat(agentStore.getSorting.to_date, 'YYYY-MM-DD','DD-MM-YYYY')]}
                        format='DD-MM-YYYY'
                        disabledDate={current => {
                            return isDemo() || current && current > moment().endOf('day');
                        }}
                        onKeyDown={(val) => {
                            if (val.code.toLowerCase().includes("key")) {
                                val.preventDefault()
                                val.stopPropagation()
                                return
                            }
                        }}
                        //@ts-ignore
                        onChange={(dates: [any, any], dateStrings: [string, string]) => {
                            if (dates) {
                                const fromDate = toIsoStringHelper(replaceFormat(dateStrings[0], 'DD-MM-YYYY','YYYY-MM-DD').startOf('day').toDate())
                                const toDate = toIsoStringHelper(replaceFormat(dateStrings[1], 'DD-MM-YYYY','YYYY-MM-DD').endOf('day').toDate())
                                setFiltersDay('')
                                agentStore.setSorting({...agentStore.getSorting, from_date:fromDate, to_date:toDate})

                                getAgents()
                                getGeneralInfoAgents()

                            }

                        }}
                    />
                </div>
            </div>
            {columns.length ? <div className='table-responsive'>
                <table
                    id='kt_table_users'
                    className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                    {...getTableProps()}
                >
                    <thead>
                    <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                        {headers.map((column: ColumnInstance<CompanyResponse>) => (
                            <CustomHeaderColumn key={column.id} column={column}/>
                        ))}
                    </tr>
                    </thead>
                    <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                    {rows.length > 0 ? (
                        rows.map((row: Row<CompanyResponse>, i) => {
                            prepareRow(row)
                            return <CustomRow row={row} key={`row-${i}-${row.id}`}/>
                        })
                    ) : (
                        <tr>
                            <td colSpan={7}>
                                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                    {intl.formatMessage({id: 'NO_MATCHING'})}
                                </div>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div> : <div style={{height: "55vh"}} className="d-flex justify-content-center align-items-center">
                <a href="https://demo.globalpay.uz" target="_blank" className="btn btn-success text-white fs-3">
                    {intl.formatMessage({id: "WATCH_DEMO_COMPANY1"})}
                    <KTSVG path='/media/icons/duotune/arrows/arr001.svg' className='svg-icon-3 ms-3' />
                </a>
            </div>}
            {rows.length>0?<Pagination withInfo={true} position='end' infoText='агентов' pagination={pagination} updatePage={updatePage}/>:''}

        </KTCardBody>
    )
})

export {TableStatistics}
