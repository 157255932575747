import {useEffect, useRef, useState} from 'react'
import {
  MenuComponent,
  DrawerComponent,
  ScrollComponent,
  ScrollTopComponent,
  StickyComponent,
  ToggleComponent,
  SwapperComponent,
} from '../assets/ts/components'
import { ThemeModeComponent } from '../assets/ts/layout'

import {useLayout} from './core'
import {useAuth} from "../../app/modules/auth";

export function MasterInit() {
  const {config} = useLayout()
  const {logout} = useAuth()

  const [trackerCount, setTrackerCount] = useState(0)
  const isFirstRun = useRef(true)
  const pluginsInitialization = () => {
    isFirstRun.current = false
    ThemeModeComponent.init()
    setTimeout(() => {
      ToggleComponent.bootstrap()
      ScrollTopComponent.bootstrap()
      DrawerComponent.bootstrap()
      StickyComponent.bootstrap()
      MenuComponent.bootstrap()
      ScrollComponent.bootstrap()
      SwapperComponent.bootstrap()
    }, 500)
  }

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
      pluginsInitialization()
    }
  }, [config])


  useEffect(()=>{

    if(window!==undefined){
      window.addEventListener('mousemove', tracker)
      window.addEventListener('scroll', tracker)
      window.addEventListener('keydown', tracker)
    }
    return ()=>{
      window.removeEventListener('mousemove', tracker)
      window.removeEventListener('scroll', tracker)
      window.removeEventListener('keydown', tracker)
    }
  }, [])


  useEffect(()=>{
    const session_track = setInterval(()=>{
      const token = localStorage.getItem('kt-auth-react-v')

      setTrackerCount((prev)=>{
        if(token){
          if(prev===3600){
            logout()
            return 0
          }
          return prev+1
        }
        return 0

      })
    }, 1000)

    return ()=>{
      clearInterval(session_track)
    }
  }, [])



  function tracker(){
    setTrackerCount(0)
  }

  return <></>
}
