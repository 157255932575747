import React, {useState} from 'react';
import {ReplacePhone, roleRouting, roleRoutingAdmin} from "@utils/index";
import {KTSVG} from "@metronic/helpers";
import {confirmAlert} from "react-confirm-alert";
import {Admins} from "@providers/admins/_request";
import {admins} from "@store/admins";
import {employeeStore} from "@store/employee";
import {useErrors} from "@providers/context/ErrorsProvider";
import {useIntl} from "react-intl";
import {company} from "@store/company";
import {UsersListLoading} from "@metronic/partials/widgets/tables/_modals/components/loading/UserListLoading";
import {openNotificationError, openNotificationSuccess} from "@metronic/partials/notification";

interface props {
    id: number
}

const DeleteAdmin = ({id}: props) => {

    const {setError} = useErrors()
    const intl = useIntl()
    const me = company.getMe
    const role = employeeStore.getRole
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
    const deleteItem = async () => {
        if (roleRoutingAdmin('action', role, 'admin-delete') || roleRouting('employees/delete', role)) {

            confirmAlert({
                title: intl.formatMessage({id: 'WARNING'}),
                message: intl.formatMessage({id: 'admin.delete'}),
                buttons: [
                    {
                        label: intl.formatMessage({id: 'ACTIONS_YES'}),
                        onClick: async () => {
                            if (roleRoutingAdmin('action', role, 'is-admin')) {
                                setConfirmLoading(true)
                                Admins.deleteAdmin(id)
                                    .then(() => {
                                        onUpdateAdmins()
                                        openNotificationSuccess(intl.formatMessage({id: 'admin.deleted'}))
                                    }).catch(err => {
                                    setError({
                                        status: err?.response?.status,
                                        message: err?.response?.data?.code === "METHOD_NOT_ALLOWED" ? intl.formatMessage({id: 'not_employee'}) : err?.response?.data?.message,
                                        isError: true
                                    })
                                }).finally(() => setConfirmLoading(false))
                            }

                        }
                    },
                    {
                        label: intl.formatMessage({id: 'ACTIONS_NO'}),
                        onClick: async () => {
                            return
                        }
                    }
                ]
            })

        }
    }


    const onUpdateAdmins = () => {
        Admins.getAdmins()
            .then(result => {
                admins.setAdmins((result?.data).map((item: any) => {
                    return {
                        ...item,
                        phone: ReplacePhone(item.phone || ''),
                        role: intl.formatMessage({id: item.role}),
                    }
                }))
            })
            .catch(err => {
                setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
            })
    }

    return (
        <>
            {confirmLoading && <UsersListLoading/>}
            {
                ((roleRoutingAdmin('action', role, 'admin-delete') && me.id !== id) || roleRouting('employees/delete', role)) ? (
                    <a href="#" onClick={(e) => {
                        e.preventDefault()
                        deleteItem()
                    }} className="btn btn-icon-gray-500 btn-active-icon-danger p-5">
                        <KTSVG path='/media/icons/duotune/general/gen027.svg'
                               className="svg-icon-2 svg_icon_delete"/></a>) : ''
            }
        </>
    );
};

export default DeleteAdmin;