import React, {FC, useEffect, useState} from 'react'
import {KTSVG} from '@metronic/helpers'
import {Button, Checkbox, DatePicker, Drawer, Dropdown, Menu, MenuProps, Space} from 'antd';
import {useIntl} from "react-intl";
import moment from "moment/moment";
import {Statistic} from "@providers/statistic/_request";
import {useErrors} from "@providers/context/ErrorsProvider";
import "../../../../_metronic/assets/sass/style.scss"
import {CheckboxValueType} from "antd/es/checkbox/Group";
import {
    APPROVED,
    CANCELED,
    card_transactions,
    FAILED,
    REVERT,
    statuses_card,
    statuses_const,
    statuses_transactions
} from "@utils/constants";
import {ArrowRightOutlined, DownOutlined, MinusOutlined} from '@ant-design/icons';

import {CardsChartWidget} from "../chart";
import {isDemo, NumReplace, roleRoutingAdmin} from "@utils/index";
import {useNavigate} from "react-router-dom";
import {toIsoStringHelper} from "../../autopay/dateHelper";
import localeRu from "antd/es/date-picker/locale/ru_RU";
import localeEn from "antd/es/date-picker/locale/en_US";
import {employeeStore} from "@store/employee";
import {IStatistic, statisticStatus} from "@providers/statistic/_modals";
import { formatDateRequest } from '@utils/formatDateRequest';


type Props = {
    open?: boolean
    setDataCharts: (val: any) => void
    companyId: string
    params: any
    onDateChange: Function
    onCardSelect: Function
}


const dataColors: any = {
    successful: "bg-success",
    all: "bg-dark-blue",
    declined: "bg-warning",
    canceled: "bg-danger"
}

const ActivityDrawer: FC<Props> = ({open, params, setDataCharts, companyId, onDateChange, onCardSelect}) => {

    const [from_date, setFromDate] = useState(moment().subtract(1, 'month').format('YYYY-MM-DD'))
    const [to_date, setToDate] = useState(moment().format('YYYY-MM-DD'))
    const [loading, setLoading] = useState(true)
    const [dataRender, setDataRender] = useState<any>(null)
    const [currentCard, setCurrentCard] = useState<string[]>([])
    const [currentStatus, setCurrentStatus] = useState<string>('')
    const [statistic_data, setStatistic_data] = useState<any>({})
    const [navigateData, setNavigateData] = useState({})
    const navigate = useNavigate()
    const intl = useIntl()
    const lang = JSON.parse(localStorage.getItem('i18nConfig') ?? '{}')?.selectedLang

    const {setError} = useErrors()


    useEffect(() => {

        if (params && open) {

            if (params?.toDate === params?.fromDate) {
                setToDate(params?.toDate)
                setFromDate(moment(params?.fromDate).subtract(1, 'week').format('YYYY-MM-DD'))
            } else {
                setToDate(params?.toDate)
                setFromDate(params?.fromDate)
            }

            if (params.processingTypes.length > 0) {
                setCurrentCard(params.processingTypes.map((item: string) => item.toUpperCase()))
            }
            setCurrentStatus(params.statuses.length > 1 ? 'GENERAL' : params.statuses[0])
            getResult(params)

        }
    }, [params])


    useEffect(() => {
        if (dataRender) {
            setLoading(false)
        }
    }, [dataRender])


    useEffect(() => {

        if (!loading && params) {
            const data: IStatistic = {
                processingTypes: currentCard,
                statuses: currentStatus === 'general' ? statuses_transactions : [currentStatus.toUpperCase()],
                cashboxes: params?.cashboxes,
                companyId: Number(companyId),
                fromDate: formatDateRequest( from_date, "fromDate"),
                toDate: formatDateRequest( to_date, "toDate")
            }

            getResult(data)
        }
    }, [currentStatus, currentCard])

    const onClose = () => {
        setDataCharts(null);
        setCurrentStatus('')
        setCurrentCard([])
    }


    async function getResult(values: IStatistic) {


        setNavigateData({
            statuses: Array.isArray(values?.statuses) ? values?.statuses : [],
            // ppc_types: values?.ppc_types.map((item: string) => item.toUpperCase()),
            from_date: values.fromDate,
            to_date: values.toDate
        })
        setLoading(true)
        let values_parse = {...values}


        //new logic
        if (currentStatus.length) {
            // values_parse = {...values_parse, statuses: [currentStatus.toUpperCase()]}
            // values_parse = {...values_parse, statuses: [INIT, REVERT, FAILED, APPROVED]}
        } else {
            // setCurrentStatus(values.statuses.length > 1 ? 'GENERAL' : values.statuses[0])
            // values_parse = {...values_parse, statuses: [INIT, REVERT, FAILED, APPROVED]}
        }

        await Statistic.getDetailedStatistic({
            ...values_parse,
            fromDate:values.fromDate &&  formatDateRequest(values.fromDate, "fromDate"), 
            toDate: values.toDate && formatDateRequest(values.toDate, "toDate")
        })

            .then(result => {
                setStatistic_data(result?.data?.result || [])

                dataParse({
                    data: result?.data?.result,
                    cards: values.processingTypes?.length === 4 ?  [...statuses_card, 'general'] : statuses_card,
                    status: params.statuses.length > 1 ? 'general' : params.statuses[0]
                })
            }).catch(e => {
                setError({
                    status: e?.response?.status,
                    message: e?.response?.data?.message,
                    isError: true
                })
            })

    }

    function getCountStatuses({type = statisticStatus.approved, currency = 'UZS'}: {
        type: statisticStatus,
        currency?: string
    }) {
        let result: any = []
        if (statistic_data.length) {

            let sum = 0,
                count = 0

            if (statistic_data.length > 1) {
                sum = statistic_data.reduce((previousValue: any, currentValue: any) => {

                    if (currentValue[type]) {
                        if (typeof previousValue === 'object') {
                            return previousValue[type].totalAmount + currentValue[type].totalAmount
                        }

                        return previousValue + currentValue[type].totalAmount
                    }

                    return 0


                })


                count = statistic_data.reduce((previousValue: any, currentValue: any) => {

                    if (currentValue[type]) {
                        if (typeof previousValue === 'object') {
                            return previousValue[type].totalCount + currentValue[type].totalCount
                        }

                        return previousValue + currentValue[type].totalCount
                    }
                    return 0
                })
            } else {
                sum = statistic_data[0][type].totalAmount
                count = statistic_data[0][type].totalCount
            }


            result.push({
                sum: sum ? NumReplace(sum / 100 + '') + ' ' + currency : 0,
                count

            })

        } else {

            result = statuses_card.map((item: any) => {
                return {
                    card: item.toUpperCase(),
                    sum: 0,
                    count: 0
                }
            })
        }

        return result

    }


    const sumAmount = (status: statisticStatus) => {
        if (statistic_data.length) {
            if (statistic_data.length > 1) {
                let sum = statistic_data.reduce((previousValue: any, currentValue: any) => {

                    if (currentValue[status]) {
                        if (typeof previousValue === 'object') {
                            return previousValue[status].totalAmount + currentValue[status].totalAmount
                        }

                        return previousValue + currentValue[status].totalAmount
                    }
                    return 0

                });

                return sum / 100
            }

            return statistic_data[0][status].totalAmount / 100

        }

        return 0
    }


    function dataParse({data, cards = [], status = statisticStatus.general}: {
        data: any[],
        cards?: string[],
        status?: statisticStatus
    }) {

        let date_arr = data.map(item => item.date)
        let status_lower = status.toLowerCase()

        const series = cards.map((card: string) => {
            let name, dashboard

            name = `${card} - ${intl.formatMessage({id: status.toUpperCase()})}`


            dashboard = data.map(item => {
                if (card === 'general') {
                    return item['general'][`totalAmount`]
                }
                if (item[status_lower] && item[status_lower][`${card}Amount`]) {
                    return item[status_lower][`${card}Amount`]
                }
                return 0

            })


            return {name, data: dashboard}
        })


        setDataRender({series, categories: date_arr})


    }


    const handleMenuClick: MenuProps['onClick'] = e => {
        setCurrentStatus(e.key)
    };

    const menu = (
        <Menu
            onClick={handleMenuClick}
            selectable
            defaultSelectedKeys={statuses_const.filter(item => item.toUpperCase() === currentStatus)}
            items={[
                {
                    key: 'general',
                    label: intl.formatMessage({id: "general".toUpperCase()}),
                },
                {
                    key: 'revert',
                    label: intl.formatMessage({id: "revert".toUpperCase()}),
                },
                {
                    key: 'approved',
                    label: intl.formatMessage({id: "approved".toUpperCase()}),
                },
                {
                    key: 'failed',
                    label: intl.formatMessage({id: "declined".toUpperCase()}),
                }
            ]}
        />
    );


    function replaceFormat(date: any, formatFrom: string, formatTo: string) {
        let newDate = moment(date, formatFrom).format(formatTo)
        return moment(newDate, formatTo)
    }


    return (
        <Drawer width={`70%`} placement="right" closable={false} onClose={onClose} open={open}>

            <div className={`card-header d-flex align-items-center justify-content-between `}
                 style={{padding: "0 24px", marginTop: "15px"}}>
                {
                    params?.statuses ? (
                        <h3 className='card-title fw-bolder text-dark'>{intl.formatMessage({id: `transaction-${params?.statuses ? `${params?.statuses.length > 1 ? 'general' : params?.statuses[0].toLowerCase() ?? ''}` : ''}`})} </h3>) : ''
                }

                <div className='card-toolbar'>
                    <button
                        type='button'
                        className='btn btn-sm btn-icon'
                        onClick={onClose}
                    >
                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                    </button>
                </div>
            </div>
            <div className="mt-5" style={{padding: "24px"}}>
                <div>
                    <div className='toolbar-filter'>
                        <div className='row align-items-center mb-3 justify-content-end'>

                            <div className='col-6 d-flex justify-content-start'>
                                {dataRender && dataRender.series && dataRender.series.filter((item: {name: string, data: number[]}) => !item.name.includes('general')).map((item: {
                                    name: string,
                                    data: number[]
                                }) => {
                                    const data: any = {
                                        'uzcard': {
                                            icon: '/media/svg/uzcard.svg',
                                            background: '#233C7E'
                                        },
                                        'humo': {
                                            icon: '/media/svg/humo.svg',
                                            background: '#BEA645'
                                        },
                                        'visa': {
                                            icon: '/media/svg/visa.svg',
                                            background: '#F79E1B'
                                        },
                                        'master': {
                                            icon: '/media/svg/mastercard.svg',
                                            background: '#f70e1b'
                                        }
                                    }
                                    const key = item.name.split('-')[0].replace(/ /g, '').toLowerCase()
                                    const currentCard = data[key]


                                    return <div key={key} className='d-flex align-items-center ms-2'>
                                        <div style={{
                                            width: "24px",
                                            height: "8px",
                                            backgroundColor: currentCard ? currentCard.background : 'inherit',
                                            marginRight: "8px",
                                            borderRadius: "5px"
                                        }}/>
                                        <div className='bg-lighten'
                                             style={{width: '25px', height: '25px'}}>
                                            <img
                                                src={currentCard ? currentCard.icon : ''}
                                                className='w-100 h-100'
                                                alt={'Card Icon'}
                                                style={{objectFit: 'contain'}}/>
                                        </div>
                                        <p className='mx-2 mb-0 fw-semibold'
                                           style={{color: 'var(--kt-gray-600)'}}>{item.name}</p>
                                    </div>


                                })}
                            </div>
                            <div className='col-6 d-flex justify-content-end'>
                                {
                                    params && params?.statuses && params?.statuses.length > 0 ? (<div className="pe-7">
                                        <Dropdown overlay={menu}>
                                            <Button
                                                className='rounded datepicker_form form-control-lg form-control-solid'>
                                                <Space>
                                                    {intl.formatMessage({id: currentStatus !== "" ? currentStatus.toUpperCase() : "CANCELED".toUpperCase()})}
                                                    <DownOutlined
                                                        style={{
                                                            fontSize: 10,
                                                            display: "flex",
                                                            justifyContent: "center"
                                                        }}/>
                                                </Space>
                                            </Button>
                                        </Dropdown>
                                    </div>) : ''}
                                <DatePicker.RangePicker
                                    style={{border: "none"}}
                                    separator={<MinusOutlined/>}
                                    locale={lang === 'ru' ? localeRu : localeEn}
                                    key={`${from_date}-${to_date}`}
                                    placeholder={[intl.formatMessage({id: 'calendar_start'}), intl.formatMessage({id: 'calendar_end'})]}
                                    className='datepicker_form form-control-lg form-control-solid'
                                    defaultValue={[replaceFormat(from_date, 'YYYY-MM-DD', 'DD-MM-YYYY'),
                                        replaceFormat(to_date, 'YYYY-MM-DD', 'DD-MM-YYYY')]}
                                    format='DD-MM-YYYY'
                                    disabledDate={current => {
                                        return isDemo() || current && current > moment().endOf('day');
                                    }}
                                    //@ts-ignore
                                    onChange={(dates: [any, any], dateStrings: [string, string]) => {
                                        if (dates) {
                                            let from_date = replaceFormat(dateStrings[0], 'DD-MM-YYYY', 'YYYY-MM-DD').format()
                                            let to_date = replaceFormat(dateStrings[1], 'DD-MM-YYYY', 'YYYY-MM-DD').format()

                                            setFromDate(from_date)
                                            setToDate(to_date)
                                            const data: IStatistic = {
                                                cashboxes: params.branches,
                                                companyId: +companyId,
                                                fromDate: formatDateRequest( from_date, "fromDate"),
                                                toDate: formatDateRequest( to_date, "toDate"),
                                                statuses: params.statuses,
                                                processingTypes: currentCard.length ? currentCard : [params?.ppc_types[0].toUpperCase()]
                                            }

                                            getResult(data)
                                            onDateChange(dates, dateStrings)
                                        }

                                    }}
                                />
                            </div>

                        </div>


                    </div>
                    {
                        roleRoutingAdmin('action', employeeStore.getRole, 'is-admin') ? '' : (
                            <div className="d-flex justify-content-end">
                                <Button onClick={() => {
                                    navigate(`/${companyId}/payments/transactions`, {state: navigateData})
                                }} className='rounded datepicker_form form-control-solid'>
                                    <Space>
                                        Посмотреть транзакции
                                        <ArrowRightOutlined
                                            style={{
                                                fontSize: 10,
                                                display: "flex",
                                                justifyContent: "center",
                                                marginLeft: "10px"
                                            }}/>
                                    </Space>
                                </Button>
                            </div>)
                    }
                </div>
                <div>

                    {!loading && dataRender ? (
                        <>

                            {dataRender ?
                                <CardsChartWidget key={dataRender?.categories[0]} className='card-xl-stretch mb-xl-8'
                                                  data={dataRender}/> : !loading &&
                                <h3 className='text-center'>{intl.formatMessage({id: 'no_data_statistic'})}</h3>}

                        </>
                    ) : <div className='mb-5 mt-15 p-3' style={{minHeight: '200px'}}>
                        {!loading && <h3 className='text-center'>{intl.formatMessage({id: 'no_data_statistic'})}</h3>}
                    </div>}
                </div>

                <div className='d-flex align-items-center'>
                    <span className="fw-bold fs-1 me-8">Общая статистика по </span>
                    {
                        // params && params?.ppc_types.length > 0 && (
                        <Checkbox.Group style={{display: "flex"}} key={currentCard.join(',')} defaultValue={currentCard}
                                        onChange={(e: CheckboxValueType[]) => {
                                            //@ts-ignore
                                            setCurrentCard(e)
                                            onCardSelect({processingTypes: e})
                                        }}>
                            {
                                card_transactions.map((item: string, index: number) => {
                                    const icons: any = {
                                        'UZCARD': '/media/svg/uzcard.svg',
                                        'HUMO': '/media/svg/humo.svg',
                                        'VISA': '/media/svg/visa.svg',
                                        'MASTERCARD': '/media/svg/mastercard.svg'
                                    }
                                    return (
                                        <Checkbox value={item.toUpperCase()} key={index}>
                                            <div className='d-flex'>
                                                <div className='symbol-label bg-lighten'
                                                     style={{width: '25px', height: '25px'}}>
                                                    <img src={icons[item]} className='w-100 h-100'
                                                         style={{objectFit: 'contain'}}/>
                                                </div>
                                                <p className='mx-2 mb-0 fw-semibold'
                                                   style={{color: 'var(--kt-gray-600)'}}>{item[0].toUpperCase() + item.slice(1)}</p>
                                            </div>
                                        </Checkbox>
                                    )
                                })
                            }
                        </Checkbox.Group>
                        // )
                    }


                </div>
                <div className="d-flex justify-content-between row mt-5 gx-3 row g-5 g-xl-8">
                    <ChartMiniWidgetMixed
                        className='col-xl-3 col-lg-3 mb-xl-8' color='dark-blue' data={{
                        status: 'GENERAL',
                        sum: [`${sumAmount(statisticStatus.general) ? NumReplace(sumAmount(statisticStatus.general) + '') : 0} UZS`],
                        details: getCountStatuses({type: statisticStatus.general})
                    }}/>

                    <ChartMiniWidgetMixed className='col-xl-3 col-lg-3 mb-xl-8' color='success' data={{
                        status: APPROVED,
                        sum: [`${sumAmount(statisticStatus.approved) ? NumReplace(sumAmount(statisticStatus.approved) + '') : 0} UZS`],
                        details: getCountStatuses({type: statisticStatus.approved})
                    }}/>

                    {/*<ChartMiniWidgetMixed className='col-xl-3 col-lg-3 mb-xl-8' color='danger' data={{*/}
                    {/*    status: INIT,*/}
                    {/*    sum: [`${sumAmount('init') ? NumReplace(sumAmount('init') + '') : 0} UZS`],*/}
                    {/*    details: getCountStatuses('init')*/}
                    {/*}}/>*/}

                    <ChartMiniWidgetMixed className='col-xl-3 col-lg-3 mb-xl-8' color='warning' data={{
                        status: REVERT,
                        sum: [`${sumAmount(statisticStatus.revert) ? NumReplace(sumAmount(statisticStatus.revert) + '') : 0} UZS`],
                        details: getCountStatuses({type: statisticStatus.revert})
                    }}/>

                    <ChartMiniWidgetMixed className='col-xl-3 col-lg-3 mb-xl-8' color='danger' data={{
                        status: FAILED,
                        sum: [`${sumAmount(statisticStatus.failed) ? NumReplace(sumAmount(statisticStatus.failed) + '') : 0} UZS`],
                        details: getCountStatuses({type: statisticStatus.failed})
                    }}/>
                </div>

            </div>

        </Drawer>
    )
}


type ChartMiniWidgetMixedProps = {
    className: string
    color: string
    data: {
        status: string
        sum: string[]
        details: {
            card: string
            sum: string
            count: number
        }[]

    }
    children?: any
}

export const ChartMiniWidgetMixed: FC<ChartMiniWidgetMixedProps> = ({className, color, children, data}) => {
    const intl = useIntl()


    return <div className={`${className} `}>
        <div className={`card shadow`} style={{padding: "20px"}}>
            <div className={`bg-${color} w-100`} style={{
                height: "8px",
                position: "absolute",
                top: "0",
                left: "0",
                borderRadius: "12px 12px 0 0"
            }}/>
            <div className='card-body p-0 position-relative'>
                <div className='d-flex flex-stack'>
                    <h3 className='m-0 pt-1 fw-bold fs-4'
                        style={{color: "#464E5F",}}>{intl.formatMessage({id: data?.status})}</h3>
                </div>

                <div className={`pt-5 card-rounded w-100`}>
                    {
                        data.sum.map((item, index) => {
                            return (
                                <span key={index} className='fw-bold fs-3 pt-1'>{item}</span>
                            )
                        })
                    }

                </div>
                <div className={`card-rounded w-100`} style={{color: "#8B8D99"}}>
                    <span className='fw-bold fs-7 pt-1'>Кол-во: {data.details.reduce(
                        (previousValue, currentValue) => previousValue + currentValue.count, 0)}
                    </span>
                </div>
            </div>
        </div>
    </div>
}

export {ActivityDrawer}
