import {makeAutoObservable, toJS} from "mobx";

class AgentStore{
    agents:any[] = []
    sorting:any = {sortBy: "AGENT_INCOME", sortFromMax: true, page: 1, currentPage:1}
    general_statistics:any = {}
    general_statistics_page:any = {}
    agent_data:any = {}
    statistic_personal:any = []

    constructor() {
        makeAutoObservable(this)
    }

    setAgent(data:any){
        this.agents = data
    }
    setStatisticPersonal(data:any){
        this.statistic_personal = data
    }
    setStatisticGeneralPage(data:any){
        this.general_statistics_page = data
    }
    setAgentData(data:any){
        this.agent_data = data
    }
    setGeneralStatistics(data:any){
        this.general_statistics = data
    }

    setSorting(data:any){
        this.sorting = data
    }


    get getAgents(){
        return toJS(this.agents)
    }

    get getStatisticPersonal(){
        return toJS(this.statistic_personal)
    }
    get getStatisticGeneralPage(){
        return toJS(this.general_statistics_page)
    }
    get getAgentData(){
        return toJS(this.agent_data)
    }

    get getSorting(){
        return toJS(this.sorting)
    }
    get getGeneralStatistics(){
        return toJS(this.general_statistics)
    }


}



export const agentStore = new AgentStore()