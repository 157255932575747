/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {KTSVG} from '@metronic/helpers'
import {useIntl} from "react-intl";
import {Tooltip} from 'antd'
import {statuses_card, statuses_const} from "@utils/constants";
import '../../../../_metronic/assets/sass/style.scss';

type Props = {
    className: string
    color: string
    data: {
        title: string
        icons_title:string
        text_alert:string
        sum: string
        icons: string[]

    }
    tooltip?:string
    dataChartsRender: (filter:string[], showBar?:boolean)=>void
}

const WidgetMixedConnect: React.FC<Props> = ({className, color, data, dataChartsRender, tooltip}) => {

    const intl = useIntl()


    return (
        <div className={`card ${className}`}>
            {/* begin::Body */}
            <div className='card-body p-0 pb-5 d-flex flex-column justify-content-between'>
                <div className='position-relative'>
                    {/* begin::Header */}
                    <div className={`bg-${color} w-100`} style={{height:"8px",position:"absolute",top:"0",borderRadius:"12px 12px 0 0"}}></div>
                    <div className={`px-9 pt-7 card-rounded h-275px w-100`}>
                        {/* begin::Heading */}
                        <div className='d-flex flex-stack'>
                            <h3 className='m-0 fw-bold fs-3 d-flex justify-content-between w-100 align-items-center'>{data?.title}
                                {
                                    tooltip&&(<Tooltip  color='var(--kt-body-bg)'  placement="topLeft" title={()=><span style={{color: 'var(--kt-text-gray-600)'}}>{tooltip}</span>}>
                                        <i className="fa-sharp fa-solid fa-circle-info" style={{color: 'var(--kt-text-gray-600)'}}></i>
                                    </Tooltip>)
                                }
                            </h3>
                        </div>
                        {/* end::Heading */}
                        <div className='d-flex text-center flex-column pt-8'>
                            {
                                data.sum==='platforms'?(<><span className='fw-semobold fs-7'>{intl.formatMessage({id:'sub_title'})}</span>
                                    <div className='d-flex align-items-center justify-content-center mb-6 mt-3'>
                                        {
                                            data.icons.map(item=>{
                                                return(
                                                    <div key={item} className='symbol symbol-45px w-40px me-5'>
                                      <span className='symbol-label bg-lighten p-3'>
                                        <img className='w-100 h-100' style={{objectFit:'contain'}} src={item}/>
                                      </span>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div></>):data.sum==='platforms-admin'?(<>
                                    {
                                        data.icons.map(item=>{

                                            return(
                                                <div className='d-flex align-items-center mb-6' key={item}>
                                                    {/* begin::Symbol */}
                                                    <div className='symbol symbol-45px w-40px me-5'>
                                                  <span className='symbol-label bg-lighten p-3'>
                                                     <img src={item} className='w-100 h-100'
                                                          style={{objectFit: 'contain'}}/>
                                                  </span>
                                                    </div>
                                                    <div className='d-flex w-100'>
                                                        {/* begin::Title */}
                                                        <div className='mb-1 pe-3 text-start'>
                                                            <a href='#'
                                                               className='fs-7 text-gray-800 text-hover-primary fw-bold dash_count'>
                                                                0 UZS
                                                            </a>
                                                            <div className='text-gray-400 fw-semobold fs-7'
                                                                 style={{color: "#B5B5C3"}}>{item.match('laptop')?'Web':item.match('phone')?'Моб.приложение':'Telegram'}</div>
                                                        </div>

                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </>):(<>
                                    <span className='fw-semobold fs-7'>{intl.formatMessage({id:'amount_sum'})}</span>
                                    <span className='fw-bold fs-3 pt-1'>{data.sum}</span>
                                </>)
                            }
                        </div>
                    </div>

                    {data.sum==='platforms-admin'?'':(<div
                        className=' card-rounded mx-3 mb-9 px-2 py-6 position-relative z-index-1 bg-body'
                        style={{marginTop: '-100px'}}
                    >

                        {
                            data.sum==='platforms'?(<ul className='mb-10'>
                                <li className='text-gray-600'>{intl.formatMessage({id:'platform_list1'})}</li>
                                <li className='text-gray-600'>{intl.formatMessage({id:'platform_list2'})}</li>
                                <li className='text-gray-600'>{intl.formatMessage({id:'platform_list3'})}</li>
                                <li className='text-gray-600'>{intl.formatMessage({id:'platform_list4'})}</li>
                            </ul>):(<div className='d-flex align-items-center justify-content-center mb-6 flex-wrap'>
                                {
                                    data.icons.map(item=>{
                                        return(
                                            <div key={item} className='symbol symbol-45px w-40px me-5 mb-3'>
                                          <span className='symbol-label bg-lighten p-3'>
                                            <img className='w-100 h-100' style={{objectFit:'contain'}} src={item}/>
                                          </span>
                                            </div>
                                        )
                                    })
                                }
                            </div>)
                        }

                        <div className='fs-5 text-gray-500 text-center'>{data?.icons_title}</div>

                    </div>)}
                </div>

                <button className='btn-sm btn mx-auto d-block button_type fw-bold'  onClick={()=>{
                    dataChartsRender([data?.text_alert], false)
                }}>{intl.formatMessage({id:'view_all'})}</button>



            </div>
            {/* end::Body */}
        </div>
    )
}

export {WidgetMixedConnect}
