import {instantse} from "@providers/request";
import {AdminRequest, AdminResponse} from "@providers/admins/_models";
import {AxiosResponse} from "axios";

export const Admins = {
    getAdmins():Promise<AxiosResponse<AdminResponse[]>>{
        return instantse.get('/v1/account/admins')
            .then(result=>result)
    },
    getOneAdmin(id:number){
        return instantse.get(`/v1/gs/employee/${id}`)
            .then(result=>result)
    },
    createAdmin(data:AdminRequest){
        return instantse.post('/v1/gs/employee', data)
            .then(result=>result)
    },
    editAdmin(id:number, data:AdminRequest){
        return instantse.put(`/v1/gs/employee/${id}`, data)
            .then(result=>result)
    },
    deleteAdmin(id:number){
        return instantse.delete(`/v1/account/${id}`)
            .then(result=>result)
    }

}