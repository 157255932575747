import {Account} from "@providers/account/_request";
import React, {useEffect, useMemo, useState} from "react";
import {IAccountInfo} from "@providers/account/_models";
import {CompanyResponseOne} from "@providers/company/_models";
import {useErrors} from "@providers/context/ErrorsProvider";


type IProps = {
    companyData: CompanyResponseOne
}

export function RenderOwnerInfo(props: IProps) {
    const {companyData} = props
    const [ownerInfo, setOwnerInfo] = useState<IAccountInfo>()
    const {setError} = useErrors()

    useEffect(() => {
        if (companyData) {
            if (companyData.ownerId) {
                getOwnerInfo(companyData.ownerId)
            }
        }

    }, [companyData])

    const getOwnerInfo = (ownerId: number) => {
        Account.getAccountById(ownerId)
            .then(result => {
                setOwnerInfo(result.data.result)
            }).catch(err => {
            setError({status: err?.response?.status, message: err?.response?.data?.message, isError: true})
        })
    }

    if (ownerInfo) {
        return (
            <p className='mb-0 text-gray-800 fw-bold fs-5 text-break'>{`${ownerInfo.firstName} ${ownerInfo.lastName}`}</p>
        )
    }

    return <></>
}