import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserLastLoginCell} from './UserLastLoginCell'
import {UserTwoStepsCell} from './UserTwoStepsCell'
import {UserActionsCell} from './UserActionsCell'
import {TableCustomHeader} from './TableCustomHeader'
import {CompanyResponse, ICompany} from "@providers/company/_models";
import {TransactionsCompanyResp} from "@providers/adminTransactions/_models";
import moment from "moment";
import {TableText} from "./TableText";
import {NumReplace, ReplacePhone} from "@utils/index";
import {LastTransaction} from "./LastTransaction";
import {CompanyNameAdmin} from "./CompanyNameAdmin";
import {FilialResponse, ICashbox} from "@providers/filial/_models";
import {FilialNameAdmin} from "./FilialNameAdmin";
import {ActionsButtons} from "./ActionsButtons";
import {ChangeStatus} from "./ChangeStatus";
import {CustomField} from "./CustomField";




const companyColumnsOwner: ReadonlyArray<Column<CompanyResponse>> = [

  {
    Header: (props) => <TableCustomHeader tableProps={props} title='name' className='min-w-125px' />,
    id: 'name',
    Cell: ({...props}) => <UserInfoCell company={props.data[props.row.index]} />,
  },
 
  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='STATUS' className='min-w-125px' />
    ),
    id: 'status',
    Cell: ({...props}) => <UserLastLoginCell status={props.data[props.row.index].status} />,
  },
  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title='CREATED_DATE' className='min-w-125px' />
    ),
    id: 'created_date',
    Cell: ({...props}) => <UserTwoStepsCell created_date={props.data[props.row.index].created_date} />,
  },
  
  {
    Header: (props) => {
      return <TableCustomHeader tableProps={props} title='ACTIONS' className='text-end min-w-100px' />
    },
    id: 'actions',
    Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index].id} />,
  },
]

const companyColumns: ReadonlyArray<Column<ICompany>> = [

  {
    Header: (props) => <TableCustomHeader tableProps={props} title='company_name_table' className='min-w-50px' />,
    id: 'name',
    Cell: ({...props}) => <CompanyNameAdmin company={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='inn' className='min-w-125px' />
    ),
    id: 'inn',
    Cell: ({...props}) => <CustomField value={props.data[props.row.index].inn} />,
  },
  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='STATUS' className='min-w-125px' />
    ),
    id: 'status',
    Cell: ({...props}) => <UserLastLoginCell status={props.data[props.row.index].active ? 'ACTIVE' : "DISABLED"} />,
  },
  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='addressRegistry' className='min-w-125px' />
    ),
    id: 'addressRegistry',
    Cell: ({...props}) => <CustomField value={props.data[props.row.index].addressRegistry} />,
  },
]



const companyColumnsAdmin: ReadonlyArray<Column<ICompany>> = [


  {
    Header: (props) => <TableCustomHeader tableProps={props} title='company_name_table' className='min-w-50px' />,
    id: 'name',
    Cell: ({...props}) => <CompanyNameAdmin company={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='inn' className='min-w-125px' />
    ),
    id: 'inn',
    Cell: ({...props}) => <CustomField value={props.data[props.row.index].inn} />,
  },
  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='STATUS' className='min-w-125px' />
    ),
    id: 'status',
    Cell: ({...props}) => <UserLastLoginCell status={props.data[props.row.index].active ? 'ACTIVE' : "DISABLED"} />,
  },
  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='addressRegistry' className='min-w-125px' />
    ),
    id: 'addressRegistry',
    Cell: ({...props}) => <CustomField value={props.data[props.row.index].addressRegistry} />,
  },
]


const filialColumnsAdmin: ReadonlyArray<Column<ICashbox>> = [
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='№' className='min-w-50px' />,
    id: 'id',
    Cell: ({...props}) => <TableText text={props.data[props.row.index]?.id} />,
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='cashbox__name' className='min-w-100px' />,
    id: 'name',
    Cell: ({...props}) => <FilialNameAdmin filial={props.data[props.row.index]} />,
  },

  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='STATUS' className='min-w-125px' />
    ),
    id: 'status',
    Cell: ({...props}) => <UserLastLoginCell status={props.data[props.row.index].active ? 'ACTIVE' : 'DISABLED'} />,
  },
  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='' className='min-w-125px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ChangeStatus
        elemId={props.data[props.row.index].id}
        status={props.data[props.row.index].active}
        branch={true}

    />,
  },

]


const employeeColumnsAdmin: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='№' className='min-w-50px' />,
    id: 'id',
    Cell: ({...props}) => <TableText text={props.data[props.row.index]?.num} />,
  },

  {
    Header: (props) => <TableCustomHeader tableProps={props} title='phone' className='min-w-100px' />,
    id: 'name',
    Cell: ({...props}) => <TableText text={ReplacePhone(props.data[props.row.index]?.phone||'')} />,
  },

  {
    Header: (props) => (
        <TableCustomHeader tableProps={props} title='POSITION' className='min-w-125px' />
    ),
    id: 'position',
    Cell: ({...props}) => <TableText text={props.data[props.row.index]?.position} translate={true} />,
  },

  {
    Header: (props) => {
      return <TableCustomHeader tableProps={props} title='' className='text-end min-w-60px' />
    },
    id: 'actions',
    Cell: ({...props}) => <ActionsButtons Employeeid={props.data[props.row.index].id} />,
  },

]

export {companyColumns, companyColumnsOwner, companyColumnsAdmin, filialColumnsAdmin, employeeColumnsAdmin}
