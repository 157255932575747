
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserLastLoginCell} from './UserLastLoginCell'
import {UserTwoStepsCell} from './UserTwoStepsCell'
import {TableCustomHeader} from './TableCustomHeader'
import {FilialResponse, ICashbox} from "@providers/filial/_models";
import {useIntl} from "react-intl";

const usersColumns: ReadonlyArray<Column<ICashbox>> = [

  {
    Header: (props) => {
      const intl = useIntl()
      return <TableCustomHeader tableProps={props} title={intl.formatMessage({id:"cashbox__name"})} className='min-w-125px' />
    },
    id: 'name',
    Cell: ({...props}) => <UserInfoCell filial={props.data[props.row.index].name} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return <TableCustomHeader tableProps={props} title={intl.formatMessage({id:"STATUS"})} className='min-w-125px' />
    },
    id: 'status',
    Cell: ({...props}) => <UserLastLoginCell status={props.data[props.row.index].status} />,
  },
  {
    Header: (props) => {
      const intl = useIntl()
      return <TableCustomHeader tableProps={props} title={intl.formatMessage({id:"CREATED_DATE"})} className='min-w-125px' />
    },
    id: 'created_date',
    Cell: ({...props}) => <UserTwoStepsCell data={props.data[props.row.index].created_date} />,
  }
]

export {usersColumns}
