import React, {FC} from 'react'
import {useIntl} from "react-intl";
import {KTSVG} from "@metronic/helpers";

type Props = {
  status: string
}



const classStatus = {
  DRAFT: 'secondary',
  ACTIVE: 'light-success',
  DISABLED: 'light-danger',
}

const iconsStatus = {
  DRAFT: "/media/svg/revert.svg",
  ACTIVE: "/media/svg/approved.svg",
  DISABLED: "/media/svg/failed.svg",
}

const UserLastLoginCell: FC<Props> = ({status}) => {

  const intl = useIntl()

  return (
      //@ts-ignore
      <div className={`badge badge-${classStatus[status]} fw-bolder`} style={{fontSize:'10px'}}>
        {/*@ts-ignore*/}
        {status?(<><KTSVG path={iconsStatus[status]} className={`svg-icon-${classStatus[status]} svg-icon-sm svg-icon-2hx me-2`} />
          {status.length?intl.formatMessage({id:status}):''}</>):''}
      </div>
  )
}

export {UserLastLoginCell}
