import {ISuccess, IWebPaymentResponse, IWebPaymentSend, IWebPaymentsFilters} from "@providers/webPayments/models";
import {instantse} from "@providers/request";
import {AxiosResponse} from "axios";


export function createWebPayment(data: IWebPaymentSend): Promise<AxiosResponse<{ result: IWebPaymentSend }>> {
    return instantse.post(`/v1/user-service-tokens`, data)
}


export function deleteWebPayment(id: number): Promise<AxiosResponse<ISuccess>> {
    return instantse.delete(`/v1/user-service-tokens/${id}`)
}


export function getWebPayments(filters: IWebPaymentsFilters): Promise<AxiosResponse<IWebPaymentResponse>> {
    const params = new URLSearchParams()

    params.set('page', filters.page.toString())
    params.set('size', filters.size.toString())
    params.set('cashBoxId', filters.cashBoxId)
    if (filters.companyId) {
        params.set('companyId', filters.companyId)
    }
    return instantse.post('/v1/user-service-tokens/find', undefined, {
        params
    })
}