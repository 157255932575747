import React, {PropsWithChildren} from 'react';
import {HeaderProps} from "react-table";
import {IWebPaymentSend} from "@providers/webPayments/models";
import clsx from "clsx";
import {useIntl} from "react-intl";

interface props {
    tableProps: PropsWithChildren<HeaderProps<IWebPaymentSend>>
    className?: string
    title: string
}

const TableCustomHeader = ({tableProps, className, title}: props) => {
    const intl = useIntl()
    return (
        <th
            {...tableProps.column.getHeaderProps()}
            className={clsx(
                className,
            )}
            style={{cursor: 'pointer'}}
        >
            {title.length ? intl.formatMessage({id: title}) : ''}
        </th>
    );
};

export default TableCustomHeader;