import React, {FC, useEffect, useMemo, useState} from 'react'
import {Field, ErrorMessage, FormikProps} from 'formik'
import {CreateCompany} from "@providers/company/_models";
import AsyncSelect from 'react-select/async';
import {customStyles} from "@metronic/assets/ts/select.style";
import {useIntl} from "react-intl";
import {NumReplace} from "@utils/index";
import {contractColumnsGet} from "../../../../contractsList/_modals/columns/_columns";
import {ColumnInstance, Row, useTable} from "react-table";
import {grafikColumns} from "../../../_modals/columns/_columns";
import {ModSession} from "@providers/session/_modals";
import {CustomHeaderColumn} from "../../../../contractsList/_modals/columns/CustomHeaderColumn";
import {CustomRow} from "../../../../contractsList/_modals/columns/CustomRow";
import moment from 'moment';
import InputMask from "react-input-mask";
import {Schedule} from "@providers/autopay/models/_models";

interface IProps extends FormikProps<CreateCompany> {
  handleChange(arg: any): void,
    values: any,
    schedules: {
        schedules:Schedule[],
        totalPrice: number,
        last_payment_date: string
    },
  setFieldValue(name:string, val:any):void

}



const Step4: FC<IProps>  = (props) => {

    const [fields, setFields] = useState<any[]>([])
    const [fieldsChild, setFieldsChild] = useState<any[]>([])
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(null)
    const intl = useIntl()
    const data = useMemo(()=>props.schedules.schedules.map((item:any, index:number)=>{
        return{
            num: index+1,
            ...item
        }
    })||[], [props.schedules])
    const columns = useMemo(() => grafikColumns, [props.schedules])
    const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
        columns,
        data,
    })


    useEffect(()=>{
        const phones = []
        setSelectedPhoneNumber(props.values.phoneNumber)
        phones.push(props.values.phoneNumber)
        if(props.values.phoneNumber_second)  phones.push(props.values.phoneNumber_second)

        setFields(phones.map((item:string)=>{
            return {
                value: item,
                label: item
            }
        }))
    }, [props.schedules])




    const filterFields = (inputValue: string, values:any[]) => {
        return values.filter((i:any) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    }




    const loadOptionsFields = (
        inputValue: string,
        callback: (options: any[]) => void
    ) => {callback(filterFields(inputValue, fields))}

    const loadOptionsFieldsChild = (
        inputValue: string,
        callback: (options: any[]) => void
    ) => {callback(filterFields(inputValue, fieldsChild))}

  return (
    <div className='w-100'>

        <div className='mt-5'>
            <div className='d-flex justify-content-between mb-5'>
                <p className='text-gray-600 mb-0 fs-5'>{intl.formatMessage({id: 'price_credit'})}</p>
                <p className='text-gray-900 mb-0 fw-bold fs-3'>{NumReplace(props.values.productPrice)} Сум</p>
            </div>
            <div className='d-flex justify-content-between'>
                <p className='text-gray-600 mb-0 fs-5'>{intl.formatMessage({id: 'date_final_contract'})}</p>
                <p className='text-gray-900 mb-0 fw-bold fs-3'>{moment(props.schedules?.last_payment_date).format('DD.MM.YYYY')}</p>
            </div>

        </div>


        <div className='mt-10'>
            <table
                id='kt_table_users'
                className='table fw-normal align-middle table-row-bordered fs-6 gy-5 dataTable no-footer table-hover cursor-pointer'
                {...getTableProps()}
            >
                <thead>
                    <tr className='text-start text-muted fw-semibold fs-7 text-uppercase gs-0'>
                        {headers.map((column: ColumnInstance<ModSession>) => {

                                return (
                                    <CustomHeaderColumn key={column.id} column={column}/>
                                )
                            }
                        )}
                    </tr>
                </thead>
                <tbody className='text-gray-600 fw-normal' {...getTableBodyProps()}>
                {rows.length > 0 ? (
                    rows.map((row: Row<ModSession>, i) => {
                        prepareRow(row)
                        return <CustomRow row={row} key={`row-${i}-${row.id}`}/>
                    })
                ) : (
                    <tr>
                        <td colSpan={7}>
                            <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                {intl.formatMessage({id: 'NO_MATCHING'})}
                            </div>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>

            <div className='d-flex justify-content-between mt-5'>
                <p className='text-gray-600 mb-0 fs-5'>{intl.formatMessage({id:'date_start_credit'})}</p>
                <p className='mb-0 fs-3 text-gray-900 fw-bold'>{moment().format('DD.MM.YYYY')}</p>

            </div>
        </div>


        <div className="pb-14 mt-10">
            <h1 className="fw-bold text-dark">{intl.formatMessage({id: 'step4_contract_form'})}</h1>
        </div>


      <div className='fv-row mb-8'>
        <label className='form-label required'>{intl.formatMessage({id:'select_phone_number_contract'})}</label>
        <Field
          component={AsyncSelect}
          styles={customStyles}
          defaultOptions={fields}
          loadOptions={loadOptionsFields}
          cacheOptions
          value={fields.filter(item=>item.value===selectedPhoneNumber)}
          placeholder={intl.formatMessage({id:"select"})}
          onChange={(e:any)=>{
              setSelectedPhoneNumber(e.value)
          }}
        />

      </div>

    </div>
  )
}

export {Step4}
