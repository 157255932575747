import React from 'react';
import {KTSVG} from "@metronic/helpers";
import {copyToClipboard} from "@utils/onCopy/onCopy";
import {filial} from "@store/filial";

interface props {
    token: string
}

const Token = ({token}: props) => {
    const {style} = filial.getFilialOne

    const currentTheme = style.find(({name}) => name === 'theme')?.value

    const url: string = `${process.env.REACT_APP_CHECKOUT_URL}?token=${token}&theme=${currentTheme ?? 'light'}`
    const onCopy = () => copyToClipboard(url)

    return (
        <span className={'d-flex align-items-center'}>
           <span onClick={onCopy} className={'d-block py-2 px-3 me-3 rounded bg-light cursor-pointer'}>
               <KTSVG path={'/media/icons/copy.svg'}/>
           </span>
            <a href={url} target={'_blank'} rel={'noreferrer'}>
                {url}
            </a>
        </span>
    );
};

export default Token;