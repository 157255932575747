
import clsx from 'clsx'
import {FC} from 'react'
import {Row} from 'react-table'
import {CompanyResponse} from "@providers/company/_models";

type Props = {
  row: Row<any>
}

const fixTables_date:any= {
  num: 50,
  contractNumber: 125,
  phoneNumber: 150,
  productPrice: 125,
  status: 185,
  contractDate: 125,
}



const keys = Object.keys(fixTables_date)



const CustomRow: FC<Props> = ({row}) => {

  const columns = row.cells.map((item:any)=>item.column.id)
  const fix_column:any = {}
  let data_with = 0
  const nums:any = []

  columns.forEach((item:any)=>{
    if(fixTables_date[item]) {
      fix_column[item] = data_with
      nums.push(data_with)
      data_with+= fixTables_date[item]

    }
  })

  const last_td = nums.sort((a:number, b:number)=>a-b)[nums.length-1]


  return (
      <tr {...row.getRowProps()}>
        {row.cells.map((cell) => {
          return (
              <td
                  {...cell.getCellProps()}
                  className={`${clsx({'text-end min-w-100px': cell.column.id === 'actions'})} ${fix_column[cell.column.id]===last_td?'last-td':''}`}
                  style={keys.includes(cell.column.id)?{position:'sticky', backgroundColor: 'var(--kt-card-bg)', left: fix_column[cell.column.id]||0}:{}}
              >
                {cell.render('Cell')}
              </td>
          )
        })}
      </tr>
  )
}

export {CustomRow}
