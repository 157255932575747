import {notification} from "antd";

export const openNotificationSuccess = (text: string) => {
    notification.success({
        message: text,
        placement: 'topRight',
        duration: 5
    });
};

export const openNotificationError = (text: string) => {
    notification.error({
        message: text,
        placement: 'topRight',
        duration: 5,
        style: {
            zIndex: 10000
        }
    });
};