import * as Yup from 'yup'
import {REGEXP_PHONE} from "@utils/constants";
import {useIntl} from "react-intl";
import {ICreateContractSchema} from "@providers/autopay/_interfaces";
import moment from "moment";


Yup.addMethod(Yup.string, "overdueLimitMinMax", function (errorMessage) {
    return this.test(`test-limit-length`, errorMessage, function (value) {
        const { path, createError } = this;

        return (
            (value && (+value>=2 && +value <= 36)) ||
            createError({ path, message: errorMessage })
        );
    });
});


function ContractShema() {
    const intl = useIntl()
    let createContractSchemas:any[]=[]

    createContractSchemas =  [
        Yup.object({
            pinfl: Yup.string()
                .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
                .min(14, 'Не достаточно символов'),
            phoneNumber: Yup.string().matches(
                REGEXP_PHONE,
                intl.formatMessage({id: 'ERROR_VALIDATION_PHONE'})
            ).required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
            phoneNumber_second: Yup.string().matches(
                REGEXP_PHONE,
                intl.formatMessage({id: 'ERROR_VALIDATION_PHONE'})
            ),
            passportSeries: Yup.string()
                .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
                .min(2, 'Не достаточно символов'),
            passportNumber: Yup.string()
                .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
                .min(7, 'Не достаточно символов'),
            firstName: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
                .max(50, 'Слишком много символов').required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
            lastName: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
                .max(50, 'Слишком много символов').required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
            middleName: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
                .max(50, 'Слишком много символов').required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
            issuedBy: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
                .max(50, 'Слишком много символов').required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),

        }),
        Yup.object({
            contractNumber: Yup.string().max(50, 'Слишком много символов').required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
            // contractDate: Yup.date().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
            productName: Yup.string()
                .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
                .max(50, 'Слишком много символов'),
            serviceId: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),
            ikpu: Yup.string()
                .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
                .max(50, 'Слишком много символов'),



        }),
        Yup.object({
            productPrice: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
                .max(50, 'Не достаточно символов'),
            prepaymentPrice: Yup.string()
                .max(50, 'Не достаточно символов'),
            discountPrice: Yup.string()
                .max(50, 'Не достаточно символов'),
            overdueLimit: Yup.string()
                .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),

            schedules_percent: Yup.string()
                .max(50, 'Не достаточно символов'),
            scheduleDays: Yup.string().required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'}))
                //@ts-ignore
                .overdueLimitMinMax('Введите значение от 2 - 36 месяцев'),
            scheduleDate_first: Yup.date().min(moment().add(1, 'days').format('YYYY-MM-DD'), intl.formatMessage({id: 'max_date'}))
                .required(intl.formatMessage({id: 'ERROR_VALIDATION_REQUIRED'})),


        })

    ]


    return createContractSchemas
}


const inits: ICreateContractSchema = {
    "pinfl": '',
    "phoneNumber": '',
    "phoneNumber_second": '',
    "passportSeries": '',
    "passportNumber": '',
    "firstName": '',
    "lastName": '',
    "middleName": '',
    "issuedBy": '',
    "contractNumber": '',
    "productName": '',
    "serviceId": '',
    "productPrice": '',
    "prepaymentPrice": '',
    "discountPrice": '',
    "overdueLimit": '',
    "schedules_percent": '',
    "scheduleDays": '',
    "scheduleDate_first": '',
    "scheduleDate_second": ''
};




export {ContractShema, inits}
