import {makeAutoObservable, toJS} from "mobx";

class SessionStore {
    session = []

    constructor() {
        makeAutoObservable(this)
    }

    setSession(data){
        this.session = data
    }


    get getSession(){
        return toJS(this.session)
    }


}



export const sessionStore = new SessionStore()