import {makeAutoObservable, toJS} from "mobx";

class Admins{
    admins = []

    constructor() {
        makeAutoObservable(this)
    }

    setAdmins(data){
        this.admins = data
    }

    get getAdmins(){
        return toJS(this.admins)
    }
}



export const admins = new Admins()