import {useEffect, useMemo, useState} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'


import {usersColumns} from './columns/_columns'
import {KTCardBody} from '@metronic/helpers'
import {observer} from "mobx-react";
import {sessionStore} from "@store/session";
import {Session} from "@providers/session/_request";
import {ModSession} from "@providers/session/_modals";
import {useErrors} from "@providers/context/ErrorsProvider";
import {useIntl} from "react-intl";

const Table = observer(() => {

    const [isLoading, setIsLoading] = useState(true)
    const data = useMemo(() => sessionStore.getSession, [sessionStore.getSession])
    const columns = useMemo(() => usersColumns, [sessionStore.getSession])
    const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
        columns,
        data,
    })
    const {setError} = useErrors()
    const intl = useIntl()



    useEffect(()=>{
        getSession()
    }, [])

    const getSession = ()=>{
        Session.getSession()
            .then(result=>{
                setIsLoading(false)
                sessionStore.setSession(result?.data?.result??[])
            })
            .catch(err=>{
                setError({status:err?.response?.status, message: err?.response?.data?.message, isError: true})
            })
    }

    return (
        <KTCardBody className='py-4'>
            <div className='table-responsive'>
                <table
                    id='kt_table_users'
                    className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                    {...getTableProps()}
                >
                    <thead>
                    <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                        {headers.map((column: ColumnInstance<ModSession>) => (
                            <CustomHeaderColumn key={column.id} column={column} />
                        ))}
                    </tr>
                    </thead>
                    <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                    {rows.length > 0 ? (
                        rows.map((row: Row<ModSession>, i) => {
                            prepareRow(row)
                            return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                        })
                    ) : (
                        <tr>
                            <td colSpan={7}>
                                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                    {intl.formatMessage({id:'NO_MATCHING'})}
                                </div>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>

            {isLoading && ''}
        </KTCardBody>
    )
})

export {Table}
